import Stack from "../../../../../ui/stack/Stack.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import { Control, Controller } from "react-hook-form";
import { Switch } from "../../../../../ui/switch/Switch.tsx";
import { PermissionType } from "../../../../../types/role.types.ts";
import { EditRoleSchema, PermissionsSection } from "../role-edit.types.ts";
import { Title } from "../../../../../components/title/Title.tsx";

export interface RoleEditPermissionsProps {
  control: Control<EditRoleSchema>;
  section: PermissionsSection;
}

export const RoleEditPermissions = ({ control, section }: RoleEditPermissionsProps) => {
  return (
    <>
      {section.groups.map((group, groupIndex) => (
        <Box key={`section-group-${groupIndex}`}>
          <Title header={group.title} size={"xs"} paddingBottom paddingTop />

          <Stack direction={"vertical"} gap={"md"}>
            {group.items.map((item, itemIndex) => (
              <Stack
                direction={"horizontal"}
                className={"items-center"}
                gap={"sm"}
                key={`section-group-${groupIndex}-item-${itemIndex}`}
              >
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Switch
                      checked={value == PermissionType.edit}
                      onChange={(newValue) => {
                        onChange(newValue ? PermissionType.edit : PermissionType.none);
                      }}
                      size={"sm"}
                      title={item.title}
                      description={item.description}
                    />
                  )}
                  control={control}
                  name={item.field}
                />
              </Stack>
            ))}
          </Stack>
        </Box>
      ))}
    </>
  );
};
