import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { EmailPlaceholder } from "./rich-editor.types";

export const VariableHighlight = (variables?: string[]) => {
  return Extension.create({
    name: "variableHighlight",

    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey("variableHighlight"),
          props: {
            decorations: (state) => {
              const { doc } = state;
              const decorations: Decoration[] = [];
              const placeholderValues = Object.values(EmailPlaceholder);

              const placeholderRegex = new RegExp(
                [...(variables ?? []), ...placeholderValues].join("|").replace(/[[\]]/g, "\\$&"),
                "g"
              );

              doc.descendants((node, pos) => {
                if (node.isText) {
                  let match;
                  while ((match = placeholderRegex.exec(node.text ?? "")) !== null) {
                    const start = pos + match.index;
                    const end = start + match[0].length;
                    decorations.push(
                      Decoration.inline(start, end, {
                        class: "variable-highlight",
                      })
                    );
                  }
                }
              });

              return DecorationSet.create(doc, decorations);
            },
          },
        }),
      ];
    },
  });
};
