import { useCallback, useMemo, useState } from "react";
import Table from "../../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../../components/empty-state/EmptyState.tsx";
import { PaginationBlock } from "../../../../../ui/pagination/PaginationBlock.tsx";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Text from "../../../../../ui/typography/Text.tsx";
import { useTranslation } from "react-i18next";
import Box from "../../../../../ui/box/Box.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import {
  useTimeOffAccruals,
  useTimeOffDeleteAdjustment,
} from "../../../../../queries/use-time-offs.query.ts";
import {
  ApiTimeOffAccrualsFilter,
  ApiTimeOffAdjustmentPayload,
  ApiTimeOffLog,
  ApiTimeOffLogType,
  getMockTimeOffAdjustment,
} from "../../../../../types/time-off.types.ts";
import Avatar from "../../../../../ui/avatar/Avatar.tsx";
import useFormatter from "../../../../../hooks/use-formatter.hook.ts";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../../../services/analytics-service.ts";
import { useEmployeeStore } from "../../../../../stores/employee.store.ts";
import useModals from "../../../../../ui/modal/modal.store.ts";
import { PermissionType } from "../../../../../types/role.types.ts";
import { EmployeeEditTimeOffBalanceModal } from "../../../../employees/employee/widgets/EmployeeEditTimeOffBalanceModal.tsx";
import DropdownMenu from "../../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../../ui/typography/FontAwesomeIcon.tsx";
import { ConfirmationPopup } from "../../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import { EmployeeInlineCardFull } from "../../../../../components/employee-inline-card/EmployeeInlineCardFull.tsx";

export function TimeOffTabHistory() {
  const [filter, setFilter] = useState<ApiTimeOffAccrualsFilter>({
    page_number: 1,
  });
  const { t } = useTranslation();
  const { renderDate, renderEmployeeName } = useFormatter();
  const { employee: employeeMe } = useEmployeeStore();
  const modalsService = useModals();

  const { data: requests, isLoading, isFetching } = useTimeOffAccruals(filter);

  const deleteAdjustmentMutation = useTimeOffDeleteAdjustment();

  const canManageBalance =
    employeeMe && employeeMe.permissions.manage_leave_policies === PermissionType.edit;

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.timeOffs.historyViewed);
  });

  const handleEditOrCreate = useCallback(
    (adjustment?: ApiTimeOffLog) => {
      if (!employeeMe) return;

      let adjustmentPayload: ApiTimeOffAdjustmentPayload;

      if (adjustment) {
        adjustmentPayload = {
          balance: parseFloat(adjustment.balance),
          date_expire: adjustment.date_expire,
          category_id: adjustment.category.id,
          employee_id: adjustment.employee.id,
          date_adjustment: adjustment.date_adjustment,
        };
      } else {
        adjustmentPayload = getMockTimeOffAdjustment(employeeMe);
      }

      modalsService.openModal(EmployeeEditTimeOffBalanceModal, {
        adjustment: adjustmentPayload,
        adjustmentId: adjustment?.id,
      });
    },
    [employeeMe]
  );

  const handleDeleteAction = useCallback((adjustment: ApiTimeOffLog) => {
    if (!adjustment.id) return;

    modalsService.openModal(ConfirmationPopup, {
      question: t("time_off:delete_adjustment_confirm"),
      text: t("time_off:delete_adjustment_confirm_description"),
      acceptTitle: t("common:delete"),
      onAccept: async () => {
        try {
          await deleteAdjustmentMutation.mutateAsync(adjustment.id);
        } catch {
          return;
        }
      },
    });
  }, []);

  const columnHelper = createColumnHelper<ApiTimeOffLog>();
  const columns = useMemo<ColumnDef<ApiTimeOffLog>[]>(
    () => [
      {
        header: t("core:employee"),
        cell: (row) => <EmployeeInlineCardFull employee={row.row.original.employee} />,
        accessorKey: "employee",
      },
      {
        header: t("common:date"),
        cell: (row) => (
          <Text className={"text-secondary"}>{renderDate(row.row.original.date_adjustment)}</Text>
        ),
        accessorKey: "date_adjustment",
      },
      {
        header: t("time_off:category"),
        cell: (row) => (
          <Stack direction={"horizontal"} gap={"md"} items={"center"}>
            <Avatar emoji={row.row.original.category.emoji} />
            <Stack>
              <Text className={"text-base text-title"}>{row.row.original.category.title}</Text>
              <Text className={"text-secondary"}>
                {t(`time_off:accrual_action_${row.row.original.action}`)}
              </Text>
            </Stack>
          </Stack>
        ),
        accessorKey: "category",
      },
      {
        header: t("time_off:balance"),
        cell: (row) => (
          <Stack>
            <Text className={"text-secondary"}>
              {parseFloat(row.row.original.balance) > 0 ? "+" : ""}
              {parseFloat(row.row.original.balance)}
            </Text>

            {row.row.original.created_by &&
              row.row.original.created_by.id != row.row.original.employee.id && (
                <Text
                  className={"text-secondary"}
                >{`${t("common:created_by")} ${renderEmployeeName(row.row.original.created_by)}`}</Text>
              )}
          </Stack>
        ),
        accessorKey: "balance",
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"items-end"}>
            {canManageBalance && cell.row.original.action == ApiTimeOffLogType.adjustment && (
              <DropdownMenu>
                <Button
                  onClick={() => handleEditOrCreate(cell.row.original)}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                  variant={"menu"}
                >
                  {t("common:edit")}
                </Button>
                <Button
                  onClick={() => handleDeleteAction(cell.row.original)}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                  variant={"menuDanger"}
                  isLoading={deleteAdjustmentMutation.isPending}
                >
                  {t("common:delete")}
                </Button>
              </DropdownMenu>
            )}
          </Stack>
        ),
        size: 70,
      }),
    ],
    []
  );

  return (
    <Box className={"pt-6"}>
      <Table
        data={requests?.items || []}
        columns={columns}
        showSkeleton={isLoading}
        isFetching={isFetching}
        showNotFoundError={requests?.metadata.total_items == 0}
        notFoundComponent={<EmptyStateNothingFound />}
        emptyStateComponent={<EmptyState />}
      />
      {(requests?.metadata.total_pages ?? 0) > 1 && (
        <PaginationBlock
          metadata={requests?.metadata}
          onPageChange={(page) => {
            setFilter({ ...filter, page_number: page });
          }}
        />
      )}
    </Box>
  );
}
