import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { employeesKeys } from "./use-employees.query.ts";
import { apiEmployeeEmploymentStatus } from "../../api/employees/employee-employment-status.api.ts";
import { ApiEmployeeEmploymentStatusSave } from "../../types/employees/employee-employment-status.types.ts";

export const employeeEmploymentStatusKey = createQueryKeys("employeeEmploymentStatus", {
  search: (employeeId: number, pageNumber: number) => ({
    queryKey: [employeeId, pageNumber],
    queryFn: async () => {
      const result = await apiEmployeeEmploymentStatus.search(employeeId, pageNumber);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useEmployeeEmploymentStatusSearch = (employeeId: number, page: number) => {
  return useQuery({
    ...employeeEmploymentStatusKey.search(employeeId, page),
  });
};

export interface SaveParams {
  employeeId: number;
  positionId: number;
  payload: ApiEmployeeEmploymentStatusSave;
}

export const useEmployeeEmploymentStatusSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ employeeId, positionId, payload }: SaveParams) => {
      const result =
        positionId == 0
          ? await apiEmployeeEmploymentStatus.create(employeeId, payload)
          : await apiEmployeeEmploymentStatus.update(employeeId, positionId, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        // todo обнулять только нужного сотрудника
        queryKey: employeeEmploymentStatusKey.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: employeesKeys.detail(variables.employeeId).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useEmployeeEmploymentStatusDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ employeeId, positionId }: { employeeId: number; positionId: number }) => {
      const result = await apiEmployeeEmploymentStatus.delete(employeeId, positionId);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: employeeEmploymentStatusKey.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: employeesKeys.detail(variables.employeeId).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
