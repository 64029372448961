import { useCallback, useMemo } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { AnalyticReportTypes } from "../../../types/analytics/analytics.types.ts";
import { PreviewCandidatesModal } from "./widgets/PreviewCandidatesModal.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { useSearchParams } from "react-router-dom";
import {
  ApiAnalyticsAggregationDate,
  ApiAnalyticsGroupBy,
  ApiAnalyticsRecruitingFilter,
} from "../../../types/analytics/analytics.recruiting.types.ts";
import { parseUrlHelper } from "../../../helpers/parse-url.helper.ts";
import useDatePeriods from "../../../hooks/use-date-periods.hook.ts";

export const useRecruitingReports = (reportType: AnalyticReportTypes | undefined = undefined) => {
  const modalsService = useModals();
  const [searchParams] = useSearchParams();
  const { getAvailableOptions } = useDatePeriods();

  const handlePreviewApplicants = useCallback(
    (applicantIds: number[], reportType: AnalyticReportTypes) => {
      analyticsService.trackEvent(analyticEvents.reporting.dataViewed, {
        [analyticProperties.title]: reportType,
      });

      modalsService.openModal(PreviewCandidatesModal, {
        applicantIds: applicantIds,
      });
    },
    []
  );

  const filter = useMemo(() => {
    const defaultAggregationDate =
      reportType && reportType == AnalyticReportTypes.recruitingStageFlow
        ? ApiAnalyticsAggregationDate.week
        : ApiAnalyticsAggregationDate.month;
    const aggregationDateMode = (searchParams.get("aggregation_date_mode") ||
      defaultAggregationDate) as ApiAnalyticsAggregationDate;

    const options = getAvailableOptions(aggregationDateMode);
    const period = searchParams.get("period");
    let dateStart: Date;
    let dateEnd: Date;
    if (period) {
      dateStart = options.find((x) => x.key == period)?.start || options[0].start;
      dateEnd = options.find((x) => x.key == period)?.end || options[0].end;
    } else {
      dateStart = parseUrlHelper.getDate(searchParams, "date_start") || options[1].start;
      dateEnd = parseUrlHelper.getDate(searchParams, "date_end") || options[1].end;
    }
    const recruiter_ids = parseUrlHelper.getNumberArray(searchParams, "recruiter_ids");
    const job_ids = parseUrlHelper.getNumberArray(searchParams, "job_ids");
    const group_by = (searchParams.get("group_by") ||
      ApiAnalyticsGroupBy.none) as ApiAnalyticsGroupBy;

    return {
      aggregation_date_mode: aggregationDateMode,
      date_start: dateStart,
      date_end: dateEnd,
      group_by: group_by != ApiAnalyticsGroupBy.none ? group_by : null,
      recruiter_ids: recruiter_ids,
      job_ids: job_ids,
    } as ApiAnalyticsRecruitingFilter;
  }, [searchParams]);

  return {
    handlePreviewApplicants,
    filter,
  };
};
