import {
  ApiEmployeeChartItem,
  EmployeeNodeMode,
} from "../pages/employees/chart/employees-chart.types.ts";
import { apiEmployees } from "../api";

export default function UseEmployeesChartHook() {
  const loadEmployees = async (
    employeeId: number | undefined,
    mode: EmployeeNodeMode = EmployeeNodeMode.children
  ) => {
    const { data: employees } = await apiEmployees.chart(employeeId, mode);

    return employees;
  };

  const loadEmployee = async (employeeId: number | string) => {
    const { data: employee } = await apiEmployees.getById(employeeId);

    return employee;
  };

  const loadEmployeesChartByNodeId = async (
    nodeId: string | undefined,
    mode: EmployeeNodeMode = EmployeeNodeMode.children
  ): Promise<ApiEmployeeChartItem[]> => {
    if (!nodeId) return [];
    if (nodeId == "root") return [];

    const id = Number(nodeId);
    const employees = await loadEmployees(id, mode);

    return employees ?? [];
  };

  return {
    loadEmployees,
    loadEmployee,
    loadEmployeesChartByNodeId,
  };
}
