import { ApiDepartment } from "../../../types/department.types.ts";
import { ExpandableRow } from "../../../ui/table/table.types.ts";

function getChildrenNodes(allData: ApiDepartment[], parent_id: number | null) {
  const result: ExpandableRow<ApiDepartment>[] = [];
  const currentLayer = allData.filter((x) => x.parent_id == parent_id);
  for (let i = 0; i < currentLayer.length; i++) {
    const department = currentLayer[i];
    const children = getChildrenNodes(allData, department.id);
    result.push({
      item: department,
      subRows: children,
    });
  }

  return result;
}

export function buildTree(departments: ApiDepartment[]): ExpandableRow<ApiDepartment>[] {
  const result: ExpandableRow<ApiDepartment>[] = getChildrenNodes(departments, null);
  return result;
}
