import { api } from "./axios.ts";
import { ApiRole, ApiRoleSavePayload, ApiRoleShort } from "../types/role.types.ts";

export const apiRole = {
  getAll: async function () {
    return api.get<ApiRoleShort[]>("/api/v1/roles");
  },
  getById: async function (id: number) {
    return api.get<ApiRole>(`/api/v1/roles/${id}`);
  },
  create: async function (payload: ApiRoleSavePayload) {
    return api.post<ApiRole>("/api/v1/roles", payload);
  },
  update: async function (id: number, payload: ApiRoleSavePayload) {
    return api.put<ApiRole>(`/api/v1/roles/${id}`, payload);
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/roles/${id}`);
  },
};
