import { api } from "../axios";
import {
  ApiCandidateDisqualificationReason,
  ApiCandidateDisqualificationReasonPayload,
} from "../../types/recruit/disqualification-reason.types";

export const apiDisqualifications = {
  getAll: () => {
    return api.get<ApiCandidateDisqualificationReason[]>(
      "/api/v1/candidate-disqualification-reasons"
    );
  },
  create: (payload: ApiCandidateDisqualificationReasonPayload) => {
    return api.post<ApiCandidateDisqualificationReason>(
      "/api/v1/candidate-disqualification-reasons",
      payload
    );
  },
  update: (id: number, payload: ApiCandidateDisqualificationReasonPayload) => {
    return api.put<ApiCandidateDisqualificationReason>(
      `/api/v1/candidate-disqualification-reasons/${id}`,
      payload
    );
  },
  delete: (id: number) => {
    return api.delete(`/api/v1/candidate-disqualification-reasons/${id}`);
  },
};
