import { format, parse } from "date-fns";

export const parseUrlHelper = {
  getNumberArray: (searchParams: URLSearchParams, key: string): number[] => {
    return searchParams.get(key)
      ? decodeURIComponent(searchParams.get(key) as string)
          .split(",")
          .map((id) => parseInt(id))
      : [];
  },
  getDate: (searchParams: URLSearchParams, key: string): Date | undefined => {
    const value = searchParams.get(key);
    return value ? parse(value, "yyyy-MM-dd", new Date()) : undefined;
  },
  formatDate: (date: Date): string => {
    return format(date, "yyyy-MM-dd");
  },
};
