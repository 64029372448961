import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";

export type SelectValueType = number | string | undefined | null;

export interface SelectItem {
  id: number | string;
  title: string;
}

export interface SelectItemExtended extends SelectItem {
  caption?: string;
  avatarUrl?: string;
  emoji?: string;
}

export interface SelectSizes extends Pick<ThemeSizes, "md"> {
  [key: string]: string;
}

export interface UIKitSelectTheme extends UIKitThemeBase {
  selectedCount: string;
  readonly: string;
  disabled: string;
  hover: string;
  value: {
    base: string;
    readonly: string;
    disabled: string;
    hover: string;
  };
  pointer: {
    base: string;
    disabled: string;
  };
  variants: {
    variant: {
      default: string;
      plain: string;
    };
    size: SelectSizes;
  };
  dropdown: {
    base: string;
    itemsContainer: string;
    item: {
      base: string;
      active: {
        on: string;
        off: string;
      };
      caption: {
        on: string;
        off: string;
      };
    };
    icon: {
      base: string;
      active: {
        on: string;
        off: string;
      };
    };
  };
}

export const selectTheme: UIKitSelectTheme = {
  base:
    "relative  transition-all focus:outline-0 " +
    "flex items-center input justify-between min-w-32",
  hover: "",
  readonly: "text-secondary",
  disabled: "bg-dark/5",
  selectedCount: "font-semibold rounded-md px-2",
  pointer: {
    base: "text-dark/70",
    disabled: "text-dark/50",
  },
  value: {
    base: "overflow-ellipsis whitespace-nowrap overflow-hidden font-semibold text-dark pb-2 pt-6 h-5 box-content",
    readonly: "text-dark/70",
    disabled: "text-dark/50",
    hover: "",
  },
  variants: {
    variant: {
      default: "border-b border-dark/10",
      plain: "",
    },
    size: {
      md: "h-14",
    },
  },
  dropdown: {
    base: "shadow-md rounded-lg bg-light overflow-hidden min-w-52 flex flex-col overflow-y-auto gap-1 max-w-64",
    itemsContainer: "p-2 gap-1",
    item: {
      base:
        "w-full flex justify-between outline-0 gap-2 items-center text-default " +
        "bg-transparent hover:bg-dark/5 text-base px-2  flex-shrink-0 rounded-md cursor-pointer py-1",
      active: {
        on: "bg-dark/5 font-semibold",
        off: "",
      },
      caption: {
        on: "min-h-10",
        off: "h-7",
      },
    },
    icon: {
      base: "",
      active: {
        on: "",
        off: "",
      },
    },
  },
};

export interface InnerOption<T> {
  id: number | string;
  title: string;
  item: T;
  selected: boolean;
  // isMulti: boolean;

  caption?: string;
  avatarUrl?: string;
  emoji?: string;
  icon?: string;
}

export interface SelectPropsBase<T> {
  options?: Array<T>;
  emptyTitle: string;
  isLoading?: boolean;
  onSearchOptions?: (text: string | undefined) => void;
  searchOptions?: Array<T>;
  searchable?: boolean;
  loadOptionsOnOpen?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  variant?: "default" | "plain";
  error?: string;
  isClearable?: boolean;
  showAvatar?: boolean;
  showCaption?: boolean;
  onAddNew?: (searchText: string) => void;
  addNewPrefix?: string;
}

export interface MultiselectProps<T> extends SelectPropsBase<T> {
  isMulti?: boolean;
  onChange?: (value: SelectValueType[]) => void;
  values: SelectValueType[];
}

export interface SelectProps<T> extends SelectPropsBase<T> {
  onChange?: (value: SelectValueType) => void;
  value: SelectValueType;
}

export interface SelectOptionProps<T> {
  isMulti: boolean;
  item: InnerOption<T>;
  handleSelect: (item: InnerOption<T>) => void;
  showAvatar?: boolean;
  showCaption?: boolean;
}
