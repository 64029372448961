import { Page } from "../../../../components/page/Page.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { ApprovalSettingsProps } from "../../../../types/approval-policy.types.ts";
import { Tabs } from "../../../../ui/tabs/Tabs.tsx";
import { TabItem } from "../../../../ui/tabs/TabItem.tsx";
import { ApprovalTabPolicyCategories } from "./components/ApprovalTabPolicyCategories.tsx";
import { ApprovalTabRequests } from "./components/ApprovalTabRequests.tsx";

export function ApprovalSettings({ tab }: ApprovalSettingsProps) {
  const { t } = useTranslation();

  const handleTabChanged = (tabIndex: number) => {
    const urls = ["/settings/approval-policies", "/settings/approval-policies/history"];

    window.history.replaceState({}, "", urls[tabIndex]);
  };

  return (
    <Page>
      <Page.Header title={t("approval:settings_title")} showBack={true}></Page.Header>
      <Page.Content>
        <Stack className={"px-16"}>
          <Tabs onActiveTabChange={handleTabChanged}>
            <TabItem title={t("common:settings")}>
              <ApprovalTabPolicyCategories />
            </TabItem>
            <TabItem title={t("common:history")} active={tab == "history"}>
              <ApprovalTabRequests />
            </TabItem>
          </Tabs>
        </Stack>
      </Page.Content>
    </Page>
  );
}
