import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Page } from "../../../../components/page/Page.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import {
  useApprovalDelete,
  useApprovalPolicyDetails,
  useApprovalPolicySave,
} from "../../../../queries/use-approval-policies.query.ts";
import { Input } from "../../../../ui/input/Input.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { Rule, RuleApplySignType } from "../../../../types/role.types.ts";
import i18n from "i18next";
import { SettingsCard } from "../../components/SettingsCard.tsx";
import { useCallback, useEffect } from "react";
import { RuleEditModal } from "../../roles/edit/widgets/RuleEditModal.tsx";
import { RoleAssignToConditionsItems } from "../../roles/edit/role-edit.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import useRoleEditService from "../../roles/edit/role-edit-service.ts";
import { Switch } from "../../../../ui/switch/Switch.tsx";
import { Radio } from "../../../../ui/radio/Radio.tsx";
import {
  ApiApprovalPolicyCategory,
  ApiApprovalPolicyType,
} from "../../../../types/approval-policy.types.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import useApprovalPolicyHook from "../../../../hooks/use-approval-policy.hook.ts";

const ApprovalPolicyObject = yup.object({
  title: yup.string().required(),
  assign_to: yup.mixed<Rule[]>().required(i18n.t("errors:field_required")),
  flow_rules: yup.mixed<Rule[]>().required(i18n.t("errors:field_required")),
  flow_processing_type: yup
    .mixed<ApiApprovalPolicyType>()
    .oneOf(Object.values(ApiApprovalPolicyType))
    .required(i18n.t("errors:field_required")),
  flow_skip: yup.boolean().required(i18n.t("errors:field_required")),
});

type ApprovalPolicySchema = yup.InferType<typeof ApprovalPolicyObject>;

export function ApprovalPolicyEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const isEdit = !!id;
  const {
    handleSubmit,
    control,
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ApprovalPolicySchema>({
    mode: "onSubmit",
    resolver: yupResolver(ApprovalPolicyObject),
    defaultValues: {
      title: t("approval:default_title"),
      assign_to: [],
      flow_rules: [],
      flow_skip: false,
      flow_processing_type: ApiApprovalPolicyType.any,
    },
  });
  const navigate = useNavigate();
  const { getAssignCardTitle, getAssignCardDescription } = useRoleEditService();
  const { openModal } = useModals();
  const { getFlowRuleTypes, getFlowRuleTitleByType } = useApprovalPolicyHook();
  const policySave = useApprovalPolicySave(control);
  const policyDelete = useApprovalDelete();
  const { data: approvalPolicy } = useApprovalPolicyDetails(id ? Number(id) : undefined);
  const {
    append: assignToAppend,
    remove: assignToRemove,
    update: assignToUpdate,
  } = useFieldArray({
    control,
    name: "assign_to",
  });
  const {
    append: flowRuleAppend,
    remove: flowRuleRemove,
    update: flowRuleUpdate,
  } = useFieldArray({
    control,
    name: "flow_rules",
  });

  const handleEditAssignTo = useCallback((rule?: Rule | undefined, index?: number | undefined) => {
    openModal(RuleEditModal, {
      rule,
      forceSign: RuleApplySignType.include,
      conditionTypes: RoleAssignToConditionsItems(),
      onModified(modifiedRule: Rule) {
        if (index !== undefined) {
          assignToUpdate(index, modifiedRule);
        } else {
          assignToAppend(modifiedRule);
        }
      },
      onDelete() {
        assignToRemove(index);
      },
    });
  }, []);

  const handleEditFlowRule = useCallback((rule?: Rule | undefined, index?: number | undefined) => {
    openModal(
      RuleEditModal,
      {
        rule,
        popupTitle: index ? t("approval:edit_approval_step") : t("approval:add_approval_step"),
        conditionTypes: getFlowRuleTypes(),
        forceSign: RuleApplySignType.include,
        onModified(flowRule: Rule) {
          if (index !== undefined) {
            flowRuleUpdate(index, flowRule);
          } else {
            flowRuleAppend(flowRule);
          }
        },
        onDelete() {
          flowRuleRemove(index);
        },
      },
      {
        size: "lg",
      }
    );
  }, []);

  const handleDelete = () => {
    if (!approvalPolicy) return;
    if (policyDelete.isPending) return;

    openModal(ConfirmationPopup, {
      question: t("common:delete"),
      text: t("common:delete_confirm"),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          await policyDelete.mutateAsync(approvalPolicy.id);

          navigate(`/settings/approval-policies/`);
        } catch {
          return;
        }
      },
    });
  };

  const onSubmit = handleSubmit((policyData) => {
    policySave.mutate(
      {
        id: id ? Number(id) : 0,
        payload: {
          title: policyData.title,
          assign_to: policyData.assign_to,
          flow_rules: policyData.flow_rules,
          flow_skip: policyData.flow_skip,
          flow_processing_type: policyData.flow_processing_type,
          category: ApiApprovalPolicyCategory.time_off,
        },
      },
      {
        onSuccess(createdPolicy) {
          analyticsService.trackEvent(
            isEdit ? analyticEvents.approvals.editedPolicy : analyticEvents.approvals.createdPolicy,
            {
              [analyticProperties.id]: createdPolicy.id,
            }
          );

          navigate(`/settings/approval-policies`);
        },
      }
    );
  });

  useEffect(() => {
    if (!approvalPolicy) return;

    reset({
      title: approvalPolicy.title,
      assign_to: approvalPolicy.assign_to,
      flow_rules: approvalPolicy.flow_rules,
      flow_skip: approvalPolicy.flow_skip,
      flow_processing_type: approvalPolicy.flow_processing_type,
    });
  }, [approvalPolicy]);

  return (
    <Page>
      <Page.Header title={t(isEdit ? "common:edit" : "common:create_new")} showBack={true} />
      <Page.Content>
        <Stack className={"px-16"} gap={"2xl"}>
          <Stack gap={"lg"}>
            <Stack>
              <Input
                type={"text"}
                {...register("title")}
                error={errors.title?.message}
                label={t("common:title")}
                size={"lg"}
              />
              <Title
                header={t("approval:steps_title")}
                caption={t("approval:steps_caption")}
                paddingTop={true}
                paddingBottom={true}
                size={"lg"}
              />
              <Box variant={"border"}>
                <Stack className={"mb-4"}>
                  {getValues("flow_rules").length === 0 && t("common:empty")}
                  {getValues("flow_rules").map((rule, index) => (
                    <ListItem
                      key={`policy_milestone_${index}`}
                      title={getFlowRuleTitleByType(rule.condition_type)?.title}
                      caption={t("approval:step", { step: index + 1 })}
                      valueSlot={
                        <div
                          className={"underline hover:no-underline cursor-pointer"}
                          onClick={() => {
                            handleEditFlowRule(rule, index);
                          }}
                        >
                          {t("common:edit")}
                        </div>
                      }
                    />
                  ))}
                </Stack>

                <Button
                  onClick={() => {
                    handleEditFlowRule();
                  }}
                  variant={"secondary"}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
                >
                  {t("common:add")}
                </Button>
              </Box>
            </Stack>

            <Stack>
              <Title header={t("common:settings")} size={"xs"} paddingBottom={true} />
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    size={"sm"}
                    title={t("approval:flow_skip_title")}
                    description={t("approval:flow_skip_caption")}
                  />
                )}
                control={control}
                name={"flow_skip"}
              />
            </Stack>

            <Stack>
              <Title
                header={t("approval:mandatory_approvals_title")}
                size={"xs"}
                paddingBottom={true}
              />
              <Box variant={"border"}>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Radio
                        items={"start"}
                        value={ApiApprovalPolicyType.full}
                        checked={value == ApiApprovalPolicyType.full}
                        onChange={() => {
                          onChange(ApiApprovalPolicyType.full);
                        }}
                      >
                        <Title
                          header={t("approval:all_approvals_required_title")}
                          caption={t("approval:all_approvals_required_caption")}
                          size={"sm"}
                        ></Title>
                      </Radio>
                      <Radio
                        items={"start"}
                        value={ApiApprovalPolicyType.any}
                        checked={value == ApiApprovalPolicyType.any}
                        onChange={() => {
                          onChange(ApiApprovalPolicyType.any);
                        }}
                      >
                        <Title
                          header={t("approval:any_approvals_required_title")}
                          caption={t("approval:any_approvals_required_caption")}
                          size={"sm"}
                        ></Title>
                      </Radio>
                    </>
                  )}
                  control={control}
                  name={"flow_processing_type"}
                />
              </Box>

              <Title
                header={t("core:assign_employees")}
                caption={t("core:assign_employees_description")}
                paddingTop={true}
                paddingBottom={true}
                size={"lg"}
              />

              <Stack
                direction={"horizontal"}
                gap={"md"}
                className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
              >
                {getValues("assign_to").map((rule, index) => (
                  <SettingsCard
                    key={`role-card-${index}`}
                    title={getAssignCardTitle(rule.condition_type)}
                    description={getAssignCardDescription(rule.sign, rule.condition_entities)}
                    onClick={() => {
                      handleEditAssignTo(rule as Rule, index);
                    }}
                  />
                ))}
                <SettingsCard
                  icon={"fa-plus"}
                  title={t("core:create_new_rule")}
                  variant={"action"}
                  onClick={() => {
                    handleEditAssignTo();
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack className={"pt-md"} gap={"md"}>
            <ServerErrorField errors={errors} />
            <Button size={"lg"} type={"submit"} onClick={onSubmit} isLoading={policySave.isPending}>
              {t("common:save")}
            </Button>
            {isEdit && (
              <Button
                variant={"danger"}
                size={"lg"}
                type={"submit"}
                onClick={handleDelete}
                isLoading={policyDelete.isPending}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-xmark"} />}
              >
                {t("common:delete")}
              </Button>
            )}
          </Stack>
        </Stack>
      </Page.Content>
    </Page>
  );
}
