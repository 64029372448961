import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { Control, FieldValues } from "react-hook-form";
import { apiDisqualifications } from "../../api/recruit/disqualification-reason.api.ts";
import { ApiCandidateDisqualificationReasonPayload } from "../../types/recruit/disqualification-reason.types.ts";
import { ApiErrorException } from "../../types/api.types.ts";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";

const disqualificationsKeys = createQueryKeys("disqualification", {
  all: null,
});

export const useDisqualifications = () => {
  return useQuery({
    ...disqualificationsKeys.all,
    queryFn: async () => {
      const result = await apiDisqualifications.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
  });
};

export interface SaveParams {
  id: number;
  title: string;
}

export const useDisqualificationSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, title }: SaveParams) => {
      const payload: ApiCandidateDisqualificationReasonPayload = {
        title: title,
      };
      const result =
        id == 0
          ? await apiDisqualifications.create(payload)
          : await apiDisqualifications.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: disqualificationsKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useDisqualificationDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiDisqualifications.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: disqualificationsKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
