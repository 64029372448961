import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Page } from "../../../../components/page/Page.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import { Input } from "../../../../ui/input/Input.tsx";
import {
  ApiTimeOffCategoryType,
  ApiTimeOffCountDaysType,
  ApiTimeOffPaidType,
  ApiTimeOffPeriodTrackingTime,
} from "../../../../types/time-off.types.ts";
import { SettingsCard } from "../../components/SettingsCard.tsx";
import {
  useTimeOffCategoryDetails,
  useTimeOffDeleteCategory,
  useTimeOffSaveCategory,
} from "../../../../queries/use-time-offs.query.ts";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import Select from "../../../../ui/select/Select.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import i18n from "i18next";
import { Switch } from "../../../../ui/switch/Switch.tsx";
import useTimeOffHook from "../../../../hooks/use-time-off.hook.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { ListColumnItem } from "../../../../components/list-item/ListColumnItem.tsx";
import { DropdownSelect } from "../../../../ui/select/DropdownSelect.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { DeleteListItem } from "../../../../components/list-item/DeleteListItem.tsx";
import { TimeOffCategoryDecorationEdit } from "./widgets/TimeOffCategoryDecorationEdit.tsx";

// const limitedColors = ["#1B1D21", "#32309C", "#7124A1", "#AB2C78", "#2F9AB2", "#AFB21D", "#B22F1D"];
const TimeOffCategoryObject = yup.object({
  title: yup.string().required(i18n.t("errors:field_required")),
  description: yup.string(),
  emoji: yup.string(),
  color: yup.string(),
  period_track_type: yup
    .mixed<ApiTimeOffPeriodTrackingTime>()
    .oneOf(Object.values(ApiTimeOffPeriodTrackingTime))
    .required(i18n.t("errors:field_required")),
  count_days_type: yup
    .mixed<ApiTimeOffCountDaysType>()
    .oneOf(Object.values(ApiTimeOffCountDaysType))
    .required(i18n.t("errors:field_required")),
  paid_type: yup
    .mixed<ApiTimeOffPaidType>()
    .oneOf(Object.values(ApiTimeOffPaidType))
    .required(i18n.t("errors:field_required")),
  category_type: yup
    .mixed<ApiTimeOffCategoryType>()
    .oneOf(Object.values(ApiTimeOffCategoryType))
    .required(i18n.t("errors:field_required")),
  is_public: yup.boolean(),
});

type TimeOffCategorySchema = yup.InferType<typeof TimeOffCategoryObject>;

export function TimeOffCategoryEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { openModal } = useModals();
  const isEdit = !!id;
  const { data: timeOffCategory } = useTimeOffCategoryDetails(id ? Number(id) : undefined);
  const { getPaidTypes, getCountDaysTypes, getTimeTrackingTypes, getCategoriesTypes } =
    useTimeOffHook();
  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TimeOffCategorySchema>({
    mode: "onSubmit",
    resolver: yupResolver(TimeOffCategoryObject),
    defaultValues: {
      period_track_type: ApiTimeOffPeriodTrackingTime.days,
      emoji: "🏝",
      color: "#FFFFFF",
      count_days_type: ApiTimeOffCountDaysType.working_days,
      paid_type: ApiTimeOffPaidType.working_paid,
      category_type: ApiTimeOffCategoryType.accrues_time,
      is_public: true,
    },
  });
  const categorySave = useTimeOffSaveCategory(control);
  const categoryDelete = useTimeOffDeleteCategory();
  const navigate = useNavigate();

  const color = watch("color");
  const emoji = watch("emoji");

  useEffect(() => {
    if (!timeOffCategory) return;

    reset({
      title: timeOffCategory.title,
      description: timeOffCategory.description ?? "",
      emoji: timeOffCategory.emoji ?? "",
      color: timeOffCategory.color ?? "",
      count_days_type: timeOffCategory.count_days_type,
      paid_type: timeOffCategory.paid_type,
      is_public: timeOffCategory.is_public,
      period_track_type: timeOffCategory.period_track_type,
      category_type: timeOffCategory.category_type,
    });
  }, [timeOffCategory]);

  const onSubmit = handleSubmit((categoryData) => {
    categorySave.mutate(
      {
        id: timeOffCategory?.id ?? 0,
        payload: {
          title: categoryData.title,
          description: categoryData.description ?? "",
          is_public: categoryData.is_public ?? false,
          period_track_type: categoryData.period_track_type,
          paid_type: categoryData.paid_type,
          count_days_type: categoryData.count_days_type,
          emoji: categoryData.emoji,
          color: categoryData.color,
          category_type: categoryData.category_type,
        },
      },
      {
        onSuccess(createdCategory) {
          analyticsService.trackEvent(
            isEdit ? analyticEvents.timeOffs.edited : analyticEvents.timeOffs.created,
            {
              [analyticProperties.id]: createdCategory.id,
            }
          );

          if (isEdit) {
            navigate("/settings/time-offs");
          } else {
            navigate(`/settings/time-offs/categories/${createdCategory.id}`);
          }
        },
      }
    );
  });

  const handleDelete = () => {
    if (!timeOffCategory) return;

    const id = timeOffCategory.id;

    openModal(ConfirmationPopup, {
      question: t("common:delete"),
      text: t("common:delete_confirm"),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        await categoryDelete.mutateAsync(
          { categoryId: id },
          {
            onSuccess: () => {
              analyticsService.trackEvent(analyticEvents.timeOffs.deleted, {
                [analyticProperties.id]: id,
              });

              navigate("/settings/time-offs");
            },
          }
        );
      },
    });
  };

  const changeDecoration = () => {
    openModal(TimeOffCategoryDecorationEdit, {
      emoji: emoji ?? "",
      color: color ?? "",
      onSave(emoji: string, color: string) {
        setValue("emoji", emoji);
        setValue("color", color);
      },
    });
  };

  return (
    <Page>
      <Page.Header
        title={t(isEdit ? "time_off:edit_title" : "time_off:create_new")}
        showBack={true}
      />
      <Page.Content>
        <Stack className={"px-16"} gap={"md"}>
          <Stack direction={"horizontal"} gap={"md"}>
            <Box
              className={
                "relative w-16 h-16 group cursor-pointer rounded-lg items-center justify-center bg-brand-dark/10"
              }
              onClick={changeDecoration}
              style={{ backgroundColor: color }}
            >
              {emoji && <Avatar variant={"plain"} url={undefined} emoji={emoji} size={"xl"} />}
            </Box>
          </Stack>

          <Stack direction={"vertical"}>
            <Input
              required={true}
              label={t("common:title")}
              size={"lg"}
              type={"text"}
              {...register("title")}
              error={errors.title?.message}
            />
            <Input
              label={t("common:description")}
              type={"text"}
              {...register("description")}
              error={errors.description?.message}
            />
          </Stack>

          {isEdit && (
            <Stack direction={"vertical"}>
              <Title
                header={t("time_off:configure_policies_title")}
                paddingTop={true}
                paddingBottom={true}
                size={"lg"}
                caption={t("time_off:configure_policies_description")}
              />

              <Stack
                direction={"horizontal"}
                gap={"md"}
                className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
              >
                {timeOffCategory?.policies.map((policy) => (
                  <SettingsCard
                    title={policy.title}
                    key={policy.id}
                    description={""}
                    to={`/settings/time-offs/categories/${id}/policies/${policy.id}`}
                    extra={t("plurals:employees_count", { count: policy.employees_count })}
                  />
                ))}
                <SettingsCard
                  icon={"fa-plus"}
                  title={t("time_off:create_new_policy_title")}
                  description={t("time_off:create_new_policy_description")}
                  to={`/settings/time-offs/categories/${id}/policies/new`}
                  variant={"action"}
                />
              </Stack>
            </Stack>
          )}

          <Stack direction={"vertical"}>
            <Title
              header={t("time_off:configure_title")}
              paddingTop={true}
              paddingBottom={true}
              size={"lg"}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("time_off:period_track_type_title")}
                  options={getTimeTrackingTypes()}
                  disabled={true}
                  emptyTitle={t("common:select")}
                  value={value}
                  onChange={onChange}
                  required={true}
                  error={errors.period_track_type?.message}
                />
              )}
              control={control}
              name={"period_track_type"}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <ListColumnItem
                  title={t("time_off:category_type_title")}
                  caption={t("time_off:category_type_description")}
                  valueSlot={
                    <DropdownSelect
                      value={value}
                      onChange={onChange}
                      options={getCategoriesTypes()}
                    />
                  }
                />
              )}
              control={control}
              name={"category_type"}
            />

            <Controller
              render={({ field: { value, onChange } }) => (
                <ListColumnItem
                  title={t("time_off:count_days_types_title")}
                  caption={t("time_off:count_days_types_description")}
                  valueSlot={
                    <DropdownSelect
                      value={value}
                      onChange={onChange}
                      options={getCountDaysTypes()}
                    />
                  }
                />
              )}
              control={control}
              name={"count_days_type"}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <ListColumnItem
                  title={t("time_off:paid_type_title")}
                  caption={t("time_off:paid_type_description")}
                  valueSlot={
                    <DropdownSelect value={value} onChange={onChange} options={getPaidTypes()} />
                  }
                />
              )}
              control={control}
              name={"paid_type"}
            />
          </Stack>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Switch
                checked={value}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                size={"sm"}
                title={t("time_off:is_public_title")}
                description={t("time_off:is_public_description")}
              />
            )}
            control={control}
            name={"is_public"}
          />

          {timeOffCategory && (
            <DeleteListItem
              title={t("time_off:category_delete_title")}
              caption={t("time_off:category_delete_caption")}
              onDelete={handleDelete}
            />
          )}

          <Stack className={"pt-md"} gap={"md"}>
            <ServerErrorField errors={errors} />
            <Button
              size={"lg"}
              type={"submit"}
              onClick={onSubmit}
              isLoading={categorySave.isPending}
            >
              {isEdit ? t("common:save") : t("common:save_and_continue")}
            </Button>
          </Stack>
        </Stack>
      </Page.Content>
    </Page>
  );
}
