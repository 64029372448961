import { ApiEmployee } from "../../../types/employees/employee.types.ts";

export interface ApiEmployeeChartItem {
  employee: ApiEmployee;
  direct_subordinates_count: number;
  parent_id: number | null;
}

export interface ApiEmployeeChartItem {
  employee: ApiEmployee;
  direct_subordinates_count: number;
}

export enum EmployeeNodeMode {
  parent = "parent",
  children = "children",
}
