import { Title } from "../../../../../components/title/Title.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { ListItem } from "../../../../../components/list-item/ListItem.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../../ui/typography/FontAwesomeIcon.tsx";
import { useTranslation } from "react-i18next";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { TimeOffCategoryPolicySchema } from "../TimeOffCategoryPolicyEdit.tsx";
import { useCallback } from "react";
import {
  ApiTimeOffPolicy,
  ApiTimeOffPolicyMilestone,
} from "../../../../../types/time-off.types.ts";
import { TimeOffMilestoneEditModal } from "../widget/TimeOffMilestoneEditModal.tsx";
import useModals from "../../../../../ui/modal/modal.store.ts";
import LinkButtonTo from "../../../../../ui/link/LinkButtonTo.tsx";

export interface TimeOffPolicyMilestonesProps {
  formHook: UseFormReturn<TimeOffCategoryPolicySchema>;
  timeOffPolicy?: ApiTimeOffPolicy;
}

export function TimeOffPolicyMilestones({ timeOffPolicy, formHook }: TimeOffPolicyMilestonesProps) {
  const { t } = useTranslation();
  const { openModal } = useModals();
  const { control, getValues } = formHook;

  const {
    append: milestoneAppend,
    remove: milestoneRemove,
    update: milestoneUpdate,
  } = useFieldArray({
    control,
    name: "milestones",
  });

  const handleEditMilestone = useCallback(
    (milestone?: ApiTimeOffPolicyMilestone, index?: number) => {
      openModal(TimeOffMilestoneEditModal, {
        milestone,
        index,
        onModified: (milestone: ApiTimeOffPolicyMilestone) => {
          if (index !== undefined) {
            milestoneUpdate(index, milestone);
          } else {
            milestoneAppend(milestone);
          }
        },
        onDelete: () => {
          milestoneRemove(index);
        },
      });
    },
    [timeOffPolicy]
  );

  return (
    <Stack>
      <Title
        header={t("time_off:milestones")}
        caption={t("time_off:milestones_description")}
        size={"xs"}
        className={"text-dark/60"}
        paddingBottom
      />
      <Box variant={"border"}>
        <Stack className={"mb-4"}>
          {getValues("milestones").length === 0 && t("common:empty")}
          {getValues("milestones").map((milestone, index) => (
            <ListItem
              key={`policy_milestone_${index}`}
              title={`${milestone.title}`}
              valueSlot={
                <LinkButtonTo
                  onClick={() => {
                    handleEditMilestone(milestone, index);
                  }}
                >
                  {t("common:edit")}
                </LinkButtonTo>
              }
            />
          ))}
        </Stack>

        <Button
          onClick={() => {
            handleEditMilestone();
          }}
          variant={"secondary"}
          leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
        >
          {t("time_off:add_milestone")}
        </Button>
      </Box>
    </Stack>
  );
}
