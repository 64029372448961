import useModals from "../../../../ui/modal/modal.store.ts";
import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo, useState } from "react";
import { ApiJobsFilter, ApiJobsFilterDefault } from "../../../../types/recruit/job.types.ts";
import Select from "../../../../ui/select/Select.tsx";
import {
  ApiCandidateCreatePayload,
  ApiCandidateMassSave,
  ApiCandidateMassSaveAction,
  ApiCandidateMassSaveFilter,
} from "../../../../types/recruit/candidate.types.ts";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ApiApplicantShort } from "../../../../types/recruit/applicant.types.ts";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import {
  ApiPipelineStageShort,
  PipelineStageTypes,
} from "../../../../types/recruit/stages.types.ts";
import { useJobsSearch } from "../../../../queries/recruit/use-jobs.query.ts";
import { useCandidateCreate } from "../../../../queries/recruit/use-candidates.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { useCandidateMassSaveByFilter } from "../../../../queries/recruit/use-candidate-actions.query.ts";
import useToasts from "../../../../hooks/use-toasts.hook.tsx";
import { SelectItemExtended } from "../../../../ui/select/select.types.ts";

export interface ApplicantAssignToJobModalProps extends ModalProps {
  applicant?: ApiApplicantShort;
  candidatesFilter?: ApiCandidateMassSaveFilter;
  onUpdatedByFilter?: () => void;
}

export function ApplicantAssignToJobModal({
  applicant,
  candidatesFilter,
  onUpdatedByFilter,
  ...props
}: ApplicantAssignToJobModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const [jobsFilter, setJobsFilter] = useState<ApiJobsFilter>(ApiJobsFilterDefault);
  const { data: jobsSearched, isLoading: jobsIsLoading } = useJobsSearch(jobsFilter);
  const [pipelineStages, setPipelineStages] = useState<ApiPipelineStageShort[]>([]);

  const { showInfo } = useToasts();

  const ApplicantAssignToJobSchemaObject = yup.object({
    job_id: yup.number().required(i18n.t("errors:field_required")),
    pipeline_stage_id: yup.number().required(i18n.t("errors:field_required")),
  });

  type ApplicantAssignToJobSchema = yup.InferType<typeof ApplicantAssignToJobSchemaObject>;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ApplicantAssignToJobSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ApplicantAssignToJobSchemaObject),
  });

  const candidateCreate = useCandidateCreate(control);
  const candidateMassSave = useCandidateMassSaveByFilter(control);

  const onSubmit = handleSubmit(async (schema) => {
    if (applicant) {
      const payload: ApiCandidateCreatePayload = {
        job_id: schema.job_id,
        applicant_id: applicant.id,
        pipeline_stage_id: schema.pipeline_stage_id,
        pipeline_id: jobsSearched?.items.find((x) => x.id == schema.job_id)?.pipeline.id || 0,
      };

      candidateCreate.mutate(payload, {
        onSuccess: (candidate) => {
          analyticsService.trackEvent(analyticEvents.applicants.assignedToJob, {
            [analyticProperties.id]: candidate.id,
            [analyticProperties.count]: 1,
            [analyticProperties.source]: "App",
          });
          close(id);
        },
      });
    } else if (candidatesFilter) {
      const payload: ApiCandidateMassSaveAction = {
        job_id: schema.job_id,
        pipeline_stage_id: schema.pipeline_stage_id,
        pipeline_id: jobsSearched?.items.find((x) => x.id == schema.job_id)?.pipeline.id || 0,
      };

      const updateByFilterPayload: ApiCandidateMassSave = {
        filter: candidatesFilter,
        action: payload,
      };

      candidateMassSave.mutate(updateByFilterPayload, {
        onSuccess: () => {
          analyticsService.trackEvent(analyticEvents.applicants.assignedToJob, {
            [analyticProperties.count]: candidatesFilter.analyticsTotalUpdatedCount,
            [analyticProperties.source]: "App",
          });
          showInfo({ title: t("common:you_changes_were_saved") });
          onUpdatedByFilter?.();
          close(id);
        },
      });
    }
  });

  async function handleSearchJobs(text: string | undefined) {
    setJobsFilter({
      ...jobsFilter,
      text: text,
    });
  }

  async function updatePipelineStages(jobId: number) {
    const pipeline = jobsSearched?.items.find((x) => x.id == jobId)?.pipeline;
    if (!pipeline) {
      // todo show error
      return;
    }
    setPipelineStages(pipeline.stages);
    if (pipeline.stages.length > 0) {
      const appliedStage = pipeline.stages.find(
        (stage) => stage.type === PipelineStageTypes.applied
      );

      setValue("pipeline_stage_id", appliedStage ? appliedStage.id : pipeline.stages[0].id);
    }
  }

  const jobSearchOptions = useMemo(() => {
    if (!jobsSearched) return [];
    return jobsSearched.items.map((job) => ({
      id: job.id,
      title: job.title,
      emoji: job.emoji,
      caption: job.department?.title,
    })) as SelectItemExtended[];
  }, [jobsSearched]);

  return (
    <>
      <Modal
        {...props}
        title={t("ats:assign_to_job")}
        withCloser
        closeByEscEnabled
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              isLoading={candidateCreate.isPending || candidateMassSave.isPending}
              disabled={candidateCreate.isPending || candidateMassSave.isPending}
              onClick={onSubmit}
              size={"lg"}
            >
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("ats:job")}
                  emptyTitle={t("common:select")}
                  isLoading={jobsIsLoading}
                  value={value}
                  searchable
                  onSearchOptions={handleSearchJobs}
                  showAvatar={true}
                  showCaption={true}
                  loadOptionsOnOpen
                  searchOptions={jobSearchOptions}
                  onChange={(value) => {
                    onChange(value);
                    updatePipelineStages(value as number);
                  }}
                  error={errors.job_id?.message}
                />
              )}
              control={control}
              name={"job_id"}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t("ats:stage")}
                  emptyTitle={t("common:select")}
                  isLoading={jobsIsLoading}
                  value={value}
                  options={pipelineStages}
                  onChange={onChange}
                  error={errors.pipeline_stage_id?.message}
                />
              )}
              control={control}
              name={"pipeline_stage_id"}
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
}
