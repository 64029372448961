import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";
import React, { ComponentProps, ReactElement, ReactNode, RefObject } from "react";

export interface ButtonSizes extends Pick<ThemeSizes, "sm" | "md" | "lg"> {
  [key: string]: string;
}

export interface ButtonVariant {
  primary: string;
  secondary: string;
  plain: string;
  menu: string;
  danger: string;
  menuDanger: string;
  // tertiary: string;
}

export interface UIKitButtonTheme extends UIKitThemeBase {
  text: string;
  variants: {
    size: ButtonSizes;
    variant: ButtonVariant;
  };
}

export const buttonTheme: UIKitButtonTheme = {
  base: "rounded-md outline-0 gap-2 items-center inline-flex transition-all ease-in overflow-hidden",
  text: "whitespace-nowrap text-ellipsis overflow-hidden",
  variants: {
    variant: {
      primary: "bg-brand-dark text-light justify-center shadow-md",
      secondary: "bg-brand-dark/5 text-brand-dark hover:bg-brand-dark/10 justify-center ",
      danger: "bg-brand-dark/5 hover:bg-brand-dark/10 justify-center text-danger",
      plain: "justify-center text-brand-dark hover:bg-dark/5",
      menu: "text-brand-dark bg-light hover:bg-dark/5 rounded-lg justify-start w-full",
      menuDanger: "text-danger bg-light hover:bg-dark/5 rounded-lg justify-start w-full",
    },
    size: {
      sm: "text-sm px-2 h-6 font-semibold",
      md: "text-base px-2 h-7 font-semibold",
      lg: "text-lg px-4 h-12 font-semibold",
    },
  },
};

export interface ButtonProps extends ComponentProps<"button"> {
  children?: React.ReactNode;
  // blank?: boolean;
  variant?: keyof ButtonVariant;
  size?: keyof ButtonSizes;
  isLoading?: boolean;
  isDisabled?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
}

export interface ButtonLinkProps extends ComponentProps<"a"> {
  children: ReactNode;
  leftIcon?: ReactElement;
  size?: keyof ButtonSizes;
  isLoading?: boolean;
  href: string;
  blank?: boolean;
  variant?: keyof ButtonVariant;
  rightIcon?: ReactElement;
}

export interface ButtonIconProps extends ComponentProps<"button"> {
  variant?: keyof ButtonVariant;
  icon: ReactElement;
  size?: keyof ButtonSizes;

  ref?: RefObject<HTMLButtonElement>;
}
