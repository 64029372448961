import { SelectItem, SelectProps, SelectValueType } from "./select.types.ts";
import Multiselect from "./Multiselect.tsx";

// https://codesandbox.io/p/sandbox/gallant-sea-rcg43b?file=%2Fsrc%2FApp.tsx
// https://codesandbox.io/p/sandbox/floating-ui-react-select-with-composable-children-qsuw76?file=%2Fsrc%2FApp.tsx%3A14%2C1-15%2C1
// https://codesandbox.io/p/sandbox/shy-snowflake-kp6479?file=%2Fsrc%2FSelect.tsx%3A41%2C1-43%2C1

export default function Select<T extends SelectItem>({
  onChange,
  value,
  ...props
}: SelectProps<T>) {
  const handleSelect = (value: SelectValueType[]) => {
    onChange?.(value.length > 0 ? value[0] : null);
  };

  return (
    <Multiselect isMulti={false} values={value ? [value] : []} onChange={handleSelect} {...props} />
  );
}
