import Stack from "../../ui/stack/Stack.tsx";
import Button from "../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import { useNavigate } from "react-router-dom";
import { useAppTheme } from "../../themes/provider.tsx";
import { PageHeaderProps } from "./page.types.ts";
import Text from "../../ui/typography/Text.tsx";
import { twMerge } from "tailwind-merge";

export default function PageHeader({
  title,
  showBack = false,
  children,
  titleSlot,
  navigateTo,
  nowrap = false,
}: PageHeaderProps) {
  const navigate = useNavigate();
  const theme = useAppTheme("page");

  const handleBackNavigation = () => {
    if (navigateTo) {
      navigate(navigateTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <Stack
      direction="horizontal"
      justify={"between"}
      className={twMerge(theme.header, !nowrap ? "flex-wrap" : "")}
      items={"center"}
      gap={"sm"}
    >
      <Stack direction="horizontal" gap={"sm"} items={"center"} className={"overflow-hidden"}>
        {showBack && (
          <Button
            onClick={handleBackNavigation}
            variant="plain"
            leftIcon={<FontAwesomeIcon icon="fa-light fa-arrow-left" className={"text-lg"} />}
            className={"-ml-2"}
          ></Button>
        )}
        <Text className={"text-lg font-semibold whitespace-nowrap text-ellipsis overflow-hidden"}>
          {title}
        </Text>
        {titleSlot ?? ""}
      </Stack>
      {children}
    </Stack>
  );
}
