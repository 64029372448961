import React, { useEffect } from "react";
import useToasts from "../../hooks/use-toasts.hook.tsx";
import { useTranslation } from "react-i18next";
import analyticsService, { analyticEvents } from "../../services/analytics-service.ts";

declare global {
  interface Window {
    __APP_VERSION__: string;
  }
}

interface VersionCheckerProps {
  checkInterval?: number; // How often to check for updates (default: 5 minutes)
  toastInterval?: number; // Minimum time between toasts (default: 2 hours)
}

const LAST_TOAST_KEY = "version_updater_last_toast";
const LAST_VERSION_KEY = "version_updater_last_version";

const VersionUpdater: React.FC<VersionCheckerProps> = ({
  checkInterval = 5 * 60 * 1000, // 5 minutes
  toastInterval = 2 * 60 * 60 * 1000, // 2 hours
}) => {
  const { showInfo } = useToasts();
  const { t } = useTranslation();
  useEffect(() => {
    const canShowToast = () => {
      const lastToastTime = localStorage.getItem(LAST_TOAST_KEY);
      if (!lastToastTime) return true;

      const timeSinceLastToast = Date.now() - parseInt(lastToastTime);
      return timeSinceLastToast >= toastInterval;
    };

    const shouldShowToast = (newVersion: string) => {
      const lastVersion = localStorage.getItem(LAST_VERSION_KEY);
      // Show toast if it's a different version than last time we showed a toast
      return !lastVersion || lastVersion !== newVersion;
    };

    const checkVersion = async () => {
      try {
        const response = await fetch(`/version.json?t=${Date.now()}`, {
          headers: { "Cache-Control": "no-cache" },
        });

        if (!response.ok) return;

        const { version } = await response.json();

        if (window.__APP_VERSION__ !== version && canShowToast() && shouldShowToast(version)) {
          analyticsService.trackEvent(analyticEvents.app.newVersionAvailable);
          // Update last toast time and version
          localStorage.setItem(LAST_TOAST_KEY, Date.now().toString());
          localStorage.setItem(LAST_VERSION_KEY, version);
          showInfo({
            title: t("core:app_update_available_title"),
            description: t("core:app_update_available_description"),
            button: t("core:app_update_available_button"),
            onClick: () => {
              analyticsService.trackEvent(analyticEvents.pageActions.newVersionUpdateClicked);
              window.location.reload();
            },
            duration: Infinity,
          });
        }
      } catch (error) {
        console.error("Failed to check version:", error);
      }
    };

    checkVersion();
    const interval = setInterval(checkVersion, checkInterval);
    return () => clearInterval(interval);
  }, [checkInterval, toastInterval]);

  return null;
};

export default VersionUpdater;
