import { useEmployeeAppConfigStore, useEmployeeStore } from "../../stores/employee.store.ts";
import Stack from "../../ui/stack/Stack.tsx";
import { MenuConfigItem, MenuConfigTop } from "./sidebar-service.tsx";
import { MenuItem } from "./components/MenuItem.tsx";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Button from "../../ui/button/Button.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PermissionType } from "../../types/role.types.ts";
import { SidebarRecruitment } from "./recruit/SidebarRecruitment.tsx";
import { SidebarRecruitmentCandidates } from "./recruit/SidebarRecruitmentCandidates.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import Box from "../../ui/box/Box.tsx";
import useSidebar from "../../hooks/use-sidebar-hook.ts";
import { SidebarUserMenu } from "./components/SidebarUserMenu.tsx";
import { SidebarSettings } from "./components/SidebarSettings.tsx";
import { SidebarTeam } from "./components/team/SidebarTeam.tsx";
import i18n from "../../i18n.ts";
import { Calendar } from "../../pages/calendar/Calendar.tsx";

export default function Sidebar() {
  const { employee } = useEmployeeStore();
  const navigate = useNavigate();
  const { sidebarIsCollapsed, setSidebarIsCollapsed } = useEmployeeAppConfigStore();
  const { sidebarIsShowing } = useSidebar();

  const menuTop = useMemo(() => {
    if (!employee) return [];

    const menuConfigTop = MenuConfigTop();

    if (employee.organization.calendar_settings.is_enabled) {
      menuConfigTop.push({
        title: i18n.t("common:calendar"),
        icon: "fa-calendar-week",
        to: "/calendar",
        moduleUrlPrefixes: ["/calendar"],
        component: <Calendar />,
      });
    }

    const items: MenuConfigItem[] = [];
    for (let i = 0; i < menuConfigTop.length; i++) {
      let hasPermission = true;
      const item = menuConfigTop[i];
      if (item.permissions && item.permissions.length > 0) {
        hasPermission = false;
        for (
          let permissionIndex = 0;
          permissionIndex < item.permissions.length;
          permissionIndex++
        ) {
          if (employee.permissions[item.permissions[permissionIndex]] == PermissionType.edit) {
            hasPermission = true;
          }
        }
      }

      if (hasPermission) {
        items.push(item);
      }
    }
    return items;
  }, [employee]);

  const { pathname } = useLocation();

  const [currentNav, setCurrentNav] = useState<MenuConfigItem | null>(null);

  useEffect(() => {
    const totalMenu = [...menuTop];
    for (let i = 0; i < totalMenu.length; i++) {
      for (
        let prefixIndex = 0;
        prefixIndex < totalMenu[i].moduleUrlPrefixes.length;
        prefixIndex++
      ) {
        if (pathname.startsWith(totalMenu[i].moduleUrlPrefixes[prefixIndex])) {
          if (currentNav == null || currentNav.to != totalMenu[i].to) {
            setCurrentNav(totalMenu[i]);
            return;
          }
        }
      }
    }
  }, [employee, pathname]);

  const handleOpenSidebar = useCallback(() => {
    setSidebarIsCollapsed(false);
  }, []);

  return (
    <>
      <div className={"bg-light/70 h-full flex"}>
        <div
          className={
            "w-14 shadow-sidebar h-full flex flex-col justify-between flex-shrink-0 items-center"
          }
        >
          <Stack>
            <div
              className={"flex items-center flex-col py-4"}
              onClick={() => {
                navigate("/");
              }}
            >
              <Box className={"w-8 h-8 rounded-md overflow-hidden relative"}>
                {employee?.organization.logo_url ? (
                  <>
                    <img
                      src={employee?.organization.logo_url || "/skailer-sidebar-logo.png"}
                      className={"w-8 h-8"}
                    />
                  </>
                ) : (
                  <>
                    <Box className={"absolute inset-1 bg-brand-dark "}></Box>
                    <img
                      src={employee?.organization.logo_url || "/skailer-sidebar-logo.png"}
                      className={"w-8 h-8 absolute inset-0"}
                    />
                  </>
                )}
              </Box>
            </div>
            <Stack gap={"smd"}>
              {menuTop.map((item, index) => (
                <MenuItem
                  item={item}
                  key={`menu-top-${index}`}
                  isActive={currentNav?.to === item.to}
                />
              ))}
            </Stack>
          </Stack>
          <Box className={"pb-4"}>
            <SidebarUserMenu />
          </Box>
        </div>
        {sidebarIsShowing && (
          <div className={"w-full shadow-sidebar relative overflow-x-hidden"}>
            {!sidebarIsCollapsed ? (
              <Routes>
                <Route path={"/recruitment"}>
                  <Route index element={<SidebarRecruitment />} />
                  <Route path="candidates">
                    <Route index element={<SidebarRecruitmentCandidates />} />
                    <Route path={"*"} element={<SidebarRecruitmentCandidates />} />
                  </Route>
                </Route>
                <Route path={"/"} element={<SidebarTeam />} />
                <Route path={"/employees/*"} element={<SidebarTeam />} />
                <Route path={"/settings"}>
                  <Route index element={<SidebarSettings />} />
                  <Route path={"*"} element={<SidebarSettings />} />
                </Route>
                <Route path="/*" element={currentNav && currentNav.component} />
              </Routes>
            ) : (
              <Box className={"pt-4.5 px-3"}>
                <Button
                  leftIcon={<FontAwesomeIcon icon="fa-light fa-sidebar" />}
                  variant={"plain"}
                  onClick={handleOpenSidebar}
                />
              </Box>
            )}
          </div>
        )}
      </div>
    </>
  );
}
