import { api } from "./axios.ts";
import { ApiAction, ApiActionPayload } from "../types/action.types.ts";
import {ApiOperationResult} from "../types/common.types.ts";

export const apiApplicantActions = {
  getAll: (applicantId: number) => {
    return api.get<ApiAction[]>(`/api/v1/applicant-actions/${applicantId}`);
  },
  create: (applicantId: number, payload: ApiActionPayload) => {
    return api.post<ApiAction>(`/api/v1/applicant-actions/${applicantId}`, payload);
  },
  update: (applicantId: number, actionId: number, payload: ApiActionPayload) => {
    return api.put<ApiAction>(`/api/v1/applicant-actions/${applicantId}/${actionId}`, payload);
  },
  delete: (applicantId: number, actionId: number) => {
    return api.delete<ApiOperationResult>(`/api/v1/applicant-actions/${applicantId}/${actionId}`);
  },
};
