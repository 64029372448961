import { forwardRef } from "react";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";
import { ButtonProps } from "./button.types.ts";

const ButtonComponentFN = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonComponentFN(
  {
    children,
    variant = "primary",
    size = "md",
    isLoading = false,
    className,
    leftIcon,
    rightIcon,
    isDisabled = false,
    ...props
  }: ButtonProps,
  ref
) {
  const theirProps = { type: "button", ...props } as ButtonProps;

  const theme = useTheme("button");

  return (
    <>
      <button
        ref={ref}
        className={twMerge(
          theme.base,
          theme.variants.variant[variant],
          theme.variants.size[size],
          className
        )}
        disabled={isLoading || isDisabled}
        {...theirProps}
      >
        {isLoading && <FontAwesomeIcon icon="fa-light fa-spinner fa-spin" />}
        {leftIcon}
        {children && <span className={theme.text}>{children}</span>}
        {rightIcon}
      </button>
    </>
  );
});

const Button = ButtonComponentFN;
export default Button;
