import { ApiDocumentEdit, ApiDocumentSavePayload } from "../../../../types/document.types.ts";
import { ApiFilePathTypes } from "../../../../types/file.types.ts";
import { useDocumentSave } from "../../../../queries/use-documents.query.ts";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import useErrorHandle from "../../../../hooks/use-error-handle.hook.tsx";
import useToasts from "../../../../hooks/use-toasts.hook.tsx";
import { useCallback, useState } from "react";
import { apiFiles } from "../../../../api/file.api.ts";
import { useDropzone } from "react-dropzone";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { Input } from "../../../../ui/input/Input.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import { ApiFile } from "../../../../types/common.types.ts";
import { FileItem } from "../../../../components/file-item/FileItem.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";

export interface EmployeeDocumentEditModalProps extends ModalProps {
  employeeId: number;
  document: ApiDocumentEdit;
}

export const EmployeeDocumentEditModal = ({
  employeeId,
  document,
  ...props
}: EmployeeDocumentEditModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { handleErrors } = useErrorHandle();
  const { showError } = useToasts();

  const [title, setTitle] = useState(document.title);
  const [file, setFile] = useState<ApiFile | undefined>(document.file);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const documentSave = useDocumentSave();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("path", ApiFilePathTypes.documents);
        formData.append("is_public", "false");
        formData.append("save_original_name", "true");
        formData.append("entity_id", employeeId.toString());

        setIsUploading(true);

        const { data, error } = await apiFiles.add(formData);

        if (data) {
          setFile(data);
          if (!title) setTitle(data.name);
        } else {
          handleErrors(error);
        }
        setIsUploading(false);
      }
    },
    [title]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const handleSave = useCallback(async () => {
    if (!file) {
      showError({ title: t("errors:no_file_selected") });
      return;
    }

    const payload: ApiDocumentSavePayload = {
      title: title,
      folder_id: document.folder?.id,
      file_id: file.id,
    };
    documentSave.mutate(
      {
        id: document.id,
        employeeId: employeeId,
        payload: payload,
      },
      {
        onSuccess: (data) => {
          if (document.id == 0) {
            analyticsService.trackEvent(analyticEvents.documents.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.documents.edited, {
              [analyticProperties.id]: document.id,
            });
          }
          close(id);
        },
      }
    );
  }, [file, title, document.folder, employeeId]);

  return (
    <Modal
      {...props}
      variant={"middle"}
      title={t("core:document")}
      layout={"base"}
      withCloser={true}
      actions={
        <Stack gap={"sm"}>
          <Button
            size={"lg"}
            className={"w-full"}
            isLoading={documentSave.isPending}
            onClick={handleSave}
          >
            {t("common:save")}
          </Button>
          <Button
            variant={"plain"}
            size={"lg"}
            onClick={() => {
              close(id);
            }}
          >
            {t("common:cancel")}
          </Button>
        </Stack>
      }
    >
      <Stack>
        <Input
          required={true}
          label={t("common:title")}
          autoFocus={true}
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <Title header={t("common:file")} size={"xs"} paddingBottom paddingTop />
        <Box
          className={
            "p-8 rounded-2xl border border-dashed border-dark/30 flex flex-grow flex-col h-5/6 mb-8 justify-center"
          }
        >
          <div {...getRootProps()} className="p-8  text-center flex items-center flex-col">
            <input {...getInputProps()} />

            <Stack items={"center"}>
              <Button variant={"secondary"} className={"mx-auto mb-2"} isLoading={isUploading}>
                {t("common:upload_file")}
              </Button>
              <Box className={"text-secondary"}>
                {isDragActive
                  ? t("common:upload_file_drag_and_drop")
                  : t("common:upload_file_drag_and_drop_description")}
              </Box>
            </Stack>
          </div>
        </Box>
        {file && <FileItem file={file} />}
      </Stack>
    </Modal>
  );
};
