// import { ApiFile } from "./common.types.ts";
//

import i18n from "../i18n.ts";
import { SelectItem } from "../ui/select/select.types.ts";
import { ApiEmployee, ApiEmployeeShort } from "./employees/employee.types.ts";
import { ApiCalendar, ApiCalendarProviders } from "./calendar.types.ts";
import { DefaultTimezone } from "../services/timezone-service.ts";
import { addMinutes, roundToNearestMinutes } from "date-fns";
import { getEmployeePositionTitle } from "./employees/employee-position.types.ts";

export enum ApiEventTypes {
  interview = "interview",
  default = "default",
}

export enum ApiEventAttendeeResponseStatuses {
  accepted = "accepted",
  declined = "declined",
  needs_action = "needs_action",
  tentative = "tentative",
}

export interface ApiEventInterview {
  candidate_id: number;
  hide_salary: boolean;
}

export interface ApiEventAttendee {
  employee?: ApiEmployeeShort;
  email?: string;
  response_status: ApiEventAttendeeResponseStatuses;
  is_organizer: boolean;

  // Штука чтобы загрузить должность и ее отобразить
  runtime_only_employee?: ApiEmployee;
}

export enum ApiEventVisibility {
  public = "public",
  private = "private",
  busy = "busy",
}

export interface ApiEventConferenceData {
  title: string;
  uri: string;
  label: string;
}

export interface ApiEvent {
  id: number;
  type: ApiEventTypes;
  title: string;
  timezone: string; //"Africa/Abidjan", // формат тут https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  description: string;
  location?: string;
  organizer: ApiEventAttendee;
  calendar: ApiCalendar;
  is_all_day: boolean;

  datetime_start: Date;
  datetime_end: Date;
  visibility: ApiEventVisibility;
  provider: ApiCalendarProviders;
  interview?: ApiEventInterview;
  attendees: ApiEventAttendee[];

  conference_data?: ApiEventConferenceData;
}

export interface ApiEventEdit {
  type: ApiEventTypes;
  title: string;
  timezone: string; //"Africa/Abidjan", // формат тут https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  description: string;
  location?: string;
  calendar: ApiCalendar;
  is_all_day: boolean;
  is_conference: boolean;

  datetime_start: Date;
  datetime_end: Date;
  visibility: ApiEventVisibility;
  provider: ApiCalendarProviders;
  interview?: ApiEventInterview;
  attendees: ApiEventAttendee[];

  suggestedAttendees?: ApiEventAttendee[];
}

export const mockEventEdit: ApiEventEdit = {
  type: ApiEventTypes.default,
  is_all_day: false,
  title: "",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  description: "",
  location: "",
  calendar: {
    id: 0,
    title: "",
    timezone: DefaultTimezone,
    is_default: false,
    provider: ApiCalendarProviders.system,
  },
  datetime_start: addMinutes(roundToNearestMinutes(new Date(), { nearestTo: 30 }), 0),
  datetime_end: addMinutes(roundToNearestMinutes(new Date(), { nearestTo: 30 }), 30),
  visibility: ApiEventVisibility.public,
  provider: ApiCalendarProviders.system,
  interview: undefined,
  attendees: [],
  is_conference: true,
};

export const getMockEventEdit = (organizer: ApiEmployeeShort) => {
  return {
    ...mockEventEdit,
    datetime_start: addMinutes(roundToNearestMinutes(new Date(), { nearestTo: 30 }), 0),
    datetime_end: addMinutes(roundToNearestMinutes(new Date(), { nearestTo: 30 }), 30),
    attendees: [
      {
        employee: organizer,
        response_status: ApiEventAttendeeResponseStatuses.accepted,
        is_organizer: true,
      },
    ],
  } as ApiEventEdit;
};

export interface ApiEventFilter {
  text?: string;
  employee_ids?: number[];
  date_start?: Date;
  date_end?: Date;
  types?: ApiEventTypes[];
}

export interface ApiEventAttendeeSavePayload {
  employee_id?: number;
  email?: string;
  is_organizer: boolean;
}

export interface ApiEventSavePayload {
  type: ApiEventTypes;
  title: string;
  timezone: string; //"Africa/Abidjan", // формат тут https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  datetime_start: Date;
  datetime_end: Date;
  description: string;
  location?: string;
  calendar_id: number;
  visibility: ApiEventVisibility;
  is_all_day: boolean;
  is_conference: boolean;

  interview?: ApiEventInterview;

  attendees: ApiEventAttendeeSavePayload[];
}

export const getDurations = () => {
  return [
    {
      id: 15,
      title: i18n.t("plurals:plural_minutes", { count: 15 }),
    },
    {
      id: 30,
      title: i18n.t("plurals:plural_minutes", { count: 30 }),
    },
    {
      id: 60,
      title: i18n.t("plurals:plural_hours", { count: 1 }),
    },
    {
      id: 90,
      title: `${i18n.t("plurals:plural_hours", { count: 1 })} ${i18n.t("plurals:plural_minutes", { count: 30 })}`,
    },
  ] as SelectItem[];
};

// export const getStartTimesSelected = () => {
//   const startTimes: SelectItem[] = [];
//   for (let i = 9; i < 20; i++) {
//     startTimes.push({
//       id: i,
//       title: `${i}:00`,
//     });
//     startTimes.push({
//       id: i + 0.5,
//       title: `${i}:30`,
//     });
//   }
//   return startTimes;
// };

export const getDescriptionForAttendee = (attendee: ApiEventAttendee) => {
  const texts: string[] = [];
  if (attendee.is_organizer) {
    texts.push(i18n.t("calendar:organizer"));
  }
  if (!attendee.employee && !attendee.runtime_only_employee) {
    texts.push(i18n.t("calendar:external_contact"));
  }
  if (attendee.runtime_only_employee) {
    texts.push(getEmployeePositionTitle(attendee.runtime_only_employee));
  }
  return texts;
};

export const getAttendeeResponseText = (status: ApiEventAttendeeResponseStatuses) => {
  switch (status) {
    case ApiEventAttendeeResponseStatuses.accepted:
      return i18n.t("calendar:response_status_accepted");
    case ApiEventAttendeeResponseStatuses.declined:
      return i18n.t("calendar:response_status_needs_declined");
    case ApiEventAttendeeResponseStatuses.needs_action:
      return i18n.t("calendar:response_status_needs_action");
    case ApiEventAttendeeResponseStatuses.tentative:
      return i18n.t("calendar:response_status_needs_tentative");
  }
};
