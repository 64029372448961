import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException, ApiPassException } from "../../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { ApiCandidateCreatePayload } from "../../types/recruit/candidate.types.ts";
import { apiCandidates } from "../../api/candidate.api.ts";
import { Control, FieldValues } from "react-hook-form";
import { jobsKeys } from "./use-jobs.query.ts";
import { applicantsKeys } from "./use-applicants.query.ts";

export const candidatesKeys = createQueryKeys("candidates", {
  listByApplicantId: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("No id");
      const result = await apiCandidates.getByApplicantId(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useCandidatesByApplicant = (id: number | undefined) => {
  return useQuery({
    ...candidatesKeys.listByApplicantId(id),
  });
};

export const useCandidateCreate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (payload: ApiCandidateCreatePayload) => {
      const result = await apiCandidates.create(payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.searchInfinite._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useCandidateDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiCandidates.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: applicantsKeys.searchInfinite._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: jobsKeys.details._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useCandidateSnooze = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiCandidates.snooze(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: candidatesKeys.listByApplicantId._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
