import { useCallback, useMemo, useState } from "react";
import {
  ApiEmployeePosition,
  getEmployeePositionTitle,
} from "../../../../types/employees/employee-position.types.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button.tsx";
import { EmployeePositionModal } from "../widgets/EmployeePositionModal.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import Table from "../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import { ColumnDef, createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { EmployeePageComponentProps } from "./employee-page-components.types.ts";
import { EmployeeFieldPermissions, PermissionType } from "../../../../types/role.types.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import {
  useEmployeePositionDelete,
  useEmployeePositionsSearch,
} from "../../../../queries/employees/use-employee-positions.query.ts";
import { PaginationBlock } from "../../../../ui/pagination/PaginationBlock.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import { EmptyStateBase } from "../../../../components/empty-state/EmptyStateBase.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import usePermissions from "../../../../hooks/use-permissions.hook.ts";
import { Link } from "react-router-dom";
import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import { EmployeePositionHistoryEmploymentStatuses } from "./EmployeePositionHistoryEmploymentStatuses.tsx";

export const EmployeePositionHistory = ({ employee }: EmployeePageComponentProps) => {
  const { t } = useTranslation();
  const { renderDate, renderEmployeeName } = useFormatter();
  const { employee: employeeMe } = useEmployeeStore();
  const modalsService = useModals();
  const [page, setPage] = useState(1);
  const {
    data: employeePositions,
    isLoading,
    isFetching,
  } = useEmployeePositionsSearch(employee.id, page);

  const employeePositionDelete = useEmployeePositionDelete();

  const { canViewField, canEditAnyOfFields } = usePermissions();

  const canManagePositions: boolean = useMemo(() => {
    return canEditAnyOfFields(employee, [
      EmployeeFieldPermissions.employee_position_job_title,
      EmployeeFieldPermissions.employee_position_date,
      EmployeeFieldPermissions.employee_position_reporting_to,
      EmployeeFieldPermissions.employee_position_division,
      EmployeeFieldPermissions.employee_position_department,
      EmployeeFieldPermissions.employee_position_location,
      EmployeeFieldPermissions.employee_position_legal_entity,
      EmployeeFieldPermissions.employee_position_comment,
    ]);
  }, [employee]);

  const columnHelper = createColumnHelper<ApiEmployeePosition>();
  const columns = useMemo<ColumnDef<ApiEmployeePosition>[]>(() => {
    const inner: DisplayColumnDef<ApiEmployeePosition, unknown>[] = [];

    if (canViewField(employee, EmployeeFieldPermissions.employee_position_date)) {
      inner.push(
        columnHelper.display({
          header: t("common:date"),
          cell: (cell) => renderDate(cell.row.original.date_effective_from),
          id: "date_effective_at",
          size: 100,
        })
      );
    }

    if (canViewField(employee, EmployeeFieldPermissions.employee_position_job_title)) {
      inner.push(
        columnHelper.display({
          header: t("core:position"),
          cell: (row) => (
            <Stack>
              {row.row.original.position?.title && (
                <Text className={"text-title"}>{row.row.original.position?.title}</Text>
              )}
              {row.row.original.position_level?.title && (
                <Text className={"text-secondary"}>{row.row.original.position_level?.title}</Text>
              )}
            </Stack>
          ),
          id: "position",
        })
      );
    }

    if (canViewField(employee, EmployeeFieldPermissions.employee_position_reporting_to)) {
      inner.push(
        columnHelper.display({
          header: t("core:manager"),
          cell: (row) =>
            row.row.original.manager && (
              <Link to={`/employees/${row.row.original.manager.id}`}>
                <ListItem
                  behavior={"card"}
                  title={renderEmployeeName(row.row.original.manager)}
                  avatarUrl={row.row.original.manager.avatar_url}
                  caption={getEmployeePositionTitle(row.row.original.manager)}
                />
              </Link>
            ),
          id: "manager",
        })
      );
    }

    inner.push(
      columnHelper.display({
        header: t("common:comment"),
        cell: (row) => (!row.row.original.comment ? "" : row.row.original.comment),
        id: "comment",
      })
    );

    if (canManagePositions) {
      inner.push(
        columnHelper.display({
          id: "actions",
          cell: (cell) => (
            <Stack className={"items-end"}>
              {canManagePositions && (
                <DropdownMenu>
                  <Button
                    onClick={() => {
                      handleEditOrCreate(cell.row.original);
                    }}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                    variant={"menu"}
                  >
                    {t("common:edit")}
                  </Button>
                  <Button
                    onClick={() => {
                      handleDeleteAction(cell.row.original);
                    }}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                    variant={"menu"}
                    isLoading={employeePositionDelete.isPending}
                  >
                    {t("common:delete")}
                  </Button>
                </DropdownMenu>
              )}
            </Stack>
          ),
          size: 70,
        })
      );
    }

    return inner;
  }, [employeePositions]);

  const handleEditOrCreate = useCallback(
    (employeePosition: ApiEmployeePosition | undefined = undefined) => {
      if (!employee) return;

      const positionToEdit = employeePosition || {
        id: 0,
        date_effective_from: new Date(),
      };

      modalsService.openModal(EmployeePositionModal, {
        employee: employee,
        employeePosition: positionToEdit,
      });
    },
    [employee]
  );

  const handleDeleteAction = useCallback(
    (employeePosition: ApiEmployeePosition) => {
      employeePositionDelete.mutate(
        { employeeId: employee.id, positionId: employeePosition.id },
        {
          onSuccess: () => {
            analyticsService.trackEvent(analyticEvents.employeePositions.deleted, {
              [analyticProperties.id]: employee.id,
            });
          },
        }
      );
    },
    [employee]
  );

  return (
    <>
      <Stack gap={"lg"}>
        <Stack className={"justify-between"} direction={"horizontal"} items={"center"}>
          <Title header={t("core:positions")} size={"md"} />
          {canManagePositions && (
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              variant={"primary"}
              leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
              size={"sm"}
            >
              {t("core:assign_position")}
            </Button>
          )}
        </Stack>
        <Box>
          <Table
            data={employeePositions?.items || []}
            columns={columns}
            isFetching={isFetching || employeePositionDelete.isPending}
            showSkeleton={isLoading}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={
              <EmptyStateBase
                emoji={"🦥"}
                title={t("core:no_position_assigned")}
                primaryButton={
                  canManagePositions ? (
                    <Button
                      onClick={() => {
                        handleEditOrCreate();
                      }}
                      size={"sm"}
                      variant={"secondary"}
                    >
                      {t("core:assign_position")}
                    </Button>
                  ) : null
                }
              />
            }
          />

          {(employeePositions?.metadata.total_pages ?? 0) > 1 && (
            <PaginationBlock
              metadata={employeePositions?.metadata}
              onPageChange={(page) => {
                setPage(page);
              }}
            />
          )}
        </Box>
        {employeeMe?.permissions.dismiss_employees == PermissionType.edit && (
          <EmployeePositionHistoryEmploymentStatuses employee={employee} />
        )}
      </Stack>
    </>
  );
};
