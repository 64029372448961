import { ComponentPropsWithoutRef, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";
import { ButtonIconProps, ButtonSizes } from "./button.types.ts";

const ButtonIconComponentFN = forwardRef<HTMLButtonElement, ButtonIconProps>(function ButtonIcon(
  { variant = "primary", size = "md", className, icon, ...props }: ButtonIconProps,
  ref
) {
  const theirProps = { type: "button", ...props } as ComponentPropsWithoutRef<"button">;

  const theme = useTheme("button");
  const sizeTheme: ButtonSizes = {
    sm: "w-6 h-6",
    md: "w-7 h-7",
    lg: "w-10 h-10",
  };

  return (
    <>
      <button
        ref={ref}
        className={twMerge(
          theme.base,
          theme.variants.variant[variant],
          //"shrink-0",
          sizeTheme[size],
          className
        )}
        {...theirProps}
      >
        {icon}
      </button>
    </>
  );
});

const ButtonIcon = ButtonIconComponentFN;
export default ButtonIcon;
