import {
  ApiDataProtectionProvider,
  ApiDataProtectionProviderSettings,
  ApiDataProtectionSettingsPayload,
  DataProtectionConfigureTab,
  DataProtectionTypes,
} from "../../../../../types/data-protection.types.ts";
import Box from "../../../../../ui/box/Box.tsx";
import { Switch } from "../../../../../ui/switch/Switch.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import { ListItem } from "../../../../../components/list-item/ListItem.tsx";
import Text from "../../../../../ui/typography/Text.tsx";
import { ConfirmationPopup } from "../../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import { apiDataProtection } from "../../../../../api/data-protection.api.ts";
import useModals from "../../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import { DataProtectionConfigureModal } from "../widgets/DataProtectionConfigureModal.tsx";
import { useDataProtectionSettingsSave } from "../../../../../queries/use-data-protection.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";
import { twMerge } from "tailwind-merge";
import useDataProtectionHook from "../../../../../hooks/use-data-protection.hook.ts";

export interface DataProtectionSettingsProviderProps {
  provider: ApiDataProtectionProvider;
}

export function DataProtectionSettingsProvider({ provider }: DataProtectionSettingsProviderProps) {
  const { openModal } = useModals();
  const { t } = useTranslation();
  const save = useDataProtectionSettingsSave();
  const { getTitleByType } = useDataProtectionHook();

  const handleOnEdit = (provider: ApiDataProtectionProvider, tab: DataProtectionConfigureTab) => {
    openModal(
      DataProtectionConfigureModal,
      { data_protection_type: provider.data_protection_type, settings: provider.settings, tab },
      { size: "lg" }
    );
  };

  const handleSwitchProvider = (
    data_protection_type: DataProtectionTypes,
    settings: ApiDataProtectionProviderSettings | null | undefined,
    switchIsOn: boolean
  ) => {
    if (settings) {
      const newSettings: ApiDataProtectionSettingsPayload = {
        data_protection_type,
        ...settings,
        enabled: switchIsOn,
      };

      save.mutate(newSettings);

      analyticsService.trackEvent(analyticEvents.dataProtection.settingsToggled, {
        [analyticProperties.actionType]: switchIsOn,
        [analyticProperties.id]: data_protection_type,
      });

      return;
    }

    const typeTitle = getTitleByType(data_protection_type);

    openModal(
      ConfirmationPopup,
      {
        question: t("data_protection:unlock_title", { type_title: typeTitle }),
        text: t("data_protection:unlock_content", { type_title: typeTitle }),
        acceptTitle: t("data_protection:unlock_btn", { type_title: typeTitle }),
        onAccept: async () => {
          const result = await apiDataProtection.request(data_protection_type);

          analyticsService.trackEvent(analyticEvents.dataProtection.requestForOrganizationSent, {
            [analyticProperties.id]: data_protection_type,
          });

          return result ? Promise.resolve() : Promise.reject();
        },
      },
      { size: "lg" }
    );
  };

  return (
    <Box
      className={twMerge("p-10 border border-dark/10 rounded-md", save.isPending && "opacity-50")}
    >
      <Switch
        checked={!!provider.settings && provider.settings?.enabled}
        onChange={(isOn) => {
          handleSwitchProvider(provider.data_protection_type, provider.settings, isOn);
        }}
        size={"md"}
        title={t("data_protection:enable_consent", {
          type_title: getTitleByType(provider.data_protection_type),
        })}
        description={t("data_protection:manage_data", {
          type_title: getTitleByType(provider.data_protection_type),
        })}
      />

      {provider.settings?.enabled && (
        <Stack>
          <Title header={t("common:settings")} size={"xs"} paddingBottom paddingTop />
          <ListItem
            title={t("data_protection:agreement_retention_period")}
            caption={
              provider.settings?.retention_period_days
                ? t("plurals:plural_day", { count: provider.settings.retention_period_days })
                : t("common:not_selected")
            }
            valueSlot={
              <Text
                className={"underline text-dark/95 cursor-pointer hover:no-underline"}
                tabIndex={0}
                onClick={() => {
                  handleOnEdit(provider, DataProtectionConfigureTab.settings);
                }}
              >
                {t("common:edit")}
              </Text>
            }
          />
          <ListItem
            title={t("data_protection:privacy_policy_link")}
            caption={
              provider.settings?.privacy_policy_link.length > 0
                ? provider.settings?.privacy_policy_link
                : t("common:not_selected")
            }
            valueSlot={
              <Text
                className={"underline text-dark/95 cursor-pointer hover:no-underline"}
                tabIndex={0}
                onClick={() => {
                  handleOnEdit(provider, DataProtectionConfigureTab.settings);
                }}
              >
                {t("common:edit")}
              </Text>
            }
          />
          <ListItem
            title={t("data_protection:allow_sync_with_external_service")}
            caption={
              provider.settings?.calendar_full_names
                ? t("common:access_granted")
                : t("common:access_denied")
            }
            valueSlot={
              <Text
                className={"underline text-dark/95 cursor-pointer hover:no-underline"}
                tabIndex={0}
                onClick={() => {
                  handleOnEdit(provider, DataProtectionConfigureTab.settings);
                }}
              >
                {t("common:edit")}
              </Text>
            }
          />
          <Title header={t("core:email")} size={"xs"} paddingBottom paddingTop />
          <ListItem
            title={t("data_protection:email_content", {
              type_title: getTitleByType(provider.data_protection_type),
            })}
            caption={
              provider.settings?.email_template.length > 0
                ? provider.settings?.email_template
                : t("common:not_selected")
            }
            valueSlot={
              <Text
                className={"underline text-dark/95 cursor-pointer hover:no-underline"}
                tabIndex={0}
                onClick={() => {
                  handleOnEdit(provider, DataProtectionConfigureTab.email_template);
                }}
              >
                {t("common:edit")}
              </Text>
            }
          />
        </Stack>
      )}
    </Box>
  );
}
