import Stack from "../../ui/stack/Stack.tsx";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "../../ui/box/Box.tsx";
import { Textarea } from "../../ui/textarea/Textarea.tsx";
import Button from "../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import {
  useCandidateActionCreate,
  useCandidateActionUpdate,
} from "../../queries/recruit/use-candidate-actions.query.ts";
import {
  useApplicantActionCreate,
  useApplicantActionUpdate,
} from "../../queries/recruit/use-applicant-actions.query.ts";
import { FileUpload } from "../file-upload/FileUpload.tsx";
import { ApiEntityTypes, ApiFile } from "../../types/common.types.ts";
import { FileItem } from "../file-item/FileItem.tsx";
import { apiFiles } from "../../api/file.api.ts";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { ApiAction, ApiActionPayload, ApiActionVisibilities } from "../../types/action.types.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import LinkButtonTo from "../../ui/link/LinkButtonTo.tsx";

export interface AddCommentProps {
  entityId: number | undefined;
  entityType: ApiEntityTypes;
  action?: ApiAction;
  onSaved?: () => void;
}

export const AddComment = ({ entityId, entityType, action, onSaved }: AddCommentProps) => {
  const [isCollapsed, setIsCollapsed] = useState(!action);
  const { t } = useTranslation();
  const [text, setText] = useState(action?.comment || "");
  const [files, setFiles] = useState<ApiFile[]>(action?.files || []);
  const { handleErrors } = useErrorHandle();

  const toggleCollapsed = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const candidateActionCreate = useCandidateActionCreate();
  const applicantActionCreate = useApplicantActionCreate();

  const candidateActionUpdate = useCandidateActionUpdate();
  const applicantActionUpdate = useApplicantActionUpdate();

  const handleSave = useCallback(() => {
    if (!entityId) return;

    if (files.length === 0 && !text.trim()) {
      // setError("text", { type: "manual", message: t("errors:field_required") });
      // todo show error
      return;
    }

    const payload: ApiActionPayload = {
      comment: text,
      file_ids: files.map((f) => f.id),
      visibility: ApiActionVisibilities.public,
    };

    if (action) {
      if (entityType == ApiEntityTypes.candidate) {
        candidateActionUpdate.mutate(
          {
            id: action.item_id,
            actionId: action.id,
            payload: payload,
          },
          {
            onSuccess: () => {
              analyticsService.trackEvent(analyticEvents.candidates.actionEdited, {
                [analyticProperties.id]: action.item_id,
              });
              onSaved?.();
            },
          }
        );
      } else {
        applicantActionUpdate.mutate(
          {
            id: action.item_id,
            actionId: action.id,
            payload: payload,
          },
          {
            onSuccess: () => {
              analyticsService.trackEvent(analyticEvents.applicants.actionEdited, {
                [analyticProperties.id]: action.item_id,
              });
              onSaved?.();
            },
          }
        );
      }
    } else {
      if (entityType == ApiEntityTypes.candidate) {
        candidateActionCreate.mutate(
          { id: entityId, payload },
          {
            onSuccess: () => {
              setText("");
              setFiles([]);

              analyticsService.trackEvent(analyticEvents.candidates.addedAction, {
                [analyticProperties.id]: entityId,
                [analyticProperties.actionType]: "Comment Added",
                [analyticProperties.source]: "App",
              });
              onSaved?.();
            },
          }
        );
      } else {
        applicantActionCreate.mutate(
          { id: entityId, payload },
          {
            onSuccess: () => {
              setText("");
              setFiles([]);

              analyticsService.trackEvent(analyticEvents.applicants.addedAction, {
                [analyticProperties.id]: entityId,
                [analyticProperties.actionType]: "Comment Added",
                [analyticProperties.source]: "App",
              });
              onSaved?.();
            },
          }
        );
      }
    }
  }, [entityId, entityType, text, files, action]);

  async function removeFile(file: ApiFile) {
    setFiles(files.filter((f) => f.id !== file.id));

    const { error } = await apiFiles.delete(file.uuid);
    if (error) handleErrors(error);
  }

  if (isCollapsed) {
    return (
      <Box>
        <LinkButtonTo size={"sm"} variant={"secondary"} onClick={toggleCollapsed}>
          {t("common:add_comment") + "..."}
        </LinkButtonTo>
      </Box>
    );
  }

  return (
    <Stack gap={"sm"}>
      <Textarea
        className={"w-full"}
        onChange={(e) => setText(e.target.value)}
        value={text}
        label={t("common:comment")}
        autoFocus={true}
      />
      {files.length > 0 && (
        <Stack gap={"sm"}>
          {files.map((f) => (
            <FileItem
              key={`file-${f.id}`}
              file={f}
              onRemove={() => {
                removeFile(f);
              }}
            />
          ))}
        </Stack>
      )}
      <Stack items={"center"} justify={"between"} direction={"horizontal"} gap={"sm"}>
        <Box>
          <FileUpload
            entityId={entityId}
            entityType={entityType}
            afterSend={(file) => {
              file && setFiles([...files, file]);
            }}
          />
        </Box>

        <Button
          type={"submit"}
          isLoading={
            candidateActionCreate.isPending ||
            applicantActionCreate.isPending ||
            candidateActionUpdate.isPending
          }
          leftIcon={<FontAwesomeIcon icon={"fa-light fa-paper-plane-top text-lg"} />}
          onClick={handleSave}
        >
          {t("common:save")}
        </Button>
      </Stack>
    </Stack>
  );
};
