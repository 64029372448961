import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException } from "../../types/api.types.ts";
import { apiTerminationReasons } from "../../api/employees/dismissal-reason.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { ApiCandidateDisqualificationReasonPayload } from "../../types/recruit/disqualification-reason.types.ts";
import { SaveParams } from "../recruit/use-disqualifications.query.ts";

const employeesDismissalKeys = createQueryKeys("dismiss", {
  all: null,
});

export const useEmployeeDismissalReasons = () => {
  return useQuery({
    ...employeesDismissalKeys.all,
    queryFn: async () => {
      const result = await apiTerminationReasons.getAll();

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
    staleTime: 60 * 1000,
  });
};

export const useEmployeeDismissalReasonSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, title }: SaveParams) => {
      const payload: ApiCandidateDisqualificationReasonPayload = {
        title: title,
      };

      const result =
        id == 0
          ? await apiTerminationReasons.create(payload)
          : await apiTerminationReasons.update(id, payload);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: employeesDismissalKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useEmployeeDismissalReasonDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiTerminationReasons.delete(id);

      if (result.error) throw new ApiErrorException(result.error);

      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: employeesDismissalKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
