import { DefaultKitTheme, UIKitTheme } from "../ui/themes/theme.types.ts";
import { AppThemeConfig } from "./theme.config.types.ts";
import { DefaultAppTheme, DefaultAppThemeConfig } from "./default/default.theme.ts";
import { AppThemeSidebar } from "./default/sidebar.ts";
import { NeoAppTheme, NeoAppThemeConfig } from "./neo/neo.theme.ts";
import { SkylineAppTheme, SkylineAppThemeConfig } from "./skyline/skyline.theme.ts";
import { MaxAppTheme, MaxAppThemeConfig } from "./max/max.theme.ts";
import { WaveAppTheme, WaveAppThemeConfig } from "./wave/wave.theme.ts";

export interface AppTheme {
  body: AppThemeBase;
  layout: AppThemeLayout;
  sidebar: AppThemeSidebar;
  page: AppPageTheme;
}

export type AppThemeKey = keyof AppTheme;

export interface AppThemeBundle {
  appTheme: AppTheme;
  uiKitTheme: UIKitTheme;
  appThemeConfig: AppThemeConfig;
}

export interface AppThemeBundleConfigs {
  [key: string]: AppThemeBundle;
}

export const AppThemes: AppThemeBundleConfigs = {
  default: {
    appTheme: DefaultAppTheme,
    appThemeConfig: DefaultAppThemeConfig,
    uiKitTheme: DefaultKitTheme,
  },
  neo: {
    appTheme: NeoAppTheme,
    appThemeConfig: NeoAppThemeConfig,
    uiKitTheme: DefaultKitTheme,
  },
  skyline: {
    appTheme: SkylineAppTheme,
    appThemeConfig: SkylineAppThemeConfig,
    uiKitTheme: DefaultKitTheme,
  },
  max: {
    appTheme: MaxAppTheme,
    appThemeConfig: MaxAppThemeConfig,
    uiKitTheme: DefaultKitTheme,
  },
  wave: {
    appTheme: WaveAppTheme,
    appThemeConfig: WaveAppThemeConfig,
    uiKitTheme: DefaultKitTheme,
  },
};

export type AppThemesKeys = keyof typeof AppThemes;

export const getAppTheme = (theme: AppThemesKeys | null): AppThemeBundle => {
  if (theme == null) return AppThemes["default"];
  return AppThemes[theme];
};

export interface AppThemeBase {
  base: string;
}

export interface AppThemeLayout extends AppThemeBase {
  aside: string;
  main: string;
  variant: {
    collapsed: {
      aside: string;
      main: string;
    };
    withSidebar: {
      aside: string;
      main: string;
    };
  };
}

export type AppThemePage = AppThemeBase;

export interface AppPageTheme extends AppThemePage {
  header: string;
  content: string;
}
