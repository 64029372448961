import { ComponentProps, ReactNode } from "react";
import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";

export interface TabsProps extends ComponentProps<"div"> {
  onActiveTabChange?: (activeTab: number) => void;
  size?: keyof TabButtonSizes;
  isStretch?: boolean;
}

export interface TabsRef extends HTMLDivElement {
  setActiveTab: (activeTab: number) => void;
}

export interface UIKitTabsTheme extends UIKitThemeBase {
  tabList: {
    base: string;
    variants: {
      size: TabButtonSizes;
    };
  };
  tabButton: {
    base: string;
    stretch: string;
    active: {
      on: string;
      off: string;
    };
    variants: {
      size: TabButtonSizes;
      fit: TabButtonSizes;
    };
  };
  tabItemContainer: {
    base: string;
  };
}

export const tabsTheme: UIKitTabsTheme = {
  base: "",
  tabList: {
    base: "flex gap-sm w-full overflow-x-auto",
    variants: {
      size: {
        md: "border-b border-black/20 ",
        sm: "",
      },
    },
  },
  tabButton: {
    base: "font-semibold",
    stretch: "grow",
    active: {
      on: "text-brand-dark border-b-2 border-brand-dark",
      off: "text-dark/60",
    },
    variants: {
      size: {
        md: "py-4 ",
        sm: "py-1",
      },
      fit: {
        md: "px-4",
        sm: "py-1",
      },
    },
  },
  tabItemContainer: {
    base: "",
  },
};

export interface TabEventProps {
  target: number;
}

export interface TabItemProps extends Omit<ComponentProps<"div">, "title"> {
  active?: boolean;
  disabled?: boolean;
  title: string;
}

export interface TabButtonSizes extends Pick<ThemeSizes, "sm" | "md"> {
  [key: string]: string;
}

export interface TabButtonFit {
  md: string;
  sm: string;
}

export interface TabButtonProps extends ComponentProps<"button"> {
  title: string;
  countTotal?: number;
  isActive?: boolean;
  size?: keyof TabButtonSizes;
  fit?: keyof TabButtonFit;
  isStretch?: boolean;
}

export interface TabListProps extends ComponentProps<"div"> {
  children: ReactNode | ReactNode[];
  size?: keyof TabButtonSizes;
}
