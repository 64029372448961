import { Controller, UseFormReturn } from "react-hook-form";
import { TimeOffCategoryPolicySchema } from "../TimeOffCategoryPolicyEdit.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import { useTranslation } from "react-i18next";
import { Radio } from "../../../../../ui/radio/Radio.tsx";

export interface TimeOffPolicyRequireConfirmationProps {
  formHook: UseFormReturn<TimeOffCategoryPolicySchema>;
}

export function TimeOffPolicyRequireConfirmation({
  formHook,
}: TimeOffPolicyRequireConfirmationProps) {
  const { t } = useTranslation();
  const { control } = formHook;

  return (
    <Stack>
      <Title
        header={t("time_off:require_confirmation")}
        caption={t("time_off:require_confirmation_description")}
        size={"xs"}
        className={"text-dark/60"}
        paddingBottom
      />
      <Box variant={"border"}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <>
              <Radio
                items={"start"}
                value={"true"}
                checked={value == true}
                onChange={() => {
                  onChange(true);
                }}
              >
                <Title
                  header={t("time_off:require_confirmation_yes_caption")}
                  caption={t("time_off:require_confirmation_yes_description")}
                  size={"sm"}
                ></Title>
              </Radio>
              <Radio
                items={"start"}
                value={"false"}
                checked={value == false}
                onChange={() => {
                  onChange(false);
                }}
              >
                <Title
                  header={t("time_off:require_confirmation_no_caption")}
                  caption={t("time_off:require_confirmation_no_description")}
                  size={"sm"}
                ></Title>
              </Radio>
            </>
          )}
          control={control}
          name={"accrual_confirm_required"}
        />
      </Box>
    </Stack>
  );
}
