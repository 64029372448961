import { useCallback, useMemo } from "react";
import { RecruitingReportFilterModal } from "../widgets/RecruitingReportFilterModal.tsx";
import { AnalyticReportTypes } from "../../../../types/analytics/analytics.types.ts";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { NotificationCount } from "../../../../ui/notification-count/NotificationCount.tsx";
import { ApiAnalyticsRecruitingFilter } from "../../../../types/analytics/analytics.recruiting.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";

export interface RecruitingReportingButtonFiltersProps {
  filter: ApiAnalyticsRecruitingFilter;
  reportType: AnalyticReportTypes;
}

export const RecruitingReportingButtonFilters = ({
  filter,
  reportType,
}: RecruitingReportingButtonFiltersProps) => {
  const modalsService = useModals();
  const { t } = useTranslation();

  const filterEntitiesCount: number = useMemo(() => {
    let count = 0;

    if ((filter.job_ids?.length || 0) > 0) count++;

    return count;
  }, [filter]);

  const handleFilterClick = useCallback(() => {
    modalsService.openModal(RecruitingReportFilterModal, {
      filter: filter,
      reportType: reportType,
    });
  }, [filter, reportType]);

  return (
    <Button
      variant={filterEntitiesCount == 0 ? "secondary" : "primary"}
      leftIcon={<FontAwesomeIcon icon="fa-light fa-bars-filter" />}
      rightIcon={
        filterEntitiesCount > 0 ? (
          <NotificationCount count={filterEntitiesCount} size={"sm"} />
        ) : undefined
      }
      onClick={() => {
        handleFilterClick();
      }}
    >
      {t("common:filter")}
    </Button>
  );
};
