import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Box from "../box/Box.tsx";
import Text from "../typography/Text.tsx";
import { RichEditorToolbar } from "./RichEditorToolbar.tsx";
import { Underline } from "@tiptap/extension-underline";
import { Link } from "@tiptap/extension-link";
import "./rich-editor.css";
import { ApiEmailTemplate } from "../../types/email-template.types.ts";
import { VariableHighlight } from "./VariableHighlight.tsx";
import { PlaceholderOption } from "./rich-editor.types.ts";

export interface RichEditorProps {
  value: string;
  onChange?: (value: string) => void;
  error?: string;
  placeholder?: string;
  placeholderOptions?: PlaceholderOption[];
  emailTemplates?: ApiEmailTemplate[];
  onApplyTemplate?: (template: ApiEmailTemplate) => void;
}

// https://codesandbox.io/s/tiptap-0sqm3i?file=/src/components/Toolbar.tsx:676-718
// https://codesandbox.io/s/tiptap-react-jvstn?file=/src/tiptap.tsx

export const RichEditor = ({
  value,
  placeholder,
  onChange,
  error,
  emailTemplates,
  onApplyTemplate,
  placeholderOptions,
}: RichEditorProps) => {
  const extensions = [
    VariableHighlight(placeholderOptions?.map((item) => item.value)),
    StarterKit,
    Underline,
    Placeholder.configure({
      placeholder: placeholder || "",
    }),
    Link.configure({
      linkOnPaste: false,
      openOnClick: false,
    }),
  ];

  const editor = useEditor({
    content: value,
    extensions: extensions,

    // ...
    onUpdate({ editor }) {
      onChange?.(editor.getText() != "" ? editor.getHTML() : "");
    },
  });

  if (!editor) {
    return null;
  }

  // const isCursorOverLink = editor.getAttributes("link").href;

  return (
    <>
      <Box>
        <Box className={"border border-dark/5 rounded-t-md"}>
          <RichEditorToolbar
            editor={editor}
            emailTemplates={emailTemplates}
            placeholderOptions={placeholderOptions}
            onApplyTemplate={onApplyTemplate}
          />
          <EditorContent editor={editor} className={"p-4 rich-editor"} />
        </Box>
        {error && (
          <Box className={"pt-2 pb-4"}>
            <Text className={"text-danger"}>{error}</Text>
          </Box>
        )}
      </Box>
    </>
  );
};
