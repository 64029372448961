import { api } from "./axios.ts";
import { ApiPosition, ApiPositionFilter, ApiPositionSavePayload } from "../types/position.types.ts";
import { ApiPagedData } from "../types/filter.types.ts";

export const apiPositions = {
  getAll: async function () {
    return api.get<ApiPosition[]>("/api/v1/positions");
  },
  create: async function (payload: ApiPositionSavePayload) {
    return api.post<ApiPosition>("/api/v1/positions", payload);
  },
  update: async function (id: number, payload: ApiPositionSavePayload) {
    return api.put<ApiPosition>(`/api/v1/positions/${id}`, payload);
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/positions/${id}`);
  },
  search: async function (filter: ApiPositionFilter, signal: AbortSignal) {
    return api.post<ApiPagedData<ApiPosition>>("/api/v1/positions/search", filter, signal);
  },
};
