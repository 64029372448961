import { ApiEmployee } from "../../../types/employees/employee.types.ts";
import { ModalProps } from "../../../ui/modal/modal.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Stack from "../../../ui/stack/Stack.tsx";
import Button from "../../../ui/button/Button.tsx";
import Modal from "../../../ui/modal/modal.tsx";
import { EmployeePersonalCard } from "../employee/components/EmployeePersonalCard.tsx";
import { EmployeeAvatar } from "../../../components/employee-avatar/EmployeeAvatar.tsx";
import { useEmployeeDetails } from "../../../queries/employees/use-employees.query.ts";
import { useMemo } from "react";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";

export interface EmployeeViewModalProps extends ModalProps {
  employee?: ApiEmployee;
  employeeId?: number;
}

export function EmployeeViewModal({ employee, employeeId, ...props }: EmployeeViewModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: employeeLoaded } = useEmployeeDetails(employeeId, !!employeeId);

  const employeeToUse = useMemo(() => {
    if (employee) {
      return employee;
    }
    if (employeeLoaded) return employeeLoaded;
    return undefined;
  }, [employee, employeeLoaded]);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employees.viewedEmployee, {
      [analyticProperties.id]: id,
      [analyticProperties.source]: "Modal",
    });
  });

  return (
    <Modal
      {...props}
      title={t("core:profile")}
      withCloser
      closeByEscEnabled
      size="md"
      variant={"side"}
      layout={"stretch"}
      actions={
        <Button
          className="w-full mt-4"
          size="lg"
          onClick={() => {
            close(id);
            navigate(`/employees/${employeeId || employee?.id}`);
          }}
        >
          {t("core:view_profile")}
        </Button>
      }
    >
      <Stack>
        {employeeToUse && (
          <>
            <Stack items={"center"}>
              <EmployeeAvatar size={"2xl"} employee={employeeToUse} />
            </Stack>
            <EmployeePersonalCard employee={employeeToUse} />
          </>
        )}
        {!employeeToUse && <Skeleton className="w-full h-4" />}
      </Stack>
    </Modal>
  );
}
