import React, { ElementType } from "react";
import { twMerge } from "tailwind-merge";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";
import { useTheme } from "../themes/provider.tsx";

export type CardProps<T extends ElementType = "div"> = PolymorphicComponentProp<
  T,
  {
    actionable?: boolean;
    children: React.ReactNode;
  }
>;

export function Card({ actionable = false, children, className, ...rest }: CardProps) {
  const theme = useTheme("card");
  return (
    <>
      <div
        className={twMerge(theme.base, actionable ? theme.active.on : undefined, className)}
        {...rest}
      >
        {children}
      </div>
    </>
  );
}
