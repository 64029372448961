export enum PipelineStageTypes {
  applied = "applied",
  offer = "offer",
  hired = "hired",
  custom = "custom",
}

export interface ApiPipelineStageShort {
  id: number;
  title: string;
  emoji: string | null;
}

export interface ApiPipelineStage extends ApiPipelineStageShort {
  applicants_count: number;
  type: PipelineStageTypes;
}

export interface ApiPipelineStagSavePayload {
  title: string;
  emoji: string;
}

export const StageEmojiDefault = "😎";

export const getStageEmoji = (stage: ApiPipelineStageShort) => {
  return stage.emoji || StageEmojiDefault;
};

export const StageReservedEmoji = "🩶";
export const StageDisqualifiedEmoji = "🚫";
