import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { useEmployeeAppConfigStore } from "../../../stores/employee.store.ts";
import { useCallback } from "react";

export function SidebarControls() {
  const { setSidebarIsCollapsed } = useEmployeeAppConfigStore();

  const handleCollapseSidebar = useCallback(() => {
    setSidebarIsCollapsed(true);
  }, []);

  return (
    <Button
      onClick={handleCollapseSidebar}
      variant={"plain"}
      leftIcon={<FontAwesomeIcon icon="fa-light fa-sidebar" />}
    ></Button>
  );
}
