import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";
import { ElementType } from "react";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";

export interface ColorBlockSizes extends Pick<ThemeSizes, "sm" | "md"> {
  [key: string]: string;
}

export interface UIKitColorBlockTheme extends UIKitThemeBase {
  readonly: string;
  disabled: string;
  hover: string;
  variants: {
    size: ColorBlockSizes;
  };
}

export const colorBlockTheme: UIKitColorBlockTheme = {
  base: "flex items-center justify-center transition-all",
  readonly: "text-secondary",
  disabled: "text-dark/50",
  hover: "hover:border-gray-300",
  variants: {
    size: {
      sm: "w-4 h-4 rounded-md",
      md: "w-8 h-8 rounded-lg",
    },
  },
};

export type ColorBlockProps<T extends ElementType = "div"> = PolymorphicComponentProp<
  T,
  {
    color: string;
    selected?: boolean;
    size?: keyof ColorBlockSizes;
    onClick?: () => void;
  }
>;
