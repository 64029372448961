import { useTranslation } from "react-i18next";
import Box from "../../../ui/box/Box.tsx";
import { Switch } from "../../../ui/switch/Switch.tsx";
import { ApiCalendarSettings } from "../../../types/calendar.types.ts";
import { useEffect } from "react";
import { Page } from "../../../components/page/Page.tsx";
import Button from "../../../ui/button/Button.tsx";
import { Title } from "../../../components/title/Title.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import {
  useCalendarSettingsUpdate,
  useOrganizationSettings,
} from "../../../queries/settings/use-organization.query.ts";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import { useEffectOnce } from "react-use";
import useToasts from "../../../hooks/use-toasts.hook.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ServerErrorField } from "../../../hooks/use-error-handle.hook.tsx";

interface SettingsConfig {
  settingItem: keyof ApiCalendarSettings;
  title: string;
  description: string;
}

const settingsConfig: SettingsConfig[] = [
  {
    settingItem: "show_employee_leaves",
    title: "calendar:show_employee_leaves",
    description: "calendar:show_employee_leaves_description",
  },
  {
    settingItem: "show_birthdays",
    title: "calendar:show_birthdays",
    description: "calendar:show_birthdays_description",
  },
  {
    settingItem: "show_starting_dates_of_new_hires",
    title: "calendar:show_starting_dates_of_new_hires",
    description: "calendar:show_starting_dates_of_new_hires_description",
  },
  {
    settingItem: "show_anniversaries",
    title: "calendar:show_anniversaries",
    description: "calendar:show_anniversaries_description",
  },
  {
    settingItem: "show_last_day_of_employees",
    title: "calendar:show_last_day_of_employees",
    description: "calendar:show_last_day_of_employees_description",
  },
];

const CalendarSettingsSchemaObject = yup.object({
  is_enabled: yup.boolean().required(),
  show_employee_leaves: yup.boolean().required(),
  show_birthdays: yup.boolean().required(),
  show_starting_dates_of_new_hires: yup.boolean().required(),
  show_anniversaries: yup.boolean().required(),
  show_last_day_of_employees: yup.boolean().required(),
});

interface CalendarSettingsSchema extends yup.InferType<typeof CalendarSettingsSchemaObject> {}

export function CalendarSettings() {
  const { t } = useTranslation();
  const { showInfo } = useToasts();
  const { data: organizationSettings, isLoading } = useOrganizationSettings();

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<CalendarSettingsSchema>({
    mode: "onSubmit",
    resolver: yupResolver(CalendarSettingsSchemaObject),
  });

  const calendarSettingsUpdate = useCalendarSettingsUpdate(control);
  const isEnabled = watch("is_enabled");

  useEffect(() => {
    if (organizationSettings?.calendar_settings) {
      reset(organizationSettings.calendar_settings);
    }
  }, [organizationSettings]);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.organization.settingsViewed, {
      [analyticProperties.source]: "Calendar",
    });
  });

  const onSubmit = handleSubmit((formData) => {
    calendarSettingsUpdate.mutate(formData, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.organization.settingsEdited, {
          [analyticProperties.actionType]: "Details Edited",
          [analyticProperties.source]: "Calendar",
        });
        showInfo({ title: t("common:you_changes_were_saved") });
      },
    });
  });

  if (isLoading) {
    return (
      <Page>
        <Page.Header showBack title={t("calendar:calendar")} />
        <Page.Content>
          <Stack gap="xl" className="container-sm mx-auto">
            <Skeleton className="h-8 w-full" />
          </Stack>
        </Page.Content>
      </Page>
    );
  }

  return (
    <Page>
      <Page.Header showBack title={t("calendar:calendar")} />
      <Page.Content>
        <Stack gap="xl" className="container-sm mx-auto">
          <form onSubmit={onSubmit}>
            <Stack>
              <Title
                size="lg"
                paddingBottom
                paddingTop
                header={t("calendar:calendar")}
                caption={t("calendar:settings_calendar_description")}
              />

              <Box variant="border">
                <Switch
                  checked={isEnabled}
                  onChange={(value) => setValue("is_enabled", value)}
                  size="md"
                  title={t("calendar:enable_calendar")}
                  description={t("calendar:enable_calendar_description")}
                />
              </Box>

              {isEnabled && (
                <Stack>
                  <Title
                    header={t("calendar:calendar_settings")}
                    size="xs"
                    paddingBottom
                    paddingTop
                  />
                  <Stack gap="sm">
                    {settingsConfig.map((config) => (
                      <Box key={config.settingItem} variant="border">
                        <Switch
                          checked={watch(config.settingItem)}
                          onChange={(value) => setValue(config.settingItem, value)}
                          size="md"
                          title={t(config.title)}
                          description={t(config.description)}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              )}

              <ServerErrorField errors={errors} />

              <Box className="w-full flex flex-col pt-10">
                <Button type="submit" size="lg" isLoading={calendarSettingsUpdate.isPending}>
                  {t("common:save")}
                </Button>
              </Box>
            </Stack>
          </form>
        </Stack>
      </Page.Content>
    </Page>
  );
}
