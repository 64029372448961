import useFormatter from "../../hooks/use-formatter.hook.ts";
import { ApiEmployee } from "../../types/employees/employee.types.ts";
import { getEmployeePositionTitle } from "../../types/employees/employee-position.types.ts";
import { EmployeeAvatar } from "../employee-avatar/EmployeeAvatar.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import { useCallback } from "react";
import { EmployeeViewModal } from "../../pages/employees/widgets/EmployeeViewModal.tsx";
import useModals from "../../ui/modal/modal.store.ts";

export interface EmployeeInlineCardFullProps {
  employee: ApiEmployee;
}

export const EmployeeInlineCardFull = ({ employee }: EmployeeInlineCardFullProps) => {
  const { renderEmployeeName } = useFormatter();
  const { openModal } = useModals();

  const handleClick = useCallback(() => {
    openModal(EmployeeViewModal, {
      employeeId: employee.id,
    });
  }, [employee]);

  return (
    <Stack
      direction={"horizontal"}
      className={"items-center cursor-pointer"}
      gap={"sm"}
      onClick={handleClick}
    >
      <EmployeeAvatar size={"md"} employee={employee} />
      <Stack>
        <Text className={"text-title"}>{renderEmployeeName(employee)}</Text>
        <Text className={"text-secondary"}>{getEmployeePositionTitle(employee)}</Text>
      </Stack>
    </Stack>
  );
};
