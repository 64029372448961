import Stack from "../../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import { Controller, UseFormReturn } from "react-hook-form";
import { Radio } from "../../../../../ui/radio/Radio.tsx";
import { ApiTimeOffPolicyMilestoneTransferType } from "../../../../../types/time-off.types.ts";
import { useTranslation } from "react-i18next";
import { TimeOffCategoryPolicySchema } from "../TimeOffCategoryPolicyEdit.tsx";

export interface TimeOffPolicyMilestoneTransferProps {
  formHook: UseFormReturn<TimeOffCategoryPolicySchema>;
}

export function TimeOffPolicyMilestoneTransfer({ formHook }: TimeOffPolicyMilestoneTransferProps) {
  const { t } = useTranslation();
  const { control } = formHook;

  return (
    <Stack>
      <Title
        header={t("time_off:milestone_transfer_type_title")}
        caption={t("time_off:milestone_transfer_type_description")}
        className={"text-dark/60"}
        size={"xs"}
        paddingBottom
      />
      <Box variant={"border"}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <>
              <Radio
                items={"start"}
                value={ApiTimeOffPolicyMilestoneTransferType.immediately_on_condition}
                checked={value == ApiTimeOffPolicyMilestoneTransferType.immediately_on_condition}
                onChange={() => {
                  onChange(ApiTimeOffPolicyMilestoneTransferType.immediately_on_condition);
                }}
              >
                <Title
                  header={t("time_off:milestone_transfer_type_immediately_title")}
                  caption={t("time_off:milestone_transfer_type_immediately_caption")}
                  size={"sm"}
                ></Title>
              </Radio>
              <Radio
                items={"start"}
                value={ApiTimeOffPolicyMilestoneTransferType.end_of_accrual_period}
                checked={value == ApiTimeOffPolicyMilestoneTransferType.end_of_accrual_period}
                onChange={() => {
                  onChange(ApiTimeOffPolicyMilestoneTransferType.end_of_accrual_period);
                }}
              >
                <Title
                  header={t("time_off:milestone_transfer_type_end_period_title")}
                  caption={t("time_off:milestone_transfer_type_end_period_caption")}
                  size={"sm"}
                ></Title>
              </Radio>
            </>
          )}
          control={control}
          name={"milestone_transfer_type"}
        />
      </Box>
    </Stack>
  );
}
