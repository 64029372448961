import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { BoxProps } from "./box.types";
import { boxTheme } from "./box.types";

export const Box = forwardRef<HTMLDivElement, BoxProps>(function Box(
  { children, variant = "default", isLoading, className, ...props }: BoxProps,
  ref
) {
  return (
    <div
      ref={ref}
      className={twMerge(
        boxTheme.base,
        boxTheme.variants[variant],
        isLoading && "opacity-50",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
});

export default Box;
