import { ApiFile } from "./common.types.ts";
import {
  ApiEmployee,
  ApiEmployeeShort,
  ApiEmployeeTimeOffCategory,
} from "./employees/employee.types.ts";
import { ApiTimeOffCategory } from "./time-off.types.ts";

export enum ApiApprovalRequestTypes {
  time_off = "time_off",
}

export enum ApiApprovalRequestStatus {
  awaiting = "awaiting",
  approved = "approved",
  rejected = "rejected",
  canceled = "canceled",
}

export interface ApiApprovalRequest {
  id: number;
  type: ApiApprovalRequestTypes;
  status: ApiApprovalRequestStatus;
  datetime_updated_at: Date;
  datetime_created_at: Date;
  employee: ApiEmployee;
  comment: string;
  files: ApiFile[];
  time_off_data: ApiTimeOffRequestData;
  confirmations?: ApiApprovalRequestConfirmation[];
  created_by?: ApiEmployeeShort;
}

export interface ApiApprovalRequestDetails {
  request: ApiApprovalRequest;
  time_off_category?: ApiEmployeeTimeOffCategory;
}

export interface ApiApprovalRequestConfirmation {
  from_employee: ApiEmployee;
  confirmed_at?: Date;
  rejected_at?: Date;
}

export interface ApiApprovalRequestPayload {
  type: ApiApprovalRequestTypes;
  comment?: string;
  file_ids?: number[];
  employee_id?: number;
  time_off_data: { date_from: Date; date_to: Date; category_id: number };
}

export interface ApiTimeOffRequestData {
  date_from: Date;
  date_to: Date;
  balance_requested: string;
  category: { id: number; title?: string; description?: string; emoji?: string };
}

export interface ApiApprovalRequestsFilter {
  page_number: number;
}

export interface ApiApprovalRequestTimeOffData {
  date_from: Date;
  date_to: Date;
  category: ApiTimeOffCategory;
}

export interface ApiApprovalRequestTimeOffCheck {
  possible_to_create: boolean;
  type: ApiApprovalRequestTypes;
  confirming_employees: ApiEmployeeShort[];
  time_off_data: ApiApprovalRequestTimeOffData;
  reject_code: ApiApprovalRequestTimeOffRejectCode;
  balance_left: number;
}

export enum ApiApprovalRequestTimeOffRejectCode {
  invalid_request = "invalid_request",
  no_approval_policy = "no_approval_policy",
  no_confirmation_employees = "no_confirmation_employees",
  time_off_category_not_found = "time_off_category_not_found",
  not_enough_days = "not_enough_days",
  asked_date_from_less_then_today = "asked_date_from_less_then_today",
  asked_less_then_min_daily_amount = "asked_less_then_min_daily_amount",
  asked_less_then_min_request_total = "asked_less_then_min_request_total",
  asked_less_then_max_request_total = "asked_less_then_max_request_total",
  asked_date_from_less_then_min_notice_period = "asked_date_from_less_then_min_notice_period",
  asked_date_from_more_then_max_notice_period = "asked_date_from_more_then_max_notice_period",
}
