import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { ApiDocument } from "../../../../types/document.types.ts";
import { useDocumentSave, useDocumentsFolders } from "../../../../queries/use-documents.query.ts";
import { useState } from "react";
import Select from "../../../../ui/select/Select.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";

export interface EmployeeDocumentsMoveFileModalProps extends ModalProps {
  document: ApiDocument;
  employeeId: number;
}

export function EmployeeDocumentsMoveFileModal({
  document,
  employeeId,
  ...props
}: EmployeeDocumentsMoveFileModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const [documentState, setDocumentState] = useState(document);

  const { data: folders } = useDocumentsFolders(employeeId);
  const documentSave = useDocumentSave();

  const onSubmit = () => {
    documentSave.mutate(
      {
        employeeId: employeeId,
        id: document.id,
        payload: {
          title: document.title,
          text: document.text,
          folder_id: documentState.folder?.id,
          file_id: document.file?.id,
        },
      },
      {
        onSuccess: () => {
          analyticsService.trackEvent(analyticEvents.documents.moved, {
            [analyticProperties.id]: document.id,
          });
          close(id);
        },
      }
    );
  };

  return (
    <>
      <Modal
        {...props}
        title={t("common:move_to")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={documentSave.isPending}
              onClick={onSubmit}
            >
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <Select
          isClearable
          value={documentState.folder?.id}
          emptyTitle={t("core:folder_root")}
          label={t("core:folder")}
          options={folders}
          onChange={(e) => {
            setDocumentState({
              ...documentState,
              folder: folders?.find((x) => x.id == e),
            });
          }}
        />
      </Modal>
    </>
  );
}
