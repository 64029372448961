import Toast from "../ui/toast/Toast.tsx";
import toast from "react-hot-toast";

export interface UseToastParams {
  title: string;
  description?: string;
  button?: string;
  onClick?: () => void;
  duration?: number;
}

export default function useToasts() {
  // https://react-hot-toast.com/docs/toaster

  function showInfo({ title, description, button, onClick, duration = 4 * 1000 }: UseToastParams) {
    toast.custom(
      (t) => (
        <Toast
          title={title}
          description={description}
          type="info"
          visible={t.visible}
          buttonTitle={button}
          buttonOnClick={onClick}
          onClick={() => {
            toast.dismiss(t.id);
          }}
        />
      ),
      { duration: duration }
    );
  }

  function showError({ title, description, button, onClick }: UseToastParams) {
    toast.custom((t) => (
      <Toast
        title={title}
        description={description}
        type="error"
        visible={t.visible}
        buttonTitle={button}
        buttonOnClick={onClick}
        onClick={() => {
          toast.dismiss(t.id);
        }}
      />
    ));
  }

  return {
    showInfo,
    showError,
  };
}
