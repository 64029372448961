import { TFunction } from "i18next";

export enum EmailPlaceholder {
  CANDIDATE_LAST = "[candidate last]",
  CANDIDATE_FIRST = "[candidate first]",
  CANDIDATE_FULL = "[candidate full]",
  CANDIDATE_EMAIL = "[candidate email]",
  JOB_TITLE = "[job title]",
  COMPANY_NAME = "[company name]",
  SENDER_FIRST = "[sender first]",
  SENDER_LAST = "[sender last]",
  SENDER_FULL = "[sender full]",
  SENDER_EMAIL = "[sender email]",
  SENDER_JOB_TITLE = "[sender job title]",
  SENDER_PHONE = "[sender phone]",
}

export interface EmailPlaceholderOption {
  id: EmailPlaceholder;
  title: string;
  value: EmailPlaceholder;
}

export interface PlaceholderOption {
  title: string;
  value: string;
}

export const getEmailPlaceholderOptions = (t: TFunction): EmailPlaceholderOption[] => [
  {
    id: EmailPlaceholder.CANDIDATE_LAST,
    title: t("core:email_placeholder_candidate_last_name"),
    value: EmailPlaceholder.CANDIDATE_LAST,
  },
  {
    id: EmailPlaceholder.CANDIDATE_FIRST,
    title: t("core:email_placeholder_candidate_first_name"),
    value: EmailPlaceholder.CANDIDATE_FIRST,
  },
  {
    id: EmailPlaceholder.CANDIDATE_FULL,
    title: t("core:email_placeholder_candidate_full_name"),
    value: EmailPlaceholder.CANDIDATE_FULL,
  },
  {
    id: EmailPlaceholder.CANDIDATE_EMAIL,
    title: t("core:email_placeholder_candidate_email"),
    value: EmailPlaceholder.CANDIDATE_EMAIL,
  },
  {
    id: EmailPlaceholder.JOB_TITLE,
    title: t("ats:job"),
    value: EmailPlaceholder.JOB_TITLE,
  },
  {
    id: EmailPlaceholder.COMPANY_NAME,
    title: t("common:company"),
    value: EmailPlaceholder.COMPANY_NAME,
  },
  {
    id: EmailPlaceholder.SENDER_FIRST,
    title: t("core:email_placeholder_sender_first_name"),
    value: EmailPlaceholder.SENDER_FIRST,
  },
  {
    id: EmailPlaceholder.SENDER_LAST,
    title: t("core:email_placeholder_sender_last_name"),
    value: EmailPlaceholder.SENDER_LAST,
  },
  {
    id: EmailPlaceholder.SENDER_FULL,
    title: t("core:email_placeholder_sender_full_name"),
    value: EmailPlaceholder.SENDER_FULL,
  },
  {
    id: EmailPlaceholder.SENDER_EMAIL,
    title: t("core:email_placeholder_sender_email"),
    value: EmailPlaceholder.SENDER_EMAIL,
  },
  {
    id: EmailPlaceholder.SENDER_JOB_TITLE,
    title: t("core:email_placeholder_sender_job_title"),
    value: EmailPlaceholder.SENDER_JOB_TITLE,
  },
  {
    id: EmailPlaceholder.SENDER_PHONE,
    title: t("core:email_placeholder_sender_phone"),
    value: EmailPlaceholder.SENDER_PHONE,
  },
];
