import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiDivisions } from "../api/division.api.ts";
import { ApiDivisionFilter } from "../types/division.types.ts";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { ApiErrorException } from "../types/api.types.ts";
import { useMemo, useState } from "react";
import { SelectItem } from "../ui/select/select.types.ts";

const divisions = createQueryKeys("divisions", {
  search: (filter: ApiDivisionFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiDivisions.search(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useDivisionsSearch = (filter: ApiDivisionFilter, enabled = true) => {
  return useQuery({
    ...divisions.search(filter),
    enabled: enabled,
  });
};

export interface DivisionSaveParams {
  id: number;
  title: string;
}

export const useDivisionSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, title }: DivisionSaveParams) => {
      const result =
        id == 0 ? await apiDivisions.create(title) : await apiDivisions.update(id, title);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: divisions.search._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useDivisionDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiDivisions.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: divisions.search._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useDivisionsSearchInline = (enabled: boolean = true) => {
  const [filter, setFilter] = useState<ApiDivisionFilter>({
    page_number: 1,
    text: undefined,
  });
  const { data, isLoading } = useDivisionsSearch(filter, enabled);
  const options = useMemo(() => {
    return (data?.items || []) as SelectItem[];
  }, [data]);

  function search(text: string | undefined) {
    setFilter({ ...filter, text: text });
  }

  return {
    data,
    isLoading,
    options,
    search,
  };
};
