import React, { ElementType, forwardRef } from "react";
import { PolymorphicRef } from "../../helpers/generic-as-prop.ts";
import { twMerge } from "tailwind-merge";
import { StackProps, stackTheme } from "./stack.types.ts";
import { useTheme } from "../themes/provider.tsx";

type StackComponentType = <C extends React.ElementType = "div">(
  props: StackProps<C>
) => React.ReactNode | null;

const StackComponentFN: StackComponentType = forwardRef(
  <T extends ElementType = "div">(
    { direction = "vertical", gap, justify, items, children, className, ...props }: StackProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const theme = useTheme("stack");
    const gapClass = gap ? stackTheme.gap[gap] : undefined;
    const justifyClass = justify ? stackTheme.justify[justify] : undefined;
    const itemsClass = items ? stackTheme.items[items] : undefined;

    return (
      <div
        ref={ref}
        className={twMerge(
          theme.base,
          theme.direction[direction],
          gapClass,
          justifyClass,
          itemsClass,
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default StackComponentFN;
