import { ApiFilterBase } from "./filter.types.ts";

import { SelectItem } from "../ui/select/select.types.ts";

export interface ApiDivision {
  id: number;
  title: string;
  employees_count: number;
}

export interface ApiDivisionFilter extends ApiFilterBase {
  text?: string;
}

export interface ApiDivisionShort extends SelectItem {
  id: number;
}

export const ApiDivisionsFilterDefault: ApiDivisionFilter = {
  page_number: 1,
  text: "",
};
