import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTheme } from "../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import { InputProps } from "./input.types.ts";
import Box from "../box/Box.tsx";
import Text from "../typography/Text.tsx";
import Button from "../button/Button.tsx";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";

export const Input = forwardRef<HTMLInputElement, InputProps>(function InputWithLabel(
  {
    type = "text",
    placeholder = "",
    label = "",
    size = "md",
    disabled,
    readOnly,
    className,
    onFocus,
    onBlur,
    onInput,
    onChange,
    value,
    required,
    error,
    ...rest
  }: InputProps,
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme("input");

  const emitFocus = () => {
    if (isFocused) return;
    if (readOnly || disabled) return;
    if (!inputRef.current) return;

    inputRef.current.focus();
  };

  useImperativeHandle(ref, () => inputRef.current!, []);

  const [isFocused, setFocused] = useState<boolean>(false);
  const [isFilled, setFilled] = useState<boolean>(false);

  //inputRef.current?.matches(":-webkit-autofill") || false
  useEffect(() => {
    if (inputRef.current?.value?.trim().length) {
      setFilled(true);
    }
  }, [{ ...rest }]);

  // Check for autofill and value changes
  useEffect(() => {
    const checkAutofill = () => {
      if (inputRef.current) {
        // Check for Chrome autofill
        const isAutofilled = inputRef.current.matches(":-webkit-autofill");
        // Check for actual value
        const hasValue = inputRef.current.value.trim().length > 0;

        setFilled(isAutofilled || hasValue);
      }
    };

    // Initial check
    checkAutofill();

    // Set up mutation observer to detect autofill
    const observer = new MutationObserver(checkAutofill);

    if (inputRef.current) {
      observer.observe(inputRef.current, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }

    // Check periodically for the first few seconds after mount
    const intervalId = setInterval(checkAutofill, 100);
    setTimeout(() => clearInterval(intervalId), 2000);

    return () => {
      observer.disconnect();
      clearInterval(intervalId);
    };
  }, [value]);

  const onHandleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);

    onFocus && onFocus(event);
  };

  const onHandleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false);

    onBlur && onBlur(event);
  };

  const onHandleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilled(event.target.value.trim().length !== 0);

    onInput && onInput(event);
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilled(event.target.value.trim().length !== 0);

    onChange && onChange(event);
  };

  const handleCopy = () => {
    navigator?.clipboard?.writeText(value?.trim() ?? "");
  };

  return (
    <>
      <Box onClick={emitFocus} className={className}>
        <Box
          className={twMerge(
            theme.cover.base,
            disabled ? theme.cover.disabled : undefined,
            readOnly ? theme.cover.readonly : undefined,
            theme.cover.variants.size[size],
            error ? theme.cover.error : undefined
          )}
        >
          <label
            className={twMerge(
              theme.label.base,
              theme.label.variants.size[size],
              disabled ? theme.label.disabled : undefined,
              readOnly ? theme.label.readonly : undefined,
              isFocused || isFilled ? theme.label.focused : theme.label.usual
            )}
          >
            {label} {required && <span className={"text-danger"}>*</span>}
          </label>
          <Text
            className={twMerge(
              theme.placeholder.base,
              !isFilled && isFocused ? theme.placeholder.shown : undefined
            )}
          >
            {placeholder}
          </Text>
          <input
            className={twMerge(
              theme.input.base,
              disabled ? theme.input.disabled : undefined,
              readOnly ? theme.input.readonly : undefined,
              theme.input.variants.size[size]
            )}
            ref={inputRef}
            onFocus={onHandleFocus}
            onBlur={onHandleBlur}
            onInput={onHandleInput}
            onChange={onChangeInput}
            value={value}
            type={type}
            readOnly={readOnly}
            disabled={disabled}
            {...rest}
          />
          {readOnly && (
            <Button
              onClick={handleCopy}
              className={theme.copy.base}
              variant={"plain"}
              leftIcon={<FontAwesomeIcon icon={"fa-light fa-copy"} />}
            ></Button>
          )}
        </Box>
        {error && (
          <Box className={"pt-2 pb-4"}>
            <Text className={"text-danger"}>{error}</Text>
          </Box>
        )}
      </Box>
    </>
  );
});
