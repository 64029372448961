import { api } from "../axios.ts";
import {
  ApiPipeline,
  ApiPipelineSavePayload,
  ApiPipelineShort,
} from "../../types/recruit/pipeline.types.ts";

export const apiPipelines = {
  getAll: async function () {
    return api.get<ApiPipeline[]>("/api/v1/pipelines");
  },
  create: async function (payload: ApiPipelineSavePayload) {
    return api.post<ApiPipelineShort>("/api/v1/pipelines", payload);
  },
  update: async function (id: number, payload: ApiPipelineSavePayload) {
    return api.put<ApiPipelineShort>(`/api/v1/pipelines/${id}`, payload);
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/pipelines/${id}`);
  },
  getById: async function (id: number) {
    return api.get<ApiPipeline>(`/api/v1/pipelines/${id}`);
  },
};
