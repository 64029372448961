import { api } from "./axios.ts";
import { ApiDivision, ApiDivisionFilter } from "../types/division.types.ts";
import { ApiPagedData } from "../types/filter.types.ts";

export const apiDivisions = {
  create: async function (title: string) {
    return api.post<ApiDivision>("/api/v1/divisions", {
      title: title,
    });
  },
  update: async function (id: number, title: string) {
    return api.put<ApiDivision>(`/api/v1/divisions/${id}`, {
      title: title,
    });
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/divisions/${id}`);
  },
  search: async function (filter: ApiDivisionFilter, signal: AbortSignal) {
    return api.post<ApiPagedData<ApiDivision>>("/api/v1/divisions/search", filter, signal);
  },
};
