import { ElementType } from "react";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";
import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";

export interface InputSizes extends Pick<ThemeSizes, "md" | "lg"> {
  [key: string]: string;
}

export interface UIKitInputTheme extends UIKitThemeBase {
  label: {
    base: string;
    focused: string;
    usual: string;
    disabled: string;
    readonly: string;
    variants: {
      size: InputSizes;
    };
  };
  placeholder: {
    base: string;
    shown: string;
  };
  input: {
    base: string;
    disabled: string;
    readonly: string;
    variants: {
      size: InputSizes;
    };
  };
  cover: {
    base: string;
    disabled: string;
    readonly: string;
    variants: {
      size: InputSizes;
    };
    error: string;
  };
  copy: {
    base: string;
  };
}

export type InputProps<T extends ElementType = "input"> = PolymorphicComponentProp<
  T,
  {
    value?: string;
    type: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    size?: keyof InputSizes;
    error?: string;
  }
>;

export const inputTheme: UIKitInputTheme = {
  base: "",
  cover: {
    base: "relative border-b border-dark/10",
    disabled: "bg-dark/5",
    readonly: "",
    variants: {
      size: {
        md: "h-14",
        lg: "h-18",
      },
    },
    error: "border-danger",
  },
  label: {
    base: "absolute transition-all text-dark/70 select-none",
    focused: "top-2 text-sm",
    usual: "top-4 text-md leading-5",
    disabled: "text-dark/50",
    readonly: "",
    variants: {
      size: {
        md: "",
        lg: "",
      },
    },
  },
  placeholder: {
    base: "absolute top-6 h-5 leading-5 ml-1 text-dark/70 opacity-0 transition-opacity select-none",
    shown: "opacity-100",
  },
  input: {
    base: "w-full bg-none border-none bg-transparent outline-0 font-semibold text-dark box-content",
    disabled: "text-dark/50 pointer-events-none",
    readonly: "text-dark/70 pointer-events-none",
    variants: {
      size: {
        md: "h-5 pt-6 pb-2 text-md",
        lg: "h-6 pt-7 pb-3 text-3xl",
      },
    },
  },
  copy: {
    base: "absolute right-2 top-4 h-7",
  },
};
