import { ComponentProps } from "react";

export interface BoxVariant {
  default: string;
  border: string;
}

export interface BoxProps extends ComponentProps<"div"> {
  children?: React.ReactNode;
  variant?: keyof BoxVariant;
  isLoading?: boolean;
}

export const boxTheme = {
  base: "",
  variants: {
    default: "",
    border: "p-4 border border-dark/5 rounded-md bg-dark/5",
  },
};
