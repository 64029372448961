import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../ui/input/Input.tsx";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import {
  ApiCalendarHoliday,
  ApiCalendarHolidaySavePayload,
} from "../../../../types/calendar-holiday.types.ts";
import { useCalendarHolidaySave } from "../../../../queries/use-calendar-holidays.query.ts";
import { useCountries, useLocationsSearchInline } from "../../../../queries/use-locations.query.ts";
import Select from "../../../../ui/select/Select.tsx";
import Multiselect from "../../../../ui/select/Multiselect.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { Switch } from "../../../../ui/switch/Switch.tsx";
import { useState } from "react";

export interface CalendarHolidayEditModalProps extends ModalProps {
  calendarHoliday: ApiCalendarHoliday;
}

export const CalendarHolidayEditModal = ({
  calendarHoliday,
  ...props
}: CalendarHolidayEditModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const calendarHolidaySchema = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
    country_id: yup.number().required(i18n.t("errors:field_required")),
    location_ids: yup.array().of(yup.number().required()).required(),
  });

  type DepartmentSchema = yup.InferType<typeof calendarHolidaySchema>;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DepartmentSchema>({
    mode: "onSubmit",
    resolver: yupResolver(calendarHolidaySchema),
    values: {
      title: calendarHoliday.title,
      country_id: calendarHoliday.country.id,
      location_ids: calendarHoliday.locations.map((x) => x.id),
    },
    defaultValues: {
      location_ids: [],
    },
  });

  const { data: countries } = useCountries();
  const locations = useLocationsSearchInline();
  const calendarHolidaySave = useCalendarHolidaySave(control);
  const [isImportFromWeb, setIsImportFromWeb] = useState(true);

  const onSubmit = handleSubmit(async (schema) => {
    const savePayload: ApiCalendarHolidaySavePayload = {
      title: schema.title,
      country_id: schema.country_id,
      location_ids: schema.location_ids,
    };
    if (calendarHoliday.id == 0 && isImportFromWeb) {
      savePayload.year = new Date().getFullYear();
    }
    calendarHolidaySave.mutate(
      { id: calendarHoliday.id, payload: savePayload },
      {
        onSuccess: (data) => {
          if (calendarHoliday.id == 0) {
            analyticsService.trackEvent(analyticEvents.calendarHolidays.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.calendarHolidays.edited, {
              [analyticProperties.id]: data.id,
            });
          }
          close(id);
        },
      }
    );
  });

  return (
    <>
      <Modal
        {...props}
        title={t("core:calendar_holiday_policy")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={calendarHolidaySave.isPending}
              onClick={onSubmit}
            >
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack>
            <Input
              label={t("common:title")}
              required={true}
              autoFocus={true}
              type="text"
              {...register("title")}
              error={errors.title?.message}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  options={countries || []}
                  disabled={calendarHoliday.id != 0}
                  emptyTitle={t("core:country")}
                  searchable
                  required
                  value={value}
                  onChange={onChange}
                  error={errors.country_id?.message}
                />
              )}
              control={control}
              name={"country_id"}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Multiselect
                  label={t("common:locations")}
                  searchable={true}
                  searchOptions={locations.options}
                  emptyTitle={t("common:select")}
                  values={value}
                  onChange={onChange}
                  options={[]}
                  error={errors.location_ids?.message}
                />
              )}
              control={control}
              name={"location_ids"}
            />

            {calendarHoliday.id == 0 && (
              <Switch
                className={"pt-4"}
                checked={isImportFromWeb}
                onChange={setIsImportFromWeb}
                title={t("core:calendar_holiday_download")}
                description={t("core:calendar_holiday_download_confirm_body", {
                  year: new Date().getFullYear(),
                })}
              />
            )}
          </Stack>
        </form>
      </Modal>
    </>
  );
};
