import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { SelectItem } from "../../../../ui/select/select.types.ts";
import { SelectOption } from "../../../../ui/select/SelectOption.tsx";
import { ApiAnalyticsGroupBy } from "../../../../types/analytics/analytics.recruiting.types.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface ButtonChooseGroupByProps {
  value: string | null | undefined;
  onChange: (group: string) => void;
}

export const RecruitingReportingButtonChooseGroupBy = ({
  value,
  onChange,
}: ButtonChooseGroupByProps) => {
  const { t } = useTranslation();
  const groupByOptions: SelectItem[] = [
    {
      id: ApiAnalyticsGroupBy.job,
      title: `${t("common:grouping")}:  ${t("ats:job")}`,
    },
    {
      id: ApiAnalyticsGroupBy.owner,
      title: `${t("common:grouping")}:  ${t("ats:recruiter")}`,
    },
    {
      id: ApiAnalyticsGroupBy.none,
      title: `${t("common:grouping")}:  ${t("common:date")}`,
    },
  ];
  const selectedGrouping = useMemo(() => {
    const groupType = value || ApiAnalyticsGroupBy.none;
    return groupByOptions.find((x) => x.id == groupType);
  }, [value]);

  return (
    <DropdownMenu
      trigger={
        <Button
          variant={"secondary"}
          rightIcon={<FontAwesomeIcon icon={"fa-light fa-chevron-down shrink-0"} />}
        >
          {selectedGrouping?.title}
        </Button>
      }
    >
      {groupByOptions.map((option: SelectItem) => (
        <SelectOption
          key={`group-by-${option.id} `}
          item={{
            id: option.id,
            title: option.title,
            selected:
              value?.toString() == option.id.toString() ||
              (value == null && option.id == ApiAnalyticsGroupBy.none),
            item: option,
          }}
          isMulti={false}
          handleSelect={() => {
            onChange(option.id.toString());
          }}
        />
      ))}
    </DropdownMenu>
  );
};
