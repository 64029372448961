import { useTranslation } from "react-i18next";
import { EmployeeAvatar } from "../../components/employee-avatar/EmployeeAvatar.tsx";
import { useEmployeeStore } from "../../stores/employee.store.ts";

import { Page } from "../../components/page/Page.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import { GradientContainer } from "../../components/gradient-container/GradientContainer.tsx";
import { Title } from "../../components/title/Title.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import Button from "../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import { useJobsSearch } from "../../queries/recruit/use-jobs.query.ts";
import { useCallback, useState } from "react";
import {
  ApiJobsFilter,
  ApiJobsFilterDefault,
  getJobEmoji,
  JobStatus,
} from "../../types/recruit/job.types.ts";
import Avatar from "../../ui/avatar/Avatar.tsx";
import { UserCard } from "../../components/user-card/UserCard.tsx";
import LinkTo from "../../ui/link/LinkTo.tsx";
import Skeleton from "../../ui/skeleton/Skeleton.tsx";
import Box from "../../ui/box/Box.tsx";
import { useEmployeeDetails, useEmployees } from "../../queries/employees/use-employees.query.ts";
import { PermissionType } from "../../types/role.types.ts";
import { ApiEmployeeFilter, ApiEmployeeFilterSort } from "../../types/employees/employee.types.ts";
import { EmployeeViewModal } from "../employees/widgets/EmployeeViewModal.tsx";
import useModals from "../../ui/modal/modal.store.ts";
import ButtonLink from "../../ui/button/ButtonLink.tsx";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../services/analytics-service.ts";
import {
  PreviewEmployeesModal,
  PreviewEmployeesModalColumn,
} from "../employees/employee/widgets/PreviewEmployeesModal.tsx";
import LinkButtonTo from "../../ui/link/LinkButtonTo.tsx";
import { HomeUpcomingEvents } from "./components/HomeUpcomingEvents.tsx";
import { HomeToday } from "./components/HomeToday.tsx";
import { ApprovalRequestTimeOffModal } from "../approvals/widgets/ApprovalRequestTimeOffModal.tsx";
import { ApiApprovalRequest } from "../../types/approval-request.types.ts";

// import { ApiFilePathTypes } from "../../types/file.types.ts";

export function Home() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { renderEmployeeName, renderPartialDate } = useFormatter();
  const { openModal } = useModals();
  const modalsService = useModals();
  const [jobsFilter] = useState<ApiJobsFilter>({
    ...ApiJobsFilterDefault,
    status_list: [JobStatus.published, JobStatus.draft],
    my_jobs: true,
  });

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.home.viewed);
  });

  const { data: employeeFull, isLoading: isEmployeeFullLoading } = useEmployeeDetails(
    employee?.id || undefined
  );
  const { data: myTeamEmployees, isLoading: isMyTeamEmployees } = useEmployees(
    {
      page_number: 1,
      manager_ids: employee ? [employee.id] : [],
    },
    !!employee
  );

  const upcomingBirthdaysFilter: ApiEmployeeFilter = {
    page_number: 1,
    sort_by: ApiEmployeeFilterSort.date_birth,
  };
  const { data: upcomingBirthdays, isLoading: isUpcomingBirthdays } =
    useEmployees(upcomingBirthdaysFilter);

  const { data: jobs, isLoading: jobsIsLoading } = useJobsSearch(jobsFilter);

  const handleEditOrCreateTimeOffRequest = useCallback(
    (timeOffRequest: ApiApprovalRequest | undefined = undefined) => {
      modalsService.openModal(ApprovalRequestTimeOffModal, { timeOffRequest: timeOffRequest });
    },
    []
  );

  return (
    <Page className={"bg-light/80 h-full overflow-auto"}>
      <div className="relative h-48">
        {employee && (
          <>
            {employee.background_url ? (
              <img
                src={employee.background_url}
                alt={t("common:background")}
                className="w-full h-full object-cover absolute inset-0"
              />
            ) : (
              employee.color_palette &&
              employee.color_palette.length >= 2 && (
                <GradientContainer color_palette={employee.color_palette} />
              )
            )}
            <div className="w-full h-full absolute inset-0 bg-gradient-to-b from-white/60 to-transparent" />
          </>
        )}
        <Stack className="relative z-10">
          <Page.Header title={t("core:home")} />
          {employee && (
            <Page.Content>
              <Stack className={"container-md mx-auto"}>
                <Stack items={"center"} direction={"vertical"} gap={"md"}>
                  <EmployeeAvatar size="2xl" employee={employee} />
                  <Stack items={"center"} direction={"vertical"}>
                    <Title
                      align={"center"}
                      header={`${t("common:welcome")}, ${employee.first_name}`}
                      size={"lg"}
                      // caption={
                      //   "Today is your " + daysWorkedFormatted + " day at " + employee.organization.title
                      // }
                    />
                    {/*<Text className={"text-dark/60"}>*/}
                    {/*  {"Today is your " + daysWorkedFormatted + " at " + employee.organization.title}*/}
                    {/*</Text>*/}
                  </Stack>
                  <Stack direction={"horizontal"} gap={"sm"}>
                    <ButtonLink
                      leftIcon={<FontAwesomeIcon icon="fa-light fa-circle-user" />}
                      variant={"secondary"}
                      href={`/employees/${employee.id}`}
                    >
                      {t("core:profile")}
                    </ButtonLink>

                    {employee.organization.time_off_tracking.is_enabled && (
                      <Stack gap={"sm"} direction={"horizontal"}>
                        <Button
                          leftIcon={<FontAwesomeIcon icon="fa-light fa-tree-palm" />}
                          variant={"secondary"}
                          onClick={() => {
                            handleEditOrCreateTimeOffRequest();
                          }}
                        >
                          {t("time_off:request_timeoff")}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <HomeToday />

                {jobsIsLoading && (
                  <Box className={"pt-10"}>
                    <Skeleton className={"w-full h-64"} />
                  </Box>
                )}
                {jobs && jobs.items.length > 0 && (
                  <>
                    <Stack direction={"horizontal"} justify={"between"}>
                      <Title
                        header={t("ats:my_active_jobs")}
                        size={"lg"}
                        paddingBottom
                        paddingTop
                      />
                      <ButtonLink
                        blank={true}
                        href={"/recruitment/jobs"}
                        rightIcon={<FontAwesomeIcon icon="fa-light fa-chevron-right" />}
                        className={"mt-10"}
                        variant={"secondary"}
                      >
                        {t("common:view_all")}
                      </ButtonLink>
                    </Stack>
                    <Stack direction={"horizontal"} gap={"md"}>
                      {jobs.items.slice(0, 5).map((job) => (
                        <LinkTo
                          to={`/recruitment/candidates/?job_id=${job.id}`}
                          variant={"plain"}
                          key={job.id}
                        >
                          <UserCard
                            size={"md"}
                            title={job.title}
                            caption={t("plurals:plural_candidates", {
                              count: job.candidates_count,
                            })}
                            avatarElement={
                              <Avatar size={"2xl"} acronym={job.title} emoji={getJobEmoji(job)} />
                            }
                          />
                        </LinkTo>
                      ))}
                    </Stack>
                  </>
                )}
                <HomeUpcomingEvents />
                <Stack direction={"horizontal"} justify={"between"}>
                  <Title
                    header={employee.organization.title}
                    size={"lg"}
                    paddingBottom
                    paddingTop
                  />
                </Stack>
                {isEmployeeFullLoading && isMyTeamEmployees && (
                  <Skeleton className={"w-full h-64"} />
                )}
                <Stack direction={"horizontal"} gap={"md"}>
                  {employeeFull && employeeFull.employee_position.department && (
                    <LinkTo
                      to={
                        employee &&
                        employee.permissions.see_departments_structure == PermissionType.edit
                          ? `/departments/chart?department_id=${employeeFull.employee_position.department.id}`
                          : ""
                      }
                      variant={"plain"}
                    >
                      <UserCard
                        size={"md"}
                        title={employeeFull.employee_position.department.title}
                        caption={t("core:department")}
                        avatarElement={
                          <Avatar
                            acronym={employeeFull.employee_position.department.title}
                            size={"2xl"}
                          />
                        }
                      />
                    </LinkTo>
                  )}
                  {myTeamEmployees && myTeamEmployees.items.length > 0 && (
                    <LinkTo
                      to={
                        employee &&
                        employee.permissions.see_organization_structure == PermissionType.edit
                          ? `/employees/chart?employee_id=${employee.id}`
                          : ""
                      }
                      variant={"plain"}
                    >
                      <UserCard
                        size={"md"}
                        title={t("core:my_subordinates")}
                        caption={t("plurals:employees_count", {
                          count: myTeamEmployees.metadata.total_items,
                        })}
                        avatarElement={
                          <Box
                            className={
                              "p-smd bg-brand-dark/5 rounded-lg aspect-square grid grid-cols-2 gap-sm"
                            }
                          >
                            {myTeamEmployees.items.slice(0, 4).map((directEmployee) => (
                              <EmployeeAvatar
                                key={`direct-employee-${directEmployee.id}`}
                                employee={directEmployee}
                                size={"xl"}
                              />
                            ))}
                          </Box>
                        }
                      />
                    </LinkTo>
                  )}
                  {employeeFull && employeeFull.employee_position.manager && (
                    <LinkButtonTo
                      className={"self-start"}
                      variant={"plain"}
                      onClick={() => {
                        if (employeeFull.employee_position.manager) {
                          openModal(EmployeeViewModal, {
                            employeeId: employeeFull.employee_position.manager.id,
                          });
                        }
                      }}
                    >
                      <UserCard
                        size={"md"}
                        title={renderEmployeeName(employeeFull.employee_position.manager)}
                        caption={t("core:my_manager")}
                        avatarElement={
                          <EmployeeAvatar
                            employee={employeeFull.employee_position.manager}
                            size={"2xl"}
                          />
                        }
                      />
                    </LinkButtonTo>
                  )}
                </Stack>

                {isUpcomingBirthdays && (
                  <Box className={"pt-10"}>
                    <Skeleton className={"w-full h-64"} />
                  </Box>
                )}
                {employee.organization.calendar_settings.is_enabled &&
                  upcomingBirthdays &&
                  upcomingBirthdays.items.length > 0 && (
                    <>
                      <Stack direction={"horizontal"} justify={"between"}>
                        <Title
                          header={t("core:upcoming_birthdays")}
                          size={"lg"}
                          paddingBottom
                          paddingTop
                        />
                        <Button
                          rightIcon={<FontAwesomeIcon icon="fa-light fa-chevron-right" />}
                          className={"mt-10"}
                          variant={"secondary"}
                          onClick={() => {
                            openModal(PreviewEmployeesModal, {
                              filter: upcomingBirthdaysFilter,
                              addColumns: [PreviewEmployeesModalColumn.birth_date],
                              modalTitle: t("core:upcoming_birthdays"),
                              source: "Home: Upcoming birthdays",
                            });
                          }}
                        >
                          {t("common:view_all")}
                        </Button>
                      </Stack>
                      <Stack direction={"horizontal"} gap={"md"}>
                        {upcomingBirthdays.items.slice(0, 5).map((birthdayEmployee) => (
                          <Box
                            key={`birthday-${birthdayEmployee.id}`}
                            className={"self-start cursor-pointer"}
                            onClick={() => {
                              openModal(EmployeeViewModal, {
                                employeeId: birthdayEmployee.id,
                              });
                            }}
                          >
                            <UserCard
                              size={"md"}
                              title={renderEmployeeName(birthdayEmployee)}
                              caption={t(
                                `${t("core:birthday")}\n${birthdayEmployee.birth_date ? renderPartialDate(birthdayEmployee.birth_date) : ""}`
                              )}
                              avatarElement={
                                <Avatar
                                  size={"2xl"}
                                  acronym={renderEmployeeName(birthdayEmployee)}
                                  url={birthdayEmployee.avatar_url}
                                />
                              }
                            />
                          </Box>
                        ))}
                      </Stack>
                    </>
                  )}
              </Stack>
            </Page.Content>
          )}
        </Stack>
      </div>
    </Page>
  );
}
