import { Page } from "../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import Text from "../../ui/typography/Text.tsx";
import LinkTo from "../../ui/link/LinkTo.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { Title } from "../../components/title/Title.tsx";

export default function EmailConfirmed() {
  const { t } = useTranslation();
  return (
    <>
      <Page>
        <Page.Content>
          <Stack className={"container-sm mx-auto py-16"}>
            <Title
              header={t("core:welcome_after_email_confirm_title")}
              size={"lg"}
              paddingBottom
              paddingTop
            />
            <Title
              header={t("core:welcome_after_email_confirm_title_2")}
              size={"md"}
              paddingBottom
              paddingTop
            />
            <Text>{t("core:welcome_after_email_confirm_description")}</Text>
            <Title
              header={t("core:welcome_after_email_confirm_whats_next")}
              size={"md"}
              paddingTop
            />
            <Title
              header={t("core:welcome_after_email_confirm_invite_your_team")}
              size={"sm"}
              paddingBottom
              paddingTop
            />
            <Text>
              {t("core:welcome_after_email_confirm_invite_your_team_description")}{" "}
              <LinkTo to={"/employees"}>
                {t("core:welcome_after_email_confirm_invite_your_team_button")}
              </LinkTo>
            </Text>
            <Title
              header={t("core:welcome_after_email_confirm_install_chrome")}
              size={"sm"}
              paddingBottom
              paddingTop
            />
            <Text>
              {t("core:welcome_after_email_confirm_install_chrome_description")}{" "}
              <LinkTo
                onClick={() => {
                  analyticsService.trackEvent(analyticEvents.pageAction, {
                    [analyticProperties.actionType]: "Extension Clicked",
                    [analyticProperties.source]: "Email Confirmed",
                  });
                }}
                target={"_blank"}
                to={
                  "https://chromewebstore.google.com/detail/skailer-sourcing-extensio/iifbgblakgpljhhnejbmfoajbbfoiocl"
                }
              >
                {t("core:welcome_after_email_confirm_install_chrome_button")}
              </LinkTo>
            </Text>
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
}
