import { RuleConditionType } from "../types/role.types.ts";
import { useTranslation } from "react-i18next";
import { SelectItem } from "../ui/select/select.types.ts";

export default function useApprovalPolicyHook() {
  const { t } = useTranslation();

  const getFlowRuleTypes = (): SelectItem[] => {
    return [
      {
        id: RuleConditionType.employee,
        title: t("core:employee"),
      },
      {
        id: RuleConditionType.direct_manager,
        title: t("core:rule_direct_manager_title"),
      },
      {
        id: RuleConditionType.manager_of_direct_manager,
        title: t("core:rule_manager_of_direct_manager_title"),
      },
      {
        id: RuleConditionType.admins,
        title: t("core:rule_admins"),
      },
    ];
  };

  const getFlowRuleTitleByType = (type: RuleConditionType): SelectItem | undefined => {
    return getFlowRuleTypes().find((rule) => rule.id === type);
  };

  return { getFlowRuleTypes, getFlowRuleTitleByType };
}
