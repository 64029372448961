import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException } from "../types/api.types.ts";
import { apiLocations } from "../api/location.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { ApiLocationSavePayload } from "../types/location.types.ts";
import { useMemo, useState } from "react";
import { SelectItem } from "../ui/select/select.types.ts";

const locationsKey = createQueryKeys("locations", {
  countries: null,
  all: null,
});

export const useCountries = (enabled = true) => {
  return useQuery({
    ...locationsKey.countries,
    queryFn: async () => {
      const result = await apiLocations.getAllCountries();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
    enabled: enabled,
  });
};

export const useLocations = (enabled = true) => {
  return useQuery({
    ...locationsKey.all,
    queryFn: async () => {
      const result = await apiLocations.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
    enabled: enabled,
  });
};

export const useLocationSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiLocationSavePayload }) => {
      const result =
        id == 0 ? await apiLocations.create(payload) : await apiLocations.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: locationsKey.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useLocationDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiLocations.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: locationsKey.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useLocationsSearchInline = (enabled: boolean = true) => {
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);

  const { data, isLoading } = useLocations(enabled);

  const options = useMemo(() => {
    const allData = (data || []) as SelectItem[];
    const regex = new RegExp(searchKey || "", "i");
    return allData.filter((option) => regex.test(option.title));
  }, [data, searchKey]);

  function search(text: string | undefined) {
    setSearchKey(text);
  }

  return {
    data,
    isLoading,
    options,
    search,
  };
};
