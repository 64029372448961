import { DependencyList, EffectCallback, useEffect } from "react";

const useAbortedEffect = (
  effect: (signal: AbortSignal) => EffectCallback | void,
  dependencies: DependencyList | undefined
): void => {
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const cleanupEffect = effect(signal);

    return () => {
      if (cleanupEffect) {
        cleanupEffect();
      }
      abortController.abort();
    };
  }, dependencies);
};

export default useAbortedEffect;
