import { useTranslation } from "react-i18next";
import { EmptyStateBase } from "./EmptyStateBase.tsx";

export default function EmptyStatePage404() {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      emoji={"🦥"}
      title={t("errors:page_not_found")}
      description={t("errors:page_not_found_description")}
    />
  );
}
