import { ComponentProps, ReactNode } from "react";
import { UIKitThemeBase } from "../themes/theme.types.ts";

export interface DropdownMenuProps extends ComponentProps<"div"> {
  trigger?: ReactNode;
  children: ReactNode;
}

export type UIKitDropdownTheme = UIKitThemeBase;

export const dropdownTheme: UIKitDropdownTheme = {
  base: "shadow-md bg-light rounded-lg overflow-hidden min-w-52 py-1 flex flex-col px-1 gap-1 max-w-96",
};
