import { useTranslation } from "react-i18next";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Page } from "../../../../components/page/Page.tsx";
import { Allotment } from "allotment";
import { useEmployeeAppConfigStore, useEmployeeStore } from "../../../../stores/employee.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useApplicantDetails,
  useApplicantShortCache,
} from "../../../../queries/recruit/use-applicants.query.ts";
import { useCallback, useMemo, useState } from "react";
import { ApplicantProfile } from "./ApplicantProfile.tsx";
import { useCandidatesByApplicant } from "../../../../queries/recruit/use-candidates.query.ts";
import { CandidateCard } from "./CandidateCard.tsx";
import { ApiCandidate } from "../../../../types/recruit/applicant.types.ts";
import { EmptyStateBase } from "../../../../components/empty-state/EmptyStateBase.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { ApplicantAssignToJobModal } from "../widgets/ApplicantAssignToJobModal.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useDebounce } from "react-use";
import { parseUrlHelper } from "../../../../helpers/parse-url.helper.ts";
import { PermissionType } from "../../../../types/role.types.ts";
import Box from "../../../../ui/box/Box.tsx";
import { JobStatus } from "../../../../types/recruit/job.types.ts";
import { Title } from "../../../../components/title/Title.tsx";

export const ApplicantDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { recruitRightPaneSize, setRecruitRightPaneSize } = useEmployeeAppConfigStore();
  const modalsService = useModals();
  const { employee } = useEmployeeStore();

  const currentApplicantId = useMemo(() => (id ? parseInt(id, 10) : undefined), [id]);

  const { data: applicantFull } = useApplicantDetails(currentApplicantId);
  const { data: applicantShort } = useApplicantShortCache(currentApplicantId);

  const { data: candidates } = useCandidatesByApplicant(currentApplicantId);

  // Костыль, но пока как временное решение START
  const [searchParams] = useSearchParams();
  const jobId = parseUrlHelper.getNumberArray(searchParams, "job_id");

  const candidatesSorted = useMemo(() => {
    if (!candidates) return [];

    const filteredCandidates = candidates.filter((candidate) => {
      return (
        candidate.job.status != JobStatus.archived ||
        (jobId && jobId.length == 1 && candidate.job.id == jobId[0])
      );
    });

    return filteredCandidates.sort((a) => {
      if (jobId && jobId.length == 1) {
        return a.job.id == jobId[0] ? -1 : 1;
      }

      return 0;
    });
  }, [candidates, jobId]);
  const candidatesOnArchivedJobs = useMemo(() => {
    if (!candidates) return [];

    return candidates.filter((candidate) => {
      return candidate.job.status == JobStatus.archived;
    });
  }, [candidates, jobId]);
  // Костыль, но пока как временное решение END

  const handleAssignToJob = useCallback(() => {
    if (!applicantShort) return;

    modalsService.openModal(ApplicantAssignToJobModal, {
      applicant: applicantShort,
    });
  }, [applicantShort]);

  // Так как событие изменения вызывается слишком часто, делаем задержку
  const [lastPanelSize, setLastPanelSize] = useState<number | undefined>(undefined);
  useDebounce(
    () => {
      if (lastPanelSize != undefined) {
        analyticsService.trackEvent(analyticEvents.pageAction, {
          [analyticProperties.actionType]: "Employee App Config Changed",
          [analyticProperties.title]: "Recruiting Right Pane Size",
          [analyticProperties.id]: lastPanelSize,
        });
      }
    },
    500,
    [lastPanelSize]
  );

  const canManageCandidate: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.manage_candidates == PermissionType.edit;
  }, [employee]);

  return (
    <Allotment
      onChange={(sizes: number[]) => {
        if (sizes.length > 1) {
          setRecruitRightPaneSize(sizes[1] | 0);
          setLastPanelSize(sizes[1] | 0);
        }
      }}
    >
      <Allotment.Pane>
        <Stack
          direction={"vertical"}
          className={"h-full overflow-y-auto flex-grow bg-light shadow-left"}
        >
          <ApplicantProfile
            applicant={applicantShort || applicantFull}
            applicantFull={applicantFull}
          />
        </Stack>
      </Allotment.Pane>
      <Allotment.Pane minSize={200} preferredSize={recruitRightPaneSize}>
        <Stack
          direction={"vertical"}
          className={"h-full overflow-y-auto bg-light/90 shadow-left flex-shrink-0"}
        >
          <Box className={"bg-light"}>
            <Page.Header title={t("ats:jobs")} nowrap={true}>
              {canManageCandidate && (
                <Stack className={"overflow-hidden"}>
                  <Button onClick={handleAssignToJob} variant={"secondary"}>
                    {t("ats:assign_to_job")}
                  </Button>
                </Stack>
              )}
            </Page.Header>
          </Box>
          <Stack>
            {candidatesSorted &&
              currentApplicantId &&
              candidatesSorted.map((candidate: ApiCandidate, index: number) => (
                <CandidateCard
                  key={`candidate-${candidate.id}`}
                  isFirst={index === 0}
                  collapsed={index > 0}
                  candidate={candidate}
                  applicantId={currentApplicantId}
                />
              ))}
            {currentApplicantId && candidatesOnArchivedJobs.length > 0 && (
              <>
                <Box className={" px-6 border-dark/5 border-b"}>
                  <Title
                    header={t("ats:job_status_archived")}
                    size={"xs"}
                    paddingTop={true}
                    paddingBottom={true}
                  />
                </Box>
                {candidatesOnArchivedJobs.map((candidate: ApiCandidate) => (
                  <CandidateCard
                    key={`candidate-${candidate.id}`}
                    isFirst={false}
                    collapsed={true}
                    candidate={candidate}
                    applicantId={currentApplicantId}
                  />
                ))}
              </>
            )}
          </Stack>
          {candidates && candidates.length == 0 && (
            <EmptyStateBase
              emoji={"🦥"}
              title={t("ats:no_jobs_assigned")}
              primaryButton={
                <Button onClick={handleAssignToJob} size={"sm"} variant={"secondary"}>
                  {t("ats:assign_to_job")}
                </Button>
              }
            />
          )}
        </Stack>
      </Allotment.Pane>
    </Allotment>
  );
};
