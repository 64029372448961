import Stack from "../../../ui/stack/Stack.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { ApiApprovalRequestTimeOffRejectCode } from "../../../types/approval-request.types.ts";
import { useTranslation } from "react-i18next";

export function ApprovalRequestTimeOffRejectInfo({
  code,
}: {
  code: ApiApprovalRequestTimeOffRejectCode;
}) {
  const { t } = useTranslation();
  const error = t(`time_off:reject_code_${code}`);

  switch (code) {
    case ApiApprovalRequestTimeOffRejectCode.asked_less_then_min_daily_amount:
    case ApiApprovalRequestTimeOffRejectCode.asked_less_then_min_request_total:
    case ApiApprovalRequestTimeOffRejectCode.asked_less_then_max_request_total:
    case ApiApprovalRequestTimeOffRejectCode.asked_date_from_less_then_min_notice_period:
    case ApiApprovalRequestTimeOffRejectCode.asked_date_from_more_then_max_notice_period:
  }

  return (
    <Stack className={"h-14"} justify={"center"}>
      <Text className={"text-danger"}>{error}</Text>
    </Stack>
  );
}
