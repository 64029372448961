import { api } from "./axios";
import { ApiFile } from "../types/common.types";
import { ApiFileLink } from "../types/file.types";

export const apiFiles = {
  /*
   * Api could be called without query.
   */
  add: (data: FormData) => {
    return api.post<ApiFile>(`/api/v1/files/add`, data);
  },
  /*
   * Api could be called without query.
   */
  delete: (uuid: string) => {
    return api.delete(`/api/v1/files/delete/${uuid}`);
  },
  /*
   * Api could be called without query.
   */
  getLink: (uuid: string, days: number) => {
    return api.get<ApiFileLink>(`/api/v1/files/links?uuid=${uuid}&days=${days}`);
  },
};
