import Stack from "../../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { ApiRole, PermissionType, RoleType } from "../../../../../types/role.types.ts";
import useRoleEditService, { FieldPermissionConfigItem } from "../role-edit-service.ts";
import { UseFormReturn } from "react-hook-form";
import { EditRoleSchema } from "../role-edit.types.ts";
import { Title } from "../../../../../components/title/Title.tsx";
import { RoleEditPermissionField } from "./RoleEditPermissionField.tsx";
import { RoleEditApplyToSection } from "./RoleEditApplyToSection.tsx";

export interface RoleEditEmployeePermissionsProps {
  role?: ApiRole | undefined;
  formHook: UseFormReturn<EditRoleSchema>;
}

export const RoleEditEmployeePermissions = ({
  role,
  formHook,
}: RoleEditEmployeePermissionsProps) => {
  const { t } = useTranslation();
  const {
    getEmployeeFieldPermissionsConfig,
    getEmployeeFieldPermissionsConfigAddress,
    getEmployeeFieldPermissionsConfigJob,
    getEmployeeFieldPermissionsCompensation,
  } = useRoleEditService();
  const { register, control, getValues } = formHook;

  return (
    <Stack>
      <Title header={t("core:personal")} size={"xs"} paddingBottom paddingTop />
      {getEmployeeFieldPermissionsConfig().map((item, index) => (
        <RoleEditPermissionField
          item={item as FieldPermissionConfigItem}
          register={register}
          control={control}
          key={`employee-field-permission-${index}`}
        />
      ))}
      <Title header={t("core:address")} size={"xs"} paddingBottom paddingTop />
      {getEmployeeFieldPermissionsConfigAddress().map((item, index) => (
        <RoleEditPermissionField
          item={item as FieldPermissionConfigItem}
          register={register}
          control={control}
          key={`employee-field-permission-address-${index}`}
        />
      ))}
      <Title header={t("core:position")} size={"xs"} paddingBottom paddingTop />
      {getEmployeeFieldPermissionsConfigJob().map((item, index) => (
        <RoleEditPermissionField
          item={item as FieldPermissionConfigItem}
          register={register}
          control={control}
          key={`employee-field-permission-position-${index}`}
        />
      ))}
      <Title header={t("core:compensation")} size={"xs"} paddingBottom paddingTop />
      {getEmployeeFieldPermissionsCompensation().map((item, index) => (
        <RoleEditPermissionField
          item={item as FieldPermissionConfigItem}
          register={register}
          control={control}
          key={`employee-field-permission-compensation-${index}`}
        />
      ))}

      <Title header={t("common:general")} size={"xs"} paddingBottom paddingTop />
      <Stack>
        <RoleEditPermissionField
          item={{
            title: t("core:documents"),
            field: "employee_permissions.employee_documents",
            availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
          }}
          register={register}
          control={control}
        />
      </Stack>

      {[RoleType.custom].indexOf(role?.type || RoleType.custom) !== -1 && (
        <RoleEditApplyToSection
          register={register}
          control={control}
          getValues={getValues}
          role={role}
        />
      )}
    </Stack>
  );
};
