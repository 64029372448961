import { useEffect, useRef, useState } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../tooltip/Tooltip.tsx";

export interface TableTruncatedTextProps {
  title: string;
}

export const TextTruncated = ({ title }: TableTruncatedTextProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const element = contentRef.current;
      if (element) {
        // Add 1px buffer to account for rounding errors
        const isOverflowing = element.scrollWidth > element.clientWidth + 1;
        setIsTruncated(isOverflowing);
        console.log("isOverflowing", isOverflowing, element.scrollWidth, element.clientWidth);
      }
    };

    // Check on mount and whenever title or width changes
    checkOverflow();

    // Add resize listener
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [title]);

  console.log("isTruncated", isTruncated, title);

  // if (isTruncated) {
  return (
    <div className={"flex overflow-hidden"}>
      <Tooltip delay={false} open={isTruncated ? undefined : false}>
        <TooltipTrigger asChild={true} ref={contentRef}>
          <div className={"whitespace-nowrap overflow-ellipsis overflow-hidden"}>{title}</div>
        </TooltipTrigger>
        <TooltipContent>{title}</TooltipContent>
      </Tooltip>
    </div>
  );
  // }
  //
  // return (
  //   <span ref={textRef} className={"line-clamp-1"}>
  //     {title}
  //   </span>
  // );
};
