import { DataProtectionResponsePage } from "../../types/data-protection.types.ts";
import Stack from "../../ui/stack/Stack.tsx";
import { FullScreenCard } from "./components/FullScreenCard.tsx";
import Box from "../../ui/box/Box.tsx";
import { useState } from "react";
import { Title } from "../../components/title/Title.tsx";
import Text from "../../ui/typography/Text.tsx";
import useDataProtectionHook from "../../hooks/use-data-protection.hook.ts";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { DataProtectionResponseForm } from "./components/DataProtectionResponseForm.tsx";
import { DataProtectionResponseLetter } from "./components/DataProtectionResponseLetter.tsx";

export interface DataProtectionResponseProps {
  page: DataProtectionResponsePage;
}

export function DataProtectionResponse({ page }: DataProtectionResponseProps) {
  const [responseSent, setResponseSent] = useState<boolean>();
  const [responseSentType, setResponseSentType] = useState<DataProtectionResponsePage>(page);
  const { getDataForResponsePage } = useDataProtectionHook();
  const pageData = getDataForResponsePage(page);
  const responseData = getDataForResponsePage(responseSentType);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.dataProtection.responsePageViewed, {
      [analyticProperties.actionType]: pageData.analyticsPage,
    });
  });

  return (
    <FullScreenCard>
      <Stack gap={"sm"} className={"w-full sm:max-w-2xl"}>
        <Box className="w-full p-6 lg:p-8 bg-light rounded-md shadow-md">
          <Stack gap={"2xl"}>
            {responseSent && (
              <Stack gap={"md"}>
                <Title header={responseData.finalTitle} />
                <Text>{responseData.finalText}</Text>
              </Stack>
            )}
            {!responseSent &&
              (~[DataProtectionResponsePage.accept, DataProtectionResponsePage.decline].indexOf(
                page
              ) ? (
                <DataProtectionResponseLetter
                  page={page}
                  onSent={(responseType) => {
                    setResponseSent(true);
                    setResponseSentType(responseType ?? page);
                  }}
                />
              ) : (
                <DataProtectionResponseForm
                  page={page}
                  onSent={() => {
                    setResponseSent(true);
                  }}
                />
              ))}
          </Stack>
        </Box>
      </Stack>
    </FullScreenCard>
  );
}
