import { api } from "../axios.ts";
import { ApiPipelineStage, ApiPipelineStagSavePayload } from "../../types/recruit/stages.types.ts";
import { ApiOperationResult } from "../../types/common.types.ts";

export const apiStages = {
  getAll: async function () {
    return api.get<ApiPipelineStage[]>("/api/v1/pipeline-stages");
  },
  create: async function (payload: ApiPipelineStagSavePayload) {
    return api.post<ApiPipelineStage>("/api/v1/pipeline-stages", payload);
  },
  update: async function (id: number, payload: ApiPipelineStagSavePayload) {
    return api.put<ApiPipelineStage>(`/api/v1/pipeline-stages/${id}`, payload);
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/pipeline-stages/${id}`);
  },
  updateSort: async function (payload: { ids: number[] }) {
    return api.put<ApiOperationResult>(`/api/v1/pipeline-stages/sort`, payload);
  },
};
