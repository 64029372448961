import { api } from "./axios";
import { ApiPagedData } from "../types/filter.types.ts";
import { ApiNote, ApiNoteEdit, ApiNotesFilter } from "../types/note.types.ts";

export const apiNotes = {
  updateNote: (employeeId: number, id: number, payload: ApiNoteEdit) => {
    return api.put<ApiNote>(`/api/v1/notes/${employeeId}/${id}`, payload);
  },
  deleteNote: (employeeId: number, id: number) => {
    return api.delete(`/api/v1/notes/${employeeId}/${id}`);
  },
  searchNotes: (employeeId: number, filter: ApiNotesFilter, signal: AbortSignal) => {
    return api.post<ApiPagedData<ApiNote>>(`/api/v1/notes/${employeeId}/search`, filter, signal);
  },
  createNote: (employeeId: number, payload: ApiNoteEdit) => {
    return api.post<ApiNote>(`/api/v1/notes/${employeeId}`, payload);
  },
};
