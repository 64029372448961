import { api } from "./axios.ts";
import { ApiCalendar } from "../types/calendar.types.ts";

export const apiCalendars = {
  // create: (payload: ApiCandidateCreatePayload) => {
  //     return api.post<ApiCandidateShort>(`/api/v1/candidates`, payload);
  // },
  // getByApplicantId: (applicantId: number) => {
  //     return api.get<ApiCandidate[]>(`/api/v1/candidates/by-applicant/${applicantId}`);
  // },
  // delete: (id: number) => {
  //     return api.delete<ApiOperationResult>(`/api/v1/candidates/${id}`);
  // },
  // snooze: (id: number) => {
  //     return api.put<ApiOperationResult>(`/api/v1/candidates/${id}/snooze`, {});
  // },
  getAll: () => {
    return api.get<ApiCalendar[]>(`/api/v1/calendars`);
  },
};
