import { useTranslation } from "react-i18next";
import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import Text from "../../../../ui/typography/Text.tsx";
import Box from "../../../../ui/box/Box.tsx";
import {
  ApiApplicant,
  ApiApplicantContacts,
  ApiApplicantShort,
} from "../../../../types/recruit/applicant.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import ContactButton from "./ContactButton.tsx";
import { ContactTypes } from "../../../../types/common.types.ts";
import { useMemo } from "react";
import { PermissionType } from "../../../../types/role.types.ts";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import { ApplicantDataProtectionListItem } from "./ApplicantDataProtectionListItem.tsx";

export interface ApplicantProfileContactsProps {
  applicant: ApiApplicantShort;
  applicantFull?: ApiApplicant;
  contacts: ApiApplicantContacts;
}

export const ApplicantProfileContacts = ({
  applicant,
  contacts,
  applicantFull,
}: ApplicantProfileContactsProps) => {
  const { t } = useTranslation();
  const { calculateAge, renderPartialDate } = useFormatter();
  const age = calculateAge(applicant.birth_date);
  const ageTitle = renderPartialDate(applicant.birth_date);
  const { employee } = useEmployeeStore();

  function getTelegramUrl(username: string) {
    const cleanUsername = username.replace(/^@/, "").replace(/^https?:\/\//, "");
    return cleanUsername.includes("t.me/")
      ? `https://${cleanUsername}`
      : `https://t.me/${cleanUsername}`;
  }

  const formatPhone = (phone: string) => {
    const cleaned = phone.replace(/\D/g, "");
    if (!cleaned) {
      return undefined;
    }

    if (/^8\d{10}$/.test(cleaned)) {
      return cleaned.replace(/^8/, "7");
    }

    return cleaned;
  };

  const formattedPhone = contacts.phone ? formatPhone(contacts.phone) : undefined;

  const canManageCandidate: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.manage_candidates == PermissionType.edit;
  }, [employee]);

  return (
    <>
      {contacts.phone && (
        <ListItem
          preTitle={t("core:phone")}
          title={contacts.phone}
          valueSlot={
            formattedPhone && (
              <Stack direction={"horizontal"} gap={"xs"}>
                <ContactButton type={ContactTypes.telegram} contact={formattedPhone} />
                <ContactButton type={ContactTypes.whatsapp} contact={formattedPhone} />
                <ContactButton type={ContactTypes.viber} contact={formattedPhone} />
              </Stack>
            )
          }
        />
      )}
      {contacts.email && (
        <ListItem
          preTitle={t("core:email")}
          title={contacts.email}
          to={`mailto:${contacts.email}`}
        />
      )}
      {contacts.skype_username && (
        <ListItem
          preTitle={t("common:skype")}
          title={contacts.skype_username}
          to={`skype:${contacts.skype_username}?chat`}
        />
      )}
      {contacts.telegram_username && (
        <ListItem
          preTitle={t("common:telegram")}
          title={contacts.telegram_username}
          to={getTelegramUrl(contacts.telegram_username)}
        />
      )}
      {ageTitle && (
        <ListItem
          preTitle={t("core:birth_date")}
          title={renderPartialDate(applicant.birth_date)}
          value={age > 0 ? t("plurals:plural_year", { count: age }) : undefined}
        />
      )}
      {applicant.location && <ListItem title={t("common:location")} caption={applicant.location} />}
      {canManageCandidate &&
        applicantFull?.data_protection &&
        applicantFull.data_protection?.map((dataProtection) => (
          <ApplicantDataProtectionListItem
            dataProtection={dataProtection}
            applicantFull={applicantFull}
            key={`data_protection_${dataProtection.type}`}
          />
        ))}
      {applicant.resume && (
        <Box className={"pt-sm"}>
          <Text className={"text-secondary"}>{applicant.resume}</Text>
        </Box>
      )}
      {applicant.salary && (
        <>
          <Title header={t("core:compensation")} paddingTop paddingBottom />
          <ListItem preTitle={t("core:salary")} title={applicant.salary} />
        </>
      )}
    </>
  );
};
