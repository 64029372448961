import { ModalProps } from "../../../ui/modal/modal.types.ts";
import { useTranslation } from "react-i18next";
import useModals from "../../../ui/modal/modal.store.ts";
import Stack from "../../../ui/stack/Stack.tsx";
import Button from "../../../ui/button/Button.tsx";
import Modal from "../../../ui/modal/modal.tsx";
import Box from "../../../ui/box/Box.tsx";
import Avatar from "../../../ui/avatar/Avatar.tsx";
import { Title } from "../../../components/title/Title.tsx";
import Text from "../../../ui/typography/Text.tsx";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import { ChoosePeriodModal } from "../../recruit/reporting/widgets/ChoosePeriodModal.tsx";
import { FileUpload } from "../../../components/file-upload/FileUpload.tsx";
import { ApiEntityTypes, ApiFile } from "../../../types/common.types.ts";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import LinkButtonTo from "../../../ui/link/LinkButtonTo.tsx";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import {
  useApprovalRequestCreate,
  useApprovalRequestUpdate,
} from "../../../queries/use-approval-requests.query.ts";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import { Tag } from "../../../components/tag/Tag.tsx";

import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { FileItem } from "../../../components/file-item/FileItem.tsx";
import { apiFiles } from "../../../api/file.api.ts";
import useErrorHandle, { ServerErrorField } from "../../../hooks/use-error-handle.hook.tsx";
import { Radio } from "../../../ui/radio/Radio.tsx";
import { Input } from "../../../ui/input/Input.tsx";
import {
  ApiApprovalRequest,
  ApiApprovalRequestPayload,
  ApiApprovalRequestTypes,
} from "../../../types/approval-request.types.ts";
import { useEmployeeTimeOffCategoriesData } from "../../../queries/employees/use-employees.query.ts";
import { ApiEmployeeTimeOffCategory } from "../../../types/employees/employee.types.ts";
import { useTimeOffCheck } from "../../../queries/use-time-offs.query.ts";
import { ApprovalRequestTimeOffRejectInfo } from "../components/ApprovalRequestTimeOffRejectInfo.tsx";
import { PermissionType } from "../../../types/role.types.ts";
import { ApiTimeOffCategoryType } from "../../../types/time-off.types.ts";

export interface ApprovalRequestTimeOffModalProps extends ModalProps {
  timeOffCategoryId?: number;
  timeOffRequest?: ApiApprovalRequest;
  employeeId?: number;
}

export const ApprovalRequestTimeOffModal = ({
  timeOffCategoryId,
  timeOffRequest,
  employeeId,
  ...props
}: ApprovalRequestTimeOffModalProps) => {
  const { t } = useTranslation();
  const { id } = { ...props };
  const { renderDate } = useFormatter();
  const { openModal, close } = useModals();
  const { handleErrors } = useErrorHandle();
  const { employee: employeeMe } = useEmployeeStore();
  const isEditMode = timeOffRequest && timeOffRequest.id > 0;
  const [files, setFiles] = useState<ApiFile[]>(timeOffRequest?.files || []);
  const aimEmployeeId = employeeId ?? employeeMe?.id;
  const { data: employeeTimeOffCategories, isLoading: employeeTimeOffCategoriesIsLoading } =
    useEmployeeTimeOffCategoriesData(aimEmployeeId ?? 0, !!aimEmployeeId);
  const canManageBalance =
    employeeMe && employeeMe.permissions.manage_leave_policies == PermissionType.edit;

  const TimeOffSchemaObject = yup.object({
    selectedRequestId: yup.number().required("Field is required"),
    choose_period_from: yup.date().required("Field is required"),
    choose_period_to: yup.date().required("Field is required"),
    add_note: yup.string().nullable(),
    approversList: yup.array().nullable(),
  });

  type TimeOffSchema = yup.InferType<typeof TimeOffSchemaObject>;

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<TimeOffSchema>({
    mode: "onSubmit",
    resolver: yupResolver(TimeOffSchemaObject),
    defaultValues: isEditMode
      ? {
          selectedRequestId: timeOffRequest.time_off_data.category.id,
          choose_period_from: timeOffRequest.time_off_data.date_from
            ? new Date(timeOffRequest.time_off_data.date_from)
            : undefined,
          choose_period_to: timeOffRequest.time_off_data.date_to
            ? new Date(timeOffRequest.time_off_data.date_to)
            : undefined,
          add_note: timeOffRequest.comment || "",
          approversList: [],
        }
      : {
          selectedRequestId: timeOffCategoryId,
          choose_period_from: undefined,
          choose_period_to: undefined,
          add_note: "",
          approversList: [],
        },
  });

  const timeoffRequestCreate = useApprovalRequestCreate(control);
  const timeoffRequestUpdate = useApprovalRequestUpdate(control);

  const selectedRequestId = watch("selectedRequestId");
  const dateFrom = watch("choose_period_from");
  const dateTo = watch("choose_period_to");
  const approvers = watch("approversList") || [];

  const checkPayload = useMemo((): ApiApprovalRequestPayload | undefined => {
    if (!selectedRequestId || !dateFrom || !dateTo) return undefined;

    return {
      type: ApiApprovalRequestTypes.time_off,
      time_off_data: {
        category_id: selectedRequestId,
        date_from: dateFrom,
        date_to: dateTo,
      },
      employee_id: aimEmployeeId,
    };
  }, [selectedRequestId, dateFrom, dateTo]);

  const { data: timeOffCheck, isLoading: timeOffCheckIsLoading } = useTimeOffCheck(checkPayload);

  useEffect(() => {
    if (employeeTimeOffCategories?.length) {
      setValue("selectedRequestId", selectedRequestId || employeeTimeOffCategories[0].id);
    }
  }, [employeeTimeOffCategories]);

  useEffect(() => {
    if (timeOffCheck?.confirming_employees) {
      setValue("approversList", timeOffCheck.confirming_employees);
    }
  }, [timeOffCheck]);

  const onSubmit = handleSubmit((timeoffRequestData) => {
    const payload: ApiApprovalRequestPayload = {
      type: ApiApprovalRequestTypes.time_off,
      comment: timeoffRequestData.add_note || undefined,
      time_off_data: {
        category_id: timeoffRequestData.selectedRequestId,
        date_from: timeoffRequestData.choose_period_from,
        date_to: timeoffRequestData.choose_period_to,
      },
      file_ids: files.map((file) => file.id),
      employee_id: employeeId,
    };

    if (isEditMode) {
      timeoffRequestUpdate.mutate(
        { id: timeOffRequest.id, payload },
        {
          onSuccess: (timeoffRequestUpdated) => {
            analyticsService.trackEvent(analyticEvents.approvals.requestEdited, {
              [analyticProperties.id]: timeoffRequestUpdated.id,
              [analyticProperties.title]: "Time Off",
            });
            close(id);
          },
        }
      );
    } else {
      timeoffRequestCreate.mutate(payload, {
        onSuccess: (timeoffRequestCreated) => {
          analyticsService.trackEvent(analyticEvents.approvals.requestCreated, {
            [analyticProperties.id]: timeoffRequestCreated.id,
            [analyticProperties.title]: "Time Off",
          });
          close(id);
        },
      });
    }
  });

  const handleSetPeriod = () => {
    openModal(ChoosePeriodModal, {
      dateFrom,
      dateTo,
      onApply: (from: Date, to: Date) => {
        setValue("choose_period_from", from);
        setValue("choose_period_to", to);
      },
    });
  };

  const selectedCategory = employeeTimeOffCategories?.find(
    (category: ApiEmployeeTimeOffCategory) => category.id === selectedRequestId
  );

  const defaultEmoji = "⛷️";
  const selectedEmoji = selectedCategory?.emoji || defaultEmoji;

  const formatDateForDisplay = (date: Date | undefined) => {
    return date ? renderDate(date) : t("time_off:choose_period");
  };

  async function removeFile(file: ApiFile) {
    setFiles(files.filter((f) => f.id !== file.id));

    const { error } = await apiFiles.delete(file.uuid);
    if (error) handleErrors(error);
  }

  const timeOffDays =
    dateFrom && dateTo && selectedCategory
      ? +selectedCategory.balance.available_balance - (timeOffCheck?.balance_left ?? 0)
      : 0;

  return (
    <Modal
      {...props}
      withCloser
      layout={"page"}
      size={"lg"}
      actions={
        <Stack gap={"sm"}>
          {!timeOffCheck?.possible_to_create && timeOffCheck?.reject_code && (
            <ApprovalRequestTimeOffRejectInfo code={timeOffCheck.reject_code} />
          )}
          <ServerErrorField errors={errors} />
          <Stack gap={"md"}>
            <Button
              className={
                timeOffCheck && !timeOffCheck.possible_to_create && !canManageBalance
                  ? "opacity-50 pointer-events-none"
                  : ""
              }
              type={"submit"}
              onClick={onSubmit}
              size={"lg"}
              isLoading={timeoffRequestUpdate.isPending || timeoffRequestCreate.isPending}
            >
              {isEditMode ? t("common:save") : t("time_off:request_timeoff")}
            </Button>
            {dateFrom &&
              dateTo &&
              selectedCategory &&
              timeOffCheck &&
              timeOffCheck.balance_left > 0 && (
                <Stack>
                  <Title
                    header={`${t("common:total")}: ${t("plurals:plural_day", {
                      count: timeOffDays,
                    })} (${selectedCategory.title})`}
                    size={"sm"}
                  />
                  <Text className={"text-secondary"}>
                    {`${renderDate(dateFrom)} - ${renderDate(dateTo)}, ${t("plurals:days_available_after_request", { count: timeOffCheck.balance_left })}`}
                  </Text>
                </Stack>
              )}
          </Stack>
        </Stack>
      }
      background={
        <img
          src={"/backgrounds/mountains.jpeg"}
          alt={"background"}
          className={"w-full h-[500px] object-cover"}
        />
      }
    >
      <form onSubmit={onSubmit} className={"gap-sm flex flex-col mt-56"}>
        <Stack gap={"sm"}>
          <Stack className={"p-8 bg-light/80 rounded-md shadow-sm backdrop-blur-md"} gap={"2xl"}>
            <Box className={"relative w-40 h-40"}>
              <Avatar variant={"opaque"} url={undefined} emoji={selectedEmoji} size={"2xl"} />
            </Box>
            <Stack gap={"md"}>
              <Title
                header={
                  isEditMode
                    ? t("time_off:edit_timeoff_request")
                    : t("time_off:create_new_timeoff_request")
                }
                caption={
                  isEditMode
                    ? t("time_off:edit_timeoff_request_description")
                    : t("time_off:create_new_timeoff_request_description")
                }
                size={"lg"}
              />
              <Stack gap={"smd"}>
                <Title header={t("time_off:choose_category")} size="xs" paddingTop />
                <Box variant="border">
                  {employeeTimeOffCategoriesIsLoading && <Skeleton className="w-full h-10" />}
                  {employeeTimeOffCategories?.map((category: ApiEmployeeTimeOffCategory) => (
                    <Radio
                      items={"start"}
                      key={category.id}
                      value={category.title}
                      checked={category.id === selectedRequestId}
                      onChange={() => setValue("selectedRequestId", category.id)}
                    >
                      <Title
                        header={category.title}
                        caption={
                          category.category_type == ApiTimeOffCategoryType.accrues_time
                            ? `${t("plurals:plural_day", {
                                count: Math.round(parseFloat(category.balance.available_balance)),
                              })} ${t("time_off:available")}`
                            : undefined
                        }
                        size={"sm"}
                      />
                    </Radio>
                  ))}
                </Box>
              </Stack>
            </Stack>
          </Stack>

          <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
            <Title header={t("time_off:choose_period")} size="md" />
            <Stack gap={"sm"}>
              <ListItem
                className={"cursor-pointer"}
                onClick={handleSetPeriod}
                title={
                  dateFrom && dateTo
                    ? `${formatDateForDisplay(dateFrom)} — ${formatDateForDisplay(dateTo)}`
                    : undefined
                }
                preTitle={t("time_off:choose_period")}
                valueSlot={
                  <Stack direction={"horizontal"} gap={"sm"}>
                    <LinkButtonTo>{t("common:select")}</LinkButtonTo>
                  </Stack>
                }
              />
              {(errors.choose_period_from || errors.choose_period_to) && (!dateFrom || !dateTo) && (
                <Box className="text-danger">
                  {errors.choose_period_from?.message || errors.choose_period_to?.message}
                </Box>
              )}
            </Stack>
            {timeOffCheckIsLoading && (
              <Stack gap={"sm"}>
                <Skeleton className={"h-4 w-16"} />
                <Skeleton className={"h-4 w-28"} />
              </Stack>
            )}
            {dateFrom &&
              dateTo &&
              selectedCategory &&
              timeOffCheck &&
              selectedCategory.category_type == ApiTimeOffCategoryType.accrues_time && (
                <Stack>
                  <Title
                    size={"sm"}
                    header={`${t("common:total")}: ${t("plurals:plural_day", { count: timeOffDays })}`}
                    caption={
                      timeOffCheck.balance_left > 0
                        ? t("plurals:plural_day", {
                            count: Math.round(+selectedCategory.balance.available_balance),
                          }) +
                          " " +
                          t("time_off:available")
                        : ""
                    }
                  />
                  {timeOffCheck.balance_left < 0 && (
                    <Text className={"text-secondary text-danger"}>
                      {t("time_off:insufficient_days_for_request")}
                    </Text>
                  )}
                </Stack>
              )}
          </Stack>

          <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
            <Title header={t("time_off:add_note")} size="md" />
            <Input
              label={t("time_off:add_note_description")}
              type={"text"}
              {...register("add_note", {
                value: "",
              })}
              error={errors.add_note?.message}
            />
          </Stack>

          <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
            <Title header={t("time_off:add_files")} size="md" />
            <Stack gap={"md"}>
              {files && files.length > 0 && (
                <Stack gap={"sm"}>
                  {files.map((file) => (
                    <FileItem
                      key={`file${file.id}`}
                      file={file}
                      onRemove={() => removeFile(file)}
                    />
                  ))}
                </Stack>
              )}
              <Stack items={"start"}>
                <Box>
                  <FileUpload
                    entityId={employeeMe?.id}
                    entityType={ApiEntityTypes.employee}
                    afterSend={(file) => {
                      if (file) setFiles([...files, file]);
                    }}
                    buttonText={t("common:attach")}
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>

          {approvers && approvers.length > 0 && (
            <Stack className={"p-8 bg-light rounded-md"} gap={"2xl"}>
              <Title header={t("time_off:approval")} size="md" />
              <Stack direction={"horizontal"} gap={"sm"} className={"flex-wrap"}>
                {approvers.map((approver) => (
                  <Tag key={`approver-${approver.id}`} color={"#000"}>
                    {`${approver.first_name} ${approver.last_name}`}
                  </Tag>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </form>
    </Modal>
  );
};
