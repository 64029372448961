import Stack from "../../../../../ui/stack/Stack.tsx";
import { useDataProtectionSettings } from "../../../../../queries/use-data-protection.query.ts";
import { DataProtectionSettingsProvider } from "./DataProtectionSettingsProvider.tsx";
import Skeleton from "../../../../../ui/skeleton/Skeleton.tsx";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";

export function DataProtectionTabSettings() {
  const { data, isLoading } = useDataProtectionSettings();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.dataProtection.viewed, {
      [analyticProperties.tab]: "Settings",
    });
  });

  return (
    <Stack className={"my-6"} gap={"lg"}>
      {isLoading && <Skeleton className={"h-8"} />}
      {data &&
        data.map((provider) => (
          <DataProtectionSettingsProvider
            provider={provider}
            key={`provider_settings_${provider.data_protection_type}`}
          />
        ))}
    </Stack>
  );
}
