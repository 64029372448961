import { api } from "./axios.ts";
import { ApiEmailTemplate, ApiEmailTemplateSavePayload } from "../types/email-template.types.ts";

export const apiEmailTemplates = {
  getAll: () => {
    return api.get<ApiEmailTemplate[]>(`/api/v1/email-templates`);
  },
  create: (payload: ApiEmailTemplateSavePayload) => {
    return api.post<ApiEmailTemplate>(`/api/v1/email-templates`, payload);
  },
  update: (id: number, payload: ApiEmailTemplateSavePayload) => {
    return api.put<ApiEmailTemplate>(`/api/v1/email-templates/${id}`, payload);
  },
  delete: (id: number) => {
    return api.delete(`/api/v1/email-templates/${id}`);
  },
};
