import { ApiEmployeeShort } from "./employees/employee.types.ts";

export enum ApiNotificationTypes {
  event_invite = "event_invite",
  password_changed = "password_changed",
  approval_request = "approval_request",
  simple = "simple",
}

export interface ApiNotificationEvent {
  id: number;
  title: string;
  datetime_start: Date;
  datetime_end: Date;
}

export interface ApiNotificationSimple {
  icon_url?: string;
  emoji?: string;
  pre_title?: string;
  title?: string;
  caption?: string;
  button_title?: string;
  href?: string;
  blank?: boolean;
}

export interface ApiNotificationApprovalRequestTimeOff {
  category_name: string;
  category_emoji?: string;
}

export interface ApiNotificationApprovalRequest {
  request_id: number;
  time_off_data?: ApiNotificationApprovalRequestTimeOff;
}

export interface ApiNotification {
  id: number;
  notification_type: ApiNotificationTypes;

  event?: ApiNotificationEvent;
  simple?: ApiNotificationSimple;
  approval_request?: ApiNotificationApprovalRequest;
}

export interface ApiNotificationsFilter {
  page_number: number;
}

export interface ApiNotificationFull {
  notification: ApiNotification;
  author: ApiEmployeeShort;
}
