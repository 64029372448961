import Stack from "../../ui/stack/Stack.tsx";
import { twMerge } from "tailwind-merge";
import Text from "../../ui/typography/Text.tsx";

const titleTheme = {
  variants: {
    size: {
      lg: "text-3xl tracking-tight font-semibold text-default",
      md: "text-lg tracking-tight font-semibold text-default",
      sm: "text-md font-semibold text-default",
      xs: "text-sm font-semibold uppercase text-default leading-[20px] ",
    },
    align: {
      left: "",
      center: "",
    },
    paddingTop: {
      xs: "pt-6",
      sm: "pt-6",
      md: "pt-8",
      lg: "pt-10",
    },
    paddingBottom: {
      xs: "pb-2",
      sm: "pb-2",
      md: "pb-2",
      lg: "pb-4",
    },
    caption: {
      xs: "text-sm text-dark/60",
      sm: "text-md text-dark/60",
      md: "text-md text-dark/60",
      lg: "text-lg text-dark/60 tracking-tight",
    },
  },
};

export interface TitleProps {
  size?: keyof typeof titleTheme.variants.size;
  align?: keyof typeof titleTheme.variants.align;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  header: string;
  caption?: string;
  className?: string;
}

type As = "h1" | "h2" | "h3" | "h4";
const sizeToComponent: Record<string, As> = {
  xs: "h4",
  sm: "h3",
  md: "h2",
  lg: "h1",
};

export const Title = ({
  header,
  caption,
  size = "md",
  paddingTop = false,
  paddingBottom = false,
  className = "",
}: TitleProps) => {
  const Component: As = sizeToComponent[size];
  return (
    <Stack
      className={twMerge(
        paddingTop ? titleTheme.variants.paddingTop[size] : undefined,
        paddingBottom ? titleTheme.variants.paddingBottom[size] : undefined
      )}
    >
      <Component className={twMerge(titleTheme.variants.size[size], className)}>{header}</Component>
      {caption && <Text className={titleTheme.variants.caption[size]}>{caption}</Text>}
    </Stack>
  );
};
