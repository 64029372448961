import { useTranslation } from "react-i18next";
import Stack from "../../../ui/stack/Stack.tsx";
import { useEffect, useMemo } from "react";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { SettingsCard } from "../components/SettingsCard.tsx";
import { getSettingsVisibleToEmployee } from "./settings.types.ts";
import { Page } from "../../../components/page/Page.tsx";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import Text from "../../../ui/typography/Text.tsx";
import LinkTo from "../../../ui/link/LinkTo.tsx";
import { Title } from "../../../components/title/Title.tsx";
import PageHeader from "../../../components/page/PageHeader.tsx";

export default function Settings() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { renderEmployeeName } = useFormatter();

  const menu = useMemo(() => {
    if (!employee) return [];

    return getSettingsVisibleToEmployee(employee);
  }, [employee]);

  useEffect(() => {
    analyticsService.trackEvent(analyticEvents.settings.viewed);
  }, []);

  return (
    <>
      <Page>
        <PageHeader title={t("common:settings")} showBack={true} />

        <Page.Content>
          <Stack className={"py-10 px-16 gap-10"}>
            {employee && (
              <Stack>
                <Title header={renderEmployeeName(employee)} size={"lg"} />
                <Stack direction={"horizontal"} gap={"sm"}>
                  <Text>{employee.email}</Text>
                  <LinkTo to={"/account-settings"}>{t("core:account_settings")}</LinkTo>
                </Stack>
              </Stack>
            )}
            {menu.map((section) => (
              <Stack key={section.title} gap={"lg"}>
                <Text size={"3xl"} className={"font-semibold"}>
                  {section.title}
                </Text>
                <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                  {section.items.map((item) => (
                    <SettingsCard
                      className={"h-full"}
                      key={item.to}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                      to={item.to}
                    />
                  ))}
                </div>
              </Stack>
            ))}
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
}
