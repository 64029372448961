import { api } from "../axios.ts";
import {
  ApiTerminationReason,
  ApiTerminationReasonSavePayload,
} from "../../types/employees/employee-dismissal-reason.types.ts";
import { ApiOperationResult } from "../../types/common.types.ts";

export const apiTerminationReasons = {
  getAll: () => {
    return api.get<ApiTerminationReason[]>("/api/v1/termination-reasons");
  },
  create: (payload: ApiTerminationReasonSavePayload) => {
    return api.post<ApiTerminationReason>("/api/v1/termination-reasons", payload);
  },
  update: (id: number, payload: ApiTerminationReasonSavePayload) => {
    return api.put<ApiTerminationReason>(`/api/v1/termination-reasons/${id}`, payload);
  },
  delete: (id: number) => {
    return api.delete<ApiOperationResult>(`/api/v1/termination-reasons/${id}`);
  },
};
