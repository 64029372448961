import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectItem } from "../ui/select/select.types.ts";

export default function useThemeOptions() {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      { id: null, title: "Default" },
      { id: "neo", title: "Hoth" },
      { id: "skyline", title: "CMYK" },
      { id: "max", title: "MAX" },
      { id: "wave", title: "Wave" },
    ] as SelectItem[];
  }, [t]);
}
