import { useAppTheme } from "../../themes/provider.tsx";

import { PageContainerProps } from "./page.types.ts";
import { twMerge } from "tailwind-merge";

export default function PageContainer({ children, className, ...props }: PageContainerProps) {
  const theme = useAppTheme("page");
  return (
    <div className={twMerge(theme.base, className)} {...props}>
      {children}
    </div>
  );
}
