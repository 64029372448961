import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiEmployee, ApiEmployeeStatus } from "../../../types/employees/employee.types.ts";
import { ColumnDef } from "@tanstack/react-table";
import Stack from "../../../ui/stack/Stack.tsx";
import Table from "../../../ui/table/Table.tsx";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { EmployeeInlineCard } from "../../../components/employee-inline-card/EmployeeInlineCard.tsx";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { EmployeeFieldPermissions, PermissionType } from "../../../types/role.types.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { Page } from "../../../components/page/Page.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { EmployeeEditModal } from "../widgets/EmployeeEditModal.tsx";
import useEmployeeSearchInline from "../../../queries/employees/use-employees.query.ts";
import { useDebounce, useEffectOnce } from "react-use";
import { PaginationBlock } from "../../../ui/pagination/PaginationBlock.tsx";
import { Search } from "../../../components/search/Search.tsx";
import Box from "../../../ui/box/Box.tsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EmployeesDismissFilterSelector } from "./components/EmployeesDismissFilterSelector.tsx";
import { isEmpty, omitBy } from "lodash";
import PageHeader from "../../../components/page/PageHeader.tsx";
import { getEmployeePositionTitle } from "../../../types/employees/employee-position.types.ts";

import { apiEmployees } from "../../../api";
import { useDownloadHelper } from "../../../helpers/use-download.helper.ts";

export default function Employees() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const modalsService = useModals();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const { isExporting, downloadFile } = useDownloadHelper();

  const employees = useEmployeeSearchInline();

  useEffect(() => {
    const employeeStatus = searchParams.get("status") || ApiEmployeeStatus.active;
    const text = searchParams.get("text") || "";

    setSearchTerm(text);

    employees.setFilter({
      ...employees.filter,
      text: text,
      page_number: 1,
      employee_status: employeeStatus as ApiEmployeeStatus,
    });
  }, [searchParams]);

  useDebounce(
    () => {
      const newParams: Record<string, string | string[]> = omitBy(
        {
          ...Object.fromEntries(searchParams),
          text: searchTerm,
        },
        isEmpty
      ) as Record<string, string | string[]>;
      setSearchParams(newParams);
    },
    300,
    [searchTerm]
  );

  const handleStatusChange = useCallback(
    (status: ApiEmployeeStatus) => {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("status", status);
      navigate(`/employees?${newParams.toString()}`);
    },
    [searchParams, navigate]
  );

  const columns = useMemo<ColumnDef<ApiEmployee>[]>(
    () => [
      {
        header: t("core:full_name"),
        cell: (row) => <EmployeeInlineCard employee={row.row.original} primary />,
        accessorKey: "title",
        minSize: 200,
        maxSize: 300,
      },
      {
        header: t("core:position"),
        cell: (row) => getEmployeePositionTitle(row.row.original),
        accessorKey: "employee_position",
        // size: 150,
      },
      {
        header: t("core:department"),
        cell: (row) => row.row.original.employee_position.department?.title,
        accessorKey: "department",
        // size: 150,
      },
      {
        header: t("core:manager"),
        cell: (row) =>
          row.row.original.employee_position.manager && (
            <EmployeeInlineCard employee={row.row.original.employee_position.manager} />
          ),
        accessorKey: "manager",
        // size: 150,
      },
    ],
    [employees.data]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employees.viewed);
  });

  const canAddEmployee: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.add_employees == PermissionType.edit;
  }, [employee]);

  const canSeeDismissedEmployees: boolean = useMemo(() => {
    return (
      employee != undefined && employee.permissions.see_dismissed_employees == PermissionType.edit
    );
  }, [employee]);

  const handleNewEmployee = useCallback(() => {
    modalsService.openModal(EmployeeEditModal, {
      employee: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        date_hired_on: new Date(),
        employee_position: {
          id: 0,
          date_effective_from: new Date(),
        },
        permissions: {
          [EmployeeFieldPermissions.employee_name]: PermissionType.view,
          [EmployeeFieldPermissions.employee_hired_on]: PermissionType.view,
          [EmployeeFieldPermissions.employee_email]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_email_personal]: PermissionType.none,
          [EmployeeFieldPermissions.employee_phone]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_phone_personal]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_gender]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_date_birth]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_date_birth_display]: PermissionType.none,
          [EmployeeFieldPermissions.employee_avatar]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_background]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_documents]: PermissionType.edit,

          [EmployeeFieldPermissions.employee_number]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_address_line_1]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_address_line_2]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_country]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_state]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_city]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_address_zip_code]: PermissionType.edit,

          [EmployeeFieldPermissions.employee_salary]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_salary_description]: PermissionType.edit,

          [EmployeeFieldPermissions.employee_position_job_title]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_date]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_reporting_to]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_division]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_department]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_location]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_legal_entity]: PermissionType.edit,
          [EmployeeFieldPermissions.employee_position_comment]: PermissionType.edit,
        },
      },
    });
  }, [employee]);

  const handleExportClick = useCallback(() => {
    const filterToExport = {
      ...employees.filter,
      format: "xlsx",
    };

    downloadFile({
      apiCall: () => apiEmployees.export(filterToExport),
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.dataExported, {
          [analyticProperties.title]: "Employees",
        });
      },
    });
  }, [employees.filter]);

  return (
    <>
      <Page>
        <PageHeader title={t("core:employees")} showBack={true} navigateTo={"/"}>
          <Stack direction={"horizontal"} gap={"sm"} items={"center"} className={"overflow-hidden"}>
            {employee && employee.permissions.reporting_export == PermissionType.edit && (
              <Button
                isLoading={isExporting}
                onClick={handleExportClick}
                leftIcon={<FontAwesomeIcon icon="fa-light fa-file-xls" />}
                variant={"secondary"}
              >
                {t("common:export_to_xlsx")}
              </Button>
            )}
            {canSeeDismissedEmployees && (
              <EmployeesDismissFilterSelector
                chosen={
                  (searchParams.get("status") as ApiEmployeeStatus) || ApiEmployeeStatus.active
                }
                onChange={handleStatusChange}
              />
            )}
            {canAddEmployee && (
              <Button
                onClick={handleNewEmployee}
                leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
              >
                {t("common:create_new")}
              </Button>
            )}
          </Stack>
        </PageHeader>
        <Page.Content>
          <Box>
            <Search
              placeholder={t("core:name")}
              value={searchTerm}
              onInput={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
              type={"text"}
            />
          </Box>
          <Table
            data={employees.data?.items || []}
            columns={columns}
            showSkeleton={employees.isLoading}
          />
          <PaginationBlock
            metadata={employees.data?.metadata}
            onPageChange={(page) => {
              employees.setFilter({ ...employees.filter, page_number: page });
            }}
          />
        </Page.Content>
      </Page>
    </>
  );
}
