import { Page } from "../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import useModals from "../../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { useEffectOnce } from "react-use";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { parseInt } from "lodash";
import {
  useCalendarHolidayDates,
  useCalendarHolidayDatesCopy,
  useCalendarHolidayDetails,
  useCalendarHolidayDownload,
} from "../../../queries/use-calendar-holidays.query.ts";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import FullCalendar from "@fullcalendar/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import enLocale from "@fullcalendar/core/locales/en-gb";
import ruLocale from "@fullcalendar/core/locales/ru";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import Stack from "../../../ui/stack/Stack.tsx";
import Text from "../../../ui/typography/Text.tsx";
import Button from "../../../ui/button/Button.tsx";
import { DayCellContentArg } from "@fullcalendar/core";
import { ConfirmationPopup } from "../../../components/confirmation-popup/ConfirmationPopup.tsx";
import { isSameDay } from "date-fns";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Title } from "../../../components/title/Title.tsx";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import {
  ApiCalendarHolidayDate,
  ApiCalendarHolidayDateType,
  calendarHolidayDateMock,
} from "../../../types/calendar-holiday.types.ts";
import { CalendarHolidayEditDateModal } from "./widgets/CalendarHolidayEditDateModal.tsx";
import { Card } from "../../../ui/card/Card.tsx";
import ButtonIcon from "../../../ui/button/ButtonIcon.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import LinkButtonTo from "../../../ui/link/LinkButtonTo.tsx";

export const CalendarHolidayDetails = () => {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { id } = useParams();
  const { data: calendarHoliday, isLoading } = useCalendarHolidayDetails(
    id ? parseInt(id, 10) : undefined
  );
  const navigate = useNavigate();
  const { employee } = useEmployeeStore();
  const { renderDate } = useFormatter();
  const fullCalendarLocale = useMemo(() => {
    if (!employee) return enLocale;
    if (employee.language == "ru") return ruLocale;

    return enLocale;
  }, [employee]);

  const [searchParams] = useSearchParams();
  const currentYear = parseInt(searchParams.get("year") || new Date().getFullYear().toString(), 10);
  const calendarRefJan = useRef<FullCalendar>(null);
  const calendarRefFeb = useRef<FullCalendar>(null);
  const calendarRefMar = useRef<FullCalendar>(null);
  const calendarRefApr = useRef<FullCalendar>(null);
  const calendarRefJMay = useRef<FullCalendar>(null);
  const calendarRefJun = useRef<FullCalendar>(null);
  const calendarRefJul = useRef<FullCalendar>(null);
  const calendarRefAug = useRef<FullCalendar>(null);
  const calendarRefSep = useRef<FullCalendar>(null);
  const calendarRefOct = useRef<FullCalendar>(null);
  const calendarRefNov = useRef<FullCalendar>(null);
  const calendarRefDec = useRef<FullCalendar>(null);

  const calendarDownload = useCalendarHolidayDownload();
  const calendarCopy = useCalendarHolidayDatesCopy();

  const { data: dates } = useCalendarHolidayDates(calendarHoliday?.id, currentYear);

  useEffect(() => {
    calendarRefJan.current?.getApi().gotoDate(new Date(currentYear, 0, 1));
    calendarRefFeb.current?.getApi().gotoDate(new Date(currentYear, 1, 1));
    calendarRefMar.current?.getApi().gotoDate(new Date(currentYear, 2, 1));
    calendarRefApr.current?.getApi().gotoDate(new Date(currentYear, 3, 1));
    calendarRefJMay.current?.getApi().gotoDate(new Date(currentYear, 4, 1));
    calendarRefJun.current?.getApi().gotoDate(new Date(currentYear, 5, 1));
    calendarRefJul.current?.getApi().gotoDate(new Date(currentYear, 6, 1));
    calendarRefAug.current?.getApi().gotoDate(new Date(currentYear, 7, 1));
    calendarRefSep.current?.getApi().gotoDate(new Date(currentYear, 8, 1));
    calendarRefOct.current?.getApi().gotoDate(new Date(currentYear, 9, 1));
    calendarRefNov.current?.getApi().gotoDate(new Date(currentYear, 10, 1));
    calendarRefDec.current?.getApi().gotoDate(new Date(currentYear, 11, 1));
  }, [
    currentYear,
    calendarRefJan,
    calendarRefFeb,
    calendarRefMar,
    calendarRefApr,
    calendarRefJMay,
    calendarRefJun,
    calendarRefJul,
    calendarRefAug,
    calendarRefSep,
    calendarRefOct,
    calendarRefNov,
    calendarRefDec,
  ]);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.calendarHolidays.viewedCalendarHoliday);
  });

  const dayCellClassNames = (args: DayCellContentArg) => {
    if (!dates) return "";
    const date = args.date;

    // Проверяем, если это праздничный день, возвращаем класс для подсветки
    const dateInDates = dates.find((d) => isSameDay(d.date, date));
    if (!dateInDates) return "";
    if (dateInDates.type == ApiCalendarHolidayDateType.non_working)
      return "bg-danger font-semibold text-light";
    if (dateInDates.type == ApiCalendarHolidayDateType.compensated)
      return "bg-danger/10 font-semibold ";

    return "";
  };

  const handleDownloadLatest = useCallback(() => {
    if (!calendarHoliday) return;
    modalsService.openModal(ConfirmationPopup, {
      question: t("core:calendar_holiday_download_confirm_title"),
      text: t("core:calendar_holiday_download_confirm_body", { year: currentYear }),
      acceptTitle: t("core:calendar_holiday_download_confirm_button"),
      onAccept: async function () {
        try {
          await calendarDownload.mutateAsync({ id: calendarHoliday.id, year: currentYear });

          analyticsService.trackEvent(analyticEvents.calendarHolidays.downloadFromWeb, {
            [analyticProperties.id]: calendarHoliday.id,
          });
        } catch {
          return;
        }
      },
    });
  }, [calendarHoliday, currentYear]);

  const calendarHolidaysByMonth = (month: number) => {
    return dates?.filter((d) => d.date.getMonth() === month) || [];
  };

  const handleAddOrEdit = useCallback(
    (date?: ApiCalendarHolidayDate) => {
      if (!calendarHoliday) return;
      const calendarHolidayDateToEdit: ApiCalendarHolidayDate = date || calendarHolidayDateMock;
      modalsService.openModal(CalendarHolidayEditDateModal, {
        calendarHolidayId: calendarHoliday.id,
        calendarHolidayDate: calendarHolidayDateToEdit,
      });
    },
    [calendarHoliday]
  );

  const handleChangeYear = useCallback(
    (toYear: number) => {
      navigate(`/settings/holidays/${id}?year=${toYear}`);
    },
    [currentYear, calendarHoliday]
  );

  const calendarRefByIndex = (index: number) => {
    switch (index) {
      case 0:
        return calendarRefJan;
      case 1:
        return calendarRefFeb;
      case 2:
        return calendarRefMar;
      case 3:
        return calendarRefApr;
      case 4:
        return calendarRefJMay;
      case 5:
        return calendarRefJun;
      case 6:
        return calendarRefJul;
      case 7:
        return calendarRefAug;
      case 8:
        return calendarRefSep;
      case 9:
        return calendarRefOct;
      case 10:
        return calendarRefNov;
      case 11:
        return calendarRefDec;
      default:
        return undefined;
    }
  };

  const copyFromPrev = useCallback(() => {
    if (!calendarHoliday) return;
    modalsService.openModal(ConfirmationPopup, {
      question: `${t("common:copy_from")} ${currentYear - 1} → ${currentYear}`,
      text: t("core:calendar_holiday_copy_from_description"),
      acceptTitle: t("common:yes"),
      onAccept: async function () {
        try {
          await calendarCopy.mutateAsync({
            id: calendarHoliday.id,
            payload: { year_from: currentYear - 1, year_to: currentYear },
          });

          analyticsService.trackEvent(analyticEvents.calendarHolidays.holidayCopied, {
            [analyticProperties.id]: calendarHoliday.id,
          });
        } catch {
          return;
        }
      },
    });
  }, [calendarHoliday, currentYear]);

  return (
    <Page>
      <Page.Header
        showBack={true}
        nowrap={true}
        titleSlot={
          <Stack direction={"horizontal"} items={"center"} gap={"md"} className={"overflow-hidden"}>
            <Text className={"text-lg font-semibold whitespace-nowrap"}>
              {calendarHoliday
                ? `${calendarHoliday.title} (${calendarHoliday.country.title})`
                : "..."}
            </Text>
            <Stack direction={"horizontal"} gap={"xs"} items={"center"}>
              <ButtonIcon
                variant={"secondary"}
                onClick={() => {
                  handleChangeYear(currentYear - 1);
                }}
                icon={<FontAwesomeIcon icon={"fa-light fa-chevron-left"} />}
              />
              <Text className={"text-title"}>{currentYear}</Text>
              <ButtonIcon
                variant={"secondary"}
                onClick={() => {
                  handleChangeYear(currentYear + 1);
                }}
                icon={<FontAwesomeIcon icon={"fa-light fa-chevron-right"} />}
              />
            </Stack>
          </Stack>
        }
      >
        <Stack direction={"horizontal"} gap={"sm"} items={"center"} className={"overflow-hidden"}>
          <DropdownMenu
            trigger={
              <Button
                variant={"secondary"}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-cloud-arrow-down"} />}
              >
                {`${t("common:import")}`}
              </Button>
            }
          >
            <Button variant={"menu"} onClick={copyFromPrev}>
              {`${t("common:copy_from")} ${currentYear - 1}...`}
            </Button>
            <Button variant={"menu"} onClick={handleDownloadLatest}>
              {`${t("core:calendar_holiday_download")}...`}
            </Button>
          </DropdownMenu>

          <Button onClick={() => handleAddOrEdit()}>{t("core:calendar_holiday_new")}</Button>
        </Stack>
      </Page.Header>

      <Page.Content className={"h-full overflow-y-auto calendar-holidays"}>
        {isLoading && <Skeleton className={"w-full h-10"} />}
        <Stack items={"start"} className={"pt-xl"}>
          <div className={"grid grid-cols-2 xl:grid-cols-3 gap-4 items-stretch"}>
            {[...Array(12)].map((_, index) => (
              <div className={"max-w-96"} key={`calendar-${index}`}>
                <Card className={"h-full"}>
                  <Title
                    className={"capitalize"}
                    paddingBottom={true}
                    size={"lg"}
                    header={renderDate(new Date(2000, index, 1), "LLLL")}
                  />

                  <FullCalendar
                    ref={calendarRefByIndex(index)}
                    key={index}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    initialDate={new Date(currentYear, index, 1)}
                    headerToolbar={false}
                    locale={fullCalendarLocale}
                    dayCellClassNames={dayCellClassNames}
                    height="auto"
                  />
                  <Stack className={"p-2"} gap={"sm"}>
                    {calendarHolidaysByMonth(index).map((d) => (
                      <ListItem
                        key={d.id}
                        preTitle={
                          renderDate(d.date) +
                          (d.type == ApiCalendarHolidayDateType.compensated
                            ? ` · ${t("core:calendar_holiday_date_compensated_title")}`
                            : "")
                        }
                        title={d.title}
                        className={"group"}
                        valueSlot={
                          <LinkButtonTo
                            onClick={() => handleAddOrEdit(d)}
                            className={"opacity-0 group-hover:opacity-100"}
                          >
                            {t("common:edit")}
                          </LinkButtonTo>
                        }
                      />
                    ))}
                  </Stack>
                </Card>
              </div>
            ))}
          </div>
        </Stack>
      </Page.Content>
    </Page>
  );
};
