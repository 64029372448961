import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonLinkProps } from "./button.types.ts";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { Link } from "react-router-dom";
import { useTheme } from "../themes/provider.tsx";

const ButtonLinkComponentFN = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  function ButtonLinkComponent(
    {
      size = "md",
      href,
      blank,
      className,
      isLoading = false,
      children,
      variant = "primary",
      leftIcon,
      rightIcon,
      ...props
    }: ButtonLinkProps,
    ref
  ) {
    const theirProps = { type: "button", ...props } as ButtonLinkProps;

    // const sizeTheme: ButtonSizes = {
    //   sm: "text-sm font-semibold",
    //   md: "text-base",
    //   lg: "text-lg",
    // };

    // const linkToTheme = useTheme("linkTo");
    const theme = useTheme("button");

    return (
      <>
        <Link
          to={href}
          ref={ref}
          target={blank ? "_blank" : undefined}
          className={twMerge(
            theme.base,
            theme.variants.variant[variant],
            theme.variants.size[size],
            className
          )}
          // disabled={isLoading}
          {...theirProps}
        >
          {isLoading && <FontAwesomeIcon icon="fa-light fa-spinner fa-spin mr-2" />}
          {leftIcon}
          {children && <span className={theme.text}>{children}</span>}
          {/*<Box*/}
          {/*  className={*/}
          {/*    twMerge()*/}
          {/*    // " inline underline-offset-2",*/}
          {/*    // linkToTheme.variants.variant[variant],*/}
          {/*    // sizeTheme[size],*/}
          {/*    // leftIcon ? "pl-sm" : "",*/}
          {/*  }*/}
          {/*>*/}
          {/*  {children}*/}
          {/*</Box>*/}
          {rightIcon}
        </Link>
      </>
    );
  }
);

const ButtonLink = ButtonLinkComponentFN;
export default ButtonLink;
