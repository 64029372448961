import { useTranslation } from "react-i18next";
import { SelectItem, SelectItemExtended } from "../ui/select/select.types.ts";
import {
  ApiTimeOffAccrualFrequencyTypes,
  ApiTimeOffCategoryType,
  ApiTimeOffCountDaysType,
  ApiTimeOffDateType,
  ApiTimeOffPaidType,
  ApiTimeOffPeriodTrackingTime,
  ApiTimeOffPolicyCarryoverCycleDateType,
  ApiTimeOffPolicyMilestoneCarryoverType,
} from "../types/time-off.types.ts";
import { ApiApprovalRequest, ApiApprovalRequestStatus } from "../types/approval-request.types.ts";
import { createColumnHelper } from "@tanstack/react-table";
import { useEmployeeMe } from "../queries/employees/use-employees.query.ts";
import Stack from "../ui/stack/Stack.tsx";
import Button from "../ui/button/Button.tsx";
import DropdownMenu from "../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../ui/typography/FontAwesomeIcon.tsx";
import { apiApprovalRequests } from "../api/approval-request.api.ts";
import { TimeOffRequestApprovalModal } from "../pages/time-offs/widgets/TimeOffRequestApprovalModal.tsx";
import { ApprovalRequestTimeOffModal } from "../pages/approvals/widgets/ApprovalRequestTimeOffModal.tsx";
import useModals from "../ui/modal/modal.store.ts";
import useErrorHandle from "./use-error-handle.hook.tsx";
import {
  useApprovalRequestCancel,
  useApprovalRequestDelete,
} from "../queries/use-approval-requests.query.ts";
import { PermissionType } from "../types/role.types.ts";

export default function useTimeOffHook() {
  const { t } = useTranslation();
  const { data: employeeMe } = useEmployeeMe();
  const { openModal } = useModals();
  const { handleErrors } = useErrorHandle();
  const deleteTimeOffRequest = useApprovalRequestDelete();
  const cancelRequest = useApprovalRequestCancel();

  const getPaidTypes = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:paid_type_working_paid_title"),
        caption: t("time_off:paid_type_working_paid_description"),
        id: ApiTimeOffPaidType.working_paid,
      },
      {
        title: t("time_off:paid_type_not_working_paid_title"),
        caption: t("time_off:paid_type_not_working_paid_description"),
        id: ApiTimeOffPaidType.not_working_paid,
      },
      {
        title: t("time_off:paid_type_not_working_unpaid_title"),
        caption: t("time_off:paid_type_not_working_unpaid_description"),
        id: ApiTimeOffPaidType.not_working_unpaid,
      },
    ];
  };

  const getTimeTrackingTypes = (): SelectItem[] => {
    return [
      {
        title: t("time_off:period_track_type_days_title"),
        id: ApiTimeOffPeriodTrackingTime.days,
      },
      {
        title: t("time_off:period_track_type_hours_title"),
        id: ApiTimeOffPeriodTrackingTime.hours,
      },
    ];
  };

  const getCountDaysTypes = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:count_days_types_calendar_days_title"),
        caption: t("time_off:count_days_types_calendar_days_description"),
        id: ApiTimeOffCountDaysType.calendar_days,
      },
      {
        title: t("time_off:count_days_types_working_days_title"),
        caption: t("time_off:count_days_types_working_days_description"),
        id: ApiTimeOffCountDaysType.working_days,
      },
    ];
  };

  const getCategoriesTypes = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:category_type_accrues_time_title"),
        caption: t("time_off:category_type_accrues_time_description"),
        id: ApiTimeOffCategoryType.accrues_time,
      },
      {
        title: t("time_off:category_type_flexible_title"),
        caption: t("time_off:category_type_flexible_description"),
        id: ApiTimeOffCategoryType.flexible,
      },
      {
        title: t("time_off:category_type_manual_title"),
        caption: t("time_off:category_type_manual_description"),
        id: ApiTimeOffCategoryType.manual,
      },
    ];
  };

  const getCarryoverDateTypes = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:carryover_date_hire_title"),
        id: ApiTimeOffPolicyCarryoverCycleDateType.hire_date,
      },
      {
        title: t("time_off:carryover_date_quarter_title"),
        id: ApiTimeOffPolicyCarryoverCycleDateType.quarter_start,
      },
      {
        title: t("time_off:carryover_date_specific_title"),
        id: ApiTimeOffPolicyCarryoverCycleDateType.specific_date,
      },
    ];
  };

  const getCarryoverExpiringEnabled = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:carryover_expiration_yes_title"),
        caption: t("time_off:carryover_expiration_yes_caption"),
        id: 1,
      },
      {
        title: t("time_off:carryover_expiration_no_title"),
        caption: t("time_off:carryover_expiration_no_caption"),
        id: 0,
      },
    ];
  };

  const getCarryoverExpiringDateTypes = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:carryover_expiration_date_day_title"),
        id: ApiTimeOffDateType.day,
      },
      {
        title: t("time_off:carryover_expiration_date_week_title"),
        id: ApiTimeOffDateType.week,
      },
      {
        title: t("time_off:carryover_expiration_date_month_title"),
        id: ApiTimeOffDateType.month,
      },
      {
        title: t("time_off:carryover_expiration_date_year_title"),
        id: ApiTimeOffDateType.year,
      },
    ];
  };

  const getAccrualFrequencyTypes = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:milestone_accrual_date_type_daily"),
        id: ApiTimeOffAccrualFrequencyTypes.daily,
      },
      {
        title: t("time_off:milestone_accrual_date_type_weekly"),
        id: ApiTimeOffAccrualFrequencyTypes.weekly,
      },
      {
        title: t("time_off:milestone_accrual_date_type_biweekly"),
        id: ApiTimeOffAccrualFrequencyTypes.biweekly,
      },
      {
        title: t("time_off:milestone_accrual_date_type_semimonthly"),
        id: ApiTimeOffAccrualFrequencyTypes.semimonthly,
      },
      {
        title: t("time_off:milestone_accrual_date_type_monthly"),
        id: ApiTimeOffAccrualFrequencyTypes.monthly,
      },
      {
        title: t("time_off:milestone_accrual_date_type_quarterly"),
        id: ApiTimeOffAccrualFrequencyTypes.quarterly,
      },
      {
        title: t("time_off:milestone_accrual_date_type_semiannual"),
        id: ApiTimeOffAccrualFrequencyTypes.semiannual,
      },
      {
        title: t("time_off:milestone_accrual_date_type_yearly"),
        id: ApiTimeOffAccrualFrequencyTypes.yearly,
      },
      {
        title: t("time_off:milestone_accrual_date_type_hire_date"),
        id: ApiTimeOffAccrualFrequencyTypes.hire_date,
      },
    ];
  };

  const getMilestoneCarryoverTypes = (): SelectItemExtended[] => {
    return [
      {
        title: t("time_off:milestone_carryover_none_title"),
        caption: t("time_off:milestone_carryover_none_caption"),
        id: ApiTimeOffPolicyMilestoneCarryoverType.none,
      },
      {
        title: t("time_off:milestone_carryover_up_to_title"),
        caption: t("time_off:milestone_carryover_up_to_caption"),
        id: ApiTimeOffPolicyMilestoneCarryoverType.up_to,
      },
      {
        title: t("time_off:milestone_carryover_unlimited_title"),
        caption: t("time_off:milestone_carryover_unlimited_caption"),
        id: ApiTimeOffPolicyMilestoneCarryoverType.unlimited,
      },
    ];
  };

  const getApprovalRequestStatuses = (): SelectItem[] => {
    return [
      {
        title: t("time_off:request_status_approved"),
        id: ApiApprovalRequestStatus.approved,
      },
      {
        title: t("time_off:request_status_canceled"),
        id: ApiApprovalRequestStatus.canceled,
      },
      {
        title: t("time_off:request_status_rejected"),
        id: ApiApprovalRequestStatus.rejected,
      },
      {
        title: t("time_off:request_status_awaiting"),
        id: ApiApprovalRequestStatus.awaiting,
      },
    ];
  };

  const getApprovalRequestStatus = (
    searchStatus: ApiApprovalRequestStatus
  ): SelectItem | undefined => {
    return getApprovalRequestStatuses().find((requestStatus) => requestStatus.id == searchStatus);
  };

  const getActionColumnForRequest = () => {
    const columnHelper = createColumnHelper<ApiApprovalRequest>();
    const canManageLeavePolicies =
      employeeMe?.permissions.manage_leave_policies == PermissionType.edit;
    const handleViewRequest = async (requestId: number) => {
      const { data: request, error } = await apiApprovalRequests.getById(requestId);

      if (error) handleErrors(error);

      if (request) {
        openModal(TimeOffRequestApprovalModal, { requestDetails: request });
      }
    };

    const handleCancelRequest = (request: ApiApprovalRequest) => {
      cancelRequest.mutate(request.id, {
        onSuccess: () => {},
      });
    };

    const handleDeleteRequest = (request: ApiApprovalRequest) => {
      deleteTimeOffRequest.mutate(request.id, {
        onSuccess: () => {},
      });
    };

    const handleEditRequest = (request: ApiApprovalRequest) => {
      openModal(ApprovalRequestTimeOffModal, {
        timeOffRequest: request,
      });
    };

    return columnHelper.display({
      id: "actions",
      cell: (data) => {
        const isMyRequest = data.row.original.employee.id == employeeMe?.id;

        if (!isMyRequest && !canManageLeavePolicies) {
          return null;
        }

        const canView = isMyRequest || canManageLeavePolicies;
        const canEdit =
          data.row.original.status === ApiApprovalRequestStatus.awaiting &&
          (canManageLeavePolicies || isMyRequest);
        const canCancel =
          data.row.original.status === ApiApprovalRequestStatus.awaiting &&
          (canManageLeavePolicies || isMyRequest);

        return (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            {canView && (
              <Button
                onClick={async () => {
                  await handleViewRequest(data.row.original.id);
                }}
                variant={"secondary"}
              >
                {t("common:view")}
              </Button>
            )}
            {(canEdit || canCancel || canManageLeavePolicies) && (
              <DropdownMenu>
                {canEdit && (
                  <Button
                    onClick={() => {
                      handleEditRequest(data.row.original);
                    }}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                    variant={"menu"}
                  >
                    {t("common:edit")}
                  </Button>
                )}
                {canCancel && (
                  <Button
                    onClick={() => {
                      handleCancelRequest(data.row.original);
                    }}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-folder-gear"} />}
                    variant={"menu"}
                  >
                    {t("common:cancel")}
                  </Button>
                )}
                {canManageLeavePolicies && (
                  <Button
                    onClick={() => {
                      handleDeleteRequest(data.row.original);
                    }}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                    variant={"menuDanger"}
                  >
                    {t("common:delete")}
                  </Button>
                )}
              </DropdownMenu>
            )}
          </Stack>
        );
      },
      size: 100,
    });
  };

  return {
    getPaidTypes,
    getTimeTrackingTypes,
    getCountDaysTypes,
    getCategoriesTypes,
    getCarryoverDateTypes,
    getCarryoverExpiringEnabled,
    getCarryoverExpiringDateTypes,
    getAccrualFrequencyTypes,
    getMilestoneCarryoverTypes,
    getApprovalRequestStatuses,
    getApprovalRequestStatus,
    getActionColumnForRequest,
  };
}
