/// <reference types="chrome"/>

interface SkailerAuthToken {
  state: {
    token: string | null;
  };
}

export const sendTokenToExtension = (token: string | null) => {
  try {
    const extensionId: string = import.meta.env.VITE_EXTENSION_ID as string;
    const message: SkailerAuthToken = { state: { token } };
    if (typeof chrome !== "undefined" && typeof chrome.runtime !== "undefined" && extensionId) {
      chrome.runtime.sendMessage(extensionId, message);
      // window.postMessage(message, "*");
      return;
    }
  } catch (error) {
    console.error("sendTokenToExtension", error);
  }
};
