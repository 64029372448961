import { SelectItem } from "../../ui/select/select.types.ts";
import i18n from "../../i18n.ts";
import { ApiFilterBase } from "../filter.types.ts";

export enum SourceTypes {
  custom = "custom",
  linkedin = "linkedin",
  manual = "manual",
}

export interface ApiSource extends SelectItem {
  id: number;
  title: string;
  source_type: SourceTypes;
  datetime_created_at: Date;
}

export interface ApiSourceSavePayload {
  title: string;
}

export function getSourceTitle(source: ApiSource): string {
  if (source.source_type == SourceTypes.manual) return i18n.t("ats:resume");
  if (source.source_type == SourceTypes.linkedin) return "Linked In";

  return source.title;
}

export interface ApiSourcesFilter extends ApiFilterBase {
  text?: string;
  source_types: string[];
}
