interface GradientContainerProps {
  color_palette: string[];
}

export const GradientContainer = ({ color_palette }: GradientContainerProps) => {
  const gradientStyle = {
    background: `radial-gradient(ellipse farthest-corner at 0% 100%, #${color_palette[0]} 0%, #${color_palette[1]} 100%)`,
  };

  return <div style={gradientStyle} className={"top-0 left-0 right-0 h-full z-0 absolute"} />;
};
