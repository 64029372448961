import { api } from "./axios.ts";
import {
  ApiDataProtectionRequest,
  ApiDataProtectionResponsePayload,
  ApiDataProtectionConsentRequestPayload,
  ApiDataProtectionProvider,
  ApiDataProtectionRequestsFilter,
  ApiDataProtectionSettingsPayload,
  DataProtectionTypes,
  DataProtectionConsentData,
  ApiDataProtectionIncreaseDaysPayload,
} from "../types/data-protection.types.ts";
import { ApiOperationResult } from "../types/common.types.ts";
import { ApiPagedData } from "../types/filter.types.ts";

export const apiDataProtection = {
  settings: () => {
    return api.get<ApiDataProtectionProvider[]>("/api/v1/data-protection/settings");
  },
  updateSettings: (payload: ApiDataProtectionSettingsPayload) => {
    return api.put<ApiDataProtectionProvider>("/api/v1/data-protection/settings", { ...payload });
  },
  request: (data_protection_type: DataProtectionTypes) => {
    return api.post<ApiOperationResult>("/api/v1/data-protection/request", {
      data_protection_type,
    });
  },
  consentRequest: (payload: ApiDataProtectionConsentRequestPayload) => {
    return api.post<ApiOperationResult>("/api/v1/data-protection/consent-request", payload);
  },
  search: (filter: ApiDataProtectionRequestsFilter, signal: AbortSignal) => {
    return api.post<ApiPagedData<ApiDataProtectionRequest>>(
      "/api/v1/data-protection/applicants/search",
      filter,
      signal
    );
  },
  accept: (answer: ApiDataProtectionResponsePayload) => {
    return api.post<ApiOperationResult>("/api/v1/data-protection/consent/accept", answer);
  },
  decline: (answer: ApiDataProtectionResponsePayload) => {
    return api.post<ApiOperationResult>("/api/v1/data-protection/consent/decline", answer);
  },
  deleteRequest: (answer: ApiDataProtectionResponsePayload) => {
    return api.post<ApiOperationResult>("/api/v1/data-protection/consent/delete-request", answer);
  },
  editRequest: (answer: ApiDataProtectionResponsePayload) => {
    return api.post<ApiOperationResult>("/api/v1/data-protection/consent/edit-request", answer);
  },
  consentData: (uuid: string) => {
    return api.get<DataProtectionConsentData>(`/api/v1/data-protection/consent/${uuid}`);
  },
  increaseDays: (applicantId: number, payload: ApiDataProtectionIncreaseDaysPayload) => {
    return api.put<ApiOperationResult>(`/api/v1/data-protection/applicants/${applicantId}`, {
      ...payload,
    });
  },
};
