import { ModalProps } from "../../../ui/modal/modal.types.ts";
import { ApiDepartment } from "../../../types/department.types.ts";
import Modal from "../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import useEmployeeSearchInline from "../../../queries/employees/use-employees.query.ts";
import { PersonalItem } from "../../../components/personal-item/PersonalItem.tsx";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import Box from "../../../ui/box/Box.tsx";
import { useDebounce } from "react-use";
import { EmployeeViewModal } from "../../employees/widgets/EmployeeViewModal.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { useCallback, useState } from "react";
import { apiEmployees } from "../../../api";
import { PaginationBlock } from "../../../ui/pagination/PaginationBlock.tsx";
import { Search } from "../../../components/search/Search.tsx";
import { Title } from "../../../components/title/Title.tsx";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import { EmptyStateBase } from "../../../components/empty-state/EmptyStateBase.tsx";

export interface DepartmentViewModalProps extends ModalProps {
  department: ApiDepartment;
}

export function DepartmentViewModal({ department, ...props }: DepartmentViewModalProps) {
  const { id } = props;
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { renderEmployeeName } = useFormatter();
  const { openModal, close } = useModals();

  useDebounce(
    () => {
      setFilter({
        ...filter,
        text: searchTerm,
      });
    },
    300,
    [searchTerm]
  );

  const { data, setFilter, filter, isLoading } = useEmployeeSearchInline(true, {
    page_number: 1,
    text: "",
    department_ids: [department.id],
  });

  const loadEmployee = useCallback(async (employeeId: number | string) => {
    const { data: employee } = await apiEmployees.getById(employeeId);

    return employee;
  }, []);

  return (
    <Modal
      {...props}
      layout={"stretch"}
      size={"md"}
      variant={"side"}
      title={department.title}
      postTitleSlot={
        <Title
          className={"text-secondary"}
          size={"lg"}
          header={`(${department.employees_count})`}
        ></Title>
      }
      withCloser
      closeByEscEnabled
    >
      <>
        {department.manager && (
          <>
            <PersonalItem
              key={`department_employee_${department.manager.id}`}
              className={"pl-0 pr-0 h-auto pt-4 pb-4 cursor-pointer"}
              primaryText={renderEmployeeName(department.manager)}
              text3={t("core:manager")}
              avatarUrl={department.manager.avatar_url ?? ""}
              onClick={async () => {
                close(id);

                if (!department.manager) return;

                const employee = await loadEmployee(department.manager?.id);

                employee && openModal(EmployeeViewModal, { employee });
              }}
            />
            <hr />
          </>
        )}
      </>

      <Search
        placeholder={t("core:name")}
        value={searchTerm}
        onInput={(e) => {
          setSearchTerm(e.currentTarget.value);
        }}
        type={"text"}
      />
      <Box className={"h-full overflow-y-auto"}>
        {isLoading && <Skeleton className={"h-8 w-full"} />}
        {data?.items.map(
          (employee) =>
            (employee.id != department.manager?.id || searchTerm) && (
              <PersonalItem
                key={`department_employee_${employee.id}`}
                className={"pl-0 pr-0 h-auto pt-4 pb-4 cursor-pointer"}
                primaryText={renderEmployeeName(employee)}
                text2={employee.employee_position?.position?.title ?? ""}
                avatarUrl={employee.avatar_url ?? ""}
                onClick={() => {
                  close(id);
                  openModal(EmployeeViewModal, { employee });
                }}
              />
            )
        )}

        {!isLoading && !data?.metadata.total_items && (
          <EmptyStateBase emoji={"🦥"} title={t("common:nothing_found")} />
        )}

        {data && data.metadata.page_number > 1 && (
          <PaginationBlock
            metadata={data?.metadata}
            onPageChange={(page) => {
              setFilter({ ...filter, page_number: page });
            }}
          />
        )}
      </Box>
    </Modal>
  );
}
