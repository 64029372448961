import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";

export interface NotificationSizes extends Pick<ThemeSizes, "sm" | "md"> {
  [key: string]: string;
}

export interface NotificationProps {
  count: number;
  variant?: keyof NotificationVariant;
  size?: keyof NotificationSizes;
}

export interface NotificationVariant {
  default: string;
}

export interface UIKitCounterTheme extends UIKitThemeBase {
  base: string;
  variants: {
    size: NotificationSizes;
    variant: NotificationVariant;
  };
}

export const counterTheme: UIKitCounterTheme = {
  base: "",
  variants: {
    size: {
      sm: "h-4 px-1 text-sm min-w-4 leading-4 rounded-lg",
      md: "h-5 py-0.5 px-2 min-w-6 leading-5 h-6 rounded-xl",
    },
    variant: {
      default: "bg-warning text-light",
    },
  },
};
