import { Card } from "../../../ui/card/Card.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { Link } from "react-router-dom";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { twMerge } from "tailwind-merge";
import Box from "../../../ui/box/Box.tsx";
import { Title } from "../../../components/title/Title.tsx";
import { ReactNode } from "react";
import Avatar from "../../../ui/avatar/Avatar.tsx";

export interface SettingsCardVariant {
  info: string;
  action: string;
}

export interface SettingsCardProps {
  title: string;
  description?: string;
  emoji?: string;
  icon?: string;
  extra?: string;
  to?: string;
  className?: string;
  variant?: keyof SettingsCardVariant;
  onClick?: () => void;
  optionsSlot?: ReactNode;
}

export function SettingsCard({
  title,
  description,
  extra,
  icon,
  emoji,
  to,
  variant = "info",
  className,
  onClick,
  optionsSlot,
}: SettingsCardProps) {
  const renderInner = () => {
    const hasIcon = icon || emoji;
    return (
      <Card
        className={twMerge(
          "cursor-pointer min-h-48 flex flex-col justify-between items-start h-full",
          variant == "action" ? "shadow-none border-dark/10 bg-dark/5 border h-full" : ""
        )}
        actionable
      >
        {hasIcon && (
          <Stack gap="sm" justify={variant == "action" ? "between" : "center"} className={""}>
            {icon && <FontAwesomeIcon icon={`fa-light ${icon}`} className={"text-icon-32 pb-4"} />}
            {emoji && <Avatar emoji={emoji} size={"md"} />}
          </Stack>
        )}

        <Stack gap={"sm"} items={"start"}>
          <Stack items={"start"} justify={"between"} direction={"horizontal"}>
            <Title header={title} size={"md"} />
            {optionsSlot}
          </Stack>
          {description && <Text className="text-secondary">{description}</Text>}
        </Stack>
        {extra && (
          <Text className="text-primary border-dark border text-sm py-1 px-2 rounded-md">
            {extra}
          </Text>
        )}
      </Card>
    );
  };
  return (
    <>
      {to ? (
        <Link
          to={to}
          className={className}
          onClick={(event) => {
            if (onClick) {
              event.stopPropagation();
              onClick();
            }
          }}
        >
          {renderInner()}
        </Link>
      ) : (
        <Box
          className={className}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          {renderInner()}
        </Box>
      )}
    </>
  );
}
