import { colorBlockTheme, UIKitColorBlockTheme } from "../colorblock/colorblock.types.ts";
import { cardTheme, UIKitCardTheme } from "../card/card.types.ts";
import { textTheme, UIKitTextTheme } from "../typography/text.types.ts";
import { avatarTheme, UIKitAvatarTheme } from "../avatar/avatar.types.ts";
import { buttonTheme, UIKitButtonTheme } from "../button/button.types.ts";
import { linkToTheme, UIKitLinkToTheme } from "../link/link-to.types.ts";
import { selectTheme, UIKitSelectTheme } from "../select/select.types.ts";
import { stackTheme, UIKitStackTheme } from "../stack/stack.types.ts";
import { tableTheme, UIKitTableTheme } from "../table/table.types.ts";
import { paginationTheme, UIKitPaginationTheme } from "../pagination/pagination.types.ts";
import { dropdownTheme, UIKitDropdownTheme } from "../dropdown-menu/dropdown-menu.types.ts";
import { modalTheme, UIKitModalTheme } from "../modal/modal.types.ts";
import { toastTheme, UIKitToastTheme } from "../toast/toast.types.ts";
import { textareaTheme, UIKitTextareaTheme } from "../textarea/textarea.types.ts";
import { datepickerTheme, UIKitDatepickerTheme } from "../datepicker/datepicker.types.ts";
import { tabsTheme, UIKitTabsTheme } from "../tabs/tabs.types.ts";
import { switchTheme, UIKithSwitchTheme } from "../switch/switch.types.ts";
import { skeletonTheme, UIKitSkeletonTheme } from "../skeleton/skeleton.types.ts";
import { inputTheme, UIKitInputTheme } from "../input/input.types.ts";
import { counterTheme, UIKitCounterTheme } from "../notification-count/notification.types.ts";
import { listOptionTheme, UIKitListOptionTheme } from "../list-option/list-option.types.ts";

export type UIKitThemeKey = keyof UIKitTheme;

export interface UIKitContextProps {
  uiKitTheme: UIKitTheme;
}

export interface UIKitThemeBase {
  base: string;
}

export interface UIKitTheme {
  button: UIKitButtonTheme;
  card: UIKitCardTheme;
  text: UIKitTextTheme;
  avatar: UIKitAvatarTheme;
  linkTo: UIKitLinkToTheme;
  select: UIKitSelectTheme;
  stack: UIKitStackTheme;
  table: UIKitTableTheme;
  pagination: UIKitPaginationTheme;
  dropdown: UIKitDropdownTheme;
  modal: UIKitModalTheme;
  toast: UIKitToastTheme;
  textarea: UIKitTextareaTheme;
  datepicker: UIKitDatepickerTheme;
  tabs: UIKitTabsTheme;
  switch: UIKithSwitchTheme;
  skeleton: UIKitSkeletonTheme;
  input: UIKitInputTheme;
  colorBlock: UIKitColorBlockTheme;
  counter: UIKitCounterTheme;
  listOption: UIKitListOptionTheme;
}

export const DefaultKitTheme: UIKitTheme = {
  button: buttonTheme,
  card: cardTheme,
  text: textTheme,
  avatar: avatarTheme,
  linkTo: linkToTheme,
  select: selectTheme,
  stack: stackTheme,
  table: tableTheme,
  pagination: paginationTheme,
  dropdown: dropdownTheme,
  modal: modalTheme,
  toast: toastTheme,
  textarea: textareaTheme,
  datepicker: datepickerTheme,
  tabs: tabsTheme,
  switch: switchTheme,
  skeleton: skeletonTheme,
  input: inputTheme,
  colorBlock: colorBlockTheme,
  counter: counterTheme,
  listOption: listOptionTheme,
};

export interface ThemeSizes {
  xs: string;
  sm: string;
  smd: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
  "3xl": string;
  "4xl": string;
  "5xl": string;
  "6xl": string;
}
