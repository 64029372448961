import Box from "../../../../../ui/box/Box.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { SettingsCard } from "../../../components/SettingsCard.tsx";
import { useTranslation } from "react-i18next";
import { useTimeOffCategories } from "../../../../../queries/use-time-offs.query.ts";
import { Card } from "../../../../../ui/card/Card.tsx";
import Skeleton from "../../../../../ui/skeleton/Skeleton.tsx";
import analyticsService, { analyticEvents } from "../../../../../services/analytics-service.ts";
import { useEffectOnce } from "react-use";

export function TimeOffTabSettings() {
  const { t } = useTranslation();
  const { data: categories, isPending } = useTimeOffCategories();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.timeOffs.categoriesViewed);
  });

  return (
    <Box>
      <Title
        header={t("time_off:title")}
        paddingTop={true}
        paddingBottom={true}
        size={"lg"}
        caption={t("time_off:settings_description")}
      />
      <Stack
        direction={"horizontal"}
        gap={"md"}
        className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 pt-4"}
      >
        {isPending ? (
          <Card className="cursor-pointer min-h-48 flex flex-col justify-between items-start">
            <Stack gap="sm" className={"w-full"}>
              <Skeleton className={"w-1/2 h-6"}></Skeleton>
              <Skeleton className={"w-full h-5"}></Skeleton>
            </Stack>
            <Skeleton className={"w-1/2 h-6"}></Skeleton>
          </Card>
        ) : (
          <>
            {categories?.map((timeOff) => (
              <SettingsCard
                title={timeOff.title}
                key={timeOff.id}
                emoji={timeOff.emoji}
                description={timeOff.description}
                to={`/settings/time-offs/categories/${timeOff.id}`}
                extra={t("plurals:policy", { count: timeOff.policies_count })}
              />
            ))}
            <SettingsCard
              icon={"fa-plus"}
              title={t("time_off:create_new")}
              description={""}
              to={`/settings/time-offs/categories/new`}
              variant={"action"}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}
