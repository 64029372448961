import { useJobRecruitersSearch } from "../../../../queries/recruit/use-jobs.query.ts";
import Box from "../../../../ui/box/Box.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { twMerge } from "tailwind-merge";
import { useCallback } from "react";

export interface RecruitersSelectorProps {
  recruiterIds: number[];
  onChange: (recruiterIds: number[]) => void;
}

export const RecruitingReportingRecruitersSelector = ({
  recruiterIds,
  onChange,
}: RecruitersSelectorProps) => {
  const { data: recruiters } = useJobRecruitersSearch();
  const { renderEmployeeName } = useFormatter();

  const handleSelect = useCallback(
    (recruiterId: number) => {
      let recruitersIdCache = recruiterIds;
      if (recruiterIds.indexOf(recruiterId) > -1) {
        recruitersIdCache = recruitersIdCache.filter((id) => id !== recruiterId);
      } else {
        recruitersIdCache.push(recruiterId);
      }
      onChange(recruitersIdCache);
    },
    [recruiterIds, onChange]
  );

  return (
    <Stack direction={"horizontal"} gap={"xs"}>
      {recruiters?.items.map((recruiter) => (
        <Box className={"mt-1 relative"} key={`recruiter-selector-${recruiter.id}`}>
          <Avatar size={"md"} url={recruiter.avatar_url} acronym={renderEmployeeName(recruiter)} />

          <Box
            className={twMerge(
              "pointer rounded-lg absolute inset-0 flex justify-center items-center opacity-0 transition-opacity bg-dark/50 group",
              recruiterIds.indexOf(recruiter.id) > -1
                ? "bg-dark/40 opacity-100"
                : "hover:bg-dark/40 hover:opacity-100 "
            )}
            onClick={(event) => {
              handleSelect(recruiter.id);
              event.stopPropagation();
            }}
          >
            <Box
              className={twMerge(
                "leading-4 h-4 w-4 flex justify-center items-center " +
                  "transition-opacity group-hover:opacity-100",
                recruiterIds.indexOf(recruiter.id) > -1 ? "opacity-100" : "opacity-0"
              )}
            >
              <svg
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={"w-full h-full align-middle text-light"}
              >
                <path
                  d="M3.87424 6.53892L3.87457 6.53924C4.15279 6.8132 4.59793 6.81173 4.87436 6.53595L4.87439 6.53592L9.93895 1.48069L9.93949 1.48015C10.2172 1.20017 10.2172 0.746738 9.93949 0.466756C9.66125 0.18619 9.20968 0.186081 8.93131 0.466429C8.9312 0.466538 8.93109 0.466647 8.93098 0.466756L4.37472 5.00648L2.06607 2.73476C1.78767 2.45477 1.33637 2.455 1.05825 2.73545C0.780585 3.01543 0.780585 3.46886 1.05825 3.74884L1.05824 3.74884L1.05918 3.74977L3.87424 6.53892Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.3"
                />
              </svg>
            </Box>
          </Box>
        </Box>
      ))}
    </Stack>
  );
};
