import React from "react";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";
import { ColorBlockProps } from "./colorblock.types.ts";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";

const ColorBlock: React.FC<ColorBlockProps> = ({
  color,
  selected = false,
  size = "sm",
  onClick,
}) => {
  const theme = useTheme("colorBlock");

  return (
    <div
      className={twMerge(
        theme.base,
        theme.variants.size[size],
        onClick ? "cursor-pointer" : undefined
      )}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {selected && <FontAwesomeIcon icon="fa-check fa-light text-white" />}
    </div>
  );
};

export default ColorBlock;
