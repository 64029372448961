import { Control, FieldErrors, FieldValues, Path } from "react-hook-form";
import { ApiError, ApiErrorException, ApiErrors, ApiValidationError } from "../types/api.types.ts";
import i18n from "../i18n.ts";
import useToasts from "./use-toasts.hook.tsx";
import Text from "../ui/typography/Text.tsx";
import Stack from "../ui/stack/Stack.tsx";

export interface ServerErrorFieldProps<T extends FieldValues> {
  errors: FieldErrors<T>;
}

export function ServerErrorField<T extends FieldValues>({ errors }: ServerErrorFieldProps<T>) {
  return (
    <>
      {errors.root?.serverError && (
        <Stack className={"h-14"} justify={"center"}>
          <Text className={"text-danger"}>{errors.root.serverError.message || "Error"}</Text>
        </Stack>
      )}
    </>
  );
}

export default function useErrorHandle() {
  const serverErrorField = "root.serverError";

  const { showError } = useToasts();

  function handleErrors<T extends FieldValues>(
    error: ApiError | undefined,
    control: Control<T> | undefined = undefined
  ) {
    if (error) {
      if (error.status == ApiErrors.validationError) {
        const validationError = error as ApiValidationError;
        if (control) {
          validationError.validationErrors.forEach(({ field, code }) => {
            const fieldState = control._fields[field]; // control.getFieldState(field as Path<T>);
            const errorMessage = i18n.t(`api_errors:api_error_${code}`);
            const errorField = fieldState != undefined ? field : serverErrorField;

            control.setError(errorField as Path<T>, {
              type: "custom",
              message: errorMessage,
            });
          });
        } else {
          // no control, but it's a validation error (422) we need to show error
          if (validationError.validationErrors.length > 0) {
            showError({
              title: i18n.t("api_errors:api_error_" + validationError.validationErrors[0].code),
            });
          }
        }
      } else {
        // Error, but its not a 422 error
        // todo show toast
      }
    } else {
      //not a sucess, but no error
      // todo show toast
    }
  }

  function onError<T extends FieldValues>(
    error: Error,
    control: Control<T> | undefined = undefined
  ) {
    if (error instanceof ApiErrorException) {
      handleErrors((error as ApiErrorException).apiError, control);
    } else {
      // todo process another errors
    }
  }

  return {
    serverErrorField,
    handleErrors,
    onError,
  };
}
