import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException } from "../types/api.types";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook";
import { apiApprovalRequests } from "../api/approval-request.api.ts";
import {
  ApiApprovalRequestPayload,
  ApiApprovalRequestsFilter,
} from "../types/approval-request.types.ts";
import { employeesKeys } from "./employees/use-employees.query.ts";

export const approvalRequestsKeys = createQueryKeys("approvalRequests", {
  search: (filter: ApiApprovalRequestsFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiApprovalRequests.search(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  my: () => ({
    queryKey: ["my"],
    queryFn: async () => {
      const result = await apiApprovalRequests.getMy();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: async () => {
      const result = await apiApprovalRequests.getById(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useApprovalRequestsSearch = (filter: ApiApprovalRequestsFilter) => {
  return useQuery({
    ...approvalRequestsKeys.search(filter),
  });
};

export const useApprovalRequestsMy = () => {
  return useQuery({
    ...approvalRequestsKeys.my(),
  });
};

// export const useApprovalRequestDetail = (id: number) => {
//   return useQuery({
//     ...approvalRequestsKeys.detail(id),
//   });
// };

export const useApprovalRequestCreate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (payload: ApiApprovalRequestPayload) => {
      const result = await apiApprovalRequests.create(payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: employeesKeys.timeOffRequests._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useApprovalRequestUpdate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiApprovalRequestPayload }) => {
      const result = await apiApprovalRequests.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: employeesKeys.timeOffRequests._def,
      });
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.detail(variables.id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useApprovalRequestCancel = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiApprovalRequests.cancel(id);
      if (result.error) throw new ApiErrorException(result.error);
      return id;
    },
    onSuccess: (id) => {
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.detail(id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useApprovalRequestDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiApprovalRequests.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return id;
    },
    onSuccess: (id) => {
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.detail(id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

// export const useApprovalRequestCheckTimeOff = () => {
//   const { onError } = useErrorHandle();
//
//   return useMutation({
//     mutationFn: async (payload: ApiApprovalRequestPayload) => {
//       const result = await apiApprovalRequests.check(payload);
//       if (result.error || !result.data) throw new ApiErrorException(result.error);
//       return result.data;
//     },
//     onError: (error: Error) => {
//       onError(error);
//     },
//   });
// };

export const useApprovalRequestAnswer = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, approve }: { id: number; approve: boolean }) => {
      const result = approve
        ? await apiApprovalRequests.approve(id)
        : await apiApprovalRequests.reject(id);
      if (result.error) throw new ApiErrorException(result.error);
      return id;
    },
    onSuccess: (id) => {
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.detail(id).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: approvalRequestsKeys.my._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
