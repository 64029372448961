import { ApiEmployeeShort } from "../../types/employees/employee.types.ts";
import LinkTo from "../../ui/link/LinkTo.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import Stack from "../../ui/stack/Stack.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip/Tooltip.tsx";
import Text from "../../ui/typography/Text.tsx";
import { useTranslation } from "react-i18next";

export interface EmployeesListCellProps {
  employees: ApiEmployeeShort[];
}

export const EmployeesListCell = ({ employees }: EmployeesListCellProps) => {
  const { renderEmployeeName } = useFormatter();
  const { t } = useTranslation();
  if (employees.length == 0) return null;

  if (employees.length == 1)
    return (
      <LinkTo
        to={`/employees/${employees[0].id}`}
        className={"text-secondary"}
        variant={"secondary"}
      >
        {renderEmployeeName(employees[0])}
      </LinkTo>
    );
  if (employees.length == 2)
    return (
      <Stack>
        <LinkTo
          to={`/employees/${employees[0].id}`}
          className={"text-secondary"}
          variant={"secondary"}
        >
          {renderEmployeeName(employees[0])}
        </LinkTo>
        <LinkTo
          to={`/employees/${employees[1].id}`}
          className={"text-secondary"}
          variant={"secondary"}
        >
          {renderEmployeeName(employees[1])}
        </LinkTo>
      </Stack>
    );
  return (
    <Stack items={"start"}>
      <LinkTo
        to={`/employees/${employees[0].id}`}
        className={"text-secondary"}
        variant={"secondary"}
      >
        {renderEmployeeName(employees[0])}
      </LinkTo>
      <Tooltip>
        <TooltipTrigger>
          <Text
            className={"text-secondary"}
          >{`+${t("plurals:plural_others", { count: employees.length - 1 })}`}</Text>
        </TooltipTrigger>
        <TooltipContent>
          <Stack>
            {employees.slice(1).map((employee) => (
              <LinkTo
                key={`employee-tooltip-${employee.id}`}
                to={`/employees/${employee.id}`}
                className={"text-light"}
                variant={"secondary"}
              >
                {renderEmployeeName(employee)}
              </LinkTo>
            ))}
          </Stack>
        </TooltipContent>
      </Tooltip>
    </Stack>
  );
};
