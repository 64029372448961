import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException, ApiPassException } from "../types/api.types.ts";
import { apiCalendarHolidays } from "../api/calendar-holiday.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import {
  ApiCalendarHolidayCopy,
  ApiCalendarHolidayDateSavePayload,
  ApiCalendarHolidaySavePayload,
} from "../types/calendar-holiday.types.ts";

const calendarHolidaysKeys = createQueryKeys("calendarHolidays", {
  all: null,
  detail: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("Id is required");
      const result = await apiCalendarHolidays.getById(id);

      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),

  dates: (id: number | undefined, year: number) => ({
    queryKey: [id, year],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("Id is required");
      const result = await apiCalendarHolidays.getDates(id, year);

      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useCalendarHolidays = () => {
  return useQuery({
    ...calendarHolidaysKeys.all,
    queryFn: async () => {
      const result = await apiCalendarHolidays.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
  });
};

export const useCalendarHolidaySave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiCalendarHolidaySavePayload }) => {
      const result =
        id == 0
          ? await apiCalendarHolidays.create(payload)
          : await apiCalendarHolidays.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useCalendarHolidayDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiCalendarHolidays.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useCalendarHolidayDetails = (id: number | undefined, enabled: boolean = true) => {
  return useQuery({
    ...calendarHolidaysKeys.detail(id),
    enabled: enabled && !!id,
  });
};

export const useCalendarHolidayDownload = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, year }: { id: number; year: number }) => {
      const result = await apiCalendarHolidays.downloadDates(id, year);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, params) => {
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.all.queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.detail(params.id).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.dates._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useCalendarHolidayDates = (id: number | undefined, year: number) => {
  return useQuery({
    ...calendarHolidaysKeys.dates(id, year),
    enabled: !!id,
  });
};

export const useCalendarHolidayDateSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({
      id,
      calendarHolidayId,
      payload,
    }: {
      id: number;
      calendarHolidayId: number;
      payload: ApiCalendarHolidayDateSavePayload;
    }) => {
      const result =
        id == 0
          ? await apiCalendarHolidays.createHoliday(calendarHolidayId, payload)
          : await apiCalendarHolidays.updateHoliday(calendarHolidayId, id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.dates._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useCalendarHolidayDatesDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, calendarHolidayId }: { id: number; calendarHolidayId: number }) => {
      const result = await apiCalendarHolidays.deleteHoliday(calendarHolidayId, id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.dates._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useCalendarHolidayDatesCopy = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiCalendarHolidayCopy }) => {
      const result = await apiCalendarHolidays.copy(id, payload);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: calendarHolidaysKeys.dates._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
