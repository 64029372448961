import Stack from "../../../../ui/stack/Stack.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { EmployeePageComponentProps } from "./employee-page-components.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import {
  useDocumentDelete,
  useDocumentsFolderDelete,
  useDocumentsFolders,
  useDocumentsSearch,
} from "../../../../queries/use-documents.query.ts";
import { ApiDocument, ApiDocumentsFilter, ApiFolder } from "../../../../types/document.types.ts";
import { EmployeeDocumentsFolderEditModal } from "../widgets/EmployeeDocumentsFolderEditModal.tsx";
import { SettingsCard } from "../../../settings/components/SettingsCard.tsx";
import { EmployeeDocumentEditModal } from "../widgets/EmployeeDocumentEditModal.tsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Text from "../../../../ui/typography/Text.tsx";
import { EmployeeInlineCard } from "../../../../components/employee-inline-card/EmployeeInlineCard.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import Table from "../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import { PaginationBlock } from "../../../../ui/pagination/PaginationBlock.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { EmptyStateBase } from "../../../../components/empty-state/EmptyStateBase.tsx";
import useFile from "../../../../hooks/use-file.hook.ts";
import ButtonIcon from "../../../../ui/button/ButtonIcon.tsx";
import { EmployeeDocumentsMoveFileModal } from "../widgets/EmployeeDocumentsMoveFileModal.tsx";
import LinkButtonTo from "../../../../ui/link/LinkButtonTo.tsx";

export const EmployeeDocuments = ({ employee }: EmployeePageComponentProps) => {
  const { t } = useTranslation();
  // const { renderDate } = useFormatter();

  const modalsService = useModals();
  const { renderDate } = useFormatter();
  const { openFileByUUID } = useFile();
  const navigate = useNavigate();

  const { data: folders } = useDocumentsFolders(employee.id);
  const [searchParams] = useSearchParams();
  const folderIdParam = searchParams.get("folder_id");
  const selectedFolder = useMemo(() => {
    const folderId = folderIdParam ? parseInt(folderIdParam, 10) : undefined;
    if (!folderId) return undefined;
    return folders?.find((folder) => folder.id === folderId);
  }, [folderIdParam, folders]);

  const canManageDocuments = useMemo(() => {
    return true;
  }, [employee]);

  const handleEditOrCreate = useCallback((folder: ApiFolder | undefined = undefined) => {
    const folderToEdit = folder || {
      id: 0,
      title: "",
    };
    modalsService.openModal(EmployeeDocumentsFolderEditModal, {
      folder: folderToEdit,
      employeeId: employee.id,
    });
  }, []);

  const handleEditOrCreateDocument = useCallback(
    (document: ApiDocument | undefined = undefined) => {
      const documentToEdit = document || {
        id: 0,
        title: "",
        text: "",
        folder: selectedFolder ? { id: selectedFolder.id, title: selectedFolder.title } : undefined,
        file: undefined,
      };
      modalsService.openModal(EmployeeDocumentEditModal, {
        employeeId: employee.id,
        document: documentToEdit,
      });
    },
    [selectedFolder]
  );

  const [documentsFilter, setDocumentsFilter] = useState<ApiDocumentsFilter>({
    page_number: 1,
    folder_id: selectedFolder ? selectedFolder.id : undefined,
    empty_folder: selectedFolder ? undefined : true,
    employee_id: employee.id,
  });

  useEffect(() => {
    setDocumentsFilter({
      ...documentsFilter,
      folder_id: selectedFolder ? selectedFolder.id : undefined,
      empty_folder: selectedFolder ? undefined : true,
    });
  }, [selectedFolder]);

  const {
    data: documents,
    isFetching,
    isLoading: isDocumentsLoading,
  } = useDocumentsSearch(documentsFilter);

  const documentDelete = useDocumentDelete();
  const documentFolderDelete = useDocumentsFolderDelete();

  const handleDeleteDocumentAction = useCallback((document: ApiDocument) => {
    modalsService.openModal(ConfirmationPopup, {
      question: t("common:delete"),
      text: document.title,
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          await documentDelete.mutateAsync(document.id);
          analyticsService.trackEvent(analyticEvents.documents.deleted, {
            [analyticProperties.id]: document.id,
          });
        } catch {
          return;
        }
      },
    });
  }, []);

  const handleMoveDocumentAction = useCallback(
    (document: ApiDocument) => {
      modalsService.openModal(EmployeeDocumentsMoveFileModal, {
        document: document,
        employeeId: employee.id,
      });
    },
    [employee]
  );

  const handleDeleteFolderAction = useCallback((folder: ApiFolder) => {
    documentFolderDelete.mutate(
      { employeeId: employee.id, id: folder.id },
      {
        onSuccess: () => {
          analyticsService.trackEvent(analyticEvents.documents.folderDeleted, {
            [analyticProperties.id]: folder.id,
          });
        },
      }
    );
  }, []);

  const handleOpenFile = useCallback(
    async (document: ApiDocument) => {
      if (!document.file) return;
      await openFileByUUID(document.file.uuid);
    },
    [openFileByUUID]
  );

  const columnHelper = createColumnHelper<ApiDocument>();
  const columns = useMemo<ColumnDef<ApiDocument>[]>(
    () => [
      {
        header: t("common:date"),
        cell: (row) => <Text>{renderDate(row.row.original.datetime_updated_at)}</Text>,
        accessorKey: "datetime_updated_at",
      },
      {
        header: t("core:document"),
        cell: (row) => (
          <Stack items={"start"}>
            {row.row.original.file ? (
              <LinkButtonTo
                variant={"secondary"}
                onClick={() => {
                  handleOpenFile(row.row.original);
                }}
                className={"text-title"}
              >
                {row.row.original.title}
              </LinkButtonTo>
            ) : (
              <Text className={"text-title"}>{row.row.original.title}</Text>
            )}

            <Text className={"text-secondary"}>{row.row.original.file?.name}</Text>
          </Stack>
        ),
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      {
        header: t("common:owner"),
        cell: (row) => <EmployeeInlineCard employee={row.row.original.owner} />,
        accessorKey: "owner",
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleEditOrCreateDocument(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                variant={"menu"}
              >
                {t("common:edit")}
              </Button>
              <Button
                onClick={() => {
                  handleMoveDocumentAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-folder-gear"} />}
                variant={"menu"}
              >
                {t("common:move_to") + "..."}
              </Button>
              <Button
                onClick={() => {
                  handleDeleteDocumentAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menuDanger"}
              >
                {t("common:delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [documents?.items]
  );

  return (
    <>
      <Stack gap={"lg"}>
        <Stack className={"justify-between"} direction={"horizontal"} items={"start"}>
          {selectedFolder ? (
            <Stack gap={"md"}>
              <ButtonIcon
                variant={"secondary"}
                onClick={() => {
                  navigate(`/employees/${employee.id}/documents`);
                }}
                icon={<FontAwesomeIcon icon="fa-light fa-arrow-left" className={"text-lg"} />}
              ></ButtonIcon>
              <Title header={selectedFolder.title} size={"md"} />
            </Stack>
          ) : (
            <Title header={t("core:documents")} size={"md"} />
          )}

          {canManageDocuments && (
            <DropdownMenu
              trigger={
                <Button
                  variant={"primary"}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
                  size={"sm"}
                >
                  {t("common:create_new")}
                </Button>
              }
            >
              <Button
                variant={"menu"}
                onClick={() => {
                  handleEditOrCreateDocument();
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-upload"} />}
              >
                {t("core:create_document")}
              </Button>
              <Button
                variant={"menu"}
                onClick={() => {
                  handleEditOrCreate();
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-folder-plus"} />}
              >
                {t("core:create_folder")}
              </Button>
            </DropdownMenu>
          )}
        </Stack>
        {!selectedFolder && (
          <Box>
            {folders && (
              <Stack
                direction={"horizontal"}
                gap={"md"}
                className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
              >
                {folders.map((folder) => (
                  <SettingsCard
                    title={folder.title}
                    to={`/employees/${employee.id}/documents?folder_id=${folder.id}`}
                    extra={t("plurals:documents_count", { count: folder.documents_count })}
                    key={`folder-${folder.id}`}
                    optionsSlot={
                      <DropdownMenu
                        trigger={
                          <ButtonIcon
                            variant={"plain"}
                            icon={<FontAwesomeIcon icon="fa-light fa-ellipsis" />}
                          />
                        }
                      >
                        <Button
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            handleEditOrCreate(folder);
                          }}
                          leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                          variant={"menu"}
                        >
                          {t("common:edit")}
                        </Button>
                        <Button
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            handleDeleteFolderAction(folder);
                          }}
                          leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                          variant={"menuDanger"}
                        >
                          {t("common:delete")}
                        </Button>
                      </DropdownMenu>
                    }
                  />
                ))}
              </Stack>
            )}
          </Box>
        )}
        <Table
          data={documents?.items || []}
          columns={columns}
          isFetching={isFetching || documentDelete.isPending}
          showSkeleton={isDocumentsLoading}
          notFoundComponent={<EmptyStateNothingFound />}
          emptyStateComponent={
            <EmptyStateBase
              emoji={"🗃️"}
              title={t("core:no_documents_created")}
              description={t("core:no_documents_created_description")}
              primaryButton={
                canManageDocuments ? (
                  <Button
                    onClick={() => {
                      handleEditOrCreateDocument();
                    }}
                    size={"sm"}
                    variant={"secondary"}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-upload"} />}
                  >
                    {t("core:create_document")}
                  </Button>
                ) : null
              }
            />
          }
        />

        <PaginationBlock
          metadata={documents?.metadata}
          onPageChange={(page) => {
            setDocumentsFilter({ ...documentsFilter, page_number: page });
          }}
        />
      </Stack>
    </>
  );
};
