import { ListOptionProps } from "./list-option.types.ts";
import Stack from "../stack/Stack.tsx";
import { useTheme } from "../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import Text from "../typography/Text.tsx";
import React, { ElementType, forwardRef } from "react";
import { PolymorphicRef } from "../../helpers/generic-as-prop.ts";

type ListOptionComponentType = <C extends React.ElementType = "div">(
  props: ListOptionProps<C>
) => React.ReactNode | null;

const ListOptionComponentFN: ListOptionComponentType = forwardRef(
  <T extends ElementType = "div">(
    { leftIcon, rightIcon, title, titleSlot, caption, captionSlot, ...props }: ListOptionProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const theme = useTheme("listOption");

    return (
      <div ref={ref} {...props}>
        <Stack className={theme.cover} direction={"horizontal"}>
          {leftIcon && <div className={theme.leftIcon}>{leftIcon}</div>}
          <Stack>
            <Text className={twMerge(theme.title)}>{title}</Text>
            {titleSlot && titleSlot}
            {caption && <Text className={twMerge(theme.caption)}>{caption}</Text>}
            {captionSlot && captionSlot}
          </Stack>
          {rightIcon && <div className={theme.rightIcon}>{rightIcon}</div>}
        </Stack>
      </div>
    );
  }
);

export default ListOptionComponentFN;
