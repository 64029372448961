import { ModalProps } from "./modal.types.ts";
import useModals from "./modal.store.ts";
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";
import { ModalLayoutStretch } from "./layouts/ModalLayoutStretch.tsx";
import { ModalLayoutBase } from "./layouts/ModalLayoutBase.tsx";
import { ModalLayoutLightbox } from "./layouts/ModalLayoutLightbox.tsx";
import { ModalLayoutPage } from "./layouts/ModalLayoutPage.tsx";
import { Fragment } from "react";

export default function Modal({
  id,
  open,
  size = "md",
  children,
  variant = "middle",
  layout = "base",
  onBack,
  ...rest
}: ModalProps) {
  const { close } = useModals();

  const { refs, context } = useFloating({
    open: open,
    onOpenChange: () => {
      close(id);
    },
  });

  const dismiss = useDismiss(context, {
    escapeKey: true,
    outsidePress: false,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: 200,
    initial: {
      transform: variant === "side" ? "translateX(20px) scale(0.9)" : "translateY(20px) scale(0.9)",
      opacity: 0,
    },
    common: {
      transform: "translateY(0) scale(1)",
      opacity: 1,
    },
  });

  const theme = useTheme("modal");
  const { getFloatingProps } = useInteractions([dismiss]);
  const LayoutComponents = {
    base: ModalLayoutBase,
    stretch: ModalLayoutStretch,
    lightbox: ModalLayoutLightbox,
    page: ModalLayoutPage,
  };
  const SelectedLayout = LayoutComponents[layout] || Fragment; // Fragment will render just children without additional markup

  return (
    <>
      <FloatingPortal>
        {isMounted && (
          <FloatingOverlay lockScroll className={theme.overlay}>
            <FloatingFocusManager context={context}>
              <div
                className={twMerge(
                  theme.wrapper,
                  theme.variants.layout[layout].wrapper,
                  theme.variants.variant[variant]
                )}
              >
                <SelectedLayout
                  id={id}
                  refFromModal={refs.setFloating}
                  style={styles}
                  onBack={onBack}
                  floatingProps={getFloatingProps()}
                  size={size}
                  {...rest}
                >
                  {children}
                </SelectedLayout>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </>
  );
}
