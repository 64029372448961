export enum ApiErrors {
  validationError = 422,
}

export interface ApiError {
  status: ApiErrors;
}

export interface ApiValidationErrorField {
  code: string;
  field: string;
  message?: string;
}

export interface ApiValidationError extends ApiError {
  validationErrors: ApiValidationErrorField[];
}

export class ApiErrorException extends Error {
  public readonly error?: ApiError;

  constructor(public apiError: ApiError | undefined) {
    super();

    this.error = apiError;
  }
}

export class ApiPassException extends Error {
  constructor(public message: string) {
    super();
  }
}
