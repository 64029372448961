import { useTranslation } from "react-i18next";
import { EmptyStateBase } from "./EmptyStateBase.tsx";

export default function EmptyState() {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      emoji={"🕵️‍♀️"}
      title={t("common:empty")}
      description={t("common:create_new_item")}
    />
  );
}
