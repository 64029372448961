import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException } from "../types/api.types.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { apiLegalEntities } from "../api/legal-entity.api.ts";
import { ApiLegalEntitySavePayload } from "../types/legal-entity.types.ts";
import { useMemo, useState } from "react";
import { SelectItem } from "../ui/select/select.types.ts";

const legalEntitiesKey = createQueryKeys("legalEntities", {
  all: null,
});

export const useLegalEntities = (enabled = true) => {
  return useQuery({
    ...legalEntitiesKey.all,
    queryFn: async () => {
      const result = await apiLegalEntities.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
    enabled: enabled,
  });
};

export const useLegalEntitySave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiLegalEntitySavePayload }) => {
      const result =
        id == 0
          ? await apiLegalEntities.create(payload)
          : await apiLegalEntities.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: legalEntitiesKey.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useLegalEntityDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiLegalEntities.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: legalEntitiesKey.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useLegalEntitiesSearchInline = (enabled: boolean = true) => {
  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);

  const { data, isLoading } = useLegalEntities(enabled);

  const options = useMemo(() => {
    const allData = (data || []) as SelectItem[];
    const regex = new RegExp(searchKey || "", "i");
    return allData.filter((option) => regex.test(option.title));
  }, [data, searchKey]);

  function search(text: string | undefined) {
    setSearchKey(text);
  }

  return {
    data,
    isLoading,
    options,
    search,
  };
};
