import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";
import { ComponentProps } from "react";

export interface SwitchSizes extends Pick<ThemeSizes, "sm" | "md"> {
  [key: string]: string;
}

export interface SwitchElement {
  base: string;
  disabled: string;
  active: {
    on: string;
    off: string;
  };
  variants: {
    size: SwitchSizes;
  };
}

export interface UIKithSwitchTheme extends UIKitThemeBase {
  base: string;
  disabled: string;
  variants: {
    size: SwitchSizes;
  };
  toggle: {
    cover: SwitchElement;
    capsule: SwitchElement;
    circle: SwitchElement;
  };
  title: SwitchElement;
  description: SwitchElement;
}

export interface SwitchProps extends Omit<ComponentProps<"button">, "onChange"> {
  checked: boolean | undefined;
  title?: string;
  description?: string;
  size?: keyof SwitchSizes;
  onChange: (value: boolean) => void;
}

export const switchTheme: UIKithSwitchTheme = {
  base: "flex flex-row text-left select-none cursor-pointer",
  disabled: "cursor-default",
  variants: {
    size: {
      sm: "gap-3",
      md: "gap-4",
    },
  },
  toggle: {
    cover: {
      base: "flex content-center items-center pt-0.5",
      disabled: "",
      active: {
        on: "",
        off: "",
      },
      variants: {
        size: {
          sm: "h-5",
          md: "h-6",
        },
      },
    },
    capsule: {
      base: "rounded-full transition duration-200 ease-in-out relative outline-1 outline",
      disabled: "bg-dark/10 outline-dark/5",
      active: {
        on: "outline-dark bg-dark",
        off: "outline-dark/5 bg-dark/20",
      },
      variants: {
        size: {
          sm: "h-3.5 w-6",
          md: "h-4.5 w-8",
        },
      },
    },
    circle: {
      base: "rounded-full bg-light shadow ring-0 transition duration-200 ease-in-out absolute left-0 outline-1 outline",
      disabled: "bg-dark/20 outline-dark/10",
      active: {
        off: "outline-dark/10 translate-x-0",
        on: "outline-dark translate-x-full -ml-1",
      },
      variants: {
        size: {
          sm: "h-3.5 w-3.5",
          md: "h-4.5 w-4.5",
        },
      },
    },
  },
  title: {
    base: "font-semibold text-dark",
    disabled: "text-dark/60",
    active: {
      on: "",
      off: "",
    },
    variants: {
      size: {
        sm: "leading-5",
        md: "leading-6",
      },
    },
  },
  description: {
    base: "text-dark/60",
    disabled: "text-dark/60",
    active: {
      on: "",
      off: "",
    },
    variants: {
      size: {
        sm: "",
        md: "",
      },
    },
  },
};
