import Modal from "../../../../../ui/modal/modal.tsx";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import { useTranslation } from "react-i18next";
import Button from "../../../../../ui/button/Button.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { EmojiPickerDropdown } from "../../../../../components/emoji/EmojiPickerDropdown.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import Avatar from "../../../../../ui/avatar/Avatar.tsx";
import FontAwesomeIcon from "../../../../../ui/typography/FontAwesomeIcon.tsx";
import { useState } from "react";
import ColorBlock from "../../../../../ui/colorblock/ColorBlock.tsx";
import useModals from "../../../../../ui/modal/modal.store.ts";

export interface TimeOffCategoryDecorationEditProps extends ModalProps {
  emoji: string;
  color: string;
  onSave: (emoji: string, color: string) => void;
}

const limitedColors = ["#1B1D21", "#32309C", "#7124A1", "#AB2C78", "#2F9AB2", "#AFB21D", "#B22F1D"];

export function TimeOffCategoryDecorationEdit({
  emoji,
  color,
  onSave,
  ...props
}: TimeOffCategoryDecorationEditProps) {
  const { t } = useTranslation();
  const { id } = props;
  const { close } = useModals();
  const [currentEmoji, setCurrentEmoji] = useState<string>(emoji);
  const [currentColor, setCurrentColor] = useState<string>(color);

  return (
    <Modal
      {...props}
      title={t("time_off:change_style")}
      withCloser
      actions={
        <Stack gap={"sm"}>
          <Button
            type={"submit"}
            size={"lg"}
            onClick={() => {
              onSave(currentEmoji, currentColor);
              close(id);
            }}
          >
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <Stack gap={"2xl"}>
        <EmojiPickerDropdown
          onEmojiSelect={(em) => {
            setCurrentEmoji(em);
          }}
        >
          <Box
            className={"relative w-40 h-40 group cursor-pointer rounded-xl"}
            style={{ backgroundColor: currentColor }}
          >
            <Avatar variant={"plain"} url={undefined} emoji={currentEmoji} size={"2xl"} />
            <Box
              className={
                "bg-dark/20 opacity-0 group-hover:opacity-100 absolute inset-0 rounded-2xl flex items-center justify-center"
              }
            >
              <FontAwesomeIcon icon={"fa-light fa-icons"} className={"text-[74px] text-light "} />
            </Box>
          </Box>
        </EmojiPickerDropdown>

        <Stack direction={"horizontal"} gap={"sm"}>
          {limitedColors.map((colorCode) => (
            <ColorBlock
              key={colorCode}
              color={colorCode}
              size="md"
              selected={currentColor === colorCode}
              onClick={() => setCurrentColor(colorCode)}
            />
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
}
