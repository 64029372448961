import { api } from "./axios.ts";
import { ApiApprovalPolicy, ApiApprovalPolicyPayload } from "../types/approval-policy.types.ts";
import { ApiOperationResult } from "../types/common.types.ts";

export const apiApprovalPolicies = {
  getAll: async function () {
    return api.get<ApiApprovalPolicy[]>("/api/v1/approval-policies");
  },
  getPolicyById: async function (id: number) {
    return api.get<ApiApprovalPolicy>(`/api/v1/approval-policies/${id}`);
  },
  createPolicy: async function (payload: ApiApprovalPolicyPayload) {
    return api.post<ApiApprovalPolicy>("/api/v1/approval-policies", payload);
  },
  updatePolicy: async function (id: number, payload: ApiApprovalPolicyPayload) {
    return api.put<ApiApprovalPolicy>(`/api/v1/approval-policies/${id}`, payload);
  },
  deletePolicy: async function (id: number) {
    return api.delete<ApiOperationResult>(`/api/v1/approval-policies/${id}`);
  },
};
