import { ApiEmployeeShort } from "./employees/employee.types.ts";

import { SelectItem } from "../ui/select/select.types.ts";
import { ApiFilterBase } from "./filter.types.ts";

export interface ApiDepartmentShort extends SelectItem {
  id: number;
  title: string;
}

export interface ApiDepartment extends SelectItem {
  id: number;
  title: string;

  employees_count: number;

  manager?: ApiEmployeeShort;
  parent_id: number | null;

  // Для чарта
  direct_subordinates_count: number;
}

export interface ApiDepartmentFilter extends ApiFilterBase {
  text?: string;
}

export interface ApiDepartmentSavePayload {
  title: string;
  manager_id: number | null;
  parent_id: number | null;
}

export enum DepartmentNodeMode {
  parent = "parent",
  children = "children",
}
