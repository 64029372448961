import React, {
  ComponentProps,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

export interface BoxWithScrollProps extends ComponentProps<"div"> {
  children?: React.ReactNode;
  onBoxScroll?: (scrollTop: number, scrollHeight: number, height: number) => void;
}

export const BoxWithScroll = forwardRef<HTMLDivElement, BoxWithScrollProps>(function Box(
  { onBoxScroll, children, className, ...props }: BoxWithScrollProps,
  ref
) {
  const internalRef = useRef<HTMLDivElement | null>(null);
  const [topShadowVisible, setTopShadowVisible] = useState<boolean>(false);
  const [bottomShadowVisible, setBottomShadowVisible] = useState<boolean>(false);

  useEffect(() => {
    internalRef?.current?.addEventListener("scroll", onScroll);

    return () => {
      internalRef?.current?.removeEventListener("scroll", onScroll);
    };
  }, [props]);

  const onScroll = () => {
    const scrollTop = internalRef?.current?.scrollTop ?? 0;
    const scrollHeight = internalRef?.current?.scrollHeight ?? 0;
    const height = internalRef?.current?.getBoundingClientRect().height ?? 0;

    setTopShadowVisible(scrollTop - 24 > 0);
    setBottomShadowVisible(Math.ceil(scrollTop + height) + 24 < scrollHeight);
    onBoxScroll?.(scrollTop, scrollHeight, height);
  };

  return (
    <div
      className={twMerge("relative overflow-y-auto h-full", className)}
      ref={(node) => {
        internalRef.current = node;
        if (typeof ref === "function") {
          ref(node);
        } else if (ref) {
          (ref as MutableRefObject<HTMLDivElement | null>).current = node;
        }
      }}
      {...props}
    >
      <div
        className={`sticky left-0 top-0 right-0 h-6 -mt-6 transition-all pointer-events-none bg-gradient-to-b from-dark/5 to-light/0 ${topShadowVisible ? "opacity-100" : "opacity-0"}`}
      ></div>
      {children}
      <div
        className={`sticky left-0 bottom-0 right-0 h-6 -mb-6 transition-all pointer-events-none bg-gradient-to-t from-dark/5 to-light/0 ${bottomShadowVisible ? "opacity-100" : "opacity-0"}`}
      ></div>
    </div>
  );
});
