import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface ThemeStore {
  theme: string | null;
  setTheme: (newTheme: string | null) => void;
}

export const useThemeStore = create<ThemeStore>()(
  persist(
    (set) => ({
      theme: null,
      setTheme: (newTheme: string | null) =>
        set(() => ({
          theme: newTheme,
        })),
    }),
    {
      name: "theme",
    }
  )
);
