import { api } from "./axios.ts";
import {
  ApiCalendarHoliday,
  ApiCalendarHolidayCopy,
  ApiCalendarHolidayDate,
  ApiCalendarHolidayDateSavePayload,
  ApiCalendarHolidaySavePayload,
} from "../types/calendar-holiday.types.ts";
import { ApiOperationResult } from "../types/common.types.ts";

export const apiCalendarHolidays = {
  create: (payload: ApiCalendarHolidaySavePayload) => {
    return api.post<ApiCalendarHoliday>(`/api/v1/calendar-holidays`, payload);
  },

  update: (id: number, payload: ApiCalendarHolidaySavePayload) => {
    return api.put<ApiCalendarHoliday>(`/api/v1/calendar-holidays/${id}`, payload);
  },
  getById: async function (id: number | string) {
    return api.get<ApiCalendarHoliday>(`/api/v1/calendar-holidays/${id}`);
  },
  delete: (id: number) => {
    return api.delete<ApiOperationResult>(`/api/v1/calendar-holidays/${id}`);
  },
  // snooze: (id: number) => {
  //     return api.put<ApiOperationResult>(`/api/v1/candidates/${id}/snooze`, {});
  // },
  getAll: () => {
    return api.get<ApiCalendarHoliday[]>(`/api/v1/calendar-holidays`);
  },
  downloadDates: (calendarHolidayId: number, year: number) => {
    return api.post(`/api/v1/calendar-holidays/${calendarHolidayId}/download`, {
      year: year,
    });
  },
  getDates: (calendarHolidayId: number, year: number) => {
    return api.post<ApiCalendarHolidayDate[]>(
      `/api/v1/calendar-holidays/${calendarHolidayId}/dates/filter`,
      {
        year: year,
      }
    );
  },
  createHoliday: (calendarHolidayId: number, payload: ApiCalendarHolidayDateSavePayload) => {
    return api.post<ApiCalendarHolidayDate>(
      `/api/v1/calendar-holidays/${calendarHolidayId}/dates`,
      payload
    );
  },
  updateHoliday: (
    calendarHolidayId: number,
    dateId: number,
    payload: ApiCalendarHolidayDateSavePayload
  ) => {
    return api.put<ApiCalendarHolidayDate>(
      `/api/v1/calendar-holidays/${calendarHolidayId}/dates/${dateId}`,
      payload
    );
  },
  deleteHoliday: (calendarHolidayId: number, dateId: number) => {
    return api.delete<ApiOperationResult>(
      `/api/v1/calendar-holidays/${calendarHolidayId}/dates/${dateId}`
    );
  },
  copy: (calendarHolidayId: number, payload: ApiCalendarHolidayCopy) => {
    return api.post<ApiOperationResult>(
      `/api/v1/calendar-holidays/${calendarHolidayId}/dates/copy`,
      payload
    );
  },
};
