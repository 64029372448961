import EmptyStatePage404 from "../components/empty-state/EmptyStatePage404.tsx";
import { Page } from "../components/page/Page.tsx";

export default function NotFound() {
  return (
    <>
      <Page>
        <EmptyStatePage404 />
      </Page>
    </>
  );
}
