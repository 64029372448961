import React, { forwardRef, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { TextareaProps } from "./textarea.types.ts";
import { useTheme } from "../themes/provider.tsx";
import Box from "../box/Box.tsx";
import Text from "../typography/Text.tsx";
import { useMergeRefs } from "@floating-ui/react";

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      required = false,
      placeholder = "",
      className,
      readOnly = false,
      disabled = false,
      maxHeight,
      label = "",
      error,
      autoFocus,
      onFocus,
      onBlur,
      onInput,
      ...props
    },
    ref
  ) => {
    const theme = useTheme("textarea");
    const [focused, setFocused] = useState<boolean>();
    const [filled, setFilled] = useState<boolean>();

    useEffect(() => {
      if (internalRef?.current?.value?.trim().length) {
        setFilled(true);
      }
    }, []);

    useEffect(() => {
      resizeTextarea();
    }, [filled, focused]);

    useEffect(() => {
      setFilled(!!internalRef?.current?.value?.trim().length);

      resizeTextarea();
    }, [{ ...props }]);

    const resizeTextarea = () => {
      if (internalRef.current) {
        if (filled) {
          internalRef.current.style.minHeight = "auto";
          if (maxHeight) {
            internalRef.current.style.minHeight =
              Math.min(internalRef.current.scrollHeight, maxHeight) + "px";
          } else {
            internalRef.current.style.minHeight = internalRef.current.scrollHeight + "px";
          }
        } else {
          internalRef.current.style.minHeight = "auto";
        }
      }
    };

    const onHandleFocus = (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      setFocused(true);

      onFocus && onFocus(event);
    };

    const onHandleBlur = (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      setFocused(false);

      onBlur && onBlur(event);
    };

    const onHandleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const isFilled = event.target.value.trim().length !== 0;

      setFilled(isFilled);

      onInput && onInput(event);

      resizeTextarea();
    };

    const internalRef = useRef<HTMLTextAreaElement | null>(null);
    const refs = useMergeRefs([internalRef, ref]);

    const emitFocus = () => {
      if (focused) return;
      if (readOnly || disabled) return;
      if (!internalRef.current) return;

      internalRef.current.focus();
    };

    // при клике на контейнер не хотим терять фокус
    const mouseDownHandler = (event: React.MouseEvent) => {
      if (focused && event.target != internalRef.current) event.preventDefault();
    };

    return (
      <Box
        onClick={emitFocus}
        onMouseDown={mouseDownHandler}
        className={twMerge(
          theme.cover.base,
          theme.cover.hover,
          readOnly ? theme.cover.readonly : undefined,
          disabled ? theme.cover.disabled : undefined,
          className
        )}
      >
        <label
          className={twMerge(
            theme.label.base,
            disabled ? theme.label.disabled : undefined,
            readOnly ? theme.label.readonly : undefined,
            focused || filled ? theme.label.focused : theme.label.usual,
            className
          )}
        >
          {label} {required && "*"}
        </label>
        <Text
          className={twMerge(
            theme.placeholder.base,
            !filled && focused ? theme.placeholder.shown : undefined,
            className
          )}
        >
          {placeholder}
        </Text>
        <textarea
          ref={refs}
          className={twMerge(
            theme.textarea.base,
            theme.textarea.hover,
            readOnly ? theme.textarea.readonly : undefined,
            disabled ? theme.textarea.disabled : undefined
          )}
          onFocus={onHandleFocus}
          onBlur={onHandleBlur}
          onInput={onHandleInput}
          readOnly={readOnly}
          autoFocus={autoFocus}
          {...props}
        />
        {error && (
          <Box className={"pt-2 pb-4"}>
            <Text className={"text-danger"}>{error}</Text>
          </Box>
        )}
      </Box>
    );
  }
);
