import { Title } from "../../../components/title/Title.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import Box from "../../../ui/box/Box.tsx";
import { Tabs } from "../../../ui/tabs/Tabs.tsx";
import { TabItem } from "../../../ui/tabs/TabItem.tsx";
import { useEvents } from "../../../queries/use-events.query.ts";
import { add, isSameDay, startOfDay } from "date-fns";
import { useMemo, useState } from "react";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import { ApiEvent, getAttendeeResponseText } from "../../../types/event.types.ts";
import { TodayItem } from "../home.types.ts";
import { apiEvents } from "../../../api/event.api.ts";
import { EventPreviewModal } from "../../../components/event/EventPreviewModal.tsx";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import Button from "../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import ButtonLink from "../../../ui/button/ButtonLink.tsx";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import {
  ApiEmployeeFilter,
  ApiEmployeeFilterSort,
} from "../../../types/employees/employee.types.ts";
import { useEmployees } from "../../../queries/employees/use-employees.query.ts";
import { EmployeeViewModal } from "../../employees/widgets/EmployeeViewModal.tsx";
import { ApiPartialDate } from "../../../types/common.types.ts";

export const HomeUpcomingEvents = () => {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { openModal } = useModals();
  const { buildFromPartialDate, renderShortDate, renderEmployeeName, renderDateAndTime } =
    useFormatter();

  const { data: upcomingEvents, isLoading: upcomingEventsIsLoading } = useEvents({
    date_start: startOfDay(new Date()),
    date_end: add(startOfDay(new Date()), { days: 5 }),
  });

  const upcomingBirthdaysFilter: ApiEmployeeFilter = {
    page_number: 1,
    sort_by: ApiEmployeeFilterSort.date_birth,
  };
  const { data: upcomingBirthdays, isLoading: upcomingBirthdaysIsLoading } =
    useEmployees(upcomingBirthdaysFilter);

  const [showAll, setShowAll] = useState(false);
  const isLoading = upcomingEventsIsLoading || upcomingBirthdaysIsLoading;

  const buildTodayItemFromEvent = (event: ApiEvent) => {
    const add: TodayItem = {};
    add.id = event.id;
    // add.emoji = "📅";
    add.preTitle = event.title;
    const texts: string[] = [];
    texts.push(renderDateAndTime(event.datetime_start));
    for (const attendee of event.attendees) {
      if (attendee.employee) {
        if (attendee.employee.id != employee?.id) {
          texts.push(renderEmployeeName(attendee.employee));
        }
      } else if (attendee.email) {
        texts.push(attendee.email);
      }
    }
    add.title = texts.length > 4 ? texts.slice(0, 4).join(" · ") + "..." : texts.join(" · ");
    add.buttonTitle = t("common:view");

    const meAsAttendee = event.attendees.find((x) => x.employee?.id == employee?.id);
    if (meAsAttendee) {
      add.caption = getAttendeeResponseText(meAsAttendee.response_status);
    }

    add.onClick = async () => {
      if (event) {
        const { data } = await apiEvents.getEventById(event.id);
        if (data) {
          openModal(EventPreviewModal, {
            event: data,
          });
        }
      }
    };

    return add;
  };

  const filteredEvents = useMemo(() => {
    if (!upcomingEvents) return [];
    const now = new Date();

    return upcomingEvents.filter((event) => {
      const eventEndDate = event.datetime_end || event.datetime_start;
      return eventEndDate >= now;
    });
  }, [upcomingEvents]);

  const hasUpcomingEvents = Boolean(filteredEvents.length > 0);
  const hasUpcomingBirthdays = Boolean(
    employee?.organization.calendar_settings.is_enabled &&
      upcomingBirthdays &&
      upcomingBirthdays.items.length > 0
  );
  const isAnyUpcoming = hasUpcomingEvents || hasUpcomingBirthdays;

  const todayEvents = useMemo(() => {
    return (
      filteredEvents
        .filter((x) => x.datetime_start <= add(startOfDay(new Date()), { days: 1 }))
        .map(buildTodayItemFromEvent) || []
    );
  }, [filteredEvents]);

  const tomorrowEvents = useMemo(() => {
    return (
      filteredEvents
        .filter(
          (x) =>
            x.datetime_start > add(startOfDay(new Date()), { days: 1 }) &&
            x.datetime_start <= add(startOfDay(new Date()), { days: 2 })
        )
        .map(buildTodayItemFromEvent) || []
    );
  }, [filteredEvents]);

  const laterEvents = useMemo(() => {
    return (
      filteredEvents
        .filter((x) => x.datetime_start > add(startOfDay(new Date()), { days: 2 }))
        .map(buildTodayItemFromEvent) || []
    );
  }, [filteredEvents]);

  const [isLoadingHomeItemId, setIslLoadingHomeItemId] = useState<number | string | undefined>(
    undefined
  );

  const isBirthdayToday = (birthDate: ApiPartialDate): boolean => {
    if (!birthDate.month || !birthDate.day) {
      return false;
    }

    const today = startOfDay(new Date());
    const currentYear = today.getFullYear();
    const birthdayThisYear = new Date(currentYear, birthDate.month - 1, birthDate.day);
    return isSameDay(today, birthdayThisYear);
  };

  return (
    <>
      {isLoading && <Skeleton className={"w-full h-5"}></Skeleton>}
      {isAnyUpcoming && (
        <Box>
          <Stack direction={"horizontal"} justify={"between"}>
            <Title header={t("calendar:upcoming")} size={"lg"} paddingBottom paddingTop />
            <ButtonLink
              href={"/calendar"}
              rightIcon={<FontAwesomeIcon icon="fa-light fa-chevron-right" />}
              className={"mt-10"}
              variant={"secondary"}
            >
              {t("calendar:view_calendar")}
            </ButtonLink>
          </Stack>
          <Box className={"bg-light border border-dark/5"}>
            <Tabs>
              {hasUpcomingEvents && (
                <TabItem title={t("calendar:events")}>
                  <Stack className={"px-8 pb-8"}>
                    {todayEvents.length > 0 && (
                      <>
                        <Title header={t("common:today")} size={"xs"} paddingBottom paddingTop />
                        {todayEvents.map((item, i) => (
                          <ListItem
                            key={`event-${i}`}
                            emoji={item.emoji}
                            avatarUrl={item.avatarUrl}
                            caption={item.caption}
                            title={item.title}
                            preTitle={item.preTitle}
                            emphasis={"top"}
                            valueSlot={
                              <Button
                                isLoading={isLoadingHomeItemId == item.id}
                                variant={"plain"}
                                onClick={async () => {
                                  setIslLoadingHomeItemId(item.id);
                                  await item.onClick?.();
                                  setIslLoadingHomeItemId(undefined);
                                }}
                              >
                                {item.buttonTitle}
                              </Button>
                            }
                          />
                        ))}
                      </>
                    )}
                    {tomorrowEvents.length > 0 && (
                      <>
                        <Title header={t("common:tomorrow")} size={"xs"} paddingBottom paddingTop />
                        {tomorrowEvents.map((item, i) => (
                          <ListItem
                            key={`event-${i}`}
                            emoji={item.emoji}
                            avatarUrl={item.avatarUrl}
                            caption={item.caption}
                            title={item.title}
                            preTitle={item.preTitle}
                            emphasis={"top"}
                            valueSlot={
                              <Button
                                isLoading={isLoadingHomeItemId == i}
                                variant={"plain"}
                                onClick={async () => {
                                  setIslLoadingHomeItemId(item.id);
                                  await item.onClick?.();
                                  setIslLoadingHomeItemId(undefined);
                                }}
                              >
                                {item.buttonTitle}
                              </Button>
                            }
                          />
                        ))}
                      </>
                    )}
                    {laterEvents.length > 0 && (
                      <>
                        <Title header={t("common:later")} size={"xs"} paddingBottom paddingTop />
                        {laterEvents.slice(0, showAll ? laterEvents.length : 5).map((item, i) => (
                          <ListItem
                            key={`event-${i}`}
                            emoji={item.emoji}
                            avatarUrl={item.avatarUrl}
                            caption={item.caption}
                            title={item.title}
                            preTitle={item.preTitle}
                            emphasis={"top"}
                            valueSlot={
                              <Button
                                isLoading={isLoadingHomeItemId == i}
                                variant={"plain"}
                                onClick={async () => {
                                  setIslLoadingHomeItemId(item.id);
                                  await item.onClick?.();
                                  setIslLoadingHomeItemId(undefined);
                                }}
                              >
                                {item.buttonTitle}
                              </Button>
                            }
                          />
                        ))}
                        {!showAll && laterEvents.length > 5 && (
                          <Box className={"pt-8"}>
                            <Button
                              onClick={() => {
                                setShowAll(!showAll);
                              }}
                              size={"sm"}
                              variant={"secondary"}
                            >
                              {t("common:show_more") + "..."}
                            </Button>
                          </Box>
                        )}
                      </>
                    )}
                  </Stack>
                </TabItem>
              )}
              {hasUpcomingBirthdays && (
                <TabItem title={t("calendar:celebrations")}>
                  {upcomingBirthdays && upcomingBirthdays.items.length > 0 && (
                    <Stack className={"p-8"}>
                      {upcomingBirthdays.items
                        .slice(0, showAll ? upcomingBirthdays.items.length : 5)
                        .map((birthdayEmployee, i) => {
                          const formattedDate = renderShortDate(
                            buildFromPartialDate(birthdayEmployee.birth_date)
                          );
                          return birthdayEmployee.birth_date ? (
                            <ListItem
                              key={`birthday-${i}`}
                              emoji={"🎂"}
                              avatarUrl={birthdayEmployee.avatar_url}
                              title={`${formattedDate} - ${
                                birthdayEmployee.id === employee?.id
                                  ? isBirthdayToday(birthdayEmployee.birth_date)
                                    ? t("calendar:happy_birthday")
                                    : t("calendar:get_ready_to_celebrate_your_birthday")
                                  : isBirthdayToday(birthdayEmployee.birth_date)
                                    ? t("calendar:congratulate_colleague")
                                    : t("calendar:get_ready_to_celebrate_colleague_birthday")
                              }`}
                              preTitle={renderEmployeeName(birthdayEmployee)}
                              emphasis={"top"}
                              valueSlot={
                                <Button
                                  isLoading={isLoadingHomeItemId == i}
                                  variant={"plain"}
                                  onClick={() => {
                                    openModal(EmployeeViewModal, {
                                      employeeId: birthdayEmployee.id,
                                    });
                                  }}
                                >
                                  {t("core:view_profile")}
                                </Button>
                              }
                            />
                          ) : null;
                        })}
                      {!showAll && upcomingBirthdays.items.length > 5 && (
                        <Box className={"pt-8"}>
                          <Button
                            onClick={() => {
                              setShowAll(!showAll);
                            }}
                            size={"sm"}
                            variant={"secondary"}
                          >
                            {t("common:show_more") + "..."}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  )}
                </TabItem>
              )}
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  );
};
