import { useApprovalRequestsSearch } from "../../../../../queries/use-approval-requests.query.ts";
import { useMemo, useState } from "react";
import {
  ApiApprovalRequest,
  ApiApprovalRequestsFilter,
} from "../../../../../types/approval-request.types.ts";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import Stack from "../../../../../ui/stack/Stack.tsx";
import Text from "../../../../../ui/typography/Text.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import Table from "../../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../../components/empty-state/EmptyState.tsx";
import { PaginationBlock } from "../../../../../ui/pagination/PaginationBlock.tsx";
import Avatar from "../../../../../ui/avatar/Avatar.tsx";
import useFormatter from "../../../../../hooks/use-formatter.hook.ts";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../../../services/analytics-service.ts";
import useTimeOffHook from "../../../../../hooks/use-time-off.hook.tsx";
import { EmployeeInlineCardFull } from "../../../../../components/employee-inline-card/EmployeeInlineCardFull.tsx";

export function ApprovalTabRequests() {
  const { t } = useTranslation();
  const { renderDate } = useFormatter();
  const [filter, setFilter] = useState<ApiApprovalRequestsFilter>({
    page_number: 1,
  });
  const { data: requests, isLoading, isFetching } = useApprovalRequestsSearch(filter);
  const { getActionColumnForRequest } = useTimeOffHook();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.approvals.historyViewed);
  });

  const columns = useMemo<ColumnDef<ApiApprovalRequest>[]>(
    () => [
      {
        header: t("core:employee"),
        cell: (row) => <EmployeeInlineCardFull employee={row.row.original.employee} />,
        accessorKey: "employee",
      },

      {
        header: t("common:created"),
        cell: (row) => <Text>{renderDate(new Date(row.row.original.datetime_created_at))}</Text>,
        accessorKey: "datetime_created_at",
        size: 100,
      },
      {
        header: t("time_off:category"),
        cell: (row) => (
          <Stack direction={"horizontal"} gap={"md"} items={"center"}>
            <Avatar emoji={row.row.original.time_off_data.category.emoji} />
            <Stack>
              <Text className={"text-base text-dark font-bold"}>
                {row.row.original.time_off_data.category.title}
              </Text>
              <Text className={"text-secondary"}>
                {t(`time_off:request_status_${row.row.original.status}`)}
              </Text>
            </Stack>
          </Stack>
        ),
        accessorKey: "type",
      },
      {
        id: "period",
        header: t("common:period"),
        cell: (row) => (
          <Text>
            {renderDate(new Date(row.row.original.time_off_data.date_from))} —{" "}
            {renderDate(new Date(row.row.original.time_off_data.date_to))}
          </Text>
        ),
        accessorKey: "period",
      },
      getActionColumnForRequest(),
    ],
    []
  );

  return (
    <Box className={"pt-6"}>
      <Table
        data={requests?.items || []}
        columns={columns}
        showSkeleton={isLoading}
        isFetching={isFetching}
        showNotFoundError={requests?.metadata.total_items == 0}
        notFoundComponent={<EmptyStateNothingFound />}
        emptyStateComponent={<EmptyState />}
      />
      {(requests?.metadata.total_pages ?? 0) > 1 && (
        <PaginationBlock
          metadata={requests?.metadata}
          onPageChange={(page) => {
            setFilter({ ...filter, page_number: page });
          }}
        />
      )}
    </Box>
  );
}
