import { ElementType, forwardRef, Ref, useEffect, useState } from "react";
import { default as ReactDatePicker, registerLocale } from "react-datepicker";
import { DatePickerProps } from "./datepicker.types.ts";
import "react-datepicker/dist/react-datepicker.css";
import { useTheme } from "../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import Box from "../box/Box.tsx";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";
import Text from "../typography/Text.tsx";

// import { en } from "date-fns/locale/en";
import { ru } from "date-fns/locale/ru";
import { useEmployeeStore } from "../../stores/employee.store.ts";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import ButtonIcon from "../button/ButtonIcon.tsx";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";

// https://reactdatepicker.com/#example-read-only-datepicker

export const Datepicker = forwardRef(
  (
    {
      value,
      onChange,
      readOnly = false,
      disabled = false,
      label = "",
      required = false,
      error,
      isClearable = false,
    }: DatePickerProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const [date, setDate] = useState<Date | null | undefined>(value);

    // registerLocale("en", en);
    registerLocale("ru", ru);

    const { employee } = useEmployeeStore();

    useEffect(() => {
      setDate(value);
    }, [value]);

    const theme = useTheme("datepicker");

    const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onChange?.(null);
      setDate(null);
    };

    return (
      <Box className="flex flex-col">
        <Box ref={ref} className={twMerge(theme.base, "flex items-center")}>
          <ReactDatePicker
            selected={date}
            onChange={(date) => {
              setDate(date);
              onChange?.(date);
            }}
            wrapperClassName={twMerge(theme.picker, readOnly ? theme.readonly : undefined)}
            readOnly={readOnly}
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            customInput={
              <DateInput
                className={"w-full"}
                disabled={disabled}
                readOnly={readOnly}
                dateRequired={required}
                label={`${label}`}
              />
            }
            showYearDropdown
            locale={employee?.language || "en"}
          />
          {isClearable && date && (
            <ButtonIcon
              className={"absolute right-0 top-1/2 -translate-y-1/2 justify-center"}
              variant={"plain"}
              onClick={handleClear}
              icon={<FontAwesomeIcon icon={"fa-light fa-xmark"} />}
            />
          )}
        </Box>
        {error && (
          <Box className={"pt-1"}>
            <Text className={"text-danger text-sm"}>{error}</Text>
          </Box>
        )}
      </Box>
    );
  }
);

export type InputDateProps<T extends ElementType = "input"> = PolymorphicComponentProp<
  T,
  {
    value?: Date;
    onClick?: () => void;
    // type: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    dateRequired: boolean;
  }
>;

const DateInput = forwardRef<HTMLDivElement, InputDateProps>(
  ({ dateRequired, label, disabled, value, onClick, readOnly }, ref) => {
    const theme = useTheme("input");
    const { renderDate } = useFormatter();
    return (
      <Box
        onClick={onClick}
        ref={ref}
        className={twMerge(
          theme.cover.base,
          disabled ? theme.cover.disabled : undefined,
          readOnly ? theme.cover.readonly : undefined
        )}
      >
        <Box
          className={twMerge(
            theme.label.base,
            disabled ? theme.label.disabled : undefined,
            readOnly ? theme.label.readonly : undefined,
            value ? theme.label.focused : theme.label.usual
          )}
        >
          {label} {dateRequired && <span className={"text-danger"}>*</span>}
        </Box>
        <Box
          className={twMerge(
            theme.input.base,
            theme.input.variants.size["md"],
            disabled ? theme.input.disabled : undefined,
            readOnly ? theme.input.readonly : undefined
          )}
        >
          {value ? renderDate(value) : " "}
        </Box>
      </Box>
    );
  }
);

export default Datepicker;
