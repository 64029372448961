import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import {
  ApiTimeOffAccrualFrequencyTypes,
  ApiTimeOffDateType,
  ApiTimeOffPolicyMilestone,
  ApiTimeOffPolicyMilestoneCarryoverType,
} from "../../../../../types/time-off.types.ts";
import Modal from "../../../../../ui/modal/modal.tsx";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import { useTranslation } from "react-i18next";
import useModals from "../../../../../ui/modal/modal.store.ts";
import Box from "../../../../../ui/box/Box.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import { Input } from "../../../../../ui/input/Input.tsx";
import Text from "../../../../../ui/typography/Text.tsx";
import Select from "../../../../../ui/select/Select.tsx";
import useTimeOffHook from "../../../../../hooks/use-time-off.hook.tsx";
import { ListItem } from "../../../../../components/list-item/ListItem.tsx";
import { Switch } from "../../../../../ui/switch/Switch.tsx";
import { ListColumnItem } from "../../../../../components/list-item/ListColumnItem.tsx";
import { DropdownSelect } from "../../../../../ui/select/DropdownSelect.tsx";
import { useEffect, useMemo } from "react";
import { ConfirmationPopup } from "../../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import Datepicker from "../../../../../ui/datepicker/Datepicker.tsx";
import LinkButtonTo from "../../../../../ui/link/LinkButtonTo.tsx";
import i18n from "../../../../../i18n.ts";

export interface TimeOffMilestoneEditModalProps extends ModalProps {
  milestone?: ApiTimeOffPolicyMilestone;
  index?: number;
  onModified: (milestone: ApiTimeOffPolicyMilestone) => void;
  onDelete: () => void;
}

const TimeOffMilestoneObject = yup.object({
  title: yup.string().required(i18n.t("errors:field_required")),

  start_date_type: yup
    .mixed<ApiTimeOffDateType>()
    .oneOf(Object.values(ApiTimeOffDateType))
    .required(),
  start_amount: yup.number().required(),

  start_balance_enabled: yup.boolean().required(),
  start_balance_amount: yup.number().required(),

  accrual_frequency_type: yup
    .mixed<ApiTimeOffAccrualFrequencyTypes>()
    .oneOf(Object.values(ApiTimeOffAccrualFrequencyTypes))
    .required(),
  accrual_frequency_date_first: yup.date().nullable(),
  accrual_frequency_date_second: yup.date().nullable(),
  accrual_amount: yup.number().required(),

  accrual_cap_enabled: yup.boolean().required(),
  accrual_cap_amount: yup.number().required(),

  carryover_type: yup
    .mixed<ApiTimeOffPolicyMilestoneCarryoverType>()
    .oneOf(Object.values(ApiTimeOffPolicyMilestoneCarryoverType))
    .required(),
  carryover_amount: yup.number().required(),
});

export type TimeOffMilestoneSchema = yup.InferType<typeof TimeOffMilestoneObject>;

export function TimeOffMilestoneEditModal({
  milestone,
  onModified,
  onDelete,
  ...props
}: TimeOffMilestoneEditModalProps) {
  const { t } = useTranslation();
  const { id } = props;
  const { getCarryoverExpiringDateTypes, getAccrualFrequencyTypes, getMilestoneCarryoverTypes } =
    useTimeOffHook();
  const { close, openModal } = useModals();

  const formHook = useForm<TimeOffMilestoneSchema>({
    mode: "onSubmit",
    resolver: yupResolver(TimeOffMilestoneObject),
    defaultValues: {
      start_amount: 0,
      start_date_type: ApiTimeOffDateType.month,
      start_balance_amount: 0,
      start_balance_enabled: false,
      carryover_type: ApiTimeOffPolicyMilestoneCarryoverType.none,
      carryover_amount: 0,
      accrual_cap_enabled: false,
      accrual_cap_amount: 0,
      accrual_frequency_type: ApiTimeOffAccrualFrequencyTypes.monthly,
      accrual_amount: 0,
    },
  });

  const {
    watch,
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = formHook;

  const onSubmit = handleSubmit((data) => {
    const milestonePayload: ApiTimeOffPolicyMilestone = {
      title: data.title,
      start_date_type: data.start_date_type,
      start_amount: data.start_amount,
      start_balance_amount: data.start_balance_amount,
      accrual_frequency_type: data.accrual_frequency_type,
      accrual_amount: data.accrual_amount,
      accrual_cap_enabled: data.accrual_cap_enabled,
      accrual_cap_amount: data.accrual_cap_amount,
      carryover_enabled: data.carryover_type !== ApiTimeOffPolicyMilestoneCarryoverType.none,
      carryover_amount:
        data.carryover_type === ApiTimeOffPolicyMilestoneCarryoverType.unlimited
          ? 0
          : data.carryover_amount,
    };

    if (withDateInAccrual) {
      milestonePayload.accrual_frequency_data = {
        date_first: data.accrual_frequency_date_first,
      };

      if (withTwoDateInAccrual)
        milestonePayload.accrual_frequency_data.date_second = data.accrual_frequency_date_second;
    }

    onModified(milestonePayload);
    close(id);
  });

  const startBalanceEnabled = watch("start_balance_enabled");
  const accrualCapEnabled = watch("accrual_cap_enabled");
  const carryoverType = watch("carryover_type");
  const accrualFrequencyType = watch("accrual_frequency_type");

  const withDateInAccrual = useMemo(() => {
    return !!~[
      ApiTimeOffAccrualFrequencyTypes.semimonthly,
      ApiTimeOffAccrualFrequencyTypes.monthly,
      ApiTimeOffAccrualFrequencyTypes.semiannual,
      ApiTimeOffAccrualFrequencyTypes.yearly,
    ].indexOf(accrualFrequencyType);
  }, [accrualFrequencyType]);

  const withTwoDateInAccrual = useMemo(() => {
    return !!~[
      ApiTimeOffAccrualFrequencyTypes.semimonthly,
      ApiTimeOffAccrualFrequencyTypes.semiannual,
    ].indexOf(accrualFrequencyType);
  }, [accrualFrequencyType]);

  useEffect(() => {
    if (!milestone) return;

    reset({
      title: milestone.title,
      start_amount: milestone.start_amount,
      start_date_type: milestone.start_date_type,
      start_balance_amount: Number(milestone.start_balance_amount),
      start_balance_enabled: milestone.start_balance_amount > 0,
      carryover_type: milestone.carryover_enabled
        ? milestone.carryover_amount > 0
          ? ApiTimeOffPolicyMilestoneCarryoverType.up_to
          : ApiTimeOffPolicyMilestoneCarryoverType.unlimited
        : ApiTimeOffPolicyMilestoneCarryoverType.none,
      carryover_amount: Number(milestone.carryover_amount),
      accrual_cap_enabled: milestone.accrual_cap_enabled,
      accrual_cap_amount: Number(milestone.accrual_cap_amount),
      accrual_amount: milestone.accrual_amount,
      accrual_frequency_type: milestone.accrual_frequency_type,
      accrual_frequency_date_first: milestone.accrual_frequency_data?.date_first,
      accrual_frequency_date_second: milestone.accrual_frequency_data?.date_second,
    });
  }, [milestone]);

  const handleDelete = () => {
    openModal(ConfirmationPopup, {
      question: t("common:delete"),
      text: t("common:delete_confirm"),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          onDelete();
          close(id);
        } catch {
          return;
        }
      },
    });
  };

  return (
    <Modal
      {...props}
      title={milestone ? t("time_off:milestone_edit") : t("time_off:milestone_new")}
      withCloser={true}
      size={"lg"}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} onClick={onSubmit}>
            {t("common:save")}
          </Button>
          <Button
            variant={"plain"}
            size={"lg"}
            onClick={() => {
              close(id);
            }}
          >
            {t("common:cancel")}
          </Button>
        </Stack>
      }
    >
      <Stack gap={"lg"}>
        <Input
          type={"text"}
          {...register("title")}
          error={errors.title?.message}
          label={t("common:title")}
          size={"lg"}
        />

        <Box variant={"border"}>
          <Stack gap={"md"}>
            <Box>
              <Title
                header={t("time_off:milestone_start")}
                size={"xs"}
                className={"text-dark/60"}
                paddingBottom={true}
              />
              <Stack gap={"md"} direction={"horizontal"}>
                <Box className={"w-48 flex-shrink-0"}>
                  <Input
                    type={"number"}
                    {...register("start_amount")}
                    error={errors.start_amount?.message}
                    label={t("time_off:milestone_start_amount_title")}
                  />
                </Box>
                <Box className={"w-full flex-grow"}>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Select
                        label={t("common:period")}
                        options={getCarryoverExpiringDateTypes()}
                        emptyTitle={t("common:select")}
                        value={value}
                        onChange={onChange}
                        error={errors.start_date_type?.message}
                      />
                    )}
                    control={control}
                    name={"start_date_type"}
                  />
                </Box>
              </Stack>
            </Box>
            <Text className={"text-secondary"}>{t("time_off:milestone_start_description")}</Text>
          </Stack>
        </Box>
        <Stack>
          <Title
            header={t("time_off:milestone_accrual_title")}
            size={"xs"}
            className={"text-dark/60"}
            paddingBottom={true}
            paddingTop={true}
          />
          <Stack gap={"md"} direction={"horizontal"}>
            <Box className={"w-48 flex-shrink-0"}>
              <Input
                type={"text"}
                {...register("accrual_amount")}
                error={errors.accrual_amount?.message}
                label={t("time_off:period_track_type_days_title")}
              />
            </Box>
            <Box className={"w-full flex-grow"}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t("common:period")}
                    options={getAccrualFrequencyTypes()}
                    emptyTitle={t("common:select")}
                    value={value}
                    onChange={onChange}
                    error={errors.accrual_frequency_type?.message}
                  />
                )}
                control={control}
                name={"accrual_frequency_type"}
              />
            </Box>
          </Stack>
          {withDateInAccrual && (
            <Stack direction={"horizontal"} gap={"md"}>
              <Box className={"w-48"}>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Datepicker
                      value={value}
                      onChange={onChange}
                      label={withTwoDateInAccrual ? t("common:date") : t("common:date")}
                      error={errors.accrual_frequency_date_first?.message}
                    />
                  )}
                  control={control}
                  name={"accrual_frequency_date_first"}
                />
              </Box>
              {withTwoDateInAccrual && (
                <Box className={"w-48"}>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Datepicker
                        value={value}
                        onChange={onChange}
                        label={t("time_off:carryover_second_date_title")}
                        error={errors.accrual_frequency_date_second?.message}
                      />
                    )}
                    control={control}
                    name={"accrual_frequency_date_second"}
                  />
                </Box>
              )}
            </Stack>
          )}
        </Stack>
        <Stack>
          <Title
            header={t("common:settings")}
            size={"xs"}
            className={"text-dark/60"}
            paddingBottom={true}
            paddingTop={true}
          />
          <Stack gap={"md"}>
            <Box>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    size={"sm"}
                    title={t("time_off:milestone_start_balance_title")}
                    description={t("time_off:milestone_start_balance_caption")}
                  />
                )}
                control={control}
                name={"start_balance_enabled"}
              />
              {startBalanceEnabled && (
                <Box className={"pl-9 w-88"}>
                  <Input
                    type={"text"}
                    {...register("start_balance_amount")}
                    error={errors.start_balance_amount?.message}
                    label={t("time_off:period_track_type_days_title")}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    size={"sm"}
                    title={t("time_off:accrual_cap_title")}
                    description={t("time_off:accrual_cap_yes_caption")}
                  />
                )}
                control={control}
                name={"accrual_cap_enabled"}
              />
              {accrualCapEnabled && (
                <Box className={"pl-9 w-88"}>
                  <Input
                    type={"text"}
                    {...register("accrual_cap_amount")}
                    error={errors.accrual_cap_amount?.message}
                    label={t("time_off:period_track_type_days_title")}
                  />
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
        <Stack>
          <Title
            header={t("time_off:carryover_settings")}
            size={"xs"}
            className={"text-dark/60"}
            paddingBottom={true}
            paddingTop={true}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <ListColumnItem
                title={t("time_off:milestone_carryover_settings_description")}
                valueSlot={
                  <DropdownSelect
                    value={value}
                    onChange={onChange}
                    options={getMilestoneCarryoverTypes()}
                  />
                }
              />
            )}
            control={control}
            name={"carryover_type"}
          />
          {carryoverType == ApiTimeOffPolicyMilestoneCarryoverType.up_to && (
            <Box className={"w-60"}>
              <Input
                type={"number"}
                {...register("carryover_amount")}
                error={errors.carryover_amount?.message}
                label={t("time_off:period_track_type_days_title")}
              />
            </Box>
          )}
        </Stack>

        {milestone && (
          <Stack>
            {/*<Title*/}
            {/*  header={t("common:delete")}*/}
            {/*  size={"xs"}*/}
            {/*  className={"text-dark/60"}*/}
            {/*  paddingBottom={true}*/}
            {/*/>*/}
            <hr className="border-dark/10" />
            <ListItem
              title={t("time_off:milestone_delete_title")}
              caption={t("time_off:milestone_delete_caption")}
              valueSlot={
                <LinkButtonTo onClick={handleDelete} variant={"danger"} className={"shrink-0"}>
                  {t("common:delete")}
                </LinkButtonTo>
              }
            ></ListItem>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
}
