import { useTranslation } from "react-i18next";
import Modal from "../../ui/modal/modal";
import Button from "../../ui/button/Button";
import useModals from "../../ui/modal/modal.store.ts";
import { ModalProps } from "../../ui/modal/modal.types.ts";
import {
  ApiEvent,
  ApiEventAttendee,
  ApiEventAttendeeResponseStatuses,
  ApiEventEdit,
  ApiEventVisibility,
  getDescriptionForAttendee,
} from "../../types/event.types.ts";
import Stack from "../../ui/stack/Stack.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import { ListItem } from "../list-item/ListItem.tsx";
import { Title } from "../title/Title.tsx";
import { UserListItem } from "../user-list/UserListItem.tsx";
import { useEmployeeStore } from "../../stores/employee.store.ts";
import { useCallback, useEffect, useState } from "react";
import { ConfirmationPopup } from "../confirmation-popup/ConfirmationPopup.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { useEventsDelete } from "../../queries/use-events.query.ts";
import Text from "../../ui/typography/Text.tsx";
import { EventEditModal } from "./EventEditModal.tsx";
import { useEmployeesByIds } from "../../queries/employees/use-employees.query.ts";
import { useEffectOnce } from "react-use";
import ButtonLink from "../../ui/button/ButtonLink.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";

export interface EventPreviewModalProps extends ModalProps {
  event: ApiEvent;
}

export function EventPreviewModal({ event, ...props }: EventPreviewModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const { renderDate, renderTime, renderEmployeeName } = useFormatter();
  const modalsService = useModals();
  const { employee } = useEmployeeStore();
  const useEventDelete = useEventsDelete();

  const [attendees, setAttendees] = useState<ApiEventAttendee[]>(event.attendees);
  const { data: usersLoaded } = useEmployeesByIds(
    event.attendees.filter((x) => x.employee).map((x) => x.employee?.id || 0)
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.events.viewed, {
      [analyticProperties.id]: event.id,
    });
  });

  useEffect(() => {
    if (usersLoaded && usersLoaded.length > 0) {
      const prevAttendees = attendees;
      for (let i = 0; i < prevAttendees.length; i++) {
        if (prevAttendees[i].employee) {
          const user = usersLoaded.find((x) => x.id == prevAttendees[i].employee?.id);
          if (user) {
            prevAttendees[i].runtime_only_employee = user;
          }
        }
      }
      setAttendees(prevAttendees);
    }
  }, [usersLoaded]);

  const handleEdit = (event: ApiEvent) => {
    const eventToEdit: ApiEventEdit = {
      ...event,
      is_conference: !!event.conference_data,
    };

    modalsService.openModal(EventEditModal, {
      event: eventToEdit,
      eventId: event?.id,
    });
  };

  const handleDelete = useCallback(() => {
    modalsService.openModal(ConfirmationPopup, {
      question: t("calendar:event_delete"),
      text: t("calendar:event_delete_description"),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          await useEventDelete.mutateAsync(event.id);

          analyticsService.trackEvent(analyticEvents.events.deleted, {
            [analyticProperties.id]: event.id,
            [analyticProperties.actionType]: "Calendar",
          });

          close(id);
        } catch {
          return;
        }
      },
    });
  }, [event]);

  const attendeeResponseStatus = (attendee: ApiEventAttendee) => {
    if (attendee.response_status == ApiEventAttendeeResponseStatuses.accepted) {
      return <Text className={"text-positive"}>{t("calendar:response_status_accepted")}</Text>;
    } else if (attendee.response_status == ApiEventAttendeeResponseStatuses.needs_action) {
      return <Text className={"text-warning"}>{t("calendar:response_status_needs_action")}</Text>;
    } else if (attendee.response_status == ApiEventAttendeeResponseStatuses.tentative) {
      return (
        <Text className={"text-warning/50"}>{t("calendar:response_status_needs_tentative")}</Text>
      );
    } else if (attendee.response_status == ApiEventAttendeeResponseStatuses.declined) {
      return <Text className={"text-danger"}>{t("calendar:response_status_needs_declined")}</Text>;
    }
    return null;
  };

  const attendeeDescription = (attendee: ApiEventAttendee) => {
    const texts: string[] = getDescriptionForAttendee(attendee);

    return (
      <Text className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}>
        {texts.join(" · ")}
      </Text>
    );
  };

  return (
    <Modal
      {...props}
      layout="base"
      size={"md"}
      title={event.title}
      withCloser
      closeByEscEnabled
      actions={
        <Stack gap={"sm"}>
          <Button onClick={() => close(id)} size={"lg"} className={"w-full"}>
            {t("common:close")}
          </Button>
          {employee?.id == event.organizer.employee?.id && (
            <Button
              onClick={() => {
                close(id);
                handleEdit(event);
              }}
              size={"lg"}
              variant={"secondary"}
              className={"w-full"}
            >
              {t("common:edit")}
            </Button>
          )}
          {employee?.id == event.organizer.employee?.id && (
            <Button onClick={handleDelete} size={"lg"} variant={"danger"} className={"w-full"}>
              {t("common:delete")}
            </Button>
          )}
        </Stack>
      }
    >
      <Stack>
        <ListItem
          preTitle={t("common:date")}
          title={
            `${renderDate(event.datetime_start)}` +
            (event.is_all_day
              ? `— ${renderDate(event.datetime_end)}`
              : ` · ${renderTime(event.datetime_start)} — ${renderTime(event.datetime_end)}`)
          }
          caption={Intl.DateTimeFormat().resolvedOptions().timeZone}
        />
        {event.conference_data && (
          <ListItem
            preTitle={event.conference_data.title}
            title={event.conference_data.label}
            valueSlot={
              <ButtonLink
                onClick={() => {
                  analyticsService.trackEvent(analyticEvents.events.joinedConference, {
                    [analyticProperties.id]: event.id,
                    [analyticProperties.source]: event.conference_data?.label,
                  });
                }}
                blank
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-video"} />}
                href={event.conference_data.uri}
              >
                {t("calendar:join")}
              </ButtonLink>
            }
          />
        )}
        {event.description && (
          <ListItem preTitle={t("common:description")} title={event.description} />
        )}
        <Title header={t("calendar:guests")} size={"xs"} paddingBottom paddingTop />
        <Stack gap={"md"}>
          {attendees.map((guest, index) => (
            <UserListItem
              key={index}
              primaryText={guest.employee ? renderEmployeeName(guest.employee) : guest.email || ""}
              avatarUrl={guest.employee?.avatar_url}
              text2Slot={attendeeDescription(guest)}
              text3Slot={attendeeResponseStatus(guest)}
            />
          ))}
        </Stack>
        <Title header={t("common:settings")} size={"xs"} paddingBottom paddingTop />
        <ListItem
          preTitle={t("calendar:event_visibility")}
          title={
            event.visibility == ApiEventVisibility.private
              ? t("calendar:event_visibility_private")
              : t("calendar:event_visibility_public")
          }
        />
      </Stack>
    </Modal>
  );
}
