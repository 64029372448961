import {
  useCalendarHolidayDelete,
  useCalendarHolidays,
} from "../../../queries/use-calendar-holidays.query.ts";
import { Page } from "../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/button/Button.tsx";
import { useCallback, useMemo } from "react";

import { CalendarHolidayEditModal } from "./widgets/CalendarHolidayEditModal.tsx";
import { ApiCalendarHoliday, calendarHolidayMock } from "../../../types/calendar-holiday.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Stack from "../../../ui/stack/Stack.tsx";
import Table from "../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../components/empty-state/EmptyStateNothingFound.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { useEffectOnce } from "react-use";
import DropdownMenu from "../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import LinkTo from "../../../ui/link/LinkTo.tsx";
import { EmptyStateBase } from "../../../components/empty-state/EmptyStateBase.tsx";

export const CalendarHolidays = () => {
  const { data: calendarHolidays, isLoading, isFetching } = useCalendarHolidays();
  const { t } = useTranslation();
  const modalsService = useModals();

  const handleEditOrCreate = useCallback((policy?: ApiCalendarHoliday) => {
    const calendarToEdit: ApiCalendarHoliday = policy || calendarHolidayMock;
    modalsService.openModal(CalendarHolidayEditModal, {
      calendarHoliday: calendarToEdit,
    });
  }, []);

  const calendarHolidayDelete = useCalendarHolidayDelete();

  const handleDeleteAction = useCallback((calendarHoliday: ApiCalendarHoliday) => {
    calendarHolidayDelete.mutate(calendarHoliday.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.calendarHolidays.deleted, {
          [analyticProperties.id]: calendarHoliday.id,
        });
      },
    });
  }, []);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.calendarHolidays.viewed);
  });

  const columnHelper = createColumnHelper<ApiCalendarHoliday>();
  const columns = useMemo<ColumnDef<ApiCalendarHoliday>[]>(
    () => [
      {
        header: t("common:title"),
        cell: (row) => (
          <LinkTo to={`/settings/holidays/${row.row.original.id}`} className={"text-title"}>
            {row.row.original.title}
          </LinkTo>
        ),
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      {
        header: t("core:country"),
        cell: (row) => <div className={""}>{row.row.original.country.title}</div>,
        accessorKey: "country",
        size: 300,
      },
      {
        header: t("common:location"),
        cell: (row) => (
          <div className={""}>
            {row.row.original.locations.map((location) => (
              <span key={`date-${location.id}`} className={"block"}>
                {location.title}
              </span>
            ))}
          </div>
        ),
        accessorKey: "location",
        size: 200,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
            <Button
              onClick={() => {
                handleEditOrCreate(cell.row.original);
              }}
              variant={"secondary"}
            >
              {t("common:edit")}
            </Button>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleDeleteAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
              >
                {t("common:delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 100,
      }),
    ],
    [calendarHolidays]
  );
  return (
    <Page>
      <Page.Header title={t("core:calendar_holidays_title")}>
        <Button onClick={() => handleEditOrCreate()}>{t("common:add")}</Button>
      </Page.Header>
      <Page.Content>
        <Table
          data={calendarHolidays || []}
          columns={columns}
          isFetching={isFetching || calendarHolidayDelete.isPending}
          showSkeleton={isLoading}
          notFoundComponent={<EmptyStateNothingFound />}
          emptyStateComponent={
            <EmptyStateBase
              emoji={"🕵️‍♀️"}
              title={t("core:calendar_holidays_empty_state_title")}
              description={t("core:calendar_holidays_empty_state_description")}
              primaryButton={
                <Button
                  onClick={() => {
                    handleEditOrCreate();
                  }}
                  size={"sm"}
                  variant={"secondary"}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-plus"} />}
                >
                  {t("common:create_new")}
                </Button>
              }
            />
          }
        />
      </Page.Content>
    </Page>
  );
};
