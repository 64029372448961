import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import i18n from "../../../../i18n.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import Box from "../../../../ui/box/Box.tsx";

export interface ChoosePeriodModalProps extends ModalProps {
  dateFrom?: Date;
  dateTo?: Date;
  // todayOrLaterIsRequired?: boolean;
  onApply: (dateFrom: Date, dateTo: Date) => void;
}

export function ChoosePeriodModal({
  dateFrom,
  dateTo,
  // todayOrLaterIsRequired = false,
  onApply,
  ...props
}: ChoosePeriodModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const periodSchema = yup.object({
    dateFrom: yup.date().required(i18n.t("errors:field_required")),
    //  if time-off should be only from today or later
    //
    // .test("is-today-or-later", t("errors:date_must_be_today_or_later"), function (value) {
    //   if (!todayOrLaterIsRequired || !value) return true;
    //
    //   // Create today's date at midnight UTC for comparison
    //   const today = new Date();
    //   today.setUTCHours(0, 0, 0, 0);
    //
    //   // Convert input date to midnight UTC
    //   const inputDate = new Date(value.getTime());
    //   inputDate.setUTCHours(0, 0, 0, 0);
    //
    //   return inputDate >= today;
    // }),
    dateTo: yup
      .date()
      .required(i18n.t("errors:field_required"))
      .test(
        "is-after-start-date",
        t("errors:end_date_should_be_greater_start_date"),
        function (endDate) {
          const startDate = this.parent.dateFrom;
          if (!startDate || !endDate) return true; // Handle undefined cases

          // Convert both to UTC midnight for date-only comparison
          const startUtc = new Date(startDate.getTime());
          startUtc.setUTCHours(0, 0, 0, 0);
          const endUtc = new Date(endDate.getTime());
          endUtc.setUTCHours(0, 0, 0, 0);

          return endUtc >= startUtc;
        }
      ),
  });

  interface PeriodSchema extends yup.InferType<typeof periodSchema> {}

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PeriodSchema>({
    mode: "onSubmit",
    resolver: yupResolver(periodSchema),
    defaultValues: {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });

  const onSubmit = handleSubmit((schemaData) => {
    onApply(schemaData.dateFrom, schemaData.dateTo);
    close(id);
  });

  return (
    <>
      <Modal
        {...props}
        title={t("common:period")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <Button type={"submit"} size={"lg"} onClick={onSubmit}>
              {t("common:save")}
            </Button>
            <Button
              type={"submit"}
              size={"lg"}
              variant={"plain"}
              onClick={() => {
                close(id);
              }}
            >
              {t("common:cancel")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Box className={"grid grid-cols-2 gap-sm"}>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Datepicker
                  value={value}
                  onChange={onChange}
                  label={t("common:date_from")}
                  error={errors.dateFrom?.message}
                />
              )}
              control={control}
              name={"dateFrom"}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Datepicker
                  value={value}
                  onChange={onChange}
                  label={t("common:date_to")}
                  error={errors.dateTo?.message}
                />
              )}
              control={control}
              name={"dateTo"}
            />
          </Box>
        </form>
      </Modal>
    </>
  );
}
