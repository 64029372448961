import { useTranslation } from "react-i18next";
import { EmptyStateBase } from "./EmptyStateBase.tsx";

export default function EmptyStateNothingFound() {
  const { t } = useTranslation();
  return (
    <EmptyStateBase
      emoji={"🤷"}
      title={t("common:nothing_found")}
      description={t("common:no_results_were_found")}
    />
  );
}
