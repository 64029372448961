import { ApiRole, Rule } from "../../../../../types/role.types.ts";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { SettingsCard } from "../../../components/SettingsCard.tsx";
import { useCallback } from "react";
import { RuleEditModal } from "../widgets/RuleEditModal.tsx";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useModals from "../../../../../ui/modal/modal.store.ts";
import { EditRoleSchema, RoleAssignToConditionsItems } from "../role-edit.types.ts";
import useRoleEditService from "../role-edit-service.ts";
import { Title } from "../../../../../components/title/Title.tsx";

export interface RoleEditAssignToSectionProps {
  role?: ApiRole | undefined;
  formHook: UseFormReturn<EditRoleSchema>;
}

export function RoleEditAssignToSection({ role, formHook }: RoleEditAssignToSectionProps) {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { getAssignCardTitle, getAssignCardDescription } = useRoleEditService();
  const { control, getValues } = formHook;

  const {
    append: assignToAppend,
    remove: assignToRemove,
    update: assignToUpdate,
  } = useFieldArray({
    control,
    name: "assign_to_list",
  });

  const handleEditAssignTo = useCallback(
    (rule?: Rule | undefined, index?: number | undefined) => {
      modalsService.openModal(RuleEditModal, {
        rule,
        conditionTypes: RoleAssignToConditionsItems(),
        onModified(modifiedRule: Rule) {
          if (index !== undefined) {
            role && assignToUpdate(index, modifiedRule);
          } else {
            assignToAppend(modifiedRule);
          }
        },
        onDelete() {
          index !== undefined && assignToRemove(index);
        },
      });
    },
    [role]
  );

  return (
    <Stack direction={"vertical"} gap={"md"}>
      <Title
        header={t("core:assign_employees")}
        caption={t("core:assign_employees_description")}
        size={"lg"}
        paddingBottom
        paddingTop
      />

      <Stack
        direction={"horizontal"}
        gap={"md"}
        className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
      >
        {getValues("assign_to_list")?.map((rule, index) => (
          <SettingsCard
            key={`role-card-${index}`}
            title={getAssignCardTitle(rule.condition_type)}
            description={getAssignCardDescription(rule.sign, rule.condition_entities)}
            onClick={() => {
              handleEditAssignTo(rule as Rule, index);
            }}
          />
        ))}

        <SettingsCard
          icon={"fa-plus"}
          title={t("core:create_new_rule")}
          description={role?.assign_to_list.length ? "" : t("core:assign_employees_to_this_role")}
          onClick={handleEditAssignTo}
          variant={"action"}
        />
      </Stack>

      {/*<Stack direction={"horizontal"} gap={"md"} items={"center"}>*/}
      {/*  <Button></Button>*/}
      {/*  <Stack direction={"horizontal"} items={"center"} gap={"sm"}>*/}
      {/*    <FontAwesomeIcon*/}
      {/*      icon="fa-thin fa-circle-exclamation"*/}
      {/*      className={"text-warning"}*/}
      {/*    />*/}
      {/*    -*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
    </Stack>
  );
}
