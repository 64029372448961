import { ModalLayoutProps } from "../modal.types.ts";
import { ModalHeader } from "../ModalHeader.tsx";
import { useTheme } from "../../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import { BoxWithScroll } from "../../box/BoxWithScroll.tsx";
import Box from "../../box/Box.tsx";

export function ModalLayoutStretch({
  children,
  id,
  title,
  withCloser,
  style,
  refFromModal,
  floatingProps,
  // className,
  size = "md",
  layout = "stretch",
  actions,
  onModalContentScroll,
  onBack,
  ...rest
}: ModalLayoutProps) {
  const theme = useTheme("modal");

  return (
    <div
      ref={refFromModal}
      style={style}
      {...floatingProps}
      className={twMerge(
        theme.base,
        theme.variants.layout[layout].content,
        theme.variants.size[size]
      )}
    >
      {title && (
        <ModalHeader id={id} title={title} withCloser={withCloser} onBack={onBack} {...rest} />
      )}

      <BoxWithScroll
        className={"overflow-y-auto flex-grow h-full"}
        onBoxScroll={onModalContentScroll}
      >
        <Box className={theme.container[size]}>{children}</Box>
      </BoxWithScroll>

      {actions && (
        <Box
          style={{ boxShadow: "0px -1px 0px 0px hsla(0, 0%, 0%, 0.04)" }}
          className={theme.variants.layout[layout].actions}
        >
          {actions}
        </Box>
      )}
    </div>
  );
}
