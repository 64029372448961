import {
  ApiApplicant,
  ApiApplicantShort,
  ApiCandidate,
} from "../../../../types/recruit/applicant.types.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { ApplicantProfileContacts } from "./ApplicantProfileContacts.tsx";
import { Tabs } from "../../../../ui/tabs/Tabs.tsx";
import { TabItem } from "../../../../ui/tabs/TabItem.tsx";
import { getSourceTitle } from "../../../../types/recruit/source.types.ts";
import ApplicantProfileCV from "./ApplicantProfileCV.tsx";
import { ApplicantProfileComments } from "./ApplicantProfileComments.tsx";
import { useTranslation } from "react-i18next";
import Box from "../../../../ui/box/Box.tsx";
import { Tag } from "../../../../components/tag/Tag.tsx";
import { useCandidatesByApplicant } from "../../../../queries/recruit/use-candidates.query.ts";
import { Title } from "../../../../components/title/Title.tsx";
import {
  getStageEmoji,
  StageDisqualifiedEmoji,
  StageReservedEmoji,
} from "../../../../types/recruit/stages.types.ts";
import ButtonIcon from "../../../../ui/button/ButtonIcon.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { useCallback } from "react";

export interface ApplicantMergeProfileCompareProps {
  applicant: ApiApplicant;
  similarApplicantIndex?: number;
  similarApplicants?: ApiApplicantShort[];
  onChangeSimilarIndex?: (index: number) => void;
}

export const ApplicantMergeProfileCompare = ({
  applicant,
  similarApplicantIndex,
  similarApplicants,
  onChangeSimilarIndex,
}: ApplicantMergeProfileCompareProps) => {
  const { t } = useTranslation();
  const { data: candidates } = useCandidatesByApplicant(applicant.id);

  const handleSelectSimilarProfile = useCallback(
    (delta: number) => {
      if (!similarApplicants || !similarApplicantIndex) return;

      let nextIndex = similarApplicantIndex - 1 + delta;
      if (nextIndex >= similarApplicants.length) nextIndex = 0;
      else if (nextIndex < 0) nextIndex = 0;
      onChangeSimilarIndex?.(nextIndex);
    },
    [similarApplicantIndex, similarApplicants]
  );

  return (
    <Stack gap={"lg"} className={"basis-1/2"}>
      <Box className={"h-[420px]"}>
        <Stack gap={"md"} className={"w-full px-6 pt-6"} items={"center"}>
          <Avatar
            size={"2xl"}
            url={applicant.photo_url}
            variant={"opaque"}
            acronym={`${applicant.first_name} ${applicant.last_name}`}
          />

          <Stack items={"center"} className={"text-lg"}>
            <Title
              header={`${applicant.first_name} ${applicant.middle_name} ${applicant.last_name}`}
              size={"lg"}
            />
            {applicant.current_position.position && (
              <Text className={"text-secondary text-center"}>
                {applicant.current_position.position}
              </Text>
            )}
            {applicant.current_position.company && (
              <Text className={"text-secondary  text-center"}>
                {applicant.current_position.company}
              </Text>
            )}
          </Stack>

          <Box className={"p-lg"}>
            <Stack direction={"horizontal"} gap={"sm"}>
              {applicant.labels.map((label, index) => (
                <Tag color={label.color} key={`applicant-tag-${index}`}>
                  {label.title}
                </Tag>
              ))}
            </Stack>
          </Box>
        </Stack>
        {similarApplicantIndex && similarApplicants && (
          <Stack items={"center"} justify={"center"}>
            <Stack direction={"horizontal"} items={"center"} gap={"sm"}>
              <ButtonIcon
                icon={<FontAwesomeIcon icon={"fa-light fa-chevron-left"} />}
                variant={"secondary"}
                onClick={() => {
                  handleSelectSimilarProfile(-1);
                }}
              />
              <Text
                className={"text-title"}
              >{`${t("ats:similar_candidate")}: ${similarApplicantIndex} ${t("common:of")} ${similarApplicants.length}`}</Text>
              <ButtonIcon
                icon={<FontAwesomeIcon icon={"fa-light fa-chevron-right"} />}
                variant={"secondary"}
                onClick={() => {
                  handleSelectSimilarProfile(1);
                }}
              />
            </Stack>
          </Stack>
        )}
      </Box>
      <ApplicantProfileContacts applicant={applicant} contacts={applicant.contacts} />
      {candidates && candidates.length > 0 && (
        <Stack gap={"lg"}>
          {candidates.map((candidate: ApiCandidate) => (
            <Box key={`candidate-${candidate.id}`}>
              <Stack direction={"horizontal"} items={"center"} gap={"sm"}>
                {candidate.disqualification_reason ? (
                  candidate.reserved === true ? (
                    <Avatar url={null} emoji={StageReservedEmoji} key={"avatar_disqualified"} />
                  ) : (
                    <Avatar url={null} emoji={StageDisqualifiedEmoji} key={"avatar_disqualified"} />
                  )
                ) : (
                  <Avatar
                    url={null}
                    emoji={getStageEmoji(candidate.pipeline_stage)}
                    key={"avatar_qualified"}
                  />
                )}
                <Stack>
                  <Text size={"lg"} className={"text-title"}>
                    {candidate.disqualification_reason == null
                      ? candidate.pipeline_stage.title
                      : t("ats:disqualified")}
                  </Text>
                  <Text className={"text-secondary"}>{candidate.job.title}</Text>
                </Stack>
              </Stack>
            </Box>
          ))}
        </Stack>
      )}
      <Stack gap={"sm"} className={"flex-grow pb-6"}>
        <Tabs>
          {applicant.cvs?.map((cv, index) => (
            <TabItem title={getSourceTitle(cv.source)} key={`resume-tab-${index}`}>
              <ApplicantProfileCV applicant={applicant} cv={cv} />
            </TabItem>
          ))}
          <TabItem title={t("core:notes")}>
            {applicant && <ApplicantProfileComments disabledComments applicant={applicant} />}
          </TabItem>
        </Tabs>
      </Stack>
    </Stack>
  );
};
