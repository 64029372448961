import {
  ApiIntegration,
  ApiIntegrationGoogleCalendarSave,
  ApiIntegrationLoginUrl,
  ApiIntegrationType,
} from "../../types/integrations/integration.types.ts";
import { api } from "../axios.ts";
import { ApiOperationResult } from "../../types/common.types.ts";

export const apiIntegrations = {
  uninstall: (identity: string) => {
    return api.delete(`/api/v1/integrations/uninstall/${encodeURIComponent(identity)}`);
  },

  uninstallForOrganization: (integrationType: ApiIntegrationType) => {
    return api.delete(`/api/v1/integrations/uninstall/organization/${integrationType}`);
  },

  /*
   * Api could be called without query
   */
  emailMarkAsRead: (emailId: number | string) => {
    return api.put(`/api/v1/integrations/email/read/${emailId}`, {});
  },
  /*
   * Api could be called without query.  This function is used to get the login URL for the email integration
   */
  install: (integrationType: ApiIntegrationType) => {
    return api.get<ApiIntegrationLoginUrl>(`/api/v1/integrations/install/${integrationType}`);
  },

  getInstalled: () => {
    return api.get<ApiIntegration[]>(`/api/v1/integrations/installed`);
  },
  getInstalledForOrganization: () => {
    return api.get<ApiIntegration[]>(`/api/v1/integrations/installed/organization`);
  },
  manageGoogleCalendar: (payload: ApiIntegrationGoogleCalendarSave) => {
    return api.post<ApiOperationResult>(
      `/api/v1/integrations/manage/google-calendar-service-account`,
      payload
    );
  },
};
