import { api } from "../axios.ts";
import { ApiJob, ApiJobSavePayload, ApiJobsFilter } from "../../types/recruit/job.types.ts";
import { ApiPagedData } from "../../types/filter.types.ts";
import { ApiEmployee } from "../../types/employees/employee.types.ts";

export const apiJobs = {
  create: (payload: ApiJobSavePayload) => {
    return api.post<ApiJob>("/api/v1/jobs", payload);
  },
  update: (id: number, payload: ApiJobSavePayload) => {
    return api.put<ApiJob>(`/api/v1/jobs/${id}`, payload);
  },
  search: (filter: ApiJobsFilter, signal?: AbortSignal) => {
    return api.post<ApiPagedData<ApiJob>>("/api/v1/jobs/search", filter, signal);
  },
  getById: (id: number) => {
    return api.get<ApiJob>(`/api/v1/jobs/${id}`);
  },
  getByListOfId: (ids: number[]) => {
    return api.post<ApiJob[]>(`/api/v1/jobs/get-by-ids`, {
      job_ids: ids,
    });
  },
  recruitersSearch: (signal?: AbortSignal) => {
    return api.post<ApiPagedData<ApiEmployee>>(
      "/api/v1/jobs/recruiters/search",
      { page_number: 1 },
      signal
    );
  },
};
