import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import { ApiTerminationReason } from "../../../../../types/employees/employee-dismissal-reason.types.ts";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import Modal from "../../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { Input } from "../../../../../ui/input/Input.tsx";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useModals from "../../../../../ui/modal/modal.store.ts";
import * as yup from "yup";
import i18n from "../../../../../i18n.ts";
import { useEmployeeDismissalReasonSave } from "../../../../../queries/employees/use-employee-dismissal-reason.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";

export interface EmployeeDismissalReasonEditModalProps extends ModalProps {
  dismissalReason: ApiTerminationReason;
}

export function EmployeeDismissalReasonEditModal({
  dismissalReason,
  ...props
}: EmployeeDismissalReasonEditModalProps) {
  const { t } = useTranslation();
  const { close } = useModals();
  const { id } = { ...props };

  const EmployeeDismissalReasonSchemaObject = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
  });

  type EmployeeDismissalReasonSchema = yup.InferType<typeof EmployeeDismissalReasonSchemaObject>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EmployeeDismissalReasonSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EmployeeDismissalReasonSchemaObject),
    defaultValues: {
      title: dismissalReason.title,
    },
  });

  const dismissalReasonSave = useEmployeeDismissalReasonSave(control);

  const onSubmit = handleSubmit((data) => {
    dismissalReasonSave.mutate(
      { id: dismissalReason.id, title: data.title },
      {
        onSuccess(data) {
          if (dismissalReason.id == 0) {
            analyticsService.trackEvent(analyticEvents.employeeDismissalReasons.created, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.employeeDismissalReasons.edited, {
              [analyticProperties.id]: dismissalReason.id,
            });
          }
          close(id);
        },
      }
    );
  });

  return (
    <Modal
      {...props}
      title={t("core:termination_reasons")}
      withCloser
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            type={"submit"}
            size={"lg"}
            isLoading={dismissalReasonSave.isPending}
            onClick={onSubmit}
          >
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Input
          label={t("common:title")}
          required={true}
          type={"text"}
          autoFocus={true}
          {...register("title")}
          error={errors.title?.message}
        />
      </form>
    </Modal>
  );
}
