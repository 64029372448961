import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { ChangeEvent, useRef, useState } from "react";
import { ApiFilePathTypes } from "../../types/file.types.ts";
import { apiFiles } from "../../api/file.api.ts";
import { ApiEntityTypes, ApiFile } from "../../types/common.types.ts";
import Button from "../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";

export interface FileUploadProps {
  entityId: number | undefined;
  entityType: ApiEntityTypes;
  afterSend?: (file: ApiFile) => void;
  buttonText?: string;
}

export const FileUpload = ({ entityId, entityType, afterSend, buttonText }: FileUploadProps) => {
  const { handleErrors } = useErrorHandle();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSubmit = async (fileForSend: File) => {
    const formData = new FormData();

    fileForSend && formData.append("file", fileForSend);

    let path: ApiFilePathTypes;

    switch (entityType) {
      case ApiEntityTypes.applicant:
        path = ApiFilePathTypes.applicant;
        break;
      case ApiEntityTypes.candidate:
        path = ApiFilePathTypes.candidate;
        break;
      case ApiEntityTypes.employee:
        path = ApiFilePathTypes.employee;
        break;
    }

    if (entityId) formData.append("entity_id", entityId.toString());
    formData.append("path", path);
    formData.append("is_public", "false");
    formData.append("save_original_name", "true");

    // beforeSend?.();
    setIsLoading(true);

    const { data: file, error } = await apiFiles.add(formData);

    if (file) {
      afterSend?.(file);
    } else {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files?.[0] ?? null;
    file && onSubmit(file);
  };

  const onClick = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  return (
    <>
      <input className={"hidden"} type={"file"} onChange={handleFileChanged} ref={fileInputRef} />

      <Button
        variant={"secondary"}
        className={"w-full"}
        onClick={onClick}
        leftIcon={<FontAwesomeIcon icon={"fa-light fa-paperclip text-lg"} />}
        isLoading={isLoading}
      >
        {buttonText && buttonText}
      </Button>
    </>
  );
};
