import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiStages } from "../../api/recruit/stages.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { ApiPipelineStage, ApiPipelineStagSavePayload } from "../../types/recruit/stages.types.ts";

const stagesKeys = createQueryKeys("stages", {
  all: null,
});

export const useStages = () => {
  return useQuery({
    ...stagesKeys.all,
    queryFn: async () => {
      const result = await apiStages.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 10 * 60 * 1000,
  });
};

export interface SaveParams {
  id: number;
  payload: ApiPipelineStagSavePayload;
}

export const useStageSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: SaveParams) => {
      const result =
        id == 0 ? await apiStages.create(payload) : await apiStages.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: stagesKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useStageDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiStages.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: stagesKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useStageSort = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async (ids: number[]) => {
      queryClient.setQueryData(stagesKeys.all.queryKey, (old: ApiPipelineStage[]) => {
        const sorted = ids.map((id: number) => old.find((d: ApiPipelineStage) => d.id === id));
        return sorted;
      });

      const result = await apiStages.updateSort({ ids });
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    // onSuccess: () => {
    // queryClient.invalidateQueries({
    //   queryKey: stagesKeys.all.queryKey,
    // });
    // },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
