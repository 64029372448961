import { ReactNode, useState } from "react";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../../ui/themes/provider.tsx";
import { EmojiPicker } from "./EmojiPicker.tsx";

export interface EmojiPickerDropdownProps {
  children?: ReactNode;
  onEmojiSelect: (emoji: string) => void;
}

export const EmojiPickerDropdown = ({ children, onEmojiSelect }: EmojiPickerDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
  });

  const dismiss = useDismiss(context, { bubbles: true });
  // const click = useClick(context);
  const role = useRole(context, { role: "menu" });
  const click = useClick(context, {
    event: "mousedown",
    toggle: true,
  });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const theme = useTheme("dropdown");

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className={twMerge(theme.base, "z-popover")}
            >
              <EmojiPicker
                onEmojiSelect={(emoji) => {
                  onEmojiSelect(emoji);
                  setIsOpen(false);
                }}
              />
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
