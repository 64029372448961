import Modal from "../../../../../ui/modal/modal.tsx";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import Button from "../../../../../ui/button/Button.tsx";
import { Input } from "../../../../../ui/input/Input.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ApiDataProtectionIncreaseDaysPayload,
  DataProtectionTypes,
} from "../../../../../types/data-protection.types.ts";
import { useDataProtectionIncreaseDays } from "../../../../../queries/use-data-protection.query.ts";
import useModals from "../../../../../ui/modal/modal.store.ts";

export interface DataProtectionIncreasePeriod extends ModalProps {
  objectId: number;
  type: DataProtectionTypes;
}

export function DataProtectionIncreasePeriod({
  objectId,
  type,
  ...props
}: DataProtectionIncreasePeriod) {
  const { t } = useTranslation();
  const dataProtectionIncreaseDaysSchema = yup.object({
    data_protection_type: yup
      .mixed<DataProtectionTypes>()
      .oneOf(Object.values(DataProtectionTypes))
      .required(),
    additional_days: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : Number(value);
      })
      .typeError(t("api_error_must_be_number")) // Ошибка, если это не число
      .required()
      .integer(t("api_error_must_integer"))
      .min(1, t("api_error_minimum_value_must", { minimum: 1 }))
      .max(3650, t("api_error_maximum_value_must", { maximum: 3650 })),
  });
  const { close } = useModals();
  const { id } = props;

  interface DataProtectionIncreaseDaysSchema
    extends yup.InferType<typeof dataProtectionIncreaseDaysSchema> {}

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataProtectionIncreaseDaysSchema>({
    mode: "onSubmit",
    resolver: yupResolver(dataProtectionIncreaseDaysSchema),
    defaultValues: {
      data_protection_type: type,
    },
  });

  const request = useDataProtectionIncreaseDays(control);

  const onSubmit = handleSubmit(async (schema) => {
    const payload: ApiDataProtectionIncreaseDaysPayload = {
      additional_days: schema.additional_days,
      data_protection_type: schema.data_protection_type,
    };

    await request.mutateAsync({ objectId, payload });

    close(id);
  });

  return (
    <Modal
      {...props}
      title={t("data_protection:increase_manually_description")}
      withCloser
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} isLoading={request.isPending} onClick={onSubmit}>
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <Input
        label={t("data_protection:increase_manually_enter_days")}
        type={"number"}
        {...register("additional_days")}
        error={errors.additional_days?.message}
      />
    </Modal>
  );
}
