import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";
import React, { ElementType, RefObject } from "react";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";

export interface StackDirection {
  vertical: string;
  horizontal: string;
}

export interface StackGap
  extends Pick<ThemeSizes, "xs" | "sm" | "smd" | "md" | "lg" | "xl" | "2xl"> {
  [key: string]: string;
}

export interface StackJustify {
  start: string;
  end: string;
  center: string;
  between: string;
}

export interface StackItems {
  baseline: string;
  start: string;
  center: string;
  end: string;
}

export interface UIKitStackTheme extends UIKitThemeBase {
  direction: StackDirection;
  gap: StackGap;
  items: StackItems;
  justify: StackJustify;
}

export type StackProps<T extends ElementType = "div"> = PolymorphicComponentProp<
  T,
  {
    children?: React.ReactNode;
    direction?: keyof StackDirection;
    gap?: keyof StackGap;
    items?: keyof StackItems;
    justify?: keyof StackJustify;
    ref?: RefObject<HTMLDivElement>;
  }
>;

export const stackTheme: UIKitStackTheme = {
  base: "flex",
  direction: {
    vertical: "flex-col",
    horizontal: "",
  },
  gap: {
    xs: "gap-xs", // 0.25rem
    sm: "gap-sm", // 0.5rem
    smd: "gap-smd", // 0.75rem
    md: "gap-md", // 1rem
    lg: "gap-lg", // 1.5rem
    xl: "gap-xl", // 2rem
    "2xl": "gap-2xl", // 2.5rem
  },
  justify: {
    start: "justify-start",
    between: "justify-between",
    end: "justify-end",
    center: "justify-center",
  },
  items: {
    baseline: "items-baseline",
    start: "items-start",
    center: "items-center",
    end: "items-end",
  },
};
