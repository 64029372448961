export default function ScreenLoader() {
  return (
    <>
      <main className="main">
        <div className="main-page-loader-wrapper">
          <div className="main-page-loader"></div>
        </div>
      </main>
    </>
  );
}
