import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { Input } from "../../../../ui/input/Input.tsx";
import { ApiFolderShort } from "../../../../types/document.types.ts";
import { useDocumentsFolderSave } from "../../../../queries/use-documents.query.ts";

export interface EmployeeDocumentsFolderEditModalProps extends ModalProps {
  folder: ApiFolderShort;
  employeeId: number;
}

export function EmployeeDocumentsFolderEditModal({
  folder,
  employeeId,
  ...props
}: EmployeeDocumentsFolderEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const folderSchema = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
  });

  interface FolderSchema extends yup.InferType<typeof folderSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FolderSchema>({
    mode: "onSubmit",
    resolver: yupResolver(folderSchema),
  });

  const documentsFolderSave = useDocumentsFolderSave(control);

  const onSubmit = handleSubmit((schemaData) => {
    documentsFolderSave.mutate(
      {
        employeeId: employeeId,
        id: folder.id,
        payload: {
          title: schemaData.title,
        },
      },
      {
        onSuccess: (data) => {
          if (folder.id == 0) {
            analyticsService.trackEvent(analyticEvents.documents.folderCreated, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.documents.folderEdited, {
              [analyticProperties.id]: folder.id,
            });
          }
          close(id);
        },
      }
    );
  });

  return (
    <>
      <Modal
        {...props}
        title={t("core:folder")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={documentsFolderSave.isPending}
              onClick={onSubmit}
            >
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Input
            required={true}
            label={t("common:title")}
            autoFocus={true}
            type="text"
            {...register("title", {
              value: folder.title,
            })}
            error={errors.title?.message}
          />
        </form>
      </Modal>
    </>
  );
}
