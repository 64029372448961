import { twMerge } from "tailwind-merge";
import { LinkButtonToProps } from "./link-to.types.ts";
import { useTheme } from "../themes/provider.tsx";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";

export default function LinkButtonTo({
  children,
  className,
  onClick,
  isLoading = false,
  size = "md",
  variant = "primary",
  ...props
}: LinkButtonToProps) {
  const theme = useTheme("linkTo");
  return (
    <>
      <span
        className={twMerge(
          theme.base,
          theme.variants.variant[variant],
          theme.variants.size[size],
          className
        )}
        onClick={onClick}
        {...props}
      >
        {isLoading && <FontAwesomeIcon icon="fa-light fa-spinner fa-spin" />}
        {children}
      </span>
    </>
  );
}
