import { api } from "../axios.ts";
import {
  ApiApplicant,
  ApiApplicantCreatePayload,
  ApiApplicantFilter,
  ApiApplicantParsed,
  ApiApplicantSavePayload,
  ApiApplicantSearchData,
  ApiApplicantShort,
} from "../../types/recruit/applicant.types.ts";
import { ApiPhotoUpdate } from "../../types/comment.types.ts";
import { ApiOperationResult } from "../../types/common.types.ts";

export const apiApplicants = {
  getById: async function (id: number) {
    return api.get<ApiApplicant>(`/api/v1/applicants/${id}`);
  },
  setPhoto: async function (id: number, fileId: number) {
    return api.put<ApiPhotoUpdate>(`/api/v1/applicants/${id}/photo`, {
      file_id: fileId,
    });
  },
  deletePhoto: async function (id: number) {
    return api.delete<ApiPhotoUpdate>(`/api/v1/applicants/${id}/photo`);
  },
  search: async function (filter: ApiApplicantFilter, signal?: AbortSignal) {
    return api.post<ApiApplicantSearchData>("/api/v1/applicants/search", filter, signal);
  },
  create: async function (payload: ApiApplicantCreatePayload) {
    return api.post<ApiApplicantShort>("/api/v1/applicants", payload);
  },
  update: async function (id: number, payload: ApiApplicantSavePayload) {
    return api.put<ApiApplicantShort>(`/api/v1/applicants/${id}`, payload);
  },
  /*
   * Api could be called without query. Uploads a file and creates a new applicant from it
   */
  uploadFile: async function (payload: FormData) {
    return api.post<ApiApplicantParsed>(
      `/api/v1/applicants/new-applicant-from-upload-cv?source_type=manual`,
      payload
    );
  },
  updateCV: async function (id: number, cvText: string, cvId: number) {
    return api.put<ApiOperationResult>(`/api/v1/applicants/${id}/cv/${cvId}`, {
      cv_text: cvText,
      cv_id: cvId,
    });
  },
  /*
   * Api could be called without query. Downloads a CV file
   */
  downloadCV: async function (
    applicantId: number,
    cvId: number,
    cvType: string,
    hideSalary: boolean = false
  ) {
    return api.get<BlobPart>(
      `/api/v1/applicants/${applicantId}/download-cv/${cvId}?cv_file_type=${cvType}&hide_salary=${hideSalary}`,
      {},
      undefined,
      {
        responseType: "blob",
      }
    );
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/applicants/${id}`);
  },
  updateLabels: async function (id: number, labels: number[]) {
    return api.put<ApiApplicant>(`/api/v1/applicants/${id}/labels`, {
      label_ids: labels,
    });
  },
  merge: async function (id: number, similarApplicantId: number, resolveType: string) {
    return api.post<ApiOperationResult>(`/api/v1/applicants/${id}/merge-applicants`, {
      applicant_id_to_add: similarApplicantId,
      resolve_type: resolveType,
    });
  },
};
