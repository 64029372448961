import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { TimeOffTabSettings } from "./components/TimeOffTabSettings.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { Tabs } from "../../../../ui/tabs/Tabs.tsx";
import { TabItem } from "../../../../ui/tabs/TabItem.tsx";
import { TimeOffSettingsProps } from "../../../../types/time-off.types.ts";
import { TimeOffTabHistory } from "./components/TimeOffTabHistory.tsx";

export function TimeOffSettings({ tab }: TimeOffSettingsProps) {
  const { t } = useTranslation();

  const handleTabChanged = (tabIndex: number) => {
    const urls = ["/settings/time-offs", "/settings/time-offs/history"];

    window.history.replaceState({}, "", urls[tabIndex]);
  };

  return (
    <Page>
      <Page.Header title={t("time_off:settings_title")} showBack={true}></Page.Header>
      <Page.Content>
        <Box className={"px-16"}>
          <Tabs onActiveTabChange={handleTabChanged}>
            <TabItem title={t("common:settings")}>
              <TimeOffTabSettings />
            </TabItem>
            <TabItem title={t("common:history")} active={tab == "history"}>
              <TimeOffTabHistory />
            </TabItem>
          </Tabs>
        </Box>
      </Page.Content>
    </Page>
  );
}
