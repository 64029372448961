import { ThemeSizes, UIKitThemeBase } from "../themes/theme.types.ts";
import React, { ElementType } from "react";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";

export interface TextSizes extends Pick<ThemeSizes, never> {
  [key: string]: string;
}

export interface UIKitTextTheme extends UIKitThemeBase {
  variants: {
    size: TextSizes;
  };
}

export const textTheme: UIKitTextTheme = {
  base: "",
  variants: {
    size: {
      xs: "text-xs", // 0.6875re
      sm: "text-sm", // 0.75rem
      md: "text-md", // 0.875rem
      lg: "text-lg", // 1ren
      xl: "text-xl", // 1.125rem
      "2xl": "text-2xl", // 1.125rem
      "3xl": "text-3xl",
      "4xl": "text-4xl",
      "5xl": "text-5xl",
      "6xl": "text-6xl",
    },
  },
};
export type TextProps<T extends ElementType = "span" | "label"> = PolymorphicComponentProp<
  T,
  {
    as?: React.ElementType;
    children: React.ReactNode;
    size?: keyof TextSizes;
  }
>;
