import Button from "../../ui/button/Button.tsx";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { apiEmployees } from "../../api";
import { useTranslation } from "react-i18next";
import { Input } from "../../ui/input/Input.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../../stores/employee.store.ts";
import { sendTokenToExtension } from "../../helpers/send-token-to-extension.ts";
import analyticsService, { analyticEvents } from "../../services/analytics-service.ts";
import useErrorHandle, { ServerErrorField } from "../../hooks/use-error-handle.hook.tsx";
import { FullScreenCard } from "./components/FullScreenCard.tsx";
import { Title } from "../../components/title/Title.tsx";
import { useEmployeeMeInvalidate } from "../../queries/employees/use-employees.query.ts";
import Box from "../../ui/box/Box.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import LinkTo from "../../ui/link/LinkTo.tsx";

export default function Invite() {
  const { t } = useTranslation();
  const { token } = useParams();
  const { handleErrors } = useErrorHandle();

  const { setToken } = useAuthStore();

  const ResetPasswordSchemaObject = yup.object({
    password: yup.string().required(t("errors:password_required")),
  });

  interface ResetPasswordSchema extends yup.InferType<typeof ResetPasswordSchemaObject> {
    // using interface instead of type generally gives nicer editor feedback
  }

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordSchema>({
    mode: "onSubmit",
    resolver: yupResolver(ResetPasswordSchemaObject),
  });

  const onSubmit = handleSubmit((data) => {
    resetPasswordAction(data);
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const employeeMeInvalidate = useEmployeeMeInvalidate();

  async function resetPasswordAction(loginData: ResetPasswordSchema) {
    if (!token) return;

    setToken(null);

    setLoading(true);
    const { data, error } = await apiEmployees.resetPassword(token);
    if (data) {
      setToken(data.access_token);
      const { data: changePasswordData, error: changePasswordError } =
        await apiEmployees.changePassword(loginData.password);
      if (changePasswordData) {
        setToken(changePasswordData.access_token);
        sendTokenToExtension(changePasswordData.access_token);
        employeeMeInvalidate();
        analyticsService.trackEvent(analyticEvents.inviteAccepted);
        navigate("/");
      } else {
        handleErrors(changePasswordError, control);
      }
    } else {
      handleErrors(error, control);
    }
    setLoading(false);
  }

  return (
    <FullScreenCard>
      <Stack gap={"sm"} className={"w-full sm:max-w-md "}>
        <Box className="w-full p-6 lg:p-8 bg-light rounded-md shadow-md">
          <Stack gap={"2xl"}>
            <a href="https://skailer.com" className="flex items-center mb-6 text-2xl font-semibold">
              <img className="w-[24px] h-[29x] mr-2  " src="/skailer-dark-solid.svg" alt="logo" />
              <Text className={"text-[22px] leading-[24px]"}>skailer</Text>
            </a>
            <Stack gap={"lg"}>
              <Title
                header={t("core:welcome_to_platform")}
                size={"lg"}
                caption={`${t("core:your_team_waiting_for_you")}`}
              />
              <form className="" onSubmit={onSubmit}>
                <Input
                  label={t("common:set_your_password")}
                  type="password"
                  {...register("password")}
                  placeholder="••••••••"
                  error={errors.password?.message}
                />
              </form>
              <ServerErrorField errors={errors} />
              <Button
                type={"submit"}
                size="lg"
                onClick={onSubmit}
                className="w-full"
                isLoading={isLoading}
                disabled={isLoading}
              >
                {t("common:accept_invitation")}
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Box className={"text-secondary"}>
          <Text>{t("core:sign_up_accept_invitation_agree")}</Text>{" "}
          <LinkTo to={"https://skailer.com/terms"} target={"_blank"}>
            {t("common:agree_with_terms_of_service")}
          </LinkTo>{" "}
          <Text>{t("common:and")}</Text>{" "}
          <LinkTo to={"https://skailer.com/privacy"} target={"_blank"}>
            {t("common:agree_with_privacy_policy")}
          </LinkTo>
        </Box>
      </Stack>
    </FullScreenCard>
  );
}
