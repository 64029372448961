import { useTranslation } from "react-i18next";
import { SidebarHeader } from "../components/SidebarHeader.tsx";
import { SidebarRecruitmentBaseMenu } from "./SidebarRecruitmentBaseMenu.tsx";

export const SidebarRecruitment = () => {
  const { t } = useTranslation();

  return (
    <>
      <SidebarHeader title={t("ats:recruit")} />
      <SidebarRecruitmentBaseMenu />
    </>
  );
};
