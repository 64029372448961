import { Box } from "../../ui/box/Box.tsx";
import Button from "../../ui/button/Button.tsx";
import DropdownMenu from "../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import { Title } from "../title/Title.tsx";
import { useTranslation } from "react-i18next";

export interface BackgroundUploadProps {
  backgroundUrl?: string;
  onUpload: () => void;
  onDelete: () => void;
  title: string;
}

export const BackgroundUpload = ({
  backgroundUrl,
  onUpload,
  onDelete,
  title,
}: BackgroundUploadProps) => {
  const { t } = useTranslation();

  const handleUpload = () => {
    onUpload();
  };

  return (
    <Stack>
      <Title size="xs" paddingBottom paddingTop header={title} />
      <Stack className="bg-dark/5 rounded-md">
        <DropdownMenu
          trigger={
            <Box className="relative w-full h-48 group cursor-pointer overflow-hidden rounded-lg">
              {backgroundUrl && (
                <div
                  className="w-full h-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${backgroundUrl})` }}
                />
              )}
              <Box className="bg-dark/20 opacity-0 group-hover:opacity-100 absolute inset-0 flex items-center justify-center">
                <FontAwesomeIcon icon="fa-light fa-image" className={"text-[40px] text-light"} />
              </Box>
            </Box>
          }
        >
          <Stack gap="sm">
            <Button
              variant="menu"
              leftIcon={<FontAwesomeIcon icon="fa-light fa-image" />}
              onClick={handleUpload}
            >
              {t("common:upload_new")}
            </Button>
            <Button
              variant="menu"
              leftIcon={<FontAwesomeIcon icon="fa-light fa-trash" />}
              onClick={onDelete}
              className="text-danger"
            >
              {t("common:delete")}
            </Button>
          </Stack>
        </DropdownMenu>
      </Stack>
    </Stack>
  );
};
