import Modal from "../../../ui/modal/modal.tsx";
import { ModalProps } from "../../../ui/modal/modal.types.ts";
import Stack from "../../../ui/stack/Stack.tsx";
import Button from "../../../ui/button/Button.tsx";
import { useTranslation } from "react-i18next";
import useModals from "../../../ui/modal/modal.store.ts";
import { EmployeeAvatar } from "../../../components/employee-avatar/EmployeeAvatar.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { Title } from "../../../components/title/Title.tsx";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import {
  ApiApprovalRequestDetails,
  ApiApprovalRequestStatus,
} from "../../../types/approval-request.types.ts";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import { useApprovalRequestAnswer } from "../../../queries/use-approval-requests.query.ts";
import { Rounding } from "../../../types/common.types.ts";
import useToasts from "../../../hooks/use-toasts.hook.tsx";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { PermissionType } from "../../../types/role.types.ts";
import { useEmployeeMe } from "../../../queries/employees/use-employees.query.ts";
import { EmployeeInlineCardFull } from "../../../components/employee-inline-card/EmployeeInlineCardFull.tsx";

export interface TimeOffRequestApprovalModalProps extends ModalProps {
  requestDetails: ApiApprovalRequestDetails;
}

export function TimeOffRequestApprovalModal({
  requestDetails,
  ...props
}: TimeOffRequestApprovalModalProps) {
  const { id } = props;
  const { close } = useModals();
  const { t } = useTranslation();
  const { renderEmployeeName, renderDate, getDaysToDate } = useFormatter();
  const { showInfo } = useToasts();
  const answerRequest = useApprovalRequestAnswer();

  const request = requestDetails.request;

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.approvals.requestViewed, {
      [analyticProperties.id]: id,
      [analyticProperties.title]: "Time Off",
    });
  });

  const acceptRequest = () => {
    answerRequest.mutate(
      { id: request.id, approve: true },
      {
        onSuccess: () => {
          showInfo({ title: t("approval:request_approved") });
          analyticsService.trackEvent(analyticEvents.approvals.requestAction, {
            [analyticProperties.id]: id,
            [analyticProperties.title]: "Time Off",
            [analyticProperties.actionType]: "Approve",
          });
          close(id);
        },
      }
    );
  };

  const rejectRequest = () => {
    answerRequest.mutate(
      { id: request.id, approve: false },
      {
        onSuccess: () => {
          showInfo({ title: t("approval:request_rejected") });
          analyticsService.trackEvent(analyticEvents.approvals.requestAction, {
            [analyticProperties.id]: id,
            [analyticProperties.title]: "Time Off",
            [analyticProperties.actionType]: "Reject",
          });
          close(id);
        },
      }
    );
  };

  const requestTitle = t("time_off:approve_request_title", {
    request_days: t("plurals:plural_day", {
      count: parseFloat(request.time_off_data.balance_requested) ?? 0,
    }),
    category_title: request.time_off_data.category.title,
  });

  const inDays = getDaysToDate(request.time_off_data.date_from, Rounding.down);

  const { data: employeeMe } = useEmployeeMe();
  const canManageLeavePolicies =
    employeeMe?.permissions.manage_leave_policies == PermissionType.edit;
  const canChangeStatus =
    request.status === ApiApprovalRequestStatus.awaiting &&
    (canManageLeavePolicies ||
      request.confirmations?.find((c) => c.from_employee.id === employeeMe?.id));

  return (
    <Modal
      {...props}
      withCloser={true}
      title={t("approval:time_off_request")}
      size={"lg"}
      actions={
        <>
          {canChangeStatus && (
            <Stack gap={"smd"}>
              <Button
                type={"submit"}
                size={"lg"}
                isLoading={answerRequest.isPending}
                onClick={acceptRequest}
                leftIcon={<FontAwesomeIcon icon="fa-light fa-check" />}
              >
                {t("approval:approve")}
              </Button>
              <Button
                variant={"plain"}
                isLoading={answerRequest.isPending}
                onClick={rejectRequest}
                leftIcon={<FontAwesomeIcon icon="fa-light fa-xmark" />}
              >
                {t("approval:reject")}
              </Button>
            </Stack>
          )}
        </>
      }
    >
      <Stack gap={"md"}>
        <Stack items={"center"} gap={"md"}>
          <EmployeeAvatar employee={request.employee} size={"2xl"} />
          <Stack className={"text-center"}>
            <Title
              header={renderEmployeeName(request.employee)}
              size={"lg"}
              caption={requestTitle}
            ></Title>
          </Stack>
        </Stack>
        <Stack>
          <ListItem
            emphasis={"middle"}
            title={t("time_off:category_type_title")}
            caption={request.time_off_data.category.title}
          />
          <ListItem
            emphasis={"middle"}
            title={t("common:total_days")}
            caption={t("plurals:plural_day", {
              count: parseFloat(request.time_off_data.balance_requested) ?? 0,
            })}
            value={`${t("time_off:available_balance")}: ${t("plurals:plural_day", { count: parseFloat(requestDetails.time_off_category?.balance.available_balance ?? "0") })}`}
          />
          <ListItem
            emphasis={"middle"}
            title={t("common:start_date")}
            caption={renderDate(request.time_off_data.date_from)}
            value={
              inDays
                ? t("common:in_days", { days: t("plurals:plural_day", { count: inDays }) })
                : ""
            }
          />
          <ListItem
            emphasis={"middle"}
            title={t("common:finish_date")}
            caption={renderDate(request.time_off_data.date_to)}
          />
          {request.comment && request.comment.length > 0 && (
            <ListItem emphasis={"middle"} title={t("common:comment")} caption={request.comment} />
          )}
          {request.created_by && request.created_by.id != request.employee.id && (
            <ListItem
              emphasis={"middle"}
              title={t("common:created_by")}
              caption={renderEmployeeName(request.created_by)}
            />
          )}
        </Stack>

        {request.confirmations && request.confirmations.length > 0 && (
          <>
            <Title header={t("time_off:approve_required")} size="xs" />
            <Stack direction={"vertical"} gap={"sm"} className={"flex-wrap"}>
              {request.confirmations.map((confirmation) => (
                <ListItem
                  key={`approver-${confirmation.from_employee.id}`}
                  titleSlot={<EmployeeInlineCardFull employee={confirmation.from_employee} />}
                  value={
                    confirmation.confirmed_at
                      ? t("time_off:request_status_approved")
                      : confirmation.rejected_at
                        ? t("time_off:request_status_rejected")
                        : t("time_off:request_status_awaiting")
                  }
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Modal>
  );
}
