import { twMerge } from "tailwind-merge";
import { ElementType } from "react";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";

export type FontAwesomeIconProps<T extends ElementType = "i"> = PolymorphicComponentProp<
  T,
  {
    icon: string;
  }
>;

export default function FontAwesomeIcon({ icon, className }: FontAwesomeIconProps) {
  return (
    <>
      <i className={twMerge(icon, className)}></i>
    </>
  );
}
