import { EmployeeFieldPermissionsKeys, PermissionType } from "../types/role.types.ts";
import { ApiEmployee } from "../types/employees/employee.types.ts";

export default function usePermissions() {
  // const { employee } = useEmployeeStore();

  function canViewField(employee: ApiEmployee | undefined, field: EmployeeFieldPermissionsKeys) {
    if (employee) {
      // Это нужно, чтобы в случае создания новых данных доступ был
      if (employee.id == 0) return true;
      return (
        employee.permissions[field] == PermissionType.view ||
        employee.permissions[field] == PermissionType.edit
      );
    }
    return false;
  }

  function canEditField(employee: ApiEmployee | undefined, field: EmployeeFieldPermissionsKeys) {
    if (employee) {
      // Это нужно, чтобы в случае создания новых данных доступ был
      if (employee.id == 0) return true;
      return employee.permissions[field] == PermissionType.edit;
    }
    return false;
  }

  function canViewAnyOfFields(
    employee: ApiEmployee | undefined,
    fields: EmployeeFieldPermissionsKeys[]
  ) {
    return fields.some((field) => canViewField(employee, field));
  }

  function canEditAnyOfFields(
    employee: ApiEmployee | undefined,
    fields: EmployeeFieldPermissionsKeys[]
  ) {
    return fields.some((field) => canEditField(employee, field));
  }

  return { canViewField, canEditField, canViewAnyOfFields, canEditAnyOfFields };
}
