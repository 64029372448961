import Stack from "../../../../ui/stack/Stack.tsx";
import {
  ApiAction,
  ApiActionItemTypes,
  ApiActionTypes,
  ApiActionVisibilities,
} from "../../../../types/action.types.ts";
import { SourceTypes } from "../../../../types/recruit/source.types.ts";
import LinkTo from "../../../../ui/link/LinkTo.tsx";
import { ActionItem } from "../../../../components/action-item/ActionItem.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { ApiApplicant } from "../../../../types/recruit/applicant.types.ts";
import {
  useApplicantActionDelete,
  useApplicantActions,
} from "../../../../queries/recruit/use-applicant-actions.query.ts";
import { AddComment } from "../../../../components/comment-input/AddComment.tsx";
import { ApiEntityTypes } from "../../../../types/common.types.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import { PermissionType } from "../../../../types/role.types.ts";
import { useCallback, useState } from "react";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";

export interface ApplicantProfileCommentsProps {
  applicant: ApiApplicant;
  disabledComments?: boolean;
}

export function ApplicantProfileComments({
  applicant,
  disabledComments = false,
}: ApplicantProfileCommentsProps) {
  const { data: actions } = useApplicantActions(applicant.id);
  const { employee } = useEmployeeStore();
  const modalsService = useModals();
  const { t } = useTranslation();
  const [editAction, setEditAction] = useState<ApiAction | undefined>(undefined);

  const canEditApplicantAction = (action: ApiAction) => {
    return (
      employee != undefined &&
      action.author.id === employee?.id &&
      [ApiActionTypes.comment_added].indexOf(action.type) !== -1
    );
  };

  const canDeleteApplicantAction = (action: ApiAction) => {
    if (employee == undefined) return false;
    if (employee.permissions.delete_all_comments == PermissionType.edit) return true;
    if (action.author.id != employee.id) return false;

    return [ApiActionTypes.comment_added].indexOf(action.type) !== -1;
  };

  const handleEditAction = useCallback(
    (action: ApiAction) => {
      if (action.type === ApiActionTypes.comment_added) {
        setEditAction(action);
        return;
      }
    },
    [applicant]
  );

  const applicantActionDelete = useApplicantActionDelete();

  const handleDeleteAction = useCallback(
    (actionId: number) => {
      modalsService.openModal(ConfirmationPopup, {
        question: t("common:delete"),
        text: t("common:delete_confirm"),
        acceptTitle: t("common:delete"),
        onAccept: async function () {
          try {
            await applicantActionDelete.mutateAsync({
              applicantId: applicant.id,
              actionId: actionId,
            });
            analyticsService.trackEvent(analyticEvents.applicants.actionDeleted, {
              [analyticProperties.id]: applicant.id,
            });
          } catch {
            return;
          }
        },
      });
    },
    [applicant]
  );

  return (
    <>
      <Stack gap={"md"} className={"mt-4"}>
        {!disabledComments && (
          <AddComment entityId={applicant.id} entityType={ApiEntityTypes.applicant} />
        )}
        <Stack>
          {actions &&
            actions.map((action) =>
              editAction && editAction.id === action.id ? (
                <AddComment
                  action={action}
                  entityId={action.item_id}
                  entityType={ApiEntityTypes.applicant}
                  onSaved={() => {
                    setEditAction(undefined);
                  }}
                />
              ) : (
                <ActionItem
                  key={`comment-v2-${action.id}`}
                  action={action}
                  actionsSlot={
                    (canEditApplicantAction(action) || canDeleteApplicantAction(action)) && (
                      <DropdownMenu
                        trigger={
                          <FontAwesomeIcon
                            icon={"fa-light fa-ellipsis"}
                            className={"cursor-pointer"}
                          />
                        }
                      >
                        {canEditApplicantAction(action) && (
                          <Button
                            onClick={() => handleEditAction(action)}
                            leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                            variant={"menu"}
                          >
                            {t("common:edit")}
                          </Button>
                        )}
                        {canDeleteApplicantAction(action) && (
                          <Button
                            onClick={() => handleDeleteAction(action.id)}
                            leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                            variant={"menu"}
                          >
                            {t("common:delete")}
                          </Button>
                        )}
                      </DropdownMenu>
                    )
                  }
                />
              )
            )}
          <ActionItem
            key={`comment-v2-created`}
            action={{
              id: 0,
              author: applicant.author,
              datetime_created_at: applicant.datetime_created_at,
              item_id: applicant.id,
              item_type: ApiActionItemTypes.applicant,
              type: ApiActionTypes.created,
              visibility: ApiActionVisibilities.public,
              files: [],
            }}
            attachments={
              applicant.source.source_type == SourceTypes.linkedin &&
              applicant.cvs.length > 0 &&
              applicant.cvs[0].source_url && (
                <Box>
                  <LinkTo
                    to={applicant.cvs[0].source_url}
                    target={"_blank"}
                    className={"break-all"}
                  >
                    {applicant.cvs[0].source_url}
                  </LinkTo>
                </Box>
              )
            }
          />
        </Stack>
      </Stack>
    </>
  );
}
