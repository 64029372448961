import { NotificationProps } from "./notification.types.ts";
import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";

export function NotificationCount({ count, variant = "default", size = "md" }: NotificationProps) {
  const theme = useTheme("counter");

  return (
    <div
      className={twMerge(theme.base, theme.variants.variant[variant], theme.variants.size[size])}
    >
      {count}
    </div>
  );
}
