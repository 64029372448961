import { useEffect, useRef, useState } from "react";
import { OrgChart } from "d3-org-chart";
import { HierarchyNode, select } from "d3";
import { renderToStaticMarkup } from "react-dom/server";
import { ChartNode } from "./ChartNode.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Button from "../../ui/button/Button.tsx";
import { useTranslation } from "react-i18next";
import FontAwesomeIcon from "../../ui/typography/FontAwesomeIcon.tsx";

export interface ChartNodeData<T> {
  id: string;
  parentId: string | null;
  title: string;
  caption?: string;
  extra?: string;
  imgUrl?: string | null;
  count: number;
  isRoot: boolean;
  isExpandUpNode: boolean;
  original?: T;
}

export interface ChartProps<T> {
  data: ChartNodeData<T>[];
  expandedSet?: Set<string>;
  onNodeClick?: (nodeId: string) => void;
  onExpandUpClick?: (nodeId: string) => void;
  onExpandClick?: (nodeId: string, needLoadData: boolean) => void;
}

export function Chart<T>({
  data,
  onNodeClick,
  onExpandClick,
  onExpandUpClick,
  expandedSet,
}: ChartProps<T>) {
  const d3Container = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<OrgChart<ChartNodeData<T>> | null>(null);
  const [expanded, setExpanded] = useState<Set<string>>(expandedSet ?? new Set([]));
  const [collapsed, setCollapsed] = useState<Set<string>>(new Set([]));
  const [rootCollapsed, setRootCollapsed] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setChart(() => {
      return new OrgChart<ChartNodeData<T>>();
    });
  }, []);

  useEffect(() => {
    if (!d3Container.current || !chart) return;

    toggleEvents();

    chart
      .container(d3Container.current as unknown as string)
      .svgHeight(d3Container.current.getBoundingClientRect().height)
      .data(
        data.filter((item) => {
          const isCollapsed = !collapsed.has(item.parentId ?? "") && !rootCollapsed;

          return isCollapsed || item.isRoot;
        })
      )
      .linkUpdate((_node, _index, nodes) => {
        nodes.forEach((nodeChild) => {
          select(nodeChild as unknown as SVGPathElement).attr("stroke", "#1B1D2133");
        });
      })
      .compact(false)
      .nodeWidth(() => 164)
      .nodeHeight((node) => {
        if (node.data.isExpandUpNode) return 24;

        return node.data.count > 0 ? 198 : 168;
      })
      .buttonContent(() => "")
      .nodeButtonHeight(() => 0)
      .nodeContent((node) => {
        return renderToStaticMarkup(renderExpandUpButton(node.data));
      })
      .onNodeClick((node: HierarchyNode<ChartNodeData<T>>) => {
        if (node.data.isExpandUpNode) return;

        onNodeClick && node.id && onNodeClick(node.id);
      })
      .render();

    chart.expandAll().render();

    toggleEvents(true);

    updateDOMElementsAfterRender();
  }, [data, chart, collapsed, rootCollapsed]);

  const renderExpandUpButton = (nodeData: ChartNodeData<T>) => {
    return nodeData.isExpandUpNode ? (
      <Stack className={"items-center cursor-default"}>
        <Button
          variant={"secondary"}
          className={"chart-expand-up-button"}
          data-id={nodeData.id}
          rightIcon={<FontAwesomeIcon icon="fa-light fa-chevron-up" />}
        >
          {t("core:to_up")}
        </Button>
      </Stack>
    ) : (
      <ChartNode expanded={nodeData.isRoot || expanded.has(nodeData.id)} data={nodeData} />
    );
  };

  const toggleEvents = (isBindMode: boolean = false) => {
    if (isBindMode) {
      d3Container.current
        ?.querySelectorAll(".structure-item .chart-expand-button")
        .forEach((button) => {
          button instanceof HTMLElement && button.addEventListener("click", clickOnExpand);
        });

      d3Container.current?.querySelectorAll(".chart-expand-up-button").forEach((button) => {
        button instanceof HTMLElement && button.addEventListener("click", clickOnExpandUp);
      });
    } else {
      d3Container.current
        ?.querySelectorAll(".structure-item .chart-expand-button")
        .forEach((button) => {
          button instanceof HTMLElement && button.removeEventListener("click", clickOnExpand);
        });

      d3Container.current?.querySelectorAll(".chart-expand-up-button").forEach((button) => {
        button instanceof HTMLElement && button.removeEventListener("click", clickOnExpandUp);
      });
    }
  };

  const updateDOMElementsAfterRender = () => {
    const rootNode = document.querySelector("#structure-node__root");

    rootNode && updateDOMElement(rootNode, rootCollapsed);

    expanded.forEach((id) => {
      const node = document.querySelector("#structure-node__" + id);

      node && updateDOMElement(node, collapsed.has(id));
    });
  };

  const updateDOMElement = (element: Element, isCollapsed: boolean) => {
    const btn = element.querySelector(".chart-expand-button");
    const icon = btn?.querySelector(".fa-light");

    if (isCollapsed) {
      element.classList.remove("border-dark", "hover:border-dark");
      element.classList.add("border-transparent", "hover:border-dark/10");

      btn?.classList.remove("bg-brand-dark", "text-light");
      btn?.classList.add("bg-brand-dark/5", "text-brand-dark", "hover:bg-brand-dark/10");

      icon?.classList.remove("fa-chevron-down");
      icon?.classList.add("fa-chevron-right");
    } else {
      element.classList.remove("hover:border-dark/10", "border-transparent");
      element.classList.add("border-dark", "hover:border-dark");

      btn?.classList.add("bg-brand-dark", "text-light");
      btn?.classList.remove("bg-brand-dark/5", "text-brand-dark", "hover:bg-brand-dark/10");

      icon?.classList.add("fa-chevron-down");
      icon?.classList.remove("fa-chevron-right");
    }
  };

  const clickOnExpandUp = (event: MouseEvent) => {
    const target = event.currentTarget as HTMLElement;
    const dataId = target.getAttribute("data-id");

    if (!dataId) return;

    event.stopPropagation();
    event.stopImmediatePropagation();

    onExpandUpClick && onExpandUpClick(dataId);
  };

  const clickOnExpand = (event: MouseEvent) => {
    const target = event.currentTarget as HTMLElement;
    const dataId = target.getAttribute("data-id");
    let needLoadBatch = false;

    event.stopPropagation();
    event.stopImmediatePropagation();

    if (!dataId) return;

    if (dataId == "root") {
      needLoadBatch = false;

      setRootCollapsed((prevState) => !prevState);

      if (rootCollapsed) {
        chart?.expandAll().render();
      } else {
        chart?.collapseAll().render();
      }
    } else {
      const newCollapsed = new Set(collapsed);
      const newExpanded = new Set(expanded);

      if (collapsed.has(dataId)) {
        newCollapsed.delete(dataId);
      } else if (!expanded.has(dataId) && !collapsed.has(dataId)) {
        needLoadBatch = true;

        newExpanded.add(dataId);
      } else if (expanded.has(dataId) && !collapsed.has(dataId)) {
        // здесь надо скрыть все узлы связанные с dataId
        newCollapsed.add(dataId);

        getChildrenIds(dataId).forEach((id) => {
          newCollapsed.add(id);
        });
      }

      setExpanded(newExpanded);
      setCollapsed(newCollapsed);
    }

    onExpandClick && onExpandClick(dataId, needLoadBatch);
  };

  const getChildrenIds = function getChildrenIds(targetId: string): string[] {
    const childrenIds: string[] = [];

    function findChildren(parentId: string) {
      for (const node of data) {
        if (node.parentId != parentId) continue;

        childrenIds.push(node.id);

        findChildren(node.id);
      }
    }

    findChildren(targetId);

    return childrenIds;
  };

  return <div className={"h-full"} ref={d3Container} />;
}
