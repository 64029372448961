export enum ApiCalendarProviders {
  system = "system",
  google_calendar = "google_calendar",
}

export interface ApiCalendar {
  id: number;
  title: string;
  timezone: string;
  is_default: boolean;
  provider: ApiCalendarProviders;
}

export interface ApiCalendarSettings {
  is_enabled: boolean;
  show_employee_leaves: boolean;
  show_birthdays: boolean;
  show_starting_dates_of_new_hires: boolean;
  show_anniversaries: boolean;
  show_last_day_of_employees: boolean;
}

export interface ApiCalendarSettingsSavePayload extends ApiCalendarSettings {}
