import { MenuConfigItem } from "../sidebar-service.tsx";
import FontAwesomeIcon from "../../../ui/typography/FontAwesomeIcon.tsx";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import Box from "../../../ui/box/Box.tsx";

export interface MenuItemProps {
  item: MenuConfigItem;
  isActive: boolean;
}

export const MenuItem = ({ item, isActive }: MenuItemProps) => {
  return (
    <>
      <Link
        to={item.to}
        className={twMerge(
          "w-8 flex flex-col items-center justify-center text-dark gap-[2px]",
          isActive ? "" : "cursor-pointer"
        )}
      >
        <Box
          className={twMerge(
            "w-8 h-8 flex flex-col items-center justify-center rounded-md",
            isActive ? "bg-dark/5" : undefined
          )}
        >
          <FontAwesomeIcon icon={item.icon} className={twMerge("fa-light text-lg")} />
        </Box>
        <span className={"text-[10px] leading-3 tracking-[-0.1px]"}>{item.title}</span>
      </Link>
    </>
  );
};
