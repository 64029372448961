import { SelectItem } from "../../../../../ui/select/select.types.ts";
import i18n from "../../../../../i18n.ts";
import { PipelineStageTypes } from "../../../../../types/recruit/stages.types.ts";

export const PipelineStageTimeLimits: SelectItem[] = Array.from({ length: 31 }, (_, index) => {
  return index === 0
    ? { id: index.toString(), title: i18n.t("ats:no_time_limit") }
    : { id: index.toString(), title: i18n.t("plurals:plural_day", { count: index }) };
});

export interface ApiStageInPipelineEdit {
  id: number;
  title: string;
  sla_limit: string;
  selected: boolean;
  type: PipelineStageTypes;
}
