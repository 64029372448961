import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../types/api.types.ts";
import { apiDocuments } from "../api/document.api.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import {
  ApiDocumentSavePayload,
  ApiDocumentsFilter,
  ApiFolderPayload,
} from "../types/document.types.ts";

export const documentsKeys = createQueryKeys("documents", {
  documentsFolders: (employeeId: number) => ({
    queryKey: [employeeId],
    queryFn: async ({ signal }) => {
      const result = await apiDocuments.getFoldersByEmployeeId(employeeId, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  search: (filter: ApiDocumentsFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiDocuments.searchDocuments(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useDocumentsFolders = (employeeId: number) => {
  return useQuery({
    ...documentsKeys.documentsFolders(employeeId),
  });
};

export const useDocumentsFolderSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({
      employeeId,
      id,
      payload,
    }: {
      employeeId: number;
      id: number;
      payload: ApiFolderPayload;
    }) => {
      const result =
        id == 0
          ? await apiDocuments.createFolder(employeeId, payload)
          : await apiDocuments.updateFolder(employeeId, id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: documentsKeys.documentsFolders._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useDocumentsFolderDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ employeeId, id }: { employeeId: number; id: number }) => {
      const result = await apiDocuments.deleteFolder(employeeId, id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: documentsKeys.documentsFolders._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useDocumentsSearch = (filter: ApiDocumentsFilter, enabled = true) => {
  return useQuery({
    ...documentsKeys.search(filter),
    enabled: enabled,
  });
};

export const useDocumentSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({
      id,
      employeeId,
      payload,
    }: {
      id: number;
      employeeId: number;
      payload: ApiDocumentSavePayload;
    }) => {
      const result =
        id == 0
          ? await apiDocuments.createDocument(employeeId, payload)
          : await apiDocuments.updateDocument(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: documentsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: documentsKeys.documentsFolders._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useDocumentDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiDocuments.deleteDocument(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: documentsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: documentsKeys.documentsFolders._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
