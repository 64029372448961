import Modal from "../../../../../ui/modal/modal.tsx";
import { EditRuleSchema, EditRuleSchemaObject, RuleEditModalProps } from "../../role.types.ts";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../../../../../ui/select/Select.tsx";
import { RuleSignItems } from "../role-edit.types.ts";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import useModals from "../../../../../ui/modal/modal.store.ts";
import Multiselect from "../../../../../ui/select/Multiselect.tsx";
import { SelectItem, SelectValueType } from "../../../../../ui/select/select.types.ts";
import { useEffect, useMemo } from "react";
import { RuleApplySignType, RuleConditionType } from "../../../../../types/role.types.ts";
import { useDivisionsSearchInline } from "../../../../../queries/use-divisitions.query.ts";
import { useDepartmentsSearchInline } from "../../../../../queries/use-departments.query.ts";
import { usePositionsSearchInline } from "../../../../../queries/use-positions.query.ts";
import useEmployeeSearchInline from "../../../../../queries/employees/use-employees.query.ts";
import { useLocationsSearchInline } from "../../../../../queries/use-locations.query.ts";
import { useLegalEntitiesSearchInline } from "../../../../../queries/use-legal-entities.query.ts";
import { ListItem } from "../../../../../components/list-item/ListItem.tsx";
import LinkButtonTo from "../../../../../ui/link/LinkButtonTo.tsx";

export function RuleEditModal({
  rule,
  onModified,
  onDelete,
  conditionTypes,
  popupTitle,
  forceSign,
  ...props
}: RuleEditModalProps) {
  const { t } = useTranslation();
  const { close } = useModals();
  const { id } = { ...props };

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    setValue,
  } = useForm<EditRuleSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EditRuleSchemaObject),
    defaultValues: {
      condition_ids: rule?.condition_ids ?? [],
      sign: forceSign ?? RuleApplySignType.include,
      condition_type: rule?.condition_type ?? RuleConditionType.employee,
      condition_entities: rule?.condition_entities ?? [],
    },
  });

  const selectedConditionType = useWatch({ control, name: "condition_type" });

  const divisions = useDivisionsSearchInline(selectedConditionType === RuleConditionType.division);
  const departments = useDepartmentsSearchInline(
    selectedConditionType === RuleConditionType.department
  );
  const positions = usePositionsSearchInline(selectedConditionType === RuleConditionType.position);
  const employee = useEmployeeSearchInline(selectedConditionType === RuleConditionType.employee);
  const locations = useLocationsSearchInline(selectedConditionType == RuleConditionType.location);
  const legalEntities = useLegalEntitiesSearchInline(
    selectedConditionType == RuleConditionType.legal_entity
  );

  const options = useMemo(() => {
    switch (selectedConditionType) {
      case RuleConditionType.division:
        return divisions.options;
      case RuleConditionType.department:
        return departments.options;
      case RuleConditionType.position:
        return positions.options;
      case RuleConditionType.employee:
        return employee.options;
      case RuleConditionType.location:
        return locations.options;
      case RuleConditionType.legal_entity:
        return legalEntities.options;
      default:
        return [] as SelectItem[];
    }
  }, [
    selectedConditionType,
    divisions.options,
    departments.options,
    positions.options,
    employee.options,
    locations.options,
    legalEntities.options,
  ]);

  const onSubmit = handleSubmit((ruleData) => {
    onModified({
      condition_type: ruleData.condition_type,
      condition_entities: ruleData.condition_entities as SelectItem[],
      condition_ids: ruleData.condition_ids,
      sign: forceSign ?? ruleData.sign,
    });

    close(id);
  });

  const handleSearch = (text: string | undefined) => {
    switch (selectedConditionType) {
      case RuleConditionType.division:
        divisions.search(text);
        break;
      case RuleConditionType.department:
        departments.search(text);
        break;
      case RuleConditionType.position:
        positions.search(text);
        break;
      case RuleConditionType.employee:
        employee.search(text);
        break;
      case RuleConditionType.location:
        locations.search(text);
        break;
      case RuleConditionType.legal_entity:
        legalEntities.search(text);
        break;
    }
  };

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name === "condition_type" && type == "change") {
        resetField("condition_entities");
        resetField("condition_ids");
        if (rule) rule.condition_entities = [];
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleSelect = (selected: SelectValueType[]) => {
    const selectedOptions = options?.filter((item) => ~selected.indexOf(item.id));

    setValue("condition_entities", selectedOptions ?? []);
  };

  const title = popupTitle ? popupTitle : rule ? t("core:edit_rule") : t("core:create_new_rule");
  const canSelectEntities =
    [
      RuleConditionType.division,
      RuleConditionType.department,
      RuleConditionType.position,
      RuleConditionType.employee,
      RuleConditionType.legal_entity,
      RuleConditionType.location,
    ].indexOf(selectedConditionType) !== -1;

  return (
    <Modal
      title={title}
      withCloser={true}
      {...props}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button type={"submit"} size={"lg"} onClick={onSubmit}>
            {t("common:save")}
          </Button>
          <Button
            variant={"plain"}
            size={"lg"}
            onClick={() => {
              close(id);
            }}
          >
            {t("common:cancel")}
          </Button>
        </Stack>
      }
    >
      <Stack gap={"lg"}>
        <form onSubmit={onSubmit}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Select
                options={conditionTypes}
                emptyTitle={t("common:select")}
                value={value}
                onChange={onChange}
              />
            )}
            control={control}
            name={`condition_type`}
          />
          {!forceSign && (
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  options={RuleSignItems()}
                  emptyTitle={t("common:select")}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              name={`sign`}
            />
          )}
          {canSelectEntities && (
            <Controller
              render={({ field: { value, onChange } }) => (
                <Multiselect
                  emptyTitle={t("common:select")}
                  values={value}
                  onChange={(selected) => {
                    handleSelect(selected);
                    onChange(selected);
                  }}
                  searchable
                  options={rule?.condition_entities}
                  onSearchOptions={handleSearch}
                  searchOptions={options}
                  loadOptionsOnOpen
                />
              )}
              control={control}
              name={`condition_ids`}
            />
          )}
        </form>
        {rule && (
          <Stack>
            <hr className="border-dark/10" />
            <ListItem
              title={t("core:delete_rule_question")}
              valueSlot={
                <LinkButtonTo
                  onClick={() => {
                    onDelete();
                    close(id);
                  }}
                  variant={"danger"}
                >
                  {t("common:delete")}
                </LinkButtonTo>
              }
            ></ListItem>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
}
