import Stack from "../../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import { Controller, UseFormReturn } from "react-hook-form";
import { ListColumnItem } from "../../../../../components/list-item/ListColumnItem.tsx";
import { DropdownSelect } from "../../../../../ui/select/DropdownSelect.tsx";
import { useTranslation } from "react-i18next";
import { TimeOffCategoryPolicySchema } from "../TimeOffCategoryPolicyEdit.tsx";
import useTimeOffHook from "../../../../../hooks/use-time-off.hook.tsx";
import { ApiTimeOffPolicyCarryoverCycleDateType } from "../../../../../types/time-off.types.ts";
import Datepicker from "../../../../../ui/datepicker/Datepicker.tsx";
import { Input } from "../../../../../ui/input/Input.tsx";
import Select from "../../../../../ui/select/Select.tsx";

export interface TimeOffCategoryPolicyCarryoverProps {
  formHook: UseFormReturn<TimeOffCategoryPolicySchema>;
}

export function TimeOffPolicyCarryover({ formHook }: TimeOffCategoryPolicyCarryoverProps) {
  const { t } = useTranslation();
  const { getCarryoverDateTypes, getCarryoverExpiringEnabled, getCarryoverExpiringDateTypes } =
    useTimeOffHook();

  const {
    watch,
    control,
    register,
    formState: { errors },
  } = formHook;

  const carryoverCycleDateType = watch("carryover_cycle_date_type");
  const carryoverExpireEnable = watch("carryover_expire_enable");

  return (
    <>
      <Stack>
        <Title
          header={t("time_off:carryover_settings")}
          caption={t("time_off:carryover_settings_description")}
          size={"xs"}
          className={"text-dark/60"}
          paddingBottom
        />
        <Box variant={"border"}>
          <Stack>
            <Controller
              render={({ field: { value, onChange } }) => (
                <ListColumnItem
                  title={t("time_off:carryover_date_title")}
                  valueSlot={
                    <DropdownSelect
                      showCaption={false}
                      value={value}
                      onChange={onChange}
                      options={getCarryoverDateTypes()}
                    />
                  }
                />
              )}
              control={control}
              name={"carryover_cycle_date_type"}
            />

            {carryoverCycleDateType == ApiTimeOffPolicyCarryoverCycleDateType.specific_date && (
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Datepicker
                    value={value}
                    onChange={onChange}
                    label={t("common:date")}
                    error={errors.carryover_cycle_date_data?.message}
                  />
                )}
                control={control}
                name={"carryover_cycle_date_data.date_first"}
              />
            )}

            <Controller
              render={({ field: { value, onChange } }) => (
                <ListColumnItem
                  title={t("time_off:carryover_expiration_title")}
                  valueSlot={
                    <DropdownSelect
                      value={Number(value)}
                      onChange={(value) => {
                        onChange(Boolean(value));
                      }}
                      options={getCarryoverExpiringEnabled()}
                    />
                  }
                />
              )}
              control={control}
              name={"carryover_expire_enable"}
            />
            {carryoverExpireEnable && (
              <Stack direction={"horizontal"} gap={"md"}>
                <Input
                  type={"number"}
                  {...register("carryover_expire_date_value.count")}
                  error={errors.carryover_expire_date_value?.message}
                  label={t("common:quantity_short")}
                />
                <Box className={"flex-shrink-0 flex-grow"}>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Select
                        label={t("time_off:carryover_expiration_date_title")}
                        options={getCarryoverExpiringDateTypes()}
                        emptyTitle={t("common:select")}
                        value={value}
                        onChange={onChange}
                        error={errors.carryover_expire_date_type?.message}
                      />
                    )}
                    control={control}
                    name={"carryover_expire_date_type"}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
