import Stack from "../../ui/stack/Stack.tsx";
import { ListItem } from "./ListItem.tsx";
import LinkButtonTo from "../../ui/link/LinkButtonTo.tsx";
import { useTranslation } from "react-i18next";

export interface DeleteListItemProps {
  title?: string;
  caption?: string;
  deleteTitle?: string;
  onDelete: () => void;
}

export const DeleteListItem = ({ title, caption, deleteTitle, onDelete }: DeleteListItemProps) => {
  const { t } = useTranslation();
  return (
    <Stack className={"pt-md"}>
      <hr className="border-dark/10" />
      <ListItem
        title={title}
        caption={caption}
        valueSlot={
          <LinkButtonTo onClick={onDelete} variant={"danger"} className={"shrink-0"}>
            {deleteTitle || t("common:delete")}
          </LinkButtonTo>
        }
      ></ListItem>
    </Stack>
  );
};
