import { EventContentArg } from "@fullcalendar/core";
import { ApiEvent, ApiEventAttendeeResponseStatuses } from "../../../types/event.types.ts";
import { useMemo } from "react";
import { EventPreviewModal } from "../../../components/event/EventPreviewModal.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { twMerge } from "tailwind-merge";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import Box from "../../../ui/box/Box.tsx";
import { useTranslation } from "react-i18next";

// import "fullcalendar/common/main.css";

export interface EventInCalendarProps {
  eventInfo: EventContentArg;
  viewAsEmployeeId?: number;
}

enum EventViewMode {
  ultrashort = "ultrashort",
  short = "short",
  full = "full",
}

interface EventViewTheme {
  title: {
    [key in keyof typeof EventViewMode]: string;
  };
  time: {
    [key in keyof typeof EventViewMode]: string;
  };
  box: {
    [key in keyof typeof EventViewMode]: string;
  };
}

const theme: EventViewTheme = {
  title: {
    ultrashort: "after:content-[','] after:pr-1",
    short: "after:content-[','] after:pr-1",
    full: "",
  },
  time: {
    ultrashort: "pl-1",
    short: "",
    full: "block text-sm whitespace-nowrap",
  },
  box: {
    ultrashort:
      "px-1 py-0 text-[10px] leading-[12px] whitespace-nowrap text-ellipsis overflow-hidden  items-center",
    short: "px-1 py-1 text-sm whitespace-nowrap text-ellipsis overflow-hidden items-center",
    full: "px-1 py-0.5 items-start whitespace-nowrap text-ellipsis overflow-hidden",
  },
};

export function EventInCalendar({ eventInfo, viewAsEmployeeId }: EventInCalendarProps) {
  const modalsService = useModals();
  const { t } = useTranslation();
  const { renderTime } = useFormatter();
  const myEvent = useMemo(() => {
    if (Object.keys(eventInfo.event.extendedProps).length > 0) {
      return eventInfo.event.extendedProps as ApiEvent;
    }
    return undefined;
  }, [eventInfo]);

  const viewMode = useMemo(() => {
    if (eventInfo.event.start && eventInfo.event.end) {
      const minutes = (eventInfo.event.end.getTime() - eventInfo.event.start.getTime()) / 1000 / 60;
      if (minutes < 30) {
        return EventViewMode.ultrashort;
      }
      if (minutes < 60) {
        return EventViewMode.short;
      }
      return EventViewMode.full;
    }
    return EventViewMode.short;
  }, [eventInfo]);

  const handleClick = () => {
    if (myEvent) {
      modalsService.openModal(EventPreviewModal, {
        event: myEvent,
      });
    }
  };

  const viewerAsAttendee = useMemo(() => {
    return (
      viewAsEmployeeId &&
      myEvent?.attendees.find((attendee) => attendee.employee?.id === viewAsEmployeeId)
    );
  }, [viewAsEmployeeId, myEvent]);

  const eventClasses = useMemo(() => {
    const classes: string[] = [];
    if (viewerAsAttendee) {
      switch (viewerAsAttendee.response_status) {
        case ApiEventAttendeeResponseStatuses.accepted:
          classes.push("bg-brand-dark text-light");
          break;
        case ApiEventAttendeeResponseStatuses.needs_action:
          classes.push("border-brand-dark border text-brand-dark bg-brand-light");
          break;
      }
    } else {
      classes.push("bg-brand-dark text-light");
    }
    if (eventInfo.isPast) {
      classes.push("opacity-50");
    }
    return classes;
  }, [viewerAsAttendee, myEvent]);

  return (
    <Box
      onClick={handleClick}
      className={twMerge(
        "flex h-full rounded-md cursor-pointer",
        theme.box[viewMode],
        eventClasses
        // isShort ? "px-1 py-0" : "px-2 py-2"
      )}
    >
      <div
        className={
          twMerge(theme.box[viewMode])
          // isShort
          //   ? "text-[10px] leading-[12px] whitespace-nowrap text-ellipsis overflow-hidden"
          //   : "text-sm"
        }
      >
        <strong className={theme.title[viewMode]}>
          {eventInfo.event.title ? eventInfo.event.title : `(${t("calendar:no_title")})`}
        </strong>
        {!eventInfo.event.allDay && viewMode != EventViewMode.full && eventInfo.event.start && (
          <span className={theme.time[viewMode]}>{renderTime(eventInfo.event.start)}</span>
        )}

        {!eventInfo.event.allDay &&
          viewMode == EventViewMode.full &&
          eventInfo.event.start &&
          eventInfo.event.end && (
            <span
              className={theme.time[viewMode]}
            >{`${renderTime(eventInfo.event.start)} — ${renderTime(eventInfo.event.end)}`}</span>
          )}
      </div>
      {/*{myEvent && !isShort && (*/}
      {/*  <div className={"text-sm text-opacity-60"}>{myEvent.description}</div>*/}
      {/*)}*/}
    </Box>
  );
}
