import { AppThemeConfig, AppThemeConfigs, MappedAppThemeConfig } from "./theme.config.types.ts";
import { AppThemes } from "./theme.types.ts";

export const mapTheme = (variables: AppThemeConfig): MappedAppThemeConfig => {
  return {
    "--color-brand-dark": variables.colorBrandDark,
    // "--color-brand-light": variables.colorBrandLight,
    // "--color-danger": variables.colorDanger,
    // "--color-primary": variables.colorPrimary,
    // "--color-primary-50": variables.colorPrimary50,
    // "--color-primary-100": variables.colorPrimary100,
    // "--color-primary-200": variables.colorPrimary200,
    // "--color-primary-300": variables.colorPrimary300,
    // "--color-primary-400": variables.colorPrimary400,
    // "--color-primary-500": variables.colorPrimary500,
    // "--color-primary-600": variables.colorPrimary600,
    // "--color-primary-700": variables.colorPrimary700,
    // "--color-primary-800": variables.colorPrimary800,
    // "--color-primary-900": variables.colorPrimary900,
    // "--color-primary-950": variables.colorPrimary950,
  };
};

// export const extend: (extending: AppTheme, newTheme: AppTheme) => AppTheme = (
//   extending: AppTheme,
//   newTheme: AppTheme
// ): AppTheme => {
//   return { ...extending, ...newTheme };
// };

export function mergeTheme<T>(extending: T, newTheme: T): T {
  return { ...extending, ...newTheme };
}

export const applyThemeConfig = (theme: keyof AppThemeConfigs): void => {
  const appThemeConfig = AppThemes[theme].appThemeConfig;
  const themeObject: MappedAppThemeConfig = mapTheme(appThemeConfig);
  if (!themeObject) return;

  const root = document.documentElement;
  Object.keys(themeObject).forEach((property) => {
    if (property === "name") {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};
