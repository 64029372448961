import { api } from "./axios.ts";
import { ApiEvent, ApiEventFilter, ApiEventSavePayload } from "../types/event.types.ts";
import { ApiOperationResult } from "../types/common.types.ts";

export const apiEvents = {
  filter: async function (filter: ApiEventFilter, signal: AbortSignal) {
    return api.post<ApiEvent[]>("/api/v1/events/filter", { ...filter }, signal);
  },
  create: async function (event: ApiEventSavePayload) {
    return api.post<ApiOperationResult>("/api/v1/events", { ...event });
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/events/${id}`);
  },
  update: async function (id: number, payload: ApiEventSavePayload) {
    return api.put<ApiOperationResult>(`/api/v1/events/${id}`, payload);
  },
  /*
   * Можем вызывать без query для передачи в параметр в динамических случаях
   */
  getEventById: async function (id: number) {
    return api.get<ApiEvent>(`/api/v1/events/${id}`);
  },
};
