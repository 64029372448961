import { useNotifications } from "../../../queries/use-notifications.query.ts";
import { useMemo, useState } from "react";
import { TodayItem } from "../home.types.ts";
import { EmployeeViewModal } from "../../employees/widgets/EmployeeViewModal.tsx";
import { apiEvents } from "../../../api/event.api.ts";
import { EventPreviewModal } from "../../../components/event/EventPreviewModal.tsx";
import { ApiNotificationTypes } from "../../../types/notification.types.ts";
import {
  ApiEmployeeFilter,
  ApiEmployeeFilterSort,
} from "../../../types/employees/employee.types.ts";
import { useEmployees } from "../../../queries/employees/use-employees.query.ts";
import { useTranslation } from "react-i18next";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import Stack from "../../../ui/stack/Stack.tsx";
import { Title } from "../../../components/title/Title.tsx";
import Box from "../../../ui/box/Box.tsx";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import Button from "../../../ui/button/Button.tsx";
import { apiNotifications } from "../../../api/notification.api.ts";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { apiApprovalRequests } from "../../../api/approval-request.api.ts";
import { TimeOffRequestApprovalModal } from "../../time-offs/widgets/TimeOffRequestApprovalModal.tsx";
import { useApprovalRequestsMy } from "../../../queries/use-approval-requests.query.ts";

export const HomeToday = () => {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const { renderEmployeeName } = useFormatter();
  const { openModal } = useModals();

  const { data: notifications } = useNotifications({ page_number: 1 });
  const { data: approvalRequests } = useApprovalRequestsMy();

  const [isLoadingHomeItemId, setIslLoadingHomeItemId] = useState<number | null>(null);
  const upcomingBirthdaysFilter: ApiEmployeeFilter = {
    page_number: 1,
    sort_by: ApiEmployeeFilterSort.date_birth,
  };
  const { data: upcomingBirthdays } = useEmployees(upcomingBirthdaysFilter);

  const [hiddenNotificationIds, setHiddenNotificationIds] = useState<number[]>([]);

  const todayItems = useMemo(() => {
    const result: TodayItem[] = [];
    if (approvalRequests && approvalRequests.length > 0) {
      for (let i = 0; i < approvalRequests.length; i++) {
        const currentRequest = approvalRequests[i];
        const add: TodayItem = {};
        add.avatarUrl = currentRequest.employee.avatar_url;
        add.preTitle = renderEmployeeName(currentRequest.employee);
        add.title = t("time_off:notification_approval_request_title", {
          category: `${currentRequest.time_off_data.category.emoji} ${currentRequest.time_off_data.category.title}`,
        });
        add.buttonTitle = t("common:review");
        add.onClick = async () => {
          const { data: request } = await apiApprovalRequests.getById(currentRequest.id);
          if (request) {
            openModal(TimeOffRequestApprovalModal, { requestDetails: request });
          }
        };
        result.push(add);
      }
    }
    if (upcomingBirthdays && upcomingBirthdays.items.length > 0) {
      const today = new Date();
      const employeesWithTodayBirthdays = upcomingBirthdays.items.filter(
        (x) =>
          x.birth_date &&
          x.birth_date.month &&
          x.birth_date.month - 1 == today.getMonth() &&
          x.birth_date.day == today.getDate()
      );

      if (employeesWithTodayBirthdays.length > 0) {
        for (const birthdayEmployee of employeesWithTodayBirthdays) {
          result.push({
            avatarUrl: birthdayEmployee.avatar_url,
            preTitle:
              birthdayEmployee.id === employee?.id
                ? t("calendar:happy_birthday")
                : t("core:your_teammate_birthday_today"),
            title: renderEmployeeName(birthdayEmployee),
            buttonTitle: t("core:view_profile"),
            onClick: async () => {
              openModal(EmployeeViewModal, {
                employee: birthdayEmployee,
              });
            },
          });
        }
      }
    }

    if (notifications && notifications.items.length > 0) {
      for (let i = 0; i < notifications.items.length; i++) {
        const currentNotification = notifications.items[i].notification;
        const currentAuthor = notifications.items[i].author;
        const add: TodayItem = {};
        let skip = false;
        if (currentNotification.event) {
          add.emoji = "📅";
          add.preTitle = t("calendar:invitation");
          add.title = currentNotification.event.title;
          add.buttonTitle = t("common:view");
          add.onClick = async () => {
            apiNotifications.markAsRead(currentNotification.id);
            if (currentNotification.event) {
              const { data } = await apiEvents.getEventById(currentNotification.event.id);
              if (data) {
                openModal(EventPreviewModal, {
                  event: data,
                });
              }
            }
          };
        } else if (currentNotification.simple) {
          add.emoji = currentNotification.simple.emoji;
          add.avatarUrl = currentNotification.simple.icon_url;
          add.preTitle = currentNotification.simple.pre_title;
          add.title = currentNotification.simple.title;
          add.caption = currentNotification.simple.caption;
          add.buttonTitle = currentNotification.simple.button_title || t("common:ok");
          add.onClick = async () => {
            if (currentNotification.simple) {
              if (currentNotification.simple.href) {
                if (currentNotification.simple.blank) {
                  window.open(currentNotification.simple.href, "_blank");
                } else {
                  window.location.href = currentNotification.simple.href;
                }
              }
            }
            apiNotifications.markAsRead(currentNotification.id, true);
            setHiddenNotificationIds([...hiddenNotificationIds, currentNotification.id]);
          };
        } else if (currentNotification.approval_request) {
          add.avatarUrl = currentAuthor.avatar_url;

          if (currentNotification.approval_request.time_off_data) {
            // Для нотификаций типа запросов мы помечаем нотификацию прочитанной и не показываем ее
            apiNotifications.markAsRead(currentNotification.id, true);
            skip = true;
          }
        } else {
          switch (notifications.items[i].notification.notification_type) {
            case ApiNotificationTypes.password_changed: {
              add.emoji = "🔐";
              add.preTitle = t("core:notification_password_updated");
              add.buttonTitle = t("common:ok");
              add.onClick = async () => {
                const { data } = await apiNotifications.markAsRead(currentNotification.id, true);
                if (data) {
                  setHiddenNotificationIds([...hiddenNotificationIds, currentNotification.id]);
                }
              };
              break;
            }
            default: {
              skip = true;
              break;
            }
          }
        }

        if (hiddenNotificationIds.indexOf(currentNotification.id) == -1 && !skip) result.push(add);
      }
    }

    return result;
  }, [upcomingBirthdays, notifications, hiddenNotificationIds, approvalRequests]);

  return (
    <>
      {todayItems.length > 0 && (
        <>
          <Stack direction={"horizontal"} justify={"between"}>
            <Title header={t("common:today")} size={"lg"} paddingBottom paddingTop />
          </Stack>
          <Stack gap={"md"}>
            {todayItems.map((item, i) => (
              <Box
                className={"rounded-md border-brand-dark/5 px-6 py-4 bg-white"}
                key={`today-${i}`}
              >
                <ListItem
                  emoji={item.emoji}
                  avatarUrl={item.avatarUrl}
                  caption={item.caption}
                  title={item.title}
                  preTitle={item.preTitle}
                  emphasis={"top"}
                  behavior={"card"}
                  valueSlot={
                    <Button
                      isLoading={isLoadingHomeItemId == i}
                      variant={"secondary"}
                      onClick={async () => {
                        setIslLoadingHomeItemId(i);
                        await item.onClick?.();
                        setIslLoadingHomeItemId(null);
                      }}
                    >
                      {item.buttonTitle}
                    </Button>
                  }
                />
              </Box>
            ))}
          </Stack>
        </>
      )}
    </>
  );
};
