import Stack from "../../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import { useDebounce, useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";
import { ReactNode, useMemo, useState } from "react";
import {
  ApiApplicantDataProtection,
  ApiDataProtectionRequestItem,
  ApiDataProtectionRequestsFilter,
  DataProtectionState,
} from "../../../../../types/data-protection.types.ts";
import Box from "../../../../../ui/box/Box.tsx";
import { Search } from "../../../../../components/search/Search.tsx";
import Table from "../../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../../components/empty-state/EmptyState.tsx";
import { PaginationBlock } from "../../../../../ui/pagination/PaginationBlock.tsx";
import { useDataProtectionSearchRequests } from "../../../../../queries/use-data-protection.query.ts";
import { ColumnDef } from "@tanstack/react-table";
import DropdownMenu from "../../../../../ui/dropdown-menu/DropdownMenu.tsx";
import Avatar from "../../../../../ui/avatar/Avatar.tsx";
import Text from "../../../../../ui/typography/Text.tsx";
import { PermissionType } from "../../../../../types/role.types.ts";
import { useEmployeeStore } from "../../../../../stores/employee.store.ts";
import useDataProtectionHook from "../../../../../hooks/use-data-protection.hook.ts";
import useFormatter from "../../../../../hooks/use-formatter.hook.ts";
import LinkTo from "../../../../../ui/link/LinkTo.tsx";
import { Rounding } from "../../../../../types/common.types.ts";
import { ApplicantDataProtectionOption } from "../../../../recruit/candidates/components/ApplicantDataProtectionOption.tsx";

export function DataProtectionTabRequests() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ApiDataProtectionRequestsFilter>({
    text: "",
    page_number: 1,
    states: [
      DataProtectionState.requested,
      DataProtectionState.given,
      DataProtectionState.expired,
      DataProtectionState.expiring,
      DataProtectionState.declined,
    ],
  });
  const { data: requests, isLoading, isFetching } = useDataProtectionSearchRequests(filter);
  const { employee } = useEmployeeStore();
  const { getTitleByType } = useDataProtectionHook();
  const { renderRelativeDate, getDaysToDate } = useFormatter();

  const canManageCandidate: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.manage_candidates == PermissionType.edit;
  }, [employee]);

  useDebounce(
    () => {
      setFilter({
        ...filter,
        page_number: 1,
        text: searchTerm,
      });
    },
    300,
    [searchTerm]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.dataProtection.viewed, {
      [analyticProperties.tab]: "Requests",
    });
  });

  const getStateElement = (dataProtection: ApiApplicantDataProtection): ReactNode => {
    const title = getTitleByType(dataProtection.type);
    switch (dataProtection.state) {
      case DataProtectionState.requested:
        return <Text>{`${title} - ${t("data_protection:consent_requested")}`}</Text>;
      case DataProtectionState.given:
        return (
          <Text
            className={"text-positive"}
          >{`${title} - ${t("data_protection:consent_received")}`}</Text>
        );
      case DataProtectionState.expired:
        return (
          <Text
            className={"text-danger"}
          >{`${title} - ${t("data_protection:consent_expired")}`}</Text>
        );
      case DataProtectionState.expiring:
        return (
          <Text
            className={"text-warning"}
          >{`${title} - ${t("data_protection:consent_expiring")}`}</Text>
        );
      case DataProtectionState.declined:
        return (
          <Text
            className={"text-danger"}
          >{`${title} - ${t("data_protection:consent_declined")}`}</Text>
        );
    }
  };

  const getRetentionPeriod = (dataProtection: ApiApplicantDataProtection): string => {
    if (
      dataProtection.state !== DataProtectionState.given &&
      dataProtection.state !== DataProtectionState.expiring
    )
      return "-";

    return t("data_protection:status_request_given", {
      count_days: t("plurals:plural_day", {
        count: getDaysToDate(dataProtection.datetime_state_end, Rounding.up),
      }),
    });
  };

  const columns = useMemo<ColumnDef<ApiDataProtectionRequestItem>[]>(
    () => [
      {
        header: t("common:sent"),
        cell: (row) =>
          row.row.original.data_protection.datetime_sent
            ? renderRelativeDate(row.row.original.data_protection.datetime_sent)
            : "",
        accessorKey: "sent",
        size: 0,
      },
      {
        header: t("ats:candidate"),
        cell: (row) => (
          <LinkTo to={`/recruitment/candidates/${row.row.original.id}/`} className={"no-underline"}>
            <Stack
              direction={"horizontal"}
              className={"text-ellipsis whitespace-nowrap"}
              gap={"sm"}
            >
              <Avatar size={"md"} url={row.row.original.photo_url} />
              <Stack direction={"vertical"}>
                <Text
                  className={
                    "text-ellipsis whitespace-nowrap max-w-32 overflow-hidden font-semibold text-dark"
                  }
                >{`${row.row.original.first_name} ${row.row.original.last_name}`}</Text>
                {getStateElement(row.row.original.data_protection)}
              </Stack>
            </Stack>
          </LinkTo>
        ),
        accessorKey: "candidate",
      },
      {
        header: t("data_protection:remaining_consent_period"),
        cell: (row) => getRetentionPeriod(row.row.original.data_protection),
        accessorKey: "retention",
      },
      {
        header: "",
        cell: (row) => (
          <Stack className={"justify-end"} direction={"horizontal"}>
            {canManageCandidate && (
              <DropdownMenu>
                <ApplicantDataProtectionOption
                  dataProtection={row.row.original.data_protection}
                  applicantId={row.row.original.id}
                  key={`protection-${row.row.original.data_protection.type}`}
                />
              </DropdownMenu>
            )}
          </Stack>
        ),
        size: 100,
        accessorKey: "controls",
      },
    ],
    []
  );

  const dataTransform = useMemo<ApiDataProtectionRequestItem[]>(() => {
    const result: ApiDataProtectionRequestItem[] = [];

    requests?.items.forEach((request) => {
      request.data_protection.forEach((dataProtectionItem) => {
        result.push({
          ...request,
          data_protection: dataProtectionItem,
        });
      });
    });

    return result;
  }, [requests]);

  return (
    <Stack>
      <Box className={"py-2 px-4"}>
        <Search
          placeholder={t("core:name")}
          value={searchTerm}
          onInput={(e) => {
            setSearchTerm(e.currentTarget.value);
          }}
          type={"text"}
        />
      </Box>
      <Table
        data={dataTransform || []}
        columns={columns}
        showSkeleton={isLoading}
        isFetching={isFetching}
        showNotFoundError={filter.text != "" && requests?.metadata.total_items == 0}
        notFoundComponent={<EmptyStateNothingFound />}
        emptyStateComponent={<EmptyState />}
      />
      <PaginationBlock
        metadata={requests?.metadata}
        onPageChange={(page) => {
          setFilter({ ...filter, page_number: page });
        }}
      />
    </Stack>
  );
}
