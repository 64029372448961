import { DefaultKitTheme, UIKitContextProps, UIKitTheme, UIKitThemeKey } from "./theme.types.ts";
import { createContext, ReactNode, useContext, useMemo } from "react";

const UIKitContext = createContext<UIKitContextProps>({
  uiKitTheme: DefaultKitTheme,
});

export interface IUIKitProviderProps {
  uiKitTheme: UIKitTheme;
  children: ReactNode;
}

export function UIKitProvider({ uiKitTheme, children }: IUIKitProviderProps) {
  const context: UIKitContextProps = {
    uiKitTheme: uiKitTheme,
  };
  return <UIKitContext.Provider value={context}>{children}</UIKitContext.Provider>;
}

export function useThemeContext() {
  const context = useContext(UIKitContext);

  if (context === undefined) {
    throw new Error("useTheme must be used within a UIKitContext");
  }
  return context;
}

export function useTheme<T extends UIKitThemeKey>(key: T): UIKitTheme[T] {
  const context = useThemeContext();
  return useMemo(() => {
    const theme = context.uiKitTheme[key];
    return theme;
  }, [key, context]);
}
