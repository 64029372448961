import {
  ApiAnalyticsAggregationDate,
  getAnalyticsAggregationDateOptions,
} from "../../../../types/analytics/analytics.recruiting.types.ts";
import Button from "../../../../ui/button/Button.tsx";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import { useMemo } from "react";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { SelectOption } from "../../../../ui/select/SelectOption.tsx";

export interface ButtonChooseAggregationDateProps {
  value: ApiAnalyticsAggregationDate;
  onChange: (value: ApiAnalyticsAggregationDate) => void;
}

export const RecruitingReportingButtonChooseAggregationDate = ({
  value,
  onChange,
}: ButtonChooseAggregationDateProps) => {
  const selectedOption = useMemo(
    () => getAnalyticsAggregationDateOptions().find((option) => option.id === value),
    [value]
  );

  return (
    <DropdownMenu
      trigger={
        <Button
          variant={"secondary"}
          rightIcon={<FontAwesomeIcon icon={"fa-light fa-chevron-down"} />}
        >
          {selectedOption?.title}
        </Button>
      }
    >
      {getAnalyticsAggregationDateOptions().map((option) => (
        <SelectOption
          key={`choose-group-period-${option.id} `}
          item={{
            id: option.id,
            title: option.title,
            selected: value === option.id,
            item: option,
          }}
          isMulti={false}
          handleSelect={(e) => {
            onChange(e.id as ApiAnalyticsAggregationDate);
          }}
        />
      ))}
    </DropdownMenu>
  );
};
