import { useCallback, useMemo, useState } from "react";
import { ApiEmployeePosition } from "../../../../types/employees/employee-position.types.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import Table from "../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import { ColumnDef, createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { EmployeePageComponentProps } from "./employee-page-components.types.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { PaginationBlock } from "../../../../ui/pagination/PaginationBlock.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { EmployeeTerminationModal } from "../widgets/EmployeeTerminationModal.tsx";
import {
  useEmployeeEmploymentStatusDelete,
  useEmployeeEmploymentStatusSearch,
} from "../../../../queries/employees/use-employee-employment-status.query.ts";
import { ApiEmployeeEmploymentStatus } from "../../../../types/employees/employee-employment-status.types.ts";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";

export const EmployeePositionHistoryEmploymentStatuses = ({
  employee,
}: EmployeePageComponentProps) => {
  const { t } = useTranslation();
  const { renderDate } = useFormatter();
  const modalsService = useModals();
  const [page, setPage] = useState(1);
  const {
    data: employeeEmploymentStatuses,
    isLoading,
    isFetching,
  } = useEmployeeEmploymentStatusSearch(employee.id, page);

  const employeeEmploymentStatusDelete = useEmployeeEmploymentStatusDelete();

  const columnHelper = createColumnHelper<ApiEmployeeEmploymentStatus>();
  const columns = useMemo<ColumnDef<ApiEmployeeEmploymentStatus>[]>(() => {
    const inner: DisplayColumnDef<ApiEmployeePosition, unknown>[] = [];

    inner.push(
      columnHelper.display({
        header: t("common:date"),
        cell: (cell) => renderDate(cell.row.original.date_effective_from),
        id: "date_effective_at",
        size: 100,
      })
    );

    inner.push(
      columnHelper.display({
        header: t("common:status"),
        cell: (row) => (
          <Stack>
            {row.row.original.termination_reason && (
              <>
                <Text className={"text-title"}>{t("core:termination")}</Text>
                <Text className={"text-secondary"}>
                  {row.row.original.termination_reason.title}
                </Text>
              </>
            )}
          </Stack>
        ),
        id: "position",
      })
    );

    inner.push(
      columnHelper.display({
        header: t("common:comment"),
        cell: (row) => (!row.row.original.comment ? "" : row.row.original.comment),
        id: "comment",
      })
    );

    inner.push(
      columnHelper.display({
        id: "actions",
        cell: (cell) => (
          <Stack className={"items-end"}>
            <DropdownMenu>
              <Button
                onClick={() => {
                  handleEditOrCreate(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                variant={"menu"}
              >
                {t("common:edit")}
              </Button>
              <Button
                onClick={() => {
                  handleDeleteAction(cell.row.original);
                }}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                variant={"menu"}
                isLoading={employeeEmploymentStatusDelete.isPending}
              >
                {t("common:delete")}
              </Button>
            </DropdownMenu>
          </Stack>
        ),
        size: 70,
      })
    );

    return inner;
  }, [employeeEmploymentStatuses]);

  const handleEditOrCreate = useCallback(
    (employmentStatus: ApiEmployeeEmploymentStatus | undefined = undefined) => {
      if (!employee) return;

      const employmentStatusToEdit = employmentStatus || {
        id: 0,
        date_effective_from: new Date(),
      };

      modalsService.openModal(EmployeeTerminationModal, {
        employee,
        employeeEmploymentStatus: employmentStatusToEdit,
      });
    },
    [employee]
  );

  const handleDeleteAction = useCallback(
    (employmentStatus: ApiEmployeeEmploymentStatus) => {
      employeeEmploymentStatusDelete.mutate(
        { employeeId: employee.id, positionId: employmentStatus.id },
        {
          onSuccess: () => {
            analyticsService.trackEvent(analyticEvents.employees.dismissalDeleted, {
              [analyticProperties.id]: employee.id,
            });
          },
        }
      );
    },
    [employee]
  );

  return (
    <>
      {isLoading && <Skeleton className={"w-full h-10"} />}
      {employeeEmploymentStatuses && employeeEmploymentStatuses.items.length > 0 && (
        <Stack gap={"lg"}>
          <Stack className={"justify-between"} direction={"horizontal"} items={"center"}>
            <Title header={t("core:employment_statuses")} size={"md"} />
          </Stack>
          <Box>
            <Table
              data={employeeEmploymentStatuses?.items || []}
              columns={columns}
              isFetching={isFetching || employeeEmploymentStatusDelete.isPending}
              showSkeleton={isLoading}
              notFoundComponent={<EmptyStateNothingFound />}
            />

            {(employeeEmploymentStatuses?.metadata.total_pages ?? 0) > 1 && (
              <PaginationBlock
                metadata={employeeEmploymentStatuses?.metadata}
                onPageChange={(page) => {
                  setPage(page);
                }}
              />
            )}
          </Box>
        </Stack>
      )}
    </>
  );
};
