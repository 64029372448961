import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException, ApiPassException } from "../../types/api.types.ts";
import { ApiJobSavePayload, ApiJobsFilter } from "../../types/recruit/job.types.ts";
import { apiJobs } from "../../api/recruit/job.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";

export const jobsKeys = createQueryKeys("jobs", {
  search: (filter: ApiJobsFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiJobs.search(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  details: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("Job id is required");
      const result = await apiJobs.getById(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  byListOfId: (ids: number[]) => ({
    queryKey: [ids],
    queryFn: async () => {
      const result = await apiJobs.getByListOfId(ids);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  recruitersSearch: null,
});

export const useJobsSearch = (filter: ApiJobsFilter) => {
  return useQuery({
    ...jobsKeys.search(filter),
  });
};
export const useJobDetails = (id: number | undefined) => {
  return useQuery({
    ...jobsKeys.details(id),
    enabled: !!id,
  });
};

export interface SaveParams {
  id: number;
  payload: ApiJobSavePayload;
}

export const useJobSave = <T extends FieldValues>(control: Control<T> | undefined = undefined) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function ({ id, payload }: SaveParams) {
      const result = id == 0 ? await apiJobs.create(payload) : await apiJobs.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: jobsKeys.search._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useJobRecruitersSearch = () => {
  return useQuery({
    ...jobsKeys.recruitersSearch,
    queryFn: async ({ signal }) => {
      const result = await apiJobs.recruitersSearch(signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  });
};

export const useJobByIds = (ids: number[]) => {
  return useQuery({
    ...jobsKeys.byListOfId(ids),
    enabled: ids.length > 0,
  });
};
