import { api } from "./axios.ts";
import { ApiCountry, ApiLocation, ApiLocationSavePayload } from "../types/location.types.ts";

export const apiLocations = {
  getAllCountries: () => {
    return api.get<ApiCountry[]>(`/api/v1/locations/countries`);
  },
  getAll: () => {
    return api.get<ApiLocation[]>(`/api/v1/locations`);
  },
  create: (payload: ApiLocationSavePayload) => {
    return api.post<ApiLocation>(`/api/v1/locations`, payload);
  },
  update: (id: number, payload: ApiLocationSavePayload) => {
    return api.put<ApiLocation>(`/api/v1/locations/${id}`, payload);
  },
  delete: (id: number) => {
    return api.delete(`/api/v1/locations/${id}`);
  },
};
