import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiPositions } from "../api/position.api.ts";
import { ApiPositionFilter, ApiPositionSavePayload } from "../types/position.types.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { useMemo, useState } from "react";
import { SelectItem } from "../ui/select/select.types.ts";

const positionsKeys = createQueryKeys("positions", {
  all: null,
  search: (filter: ApiPositionFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiPositions.search(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const usePositions = (enabled = true) => {
  return useQuery({
    ...positionsKeys.all,
    queryFn: async () => {
      const result = await apiPositions.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
    enabled: enabled,
  });
};

export const usePositionsSearch = (filter: ApiPositionFilter, enabled: boolean = true) => {
  return useQuery({
    ...positionsKeys.search(filter),
    enabled: enabled,
  });
};

export const usePositionsSearchInline = (enabled = true) => {
  const [filter, setFilter] = useState<ApiPositionFilter>({
    page_number: 1,
    text: undefined,
  });
  const { data, isLoading } = usePositionsSearch(filter, enabled);
  const options = useMemo(() => {
    return data?.items || ([] as SelectItem[]);
  }, [data]);

  function search(text: string | undefined) {
    setFilter({ ...filter, text: text });
  }

  return {
    data,
    isLoading,
    options,
    search,
  };
};

export interface PositionSaveParams {
  id: number;
  payload: ApiPositionSavePayload;
}

export const usePositionSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: PositionSaveParams) => {
      const result =
        id == 0 ? await apiPositions.create(payload) : await apiPositions.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: positionsKeys.search._def });
      queryClient.invalidateQueries({ queryKey: positionsKeys.all.queryKey });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const usePositionDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiPositions.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: positionsKeys.search._def });
      queryClient.invalidateQueries({ queryKey: positionsKeys.all.queryKey });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
