import useModals from "../../../../ui/modal/modal.store.ts";

import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import Modal from "../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import * as yup from "yup";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import i18n from "../../../../i18n.ts";
import { useLocations } from "../../../../queries/use-locations.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import Select from "../../../../ui/select/Select.tsx";
import { ApiLegalEntity, ApiLegalEntitySavePayload } from "../../../../types/legal-entity.types.ts";
import { useLegalEntitySave } from "../../../../queries/use-legal-entities.query.ts";
import { useCurrencies } from "../../../../queries/currencies.query.ts";
import { useMemo } from "react";

export interface LegalEntityEditModalProps extends ModalProps {
  legalEntityId: number;
  legalEntity?: ApiLegalEntity;
}

export function LegalEntityEditModal({
  legalEntityId,
  legalEntity,
  ...props
}: LegalEntityEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const legalEntitySchema = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
    tax_id: yup.string().nullable(),
    currency_code: yup.string().nullable(),
    location_id: yup.number().required(i18n.t("errors:field_required")),
  });

  interface LegalEntitySchema extends yup.InferType<typeof legalEntitySchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LegalEntitySchema>({
    mode: "onSubmit",
    resolver: yupResolver(legalEntitySchema),
    defaultValues: {
      title: legalEntity?.title,
      tax_id: legalEntity?.tax_id,
      currency_code: legalEntity?.currency?.code,
      location_id: legalEntity?.location.id,
    },
  });

  const legalEntitySave = useLegalEntitySave(control);
  const { data: locations } = useLocations();
  const { data: currencies } = useCurrencies();

  const currenciesMapped = useMemo(() => {
    if (currencies) {
      return currencies.map((x) => {
        return { id: x.code, title: x.code };
      });
    }
    return [];
  }, [currencies]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload: ApiLegalEntitySavePayload = {
        title: data.title,
        tax_id: data.tax_id,
        currency_code: data.currency_code,
        location_id: data.location_id,
      };
      const savedData = await legalEntitySave.mutateAsync({ id: legalEntityId, payload: payload });
      if (legalEntityId == 0) {
        analyticsService.trackEvent(analyticEvents.legalEntities.created, {
          [analyticProperties.id]: savedData.id,
        });
      } else {
        analyticsService.trackEvent(analyticEvents.legalEntities.edited, {
          [analyticProperties.id]: legalEntityId,
        });
      }
      close(id);
    } catch {
      return;
    }
  });
  return (
    <>
      <Modal
        {...props}
        title={t("core:legal_entity")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={legalEntitySave.isPending}
              onClick={onSubmit}
            >
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Input
            required={true}
            label={t("common:title")}
            autoFocus={true}
            type="text"
            {...register("title")}
            error={errors.title?.message}
          />
          <Input
            label={t("common:tax_id")}
            type={"text"}
            {...register("tax_id", {
              value: undefined,
            })}
            error={errors.tax_id?.message}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Select
                options={currenciesMapped}
                label={t("common:currency")}
                emptyTitle={t("common:select")}
                searchable
                value={value}
                onChange={onChange}
                error={errors.currency_code?.message}
              />
            )}
            control={control}
            name={"currency_code"}
          />
          <Controller
            control={control}
            name="location_id"
            render={({ field: { value, onChange } }) => (
              <Select
                required
                label={t("common:location")}
                options={locations || []}
                emptyTitle={t("common:select")}
                value={value}
                onChange={onChange}
                searchable
                error={errors.location_id?.message}
              />
            )}
          />
        </form>
      </Modal>
    </>
  );
}
