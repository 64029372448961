import { Rule } from "./role.types.ts";
import { ApiApprovalRequestsFilter, ApiApprovalRequestTypes } from "./approval-request.types.ts";
import { ApiEmployee, ApiEmployeeShort } from "./employees/employee.types.ts";

export interface TimeOffSettingsProps {
  tab: TimeOffSettingsTabs;
}

export enum TimeOffSettingsTabs {
  settings = "settings",
  history = "history",
}

export type ApiTimeOffCategoryPayload = Omit<
  ApiTimeOffCategory,
  "id" | "policies_count" | "policies"
>;

export interface ApiTimeOffCategoryShort {
  id: number;
  title: string;
  description?: string;
  policies_count: number;
  emoji?: string;
  color?: string;
  period_track_type: ApiTimeOffPeriodTrackingTime;
  category_type: ApiTimeOffCategoryType;
}

export interface ApiTimeOffCategory extends ApiTimeOffCategoryShort {
  count_days_type: ApiTimeOffCountDaysType;
  paid_type: ApiTimeOffPaidType;
  is_public: boolean;
  policies: ApiTimeOffPolicyShort[];
}

export enum ApiTimeOffPeriodTrackingTime {
  days = "days",
  hours = "hours",
}

export enum ApiTimeOffCategoryType {
  accrues_time = "accrues_time",
  flexible = "flexible",
  manual = "manual",
}

export enum ApiTimeOffPaidType {
  not_working_paid = "not_working_paid",
  working_paid = "working_paid",
  not_working_unpaid = "not_working_unpaid",
}

export enum ApiTimeOffCountDaysType {
  working_days = "business_days",
  calendar_days = "calendar_days",
}

// export interface ApiTimeOffPolicyMilestone {
//   start_date: Date;
//   initial_balance: number;
// }

export interface ApiTimeOffSettings {
  is_enabled: boolean;
}

export interface ApiTimeOffPolicyShort {
  id: number;
  title: string;
  category_id: number;
  carryover_cycle_date_type: ApiTimeOffPolicyCarryoverCycleDateType;
  carryover_cycle_date_data?: ApiTimeOffCarryoverCycleDateData | null;
  carryover_expire_enable: boolean;
  carryover_expire_date_type?: ApiTimeOffDateType;
  carryover_expire_date_value?: ApiTimeOffCarryoverExpireDateValue | null;
  limits_negative_balance_allowed: boolean;
  min_daily_amount?: number | null;
  min_request_total?: number | null;
  max_request_total?: number | null;
  min_notice_period?: number | null;
  max_notice_period?: number | null;
  start_balance_type: ApiTimeOffPolicyStartBalanceType;
  milestone_transfer_type: ApiTimeOffPolicyMilestoneTransferType;
  accrual_at_type: ApiTimeOffPolicyAccrualAtType;
  accrual_confirm_required: boolean;
  employees_count: number;
}

export interface ApiTimeOffPolicy extends ApiTimeOffPolicyShort {
  assign_to: Rule[];
  milestones: ApiTimeOffPolicyMilestone[];
}

export type ApiTimeOffPolicyPayload = Omit<ApiTimeOffPolicy, "id" | "employees_count">;

export interface ApiTimeOffPolicyMilestone {
  title?: string;
  start_date_type: ApiTimeOffDateType;
  start_amount: number;
  start_balance_amount: number;
  accrual_frequency_type: ApiTimeOffAccrualFrequencyTypes;
  accrual_frequency_data?: ApiTimeOffAccrualFrequencyData | null;
  accrual_amount: number;
  accrual_cap_enabled: boolean;
  accrual_cap_amount: number;
  carryover_enabled: boolean;
  carryover_amount: number;
}

export interface ApiTimeOffAccrualFrequencyData {
  date_first?: Date | null | undefined;
  date_second?: Date | null | undefined;
}

export interface ApiTimeOffCarryoverCycleDateData {
  date_first?: Date | null | undefined;
}

export interface ApiTimeOffCarryoverExpireDateValue {
  count: number | null | undefined;
}

export enum ApiTimeOffPolicyCarryoverCycleDateType {
  specific_date = "specific_date",
  hire_date = "hire_date",
  quarter_start = "quarter_start",
}

export enum ApiTimeOffPolicyCarryoverExpireDateType {
  days = "days",
  hours = "hours",
}

export enum ApiTimeOffPolicyStartBalanceType {
  proportional_accrual = "proportional_accrual",
  full_accrual_amount = "full_accrual_amount",
}

export enum ApiTimeOffPolicyMilestoneTransferType {
  immediately_on_condition = "immediately_on_condition",
  end_of_accrual_period = "end_of_accrual_period",
}

export enum ApiTimeOffPolicyAccrualAtType {
  start_of_accrual_period = "start_of_accrual_period",
  end_of_accrual_period = "end_of_accrual_period",
}

export enum ApiTimeOffDateType {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
}

export enum ApiTimeOffAccrualFrequencyTypes {
  daily = "daily",
  weekly = "weekly",
  biweekly = "biweekly",
  semimonthly = "semimonthly",
  monthly = "monthly",
  quarterly = "quarterly",
  semiannual = "semiannual",
  yearly = "yearly",
  hire_date = "hire_date",
}

export enum ApiTimeOffPolicyMilestoneCarryoverType {
  none = "none",
  up_to = "up_to",
  unlimited = "unlimited",
}

export interface ApiTimeOffFilter extends ApiApprovalRequestsFilter {
  type?: ApiApprovalRequestTypes.time_off;
  category_id?: number;
}

export interface ApiTimeOffAccrualsFilter {
  page_number: number;
}

export enum ApiTimeOffLogType {
  initial_balance = "initial_balance",
  accrual = "accrual",
  carryover = "carryover",
  expire = "expire",
  used = "used",
  adjustment = "adjustment",
}

export interface ApiTimeOffLog {
  id: number;
  action: ApiTimeOffLogType;
  balance: string;
  employee: ApiEmployee;
  category: ApiTimeOffCategoryShort;
  date_adjustment: Date;
  date_expire: Date | null;
  created_by?: ApiEmployeeShort;
}

export interface ApiTimeOffAdjustmentPayload {
  employee_id: number;
  category_id: number;
  balance: number;
  date_expire: Date | null;
  date_adjustment: Date;
}

export const mockTimeOffAdjustment: ApiTimeOffAdjustmentPayload = {
  employee_id: 0,
  category_id: 0,
  balance: 0,
  date_expire: null,
  date_adjustment: new Date(),
};

export const getMockTimeOffAdjustment = (employee: ApiEmployeeShort) => {
  return {
    ...mockTimeOffAdjustment,
    employee_id: employee.id,
  } as ApiTimeOffAdjustmentPayload;
};

export enum ApiTimeOffAdjustmentType {
  increase = "increase",
  decrease = "decrease",
}
