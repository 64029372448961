import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException } from "../types/api.types.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { apiEmailTemplates } from "../api/email-template.api.ts";
import { ApiEmailTemplateSavePayload } from "../types/email-template.types.ts";

const emailTemplatesKeys = createQueryKeys("emailTemplates", {
  all: null,
});

export const useEmailTemplates = (enabled = true) => {
  return useQuery({
    ...emailTemplatesKeys.all,
    queryFn: async () => {
      const result = await apiEmailTemplates.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
    enabled: enabled,
  });
};

export const useEmailTemplateSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiEmailTemplateSavePayload }) => {
      const result =
        id == 0
          ? await apiEmailTemplates.create(payload)
          : await apiEmailTemplates.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: emailTemplatesKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useEmailTemplateDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiEmailTemplates.delete(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: emailTemplatesKeys.all.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
