import { useTranslation } from "react-i18next";
import Stack from "../../../ui/stack/Stack.tsx";
import { useCallback, useMemo } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import { Page } from "../../../components/page/Page.tsx";
import useModals from "../../../ui/modal/modal.store.ts";
import { useEffectOnce } from "react-use";
import { Title } from "../../../components/title/Title.tsx";
import {
  useIntegrationDisconnect,
  useIntegrations,
} from "../../../queries/integrations/use-integrations.query.ts";
import { ApiIntegrationType } from "../../../types/integrations/integration.types.ts";
import { ListItem } from "../../../components/list-item/ListItem.tsx";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import { SettingsIntegrationGoogleCalendarModal } from "./widgets/SettingsIntegrationGoogleCalendar.tsx";
import LinkButtonTo from "../../../ui/link/LinkButtonTo.tsx";
import { apiIntegrations } from "../../../api/integrations/integration.api.ts";
import useErrorHandle from "../../../hooks/use-error-handle.hook.tsx";
import useToasts from "../../../hooks/use-toasts.hook.tsx";
import { ConfirmationPopup } from "../../../components/confirmation-popup/ConfirmationPopup.tsx";

export default function SettingsIntegrations() {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { data: integrations, isLoading } = useIntegrations();
  const { handleErrors } = useErrorHandle();
  const { showInfo } = useToasts();

  const disconnect = useIntegrationDisconnect();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.integrations.viewed);
  });

  const googleCalendarIntegration = useMemo(() => {
    return integrations?.find(
      (x) => x.integration_type == ApiIntegrationType.google_calendar_service_account
    );
  }, [integrations]);

  const slackIntegration = useMemo(() => {
    return integrations?.find((x) => x.integration_type == ApiIntegrationType.slack);
  }, [integrations]);

  const handleManageGoogleCalendar = useCallback(() => {
    modalsService.openModal(SettingsIntegrationGoogleCalendarModal, {
      integration: googleCalendarIntegration,
    });
  }, [googleCalendarIntegration]);

  const handleSlack = useCallback(async () => {
    if (slackIntegration?.is_active) {
      modalsService.openModal(ConfirmationPopup, {
        question: t("integrations:slack_account_disconnect_confirm"),
        text: t("integrations:slack_account_disconnect_confirm_description"),
        acceptTitle: t("integrations:disconnect"),
        onAccept: async () => {
          disconnect.mutate(ApiIntegrationType.slack, {
            onSuccess: () => {
              analyticsService.trackEvent(analyticEvents.integrations.disconnected, {
                [analyticProperties.actionType]: "Slack",
              });
              showInfo({
                title: t("integrations:account_disconnected"),
              });
            },
          });
        },
      });
    } else {
      analyticsService.trackEvent(analyticEvents.integrations.connectStarted, {
        [analyticProperties.actionType]: "Slack",
      });

      const { data, error } = await apiIntegrations.install(ApiIntegrationType.slack);

      if (data) {
        window.location.href = data.authorization_url;
      } else handleErrors(error);
    }
  }, [slackIntegration]);

  return (
    <>
      <Page>
        <Page.Header title={t("integrations:settings_integrations_title")} showBack />
        <Page.Content>
          <Stack gap={"xl"} className={"container-sm mx-auto"}>
            <Title
              size={"lg"}
              paddingBottom
              paddingTop
              header={t("integrations:settings_integrations_title")}
              caption={t("integrations:settings_integrations_description")}
            />
            {isLoading ? (
              <Skeleton className={"w-full h-24"} />
            ) : (
              <>
                <ListItem
                  title={t("integrations:google_calendar_title")}
                  caption={
                    googleCalendarIntegration?.is_active
                      ? t("integrations:is_active")
                      : `${t("integrations:google_calendar_description")}`
                  }
                  valueSlot={
                    <LinkButtonTo
                      onClick={() => {
                        handleManageGoogleCalendar();
                      }}
                    >
                      {googleCalendarIntegration?.is_active
                        ? t("integrations:manage")
                        : t("integrations:connect")}
                    </LinkButtonTo>
                  }
                />
                <ListItem
                  title={t("integrations:slack_title")}
                  caption={`${t("integrations:slack_description")}`}
                  valueSlot={
                    <LinkButtonTo
                      onClick={() => {
                        handleSlack();
                      }}
                    >
                      {slackIntegration?.is_active
                        ? t("integrations:disconnect")
                        : t("integrations:connect")}
                    </LinkButtonTo>
                  }
                />
              </>
            )}
          </Stack>
        </Page.Content>
      </Page>
    </>
  );
}
