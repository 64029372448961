import { ApiFilePathTypes } from "./file.types.ts";
import { SelectItem } from "../ui/select/select.types.ts";

export interface ApiFile {
  id: number;
  name: string;
  file_type: ApiFilePathTypes;
  mime_type: string;
  size: number;
  uuid: string;
}

export enum ApiEntityTypes {
  applicant = "applicant",
  candidate = "candidate",
  employee = "employee",
}

export interface ApiPartialDate {
  year: number | null;
  month: number | null;
  day: number | null;
}

export interface Time extends SelectItem {
  date: Date;
}

export interface Period {
  start: Date;
  end: Date;
  title: string;
  key: string;
}

export enum ContactTypes {
  telegram = "telegram",
  whatsapp = "whatsapp",
  viber = "viber",
}

export interface ApiOperationResult {
  success: boolean;
}

export enum Rounding {
  up = "up",
  down = "down",
  usual = "usual",
}
