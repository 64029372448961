import Box from "../../ui/box/Box.tsx";
import Avatar from "../../ui/avatar/Avatar.tsx";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import { ReactNode } from "react";

export interface UserListItemProps {
  avatarUrl?: string;
  primaryText?: string;
  primarySlot?: ReactNode;
  text2?: string;
  text2Slot?: ReactNode;
  text3?: string;
  text3Slot?: ReactNode;
  valueSlot?: ReactNode;
}

export const UserListItem = ({
  avatarUrl,
  primaryText,
  primarySlot,
  text2,
  text2Slot,
  text3,
  text3Slot,
  valueSlot,
}: UserListItemProps) => {
  return (
    <Stack justify={"between"} gap={"smd"} direction={"horizontal"} items={"center"}>
      <Stack className={"overflow-hidden"} direction={"horizontal"} gap={"smd"} items={"center"}>
        <Box className={"mt-1 relative"}>
          <Avatar size={"md"} url={avatarUrl} acronym={primaryText} />
        </Box>
        <Stack className={"overflow-hidden"}>
          {primaryText && !primarySlot && (
            <Text className={"text-title overflow-hidden overflow-ellipsis whitespace-nowrap"}>
              {primaryText}
            </Text>
          )}
          {primarySlot && primarySlot}

          {text2 && (
            <Text className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}>
              {text2}
            </Text>
          )}
          {text2Slot && text2Slot}
          {text3 && (
            <Text className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}>
              {text3}
            </Text>
          )}
          {text3Slot && text3Slot}
        </Stack>
      </Stack>
      {valueSlot}
    </Stack>
  );
};
