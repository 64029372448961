import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../ui/input/Input.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import {
  usePolicyDelete,
  useTimeOffCategoryDetails,
  useTimeOffPolicyDetails,
  useTimeOffPolicySave,
} from "../../../../queries/use-time-offs.query.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import {
  ApiTimeOffCarryoverCycleDateData,
  ApiTimeOffCarryoverExpireDateValue,
  ApiTimeOffDateType,
  ApiTimeOffPolicyAccrualAtType,
  ApiTimeOffPolicyCarryoverCycleDateType,
  ApiTimeOffPolicyMilestone,
  ApiTimeOffPolicyMilestoneTransferType,
  ApiTimeOffPolicyStartBalanceType,
} from "../../../../types/time-off.types.ts";
import i18n from "i18next";
import { useEffect } from "react";
import { Rule } from "../../../../types/role.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import { TimeOffPolicyCarryover } from "./components/TimeOffPolicyCarryover.tsx";
import { TimeOffPolicyRequireConfirmation } from "./components/TimeOffPolicyRequireConfirmation.tsx";
import { TimeOffPolicyAccruals } from "./components/TimeOffPolicyAccruals.tsx";
import { TimeOffPolicyAssignTo } from "./components/TimeOffPolicyAssignTo.tsx";
import { TimeOffPolicyMilestoneTransfer } from "./components/TimeOffPolicyMilestoneTransfer.tsx";
import { TimeOffPolicyStartBalance } from "./components/TimeOffPolicyStartBalance.tsx";
import { TimeOffPolicyLimits } from "./components/TimeOffPolicyLimits.tsx";
import { TimeOffPolicyMilestones } from "./components/TimeOffPolicyMilestones.tsx";

const TimeOffCategoryPolicyObject = yup.object({
  title: yup.string().required(i18n.t("errors:field_required")),
  limits_negative_balance_allowed: yup.boolean().required(i18n.t("errors:field_required")),
  accrual_confirm_required: yup.boolean().required(i18n.t("errors:field_required")),

  daily_amount_enabled: yup.boolean(),
  min_daily_amount: yup.number(),

  request_total_enabled: yup.boolean(),
  min_request_total: yup.number(),
  max_request_total: yup.number(),

  notice_period_enabled: yup.boolean(),
  min_notice_period: yup.number(),
  max_notice_period: yup.number(),

  start_balance_type: yup
    .mixed<ApiTimeOffPolicyStartBalanceType>()
    .oneOf(Object.values(ApiTimeOffPolicyStartBalanceType))
    .required(i18n.t("errors:field_required")),

  milestone_transfer_type: yup
    .mixed<ApiTimeOffPolicyMilestoneTransferType>()
    .oneOf(Object.values(ApiTimeOffPolicyMilestoneTransferType))
    .required(i18n.t("errors:field_required")),

  accrual_at_type: yup
    .mixed<ApiTimeOffPolicyAccrualAtType>()
    .oneOf(Object.values(ApiTimeOffPolicyAccrualAtType))
    .required(i18n.t("errors:field_required")),

  assign_to: yup.mixed<Rule[]>().required(i18n.t("errors:field_required")),
  milestones: yup.mixed<ApiTimeOffPolicyMilestone[]>().required(i18n.t("errors:field_required")),

  carryover_cycle_date_type: yup
    .mixed<ApiTimeOffPolicyCarryoverCycleDateType>()
    .oneOf(Object.values(ApiTimeOffPolicyCarryoverCycleDateType))
    .required(),
  carryover_cycle_date_data: yup.mixed<ApiTimeOffCarryoverCycleDateData>().nullable(),
  carryover_expire_enable: yup.boolean().required(),
  carryover_expire_date_type: yup
    .mixed<ApiTimeOffDateType>()
    .oneOf(Object.values(ApiTimeOffDateType)),
  carryover_expire_date_value: yup.mixed<ApiTimeOffCarryoverExpireDateValue>().nullable(),
});

export type TimeOffCategoryPolicySchema = yup.InferType<typeof TimeOffCategoryPolicyObject>;

export function TimeOffCategoryPolicyEdit() {
  const { openModal } = useModals();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: categoryId, policy_id: policyId } = useParams();
  const isEdit = !!policyId;
  const { data: timeOffCategory } = useTimeOffCategoryDetails(
    categoryId ? Number(categoryId) : undefined
  );
  const { data: timeOffPolicy } = useTimeOffPolicyDetails(policyId ? Number(policyId) : undefined);
  const policyDelete = usePolicyDelete();

  const formHook = useForm<TimeOffCategoryPolicySchema>({
    mode: "onSubmit",
    resolver: yupResolver(TimeOffCategoryPolicyObject),
    defaultValues: {
      limits_negative_balance_allowed: false,
      accrual_confirm_required: true,

      daily_amount_enabled: false, // Check setting data for daily_amount
      request_total_enabled: false, // Check setting data for request_total
      notice_period_enabled: false, // Check setting data for notice_period
      start_balance_type: ApiTimeOffPolicyStartBalanceType.proportional_accrual,
      milestone_transfer_type: ApiTimeOffPolicyMilestoneTransferType.immediately_on_condition,
      accrual_at_type: ApiTimeOffPolicyAccrualAtType.start_of_accrual_period,
      assign_to: [],
      milestones: [],

      carryover_cycle_date_type: ApiTimeOffPolicyCarryoverCycleDateType.hire_date,
      carryover_cycle_date_data: null,
      carryover_expire_enable: false,
      carryover_expire_date_type: ApiTimeOffDateType.day,
      carryover_expire_date_value: null,
    },
  });

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = formHook;

  useEffect(() => {
    if (!timeOffPolicy) return;

    reset({
      title: timeOffPolicy.title,
      assign_to: timeOffPolicy.assign_to,
      milestones: timeOffPolicy.milestones,
      limits_negative_balance_allowed: timeOffPolicy.limits_negative_balance_allowed,
      accrual_confirm_required: timeOffPolicy.accrual_confirm_required,
      carryover_cycle_date_type: timeOffPolicy.carryover_cycle_date_type,
      carryover_cycle_date_data: timeOffPolicy.carryover_cycle_date_data ?? null,
      carryover_expire_enable: timeOffPolicy.carryover_expire_enable,
      carryover_expire_date_type: timeOffPolicy.carryover_expire_date_type,
      carryover_expire_date_value: timeOffPolicy.carryover_expire_date_value ?? null,
      min_request_total: timeOffPolicy.min_request_total ?? 0,
      max_request_total: timeOffPolicy.max_request_total ?? 0,
      request_total_enabled:
        (timeOffPolicy.min_request_total ?? 0) > 0 || (timeOffPolicy.max_request_total ?? 0) > 0,
      min_daily_amount: timeOffPolicy.min_daily_amount ?? 0,
      daily_amount_enabled: (timeOffPolicy.min_daily_amount ?? 0) > 0,
      min_notice_period: timeOffPolicy.min_notice_period ?? 0,
      max_notice_period: timeOffPolicy.max_notice_period ?? 0,
      notice_period_enabled:
        (timeOffPolicy.min_notice_period ?? 0) > 0 || (timeOffPolicy.max_notice_period ?? 0) > 0,
      start_balance_type: timeOffPolicy.start_balance_type,
      milestone_transfer_type: timeOffPolicy.milestone_transfer_type,
      accrual_at_type: timeOffPolicy.accrual_at_type,
    });
  }, [timeOffPolicy]);

  const policySave = useTimeOffPolicySave(control);
  const onSubmit = handleSubmit((dataSchema) => {
    if (!timeOffCategory) return;

    policySave.mutate(
      {
        id: timeOffPolicy?.id ?? 0,
        payload: {
          title: dataSchema.title,
          assign_to: dataSchema.assign_to,
          milestones: dataSchema.milestones,
          category_id: timeOffCategory.id,
          limits_negative_balance_allowed: dataSchema.limits_negative_balance_allowed,
          accrual_confirm_required: dataSchema.accrual_confirm_required,
          carryover_cycle_date_type: dataSchema.carryover_cycle_date_type,
          carryover_cycle_date_data: dataSchema.carryover_cycle_date_data,
          carryover_expire_enable: dataSchema.carryover_expire_enable,
          carryover_expire_date_type: dataSchema.carryover_expire_date_type,
          carryover_expire_date_value: dataSchema.carryover_expire_date_value,
          min_request_total: dataSchema.request_total_enabled
            ? dataSchema.min_request_total ?? 0
            : null,
          max_request_total: dataSchema.request_total_enabled
            ? dataSchema.max_request_total ?? 0
            : null,
          min_daily_amount: dataSchema.daily_amount_enabled
            ? dataSchema.min_daily_amount ?? 0
            : null,
          min_notice_period: dataSchema.notice_period_enabled
            ? dataSchema.min_notice_period ?? 0
            : null,
          max_notice_period: dataSchema.notice_period_enabled
            ? dataSchema.max_notice_period ?? 0
            : null,
          start_balance_type: dataSchema.start_balance_type,
          milestone_transfer_type: dataSchema.milestone_transfer_type,
          accrual_at_type: dataSchema.accrual_at_type,
        },
      },
      {
        onSuccess(createdPolicy) {
          analyticsService.trackEvent(
            isEdit ? analyticEvents.timeOffs.editedPolicy : analyticEvents.timeOffs.createdPolicy,
            {
              [analyticProperties.id]: createdPolicy.id,
            }
          );

          navigate(`/settings/time-offs/categories/${categoryId}`);
        },
      }
    );
  });

  const handleDelete = () => {
    if (!timeOffPolicy) return;
    if (policySave.isPending) return;

    openModal(ConfirmationPopup, {
      question: t("common:delete"),
      text: t("common:delete_confirm"),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          await policyDelete.mutateAsync({
            policyId: timeOffPolicy.id,
            categoryId: timeOffPolicy.category_id,
          });

          navigate(`/settings/time-offs/categories/${categoryId}`);
        } catch {
          return;
        }
      },
    });
  };

  return (
    <Page>
      <Page.Header
        title={t(isEdit ? "time_off:edit_policy_title" : "time_off:new_policy_title")}
        showBack={true}
      />
      <Page.Content>
        <Stack className={"px-16"} gap={"2xl"}>
          <Stack gap={"lg"}>
            <Input
              type={"text"}
              {...register("title")}
              error={errors.title?.message}
              label={t("common:title")}
              size={"lg"}
            />

            <TimeOffPolicyMilestones formHook={formHook} />
            <TimeOffPolicyLimits formHook={formHook} />
            <TimeOffPolicyRequireConfirmation formHook={formHook} />
            <TimeOffPolicyStartBalance formHook={formHook} />
            <TimeOffPolicyMilestoneTransfer formHook={formHook} />
            <TimeOffPolicyAccruals formHook={formHook} />
            <TimeOffPolicyCarryover formHook={formHook} />
          </Stack>

          <TimeOffPolicyAssignTo formHook={formHook} timeOffPolicy={timeOffPolicy} />

          <Stack className={"pt-md"} gap={"md"}>
            <ServerErrorField errors={errors} />
            <Button size={"lg"} type={"submit"} onClick={onSubmit} isLoading={policySave.isPending}>
              {t("common:save")}
            </Button>
            {isEdit && (
              <Button
                variant={"danger"}
                size={"lg"}
                type={"submit"}
                onClick={handleDelete}
                isLoading={policySave.isPending}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-xmark"} />}
              >
                {t("common:delete")}
              </Button>
            )}
          </Stack>
        </Stack>
      </Page.Content>
    </Page>
  );
}
