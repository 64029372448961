import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Box from "../../ui/box/Box.tsx";
import { useEmployeeStore } from "../../stores/employee.store.ts";

export interface EmojiPickerProps {
  onEmojiSelect: (emoji: string) => void;
}

interface onEmojiSelectEventType {
  native: string;
}

export const EmojiPicker = ({ onEmojiSelect }: EmojiPickerProps) => {
  const { employee } = useEmployeeStore();
  return (
    <Box className={""}>
      <Picker
        locale={employee?.language || "en"}
        data={data}
        onEmojiSelect={(e: onEmojiSelectEventType) => {
          onEmojiSelect(e.native);
        }}
      />
    </Box>
  );
};
