import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import useModals from "../../../../../ui/modal/modal.store.ts";
import Modal from "../../../../../ui/modal/modal.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { Radio } from "../../../../../ui/radio/Radio.tsx";
import useToasts from "../../../../../hooks/use-toasts.hook.tsx";
import Avatar from "../../../../../ui/avatar/Avatar.tsx";
import { usePipelineById } from "../../../../../queries/recruit/use-pipelines.query.ts";
import { useDisqualifications } from "../../../../../queries/recruit/use-disqualifications.query.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";
import {
  getStageEmoji,
  StageDisqualifiedEmoji,
} from "../../../../../types/recruit/stages.types.ts";
import {
  useCandidateActionCreate,
  useCandidateActionUpdate,
  useCandidateActionUpdateByFilter,
} from "../../../../../queries/recruit/use-candidate-actions.query.ts";
import {
  ApiAction,
  ApiActionCreateByFilter,
  ApiActionCreateByFilterPayload,
  ApiActionPayload,
  ApiActionVisibilities,
} from "../../../../../types/action.types.ts";
import { ApiStageInPipeline } from "../../../../../types/recruit/pipeline.types.ts";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import { ApiCandidate } from "../../../../../types/recruit/applicant.types.ts";
import Button from "../../../../../ui/button/Button.tsx";
import { ApiFile } from "../../../../../types/common.types.ts";
import { CommentInput } from "../../../../../components/comment-input/CommentInput.tsx";
import Box from "../../../../../ui/box/Box.tsx";

export interface ChangeStageModalProps extends ModalProps {
  pipelineId: number;

  action?: ApiAction;
  candidate?: ApiCandidate;
  candidatesFilter?: ApiActionCreateByFilter;

  onUpdatedByFilter?: () => void;
}

export function ChangeStageModal({
  pipelineId,
  candidate,
  action,
  candidatesFilter,
  onUpdatedByFilter,
  ...props
}: ChangeStageModalProps) {
  const { close } = useModals();
  const { t } = useTranslation();
  const { id } = { ...props };
  const { data: pipeline } = usePipelineById(pipelineId);
  const { data: disqualificationReasons } = useDisqualifications();

  const { showError, showInfo } = useToasts();

  const [comment, setComment] = useState(action?.comment || "");
  const [files, setFiles] = useState<ApiFile[]>(action?.files || []);

  const [selectedStageId, setSelectedStageId] = useState<string | undefined>(() => {
    if (action) {
      if (action.pipeline_stage) return action.pipeline_stage.id.toString();
      return undefined;
    }

    if (candidate) {
      if (candidate.pipeline_stage) return candidate.pipeline_stage.id.toString();
    }

    return undefined;
  });
  const [disqualificationReasonId, setDisqualificationReasonId] = useState<string | undefined>(
    () => {
      if (action) {
        if (action.disqualification_reason) return action.disqualification_reason.id.toString();
        return undefined;
      }
      if (candidate) {
        if (candidate.disqualification_reason)
          return candidate.disqualification_reason.id.toString();
      }
      return undefined;
    }
  );

  const [isDeclineMode, setIsDeclineMode] = useState<boolean>(!!disqualificationReasonId);

  const candidateActionCreate = useCandidateActionCreate();
  const candidateActionUpdate = useCandidateActionUpdate();
  const candidateActionUpdateByFilter = useCandidateActionUpdateByFilter();

  const save = useCallback(
    (reserved?: boolean) => {
      const payload: ApiActionPayload = {
        visibility: ApiActionVisibilities.public,
        comment: comment,
        file_ids: files.map((file) => file.id),
      };
      let pipelineStage: ApiStageInPipeline | undefined = undefined;
      if (isDeclineMode) {
        if (!disqualificationReasonId)
          return showError({ title: t("ats:choose_disqualification_reason") });
        payload.disqualification_reason_id = +disqualificationReasonId;
        payload.reserved = reserved || false;
      } else {
        if (!selectedStageId) return showError({ title: t("ats:choose_stage") });
        pipelineStage = pipeline?.stages.find((stage) => stage.id == +selectedStageId);
        if (!pipelineStage) return showError({ title: t("ats:choose_stage") });

        payload.pipeline_stage_id = pipelineStage.id;
      }

      if (action) {
        candidateActionUpdate.mutate(
          { id: action.item_id, actionId: action.id, payload: payload },
          {
            onSuccess: () => {
              analyticsService.trackEvent(analyticEvents.candidates.actionEdited, {
                [analyticProperties.id]: action.item_id,
              });
              close(id);
            },
          }
        );
      } else if (candidate) {
        candidateActionCreate.mutate(
          { id: candidate.id, payload: payload },
          {
            onSuccess: () => {
              if (isDeclineMode) {
                analyticsService.trackEvent(analyticEvents.candidates.disqualified, {
                  [analyticProperties.id]: candidate.id,
                  [analyticProperties.count]: 1,
                  [analyticProperties.reserved]: reserved || false,
                });
              } else {
                if (candidate.disqualification_reason) {
                  analyticsService.trackEvent(analyticEvents.candidates.qualified, {
                    [analyticProperties.id]: candidate.id,
                    [analyticProperties.pipelineStageTitle]: pipelineStage?.title,
                  });
                } else {
                  analyticsService.trackEvent(analyticEvents.candidates.changedStage, {
                    [analyticProperties.id]: candidate.id,
                    [analyticProperties.pipelineStageTitle]: pipelineStage?.title,
                    [analyticProperties.count]: 1,
                  });
                }
              }
              close(id);
            },
          }
        );
      } else if (candidatesFilter) {
        const updateByFilterPayload: ApiActionCreateByFilterPayload = {
          filter: candidatesFilter,
          action: payload,
        };

        candidateActionUpdateByFilter.mutate(updateByFilterPayload, {
          onSuccess: () => {
            if (isDeclineMode) {
              analyticsService.trackEvent(analyticEvents.candidates.disqualified, {
                [analyticProperties.count]: candidatesFilter.analyticsTotalUpdatedCount,
                [analyticProperties.reserved]: payload.reserved,
              });
            } else {
              analyticsService.trackEvent(analyticEvents.candidates.changedStage, {
                [analyticProperties.count]: candidatesFilter.analyticsTotalUpdatedCount,
                [analyticProperties.pipelineStageTitle]: pipelineStage?.title,
              });
            }
            showInfo({ title: t("common:you_changes_were_saved") });
            onUpdatedByFilter?.();
            close(id);
          },
        });
      }
    },
    [isDeclineMode, pipeline, selectedStageId, disqualificationReasonId, comment, files]
  );

  const onNext = useCallback(
    (reserve?: boolean) => {
      save(reserve || false);
    },
    [isDeclineMode, save]
  );

  const onBack = useCallback(() => {
    if (!isDeclineMode) return undefined;

    return () => {
      setIsDeclineMode(false);
      setSelectedStageId(undefined);
    };
  }, [isDeclineMode, save]);

  return (
    <Modal
      {...props}
      title={isDeclineMode ? t("ats:choose_disqualification_reason") : t("ats:choose_stage")}
      withCloser={true}
      variant={"side"}
      layout={"stretch"}
      onBack={onBack()}
      actions={
        <Stack gap={"sm"}>
          <Button
            type={"submit"}
            isLoading={candidateActionCreate.isPending || candidateActionUpdateByFilter.isPending}
            onClick={() => {
              onNext();
            }}
            size={"lg"}
            className={"w-full"}
          >
            {isDeclineMode ? t("ats:disqualify") : t("common:save")}
          </Button>
          {isDeclineMode && (
            <Button
              type={"submit"}
              isLoading={candidateActionCreate.isPending || candidateActionUpdateByFilter.isPending}
              onClick={() => {
                onNext(true);
              }}
              variant={"plain"}
              size={"lg"}
              className={"w-full"}
            >
              {t("ats:disqualify_and_reserve")}
            </Button>
          )}
        </Stack>
      }
    >
      <form>
        <Stack direction={"vertical"}>
          {!isDeclineMode && (
            <>
              <Radio
                key={`option-disqualification`}
                onClick={(event) => {
                  setIsDeclineMode(true);
                  event.stopPropagation();
                }}
                className={"py-1 border-b border-dark/10"}
              >
                <Avatar url={null} emoji={StageDisqualifiedEmoji} key={"avatar_disqualified"} />
                {t("ats:disqualify")}
              </Radio>
              {pipeline?.stages.map((stage) => (
                <Box key={`option-stage-${stage.id}}`} className={"py-2 border-b border-dark/10"}>
                  <Radio
                    value={stage.id.toString()}
                    checked={stage.id.toString() == selectedStageId}
                    onChange={() => {
                      setSelectedStageId(stage.id.toString());
                    }}
                  >
                    <Avatar url={undefined} emoji={getStageEmoji(stage)} size={"md"} />

                    {stage.title}
                  </Radio>
                  {stage.id.toString() == selectedStageId && (
                    <CommentInput
                      className={"pl-8"}
                      comment={comment}
                      files={files}
                      onChangeComment={(text) => setComment(text)}
                      onChangeFiles={(files) => setFiles(files)}
                    />
                  )}
                </Box>
              ))}
            </>
          )}
          {isDeclineMode && (
            <Stack gap={"md"} direction={"vertical"}>
              <Stack direction={"vertical"} gap={"sm"}>
                {disqualificationReasons?.map((disqualificationReason) => (
                  <Box
                    key={`disqualification-reason-${disqualificationReason.id}`}
                    className={"py-2 border-b border-dark/10"}
                  >
                    <Radio
                      value={disqualificationReason.id.toString()}
                      checked={disqualificationReason.id.toString() == disqualificationReasonId}
                      onChange={() => {
                        setDisqualificationReasonId(disqualificationReason.id.toString());
                      }}
                    >
                      {disqualificationReason.title}
                    </Radio>
                    {disqualificationReason.id.toString() == disqualificationReasonId && (
                      <CommentInput
                        className={"pl-8"}
                        comment={comment}
                        files={files}
                        onChangeComment={(text) => setComment(text)}
                        onChangeFiles={(files) => setFiles(files)}
                      />
                    )}
                  </Box>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </form>
    </Modal>
  );
}
