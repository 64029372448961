import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../ui/input/Input.tsx";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import {
  ApiCalendarHolidayDate,
  ApiCalendarHolidayDateSavePayload,
  ApiCalendarHolidayDateType,
} from "../../../../types/calendar-holiday.types.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import {
  useCalendarHolidayDateSave,
  useCalendarHolidayDatesDelete,
} from "../../../../queries/use-calendar-holidays.query.ts";
import { useCallback } from "react";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import Box from "../../../../ui/box/Box.tsx";
import { Radio } from "../../../../ui/radio/Radio.tsx";
import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import LinkButtonTo from "../../../../ui/link/LinkButtonTo.tsx";

export interface CalendarHolidayEditDateModalProps extends ModalProps {
  calendarHolidayId: number;
  calendarHolidayDate: ApiCalendarHolidayDate;
}

export const CalendarHolidayEditDateModal = ({
  calendarHolidayId,
  calendarHolidayDate,
  ...props
}: CalendarHolidayEditDateModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();
  const modalsService = useModals();

  const calendarHolidaySchema = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
    date: yup.date().required(i18n.t("errors:field_required")),
    type: yup
      .mixed<ApiCalendarHolidayDateType>()
      .oneOf(Object.values(ApiCalendarHolidayDateType))
      .required(),
  });

  type CalendarHolidayDateSchema = yup.InferType<typeof calendarHolidaySchema>;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CalendarHolidayDateSchema>({
    mode: "onSubmit",
    resolver: yupResolver(calendarHolidaySchema),
    values: {
      title: calendarHolidayDate.title,
      date: calendarHolidayDate.date,
      type: calendarHolidayDate.type,
    },
  });
  const calendarHolidayDateSave = useCalendarHolidayDateSave(control);
  const calendarHolidayDateDelete = useCalendarHolidayDatesDelete();

  const onSubmit = handleSubmit(async (schema) => {
    const savePayload: ApiCalendarHolidayDateSavePayload = {
      title: schema.title,
      date: schema.date,
      type: schema.type,
    };
    calendarHolidayDateSave.mutate(
      { calendarHolidayId: calendarHolidayId, id: calendarHolidayDate.id, payload: savePayload },
      {
        onSuccess: (data) => {
          if (calendarHolidayDate.id == 0) {
            analyticsService.trackEvent(analyticEvents.calendarHolidays.holidayCreated, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.calendarHolidays.holidayEdited, {
              [analyticProperties.id]: data.id,
            });
          }
          close(id);
        },
      }
    );
  });

  const handleDelete = useCallback(async () => {
    if (!calendarHolidayDate) return;

    modalsService.openModal(ConfirmationPopup, {
      question: t("common:delete"),
      text: t("common:delete_confirm"),
      acceptTitle: t("common:delete"),
      onAccept: async function () {
        try {
          await calendarHolidayDateDelete.mutateAsync({
            id: calendarHolidayDate.id,
            calendarHolidayId: calendarHolidayId,
          });
          analyticsService.trackEvent(analyticEvents.calendarHolidays.holidayDeleted, {
            [analyticProperties.id]: calendarHolidayDate.id,
          });
          close(id);
        } catch {
          return;
        }
      },
    });
  }, [calendarHolidayDate]);

  return (
    <>
      <Modal
        {...props}
        title={t("core:calendar_holiday_edit_title")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={calendarHolidayDateSave.isPending}
              onClick={onSubmit}
            >
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack>
            <Input
              label={t("common:title")}
              required={true}
              autoFocus={true}
              type="text"
              {...register("title")}
              error={errors.title?.message}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Datepicker
                  value={value}
                  onChange={onChange}
                  required={true}
                  label={t("common:date")}
                  error={errors.date?.message}
                />
              )}
              control={control}
              name={"date"}
            />
            <Stack>
              <Title
                header={t("core:calendar_holiday_date_type")}
                size={"xs"}
                paddingBottom
                paddingTop
              />
              <Box variant={"border"}>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Radio
                        items={"start"}
                        value={ApiCalendarHolidayDateType.non_working}
                        checked={value == ApiCalendarHolidayDateType.non_working}
                        onChange={() => {
                          onChange(ApiCalendarHolidayDateType.non_working);
                        }}
                      >
                        <Title
                          header={t("core:calendar_holiday_date_non_working_title")}
                          caption={t("core:calendar_holiday_date_non_working_description")}
                          size={"sm"}
                        ></Title>
                      </Radio>
                      <Radio
                        items={"start"}
                        value={ApiCalendarHolidayDateType.compensated}
                        checked={value == ApiCalendarHolidayDateType.compensated}
                        onChange={() => {
                          onChange(ApiCalendarHolidayDateType.compensated);
                        }}
                      >
                        <Title
                          header={t("core:calendar_holiday_date_compensated_title")}
                          caption={t("core:calendar_holiday_date_compensated_description")}
                          size={"sm"}
                        ></Title>
                      </Radio>
                      <Radio
                        items={"start"}
                        value={ApiCalendarHolidayDateType.working}
                        checked={value == ApiCalendarHolidayDateType.working}
                        onChange={() => {
                          onChange(ApiCalendarHolidayDateType.working);
                        }}
                      >
                        <Title
                          header={t("core:calendar_holiday_date_working_title")}
                          caption={t("core:calendar_holiday_date_working_description")}
                          size={"sm"}
                        ></Title>
                      </Radio>
                    </>
                  )}
                  control={control}
                  name={"type"}
                />
              </Box>
            </Stack>
          </Stack>
          {calendarHolidayDate.id > 0 && (
            <Stack>
              <hr className="border-dark/10" />
              <ListItem
                valueSlot={
                  <LinkButtonTo onClick={handleDelete} variant={"danger"}>
                    {t("common:delete")}
                  </LinkButtonTo>
                }
              ></ListItem>
            </Stack>
          )}
        </form>
      </Modal>
    </>
  );
};
