import { api } from "../axios.ts";
import { ApiLabel, ApiLabelSavePayload } from "../../types/recruit/label.types.ts";

export const apiLabels = {
  getAll: async function () {
    return api.get<ApiLabel[]>("/api/v1/applicant-labels");
  },
  create: async function (payload: ApiLabelSavePayload) {
    return api.post<ApiLabel>("/api/v1/applicant-labels", payload);
  },
  update: async function (id: number, payload: ApiLabelSavePayload) {
    return api.put<ApiLabel>(`/api/v1/applicant-labels/${id}`, payload);
  },
  delete: async function (id: number) {
    return api.delete(`/api/v1/applicant-labels/${id}`);
  },
};
