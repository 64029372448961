import { useId } from "react";
import { twMerge } from "tailwind-merge";
import { SwitchProps } from "./switch.types.ts";
import { useTheme } from "../themes/provider.tsx";
import Stack from "../stack/Stack.tsx";
import Box from "../box/Box.tsx";

export const Switch = ({
  checked,
  disabled,
  onChange,
  className,
  title,
  description,
  size = "md",
  ...props
}: SwitchProps) => {
  const id = useId();
  const toggle = (): void => onChange(!checked);

  const handleClick = (): void => {
    toggle();
  };

  const theme = useTheme("switch");

  return (
    <button
      aria-checked={checked}
      disabled={disabled}
      id={`${id}-switch`}
      onClick={handleClick}
      role="switch"
      tabIndex={0}
      type="button"
      className={twMerge(
        theme.base,
        theme.variants.size[size],
        disabled && theme.disabled,
        className
      )}
      {...props}
    >
      <Box
        className={twMerge(
          theme.toggle.cover.base,
          checked ? theme.toggle.cover.active.on : theme.toggle.cover.active.off,
          disabled && theme.toggle.cover.disabled,
          theme.toggle.cover.variants.size[size]
        )}
      >
        <Box
          className={twMerge(
            theme.toggle.capsule.base,
            checked ? theme.toggle.capsule.active.on : theme.toggle.capsule.active.off,
            disabled && theme.toggle.capsule.disabled,
            theme.toggle.capsule.variants.size[size]
          )}
        >
          <Box
            className={twMerge(
              theme.toggle.circle.base,
              checked ? theme.toggle.circle.active.on : theme.toggle.circle.active.off,
              disabled && theme.toggle.circle.disabled,
              theme.toggle.circle.variants.size[size]
            )}
          />
        </Box>
      </Box>
      {(title || description) && (
        <Stack>
          {title && (
            <Box
              className={twMerge(
                theme.title.base,
                checked ? theme.title.active.on : theme.title.active.off,
                disabled && theme.title.disabled,
                theme.title.variants.size[size]
              )}
            >
              {title}
            </Box>
          )}
          {description && (
            <Box
              className={twMerge(
                theme.description.base,
                checked ? theme.description.active.on : theme.description.active.off,
                disabled && theme.description.disabled,
                theme.description.variants.size[size]
              )}
            >
              {description}
            </Box>
          )}
        </Stack>
      )}
    </button>
  );
};
