import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../types/api.types.ts";
import { useQuery } from "@tanstack/react-query";
import { ApiNotesFilter } from "../types/note.types.ts";
import { ApiNotificationsFilter } from "../types/notification.types.ts";
import { apiNotifications } from "../api/notification.api.ts";

export const notificationsKeys = createQueryKeys("notifications", {
  search: (filter: ApiNotificationsFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiNotifications.searchNotifications(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useNotifications = (filter: ApiNotesFilter, enabled = true) => {
  return useQuery({
    ...notificationsKeys.search(filter),
    enabled: enabled,
  });
};
