import { ComponentProps, ReactNode, useEffect, useState } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip.tsx";
import Stack from "../stack/Stack.tsx";
import Box from "../box/Box.tsx";
import { twMerge } from "tailwind-merge";
import Text from "../typography/Text.tsx";
import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import ButtonIcon from "../button/ButtonIcon.tsx";
import { useEmployeeStore } from "../../stores/employee.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";

export enum NewFeatures {
  mass_assign = "ATS: Mass Assign",
}

export interface NewFeatureTooltipProps extends ComponentProps<"div"> {
  title: string;
  description: string;
  feature: NewFeatures;
  preventShowIfEmployeeIdGreater?: number;
  children: ReactNode;
}

export const NewFeatureTooltip = ({
  children,
  title,
  feature,
  description,
  preventShowIfEmployeeIdGreater,
}: NewFeatureTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { employee } = useEmployeeStore();

  useEffect(() => {
    if (preventShowIfEmployeeIdGreater && !employee) {
      return;
    }
    if (
      preventShowIfEmployeeIdGreater &&
      employee &&
      employee.id > preventShowIfEmployeeIdGreater
    ) {
      return;
    }
    const hasSeen = localStorage.getItem(`new-feature-seen-${feature}`);
    if (!hasSeen) {
      setIsOpen(true); // Show tooltip if not seen
      analyticsService.trackEvent(analyticEvents.pageActions.newFeatureTooltipViewed, {
        [analyticProperties.id]: feature,
      });
    }
  }, [feature, preventShowIfEmployeeIdGreater, employee]);

  const closeTooltip = () => {
    setIsOpen(false);
    localStorage.setItem(`new-feature-seen-${feature}`, "true"); // Mark as seen
  };

  return (
    <Tooltip delay={false} open={isOpen}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent className={"max-w-96 p-3"}>
        <Stack gap={"xs"}>
          <Stack justify={"between"} items={"center"} direction={"horizontal"}>
            <Stack direction={"horizontal"} gap={"sm"} items={"center"}>
              <Box
                className={twMerge(
                  "text-sm font-semibold px-2 py-1 border rounded-md align-middle flex items-center bg-light text-dark"
                )}
              >
                <label>NEW</label>
              </Box>
              <Text className="text-light font-semibold">{title}</Text>
            </Stack>
            <ButtonIcon
              onClick={closeTooltip}
              variant={"plain"}
              className={"text-light"}
              icon={<FontAwesomeIcon icon={"fa-light fa-close"} />}
            />
          </Stack>
          <Text className="text-light/80">{description}</Text>
        </Stack>
      </TooltipContent>
    </Tooltip>
  );
};
