export enum ApiFilePathTypes {
  applicant = "applicant",
  candidate = "candidate",
  cv = "cv",
  other = "other",
  employee = "employee",
  documents = "documents",
  applicant_photo = "applicant_photo",
  background = "background",
  organization = "organization",
}

export interface ApiFileLink {
  download_url: string;
}
