import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiEventFilter, ApiEventSavePayload } from "../types/event.types.ts";
import { apiEvents } from "../api/event.api.ts";
import { ApiErrorException } from "../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";

export const eventsKeys = createQueryKeys("event", {
  filter: (filter: ApiEventFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiEvents.filter(filter, signal);

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
  }),
});

export const useEvents = (filter: ApiEventFilter, enabled: boolean = true) => {
  return useQuery({
    ...eventsKeys.filter(filter),
    enabled: enabled,
  });
};

export const useEventsSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, payload }: { id?: number; payload: ApiEventSavePayload }) => {
      const { data, error } = id
        ? await apiEvents.update(id, payload)
        : await apiEvents.create(payload);

      if (error || !data) throw new ApiErrorException(error);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: eventsKeys.filter._def });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useEventsDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (eventId: number) => {
      const { success, error } = await apiEvents.delete(eventId);

      if (error || !success) throw new ApiErrorException(error);

      return success;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: eventsKeys.filter._def });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
