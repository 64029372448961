import { AppThemeBase } from "../theme.types.ts";

export interface AppThemeSidebar extends AppThemeBase {
  menu: {
    base: string;
  };
  sidebar: {
    base: string;
  };
  menuItem: {
    base: string;
    active: {
      on: string;
      off: string;
    };
  };
  sidebarItem: {
    base: string;
    active: {
      on: string;
      off: string;
    };
    dimmed: string;
  };
  sidebarItemText: {
    active: {
      on: string;
      off: string;
    };
  };
  sidebarItemCount: {
    active: {
      on: string;
      off: string;
    };
  };
}

export const sidebarTheme: AppThemeSidebar = {
  base: " ",
  menu: {
    base: "",
  },
  sidebar: {
    base: "",
  },
  menuItem: {
    base: "w-8 flex flex-col items-center justify-center text-dark",
    active: {
      on: "",
      off: "cursor-pointer",
    },
  },
  sidebarItem: {
    base: "h-7 rounded-md flex items-center w-full px-2",
    active: {
      on: "bg-light",
      off: "hover:bg-light",
    },
    dimmed: "text-dark/60",
  },
  sidebarItemText: {
    active: {
      on: "text-dark/95 font-semibold",
      off: "text-dark/95",
    },
  },
  sidebarItemCount: {
    active: {
      on: "font-dark/80 text-sm",
      off: "text-dark/60 text-sm",
    },
  },
};
