import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import Modal from "../../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import Stack from "../../../../../ui/stack/Stack.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import useModals from "../../../../../ui/modal/modal.store.ts";
import { Tabs } from "../../../../../ui/tabs/Tabs.tsx";
import { TabItem } from "../../../../../ui/tabs/TabItem.tsx";
import { Input } from "../../../../../ui/input/Input.tsx";
import { RichEditor } from "../../../../../ui/rich-editor/RichEditor.tsx";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import * as yup from "yup";
import {
  ApiDataProtectionProviderSettings,
  ApiDataProtectionSettingsPayload,
  DataProtectionConfigureTab,
  DataProtectionTypes,
} from "../../../../../types/data-protection.types.ts";
import { Controller, useForm } from "react-hook-form";
import { useDataProtectionSettingsSave } from "../../../../../queries/use-data-protection.query.ts";
import { Switch } from "../../../../../ui/switch/Switch.tsx";
import analyticsService, { analyticEvents } from "../../../../../services/analytics-service.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import useDataProtectionHook from "../../../../../hooks/use-data-protection.hook.ts";
import { PlaceholderOption } from "../../../../../ui/rich-editor/rich-editor.types.ts";

export interface DataProtectionConfigureModalProps extends ModalProps {
  data_protection_type: DataProtectionTypes;
  tab: DataProtectionConfigureTab;
  settings?: ApiDataProtectionProviderSettings | null | undefined;
}

export function DataProtectionConfigureModal({
  data_protection_type,
  tab,
  settings,
  ...props
}: DataProtectionConfigureModalProps) {
  const { id } = props;
  const { t } = useTranslation();
  const { close } = useModals();
  const { getTitleByType } = useDataProtectionHook();
  const placeholders: PlaceholderOption[] = [
    {
      title: t("common:duration"),
      value: "[data protection duration]",
    },
    {
      title: t("data_protection:privacy_policy_link"),
      value: "[data protection privacy policy link]",
    },
    {
      title: t("data_protection:request_to_edit"),
      value: "[data protection request to edit]",
    },
    {
      title: t("data_protection:request_to_delete"),
      value: "[data protection request to delete]",
    },
    {
      title: t("common:company"),
      value: "[company name]",
    },
  ];
  const DataProtectionSchemaObject = yup.object({
    data_protection_type: yup.string().required(),
    privacy_policy_link: yup.string().required(),
    retention_period_days: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : Number(value);
      })
      .typeError(t("api_errors:api_error_must_integer")) // Ошибка, если это не число
      .required()
      .integer(t("api_errors:api_error_must_integer"))
      .min(1, t("api_errors:api_error_minimum_value_must", { minimum: 1 }))
      .max(3650, t("api_errors:api_error_maximum_value_must", { maximum: 3650 })),
    email_template: yup.string().nullable(),
    calendar_full_names: yup.bool().nullable(),
  });

  interface DataProtectionSchema extends yup.InferType<typeof DataProtectionSchemaObject> {}

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataProtectionSchema>({
    mode: "onSubmit",
    resolver: yupResolver(DataProtectionSchemaObject),
    defaultValues: {
      data_protection_type: DataProtectionTypes.gdpr,
      email_template: settings?.email_template ?? "",
      calendar_full_names: settings?.calendar_full_names ?? false,
      retention_period_days: settings?.retention_period_days ?? 0,
      privacy_policy_link: settings?.privacy_policy_link ?? "",
    },
  });

  const dataProtectionUpdate = useDataProtectionSettingsSave(control);

  const onSubmit = handleSubmit(async (schema) => {
    const settingsPayload: ApiDataProtectionSettingsPayload = {
      data_protection_type,
      enabled: true,
      email_template: schema.email_template ?? "",
      retention_period_days: schema.retention_period_days,
      privacy_policy_link: schema.privacy_policy_link,
      calendar_full_names: !!schema.calendar_full_names,
    };

    try {
      await dataProtectionUpdate.mutateAsync(settingsPayload);

      analyticsService.trackEvent(analyticEvents.dataProtection.settingsEdited);

      close(id);
    } catch {
      return;
    }
  });

  return (
    <Modal
      title={t("data_protection:settings_title", {
        type_title: getTitleByType(data_protection_type),
      })}
      withCloser={true}
      {...props}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />

          <Button
            type={"submit"}
            size={"lg"}
            isLoading={dataProtectionUpdate.isPending}
            onClick={onSubmit}
          >
            {t("common:save")}
          </Button>
          <Button
            variant={"plain"}
            size={"lg"}
            onClick={() => {
              close(id);
            }}
          >
            {t("common:cancel")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Tabs>
          <TabItem title={t("common:settings")}>
            <Stack className={"pt-6"} gap={"md"}>
              <Stack>
                <Input
                  type={"number"}
                  label={t("common:period")}
                  {...register("retention_period_days")}
                  error={errors.retention_period_days?.message}
                />

                <Input
                  type={"text"}
                  label={t("data_protection:privacy_policy_link")}
                  {...register("privacy_policy_link")}
                  error={errors.privacy_policy_link?.message}
                />
              </Stack>

              <Controller
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    onChange={(newValue) => {
                      onChange(newValue);
                    }}
                    size={"md"}
                    title={t("data_protection:allow_sync_with_external_service")}
                    description={t("data_protection:allow_sync_with_external_service_description")}
                  />
                )}
                control={control}
                name={"calendar_full_names"}
              />
            </Stack>
          </TabItem>
          <TabItem title={t("core:email_template")} active={tab === "email_template"}>
            <Stack className={"py-6 min-h-40"} gap={"lg"}>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <RichEditor
                    value={value || ""}
                    onChange={onChange}
                    placeholderOptions={placeholders}
                    placeholder={t("core:email_enter_body")}
                    error={errors.email_template?.message}
                  />
                )}
                control={control}
                name={"email_template"}
              />
            </Stack>
          </TabItem>
        </Tabs>
      </form>
    </Modal>
  );
}
