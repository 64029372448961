import { ApiCountry, ApiLocation } from "./location.types.ts";

export interface ApiCalendarHoliday {
  id: number;
  title: string;
  country: ApiCountry;
  locations: ApiLocation[];
  // datetime_created_at: str
  // datetime_updated_at: str
}

export interface ApiCalendarHolidaySavePayload {
  title: string;
  country_id: number;
  location_ids: number[];
  year?: number;
}

export const calendarHolidayMock: ApiCalendarHoliday = {
  id: 0,
  title: "",
  country: {
    id: 0,
    title: "",
  },
  locations: [],
};

export enum ApiCalendarHolidayDateType {
  working = "working",
  non_working = "non_working",
  compensated = "compensated",
}

export interface ApiCalendarHolidayDate {
  id: number;
  title: string;
  date: Date;
  type: ApiCalendarHolidayDateType;
}

export interface ApiCalendarHolidayDateSavePayload {
  date: Date;
  type: ApiCalendarHolidayDateType;
  title: string;
}

export interface ApiCalendarHolidayCopy {
  year_from: number;
  year_to: number;
}

export const calendarHolidayDateMock: ApiCalendarHolidayDate = {
  id: 0,
  title: "",
  date: new Date(),
  type: ApiCalendarHolidayDateType.non_working,
};
