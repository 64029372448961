import { apiFiles } from "../api/file.api.ts";
import useErrorHandle from "./use-error-handle.hook.tsx";

export default function useFile() {
  const { handleErrors } = useErrorHandle();

  async function openFileByUUID(uuid: string) {
    const { data: signedLink, error } = await apiFiles.getLink(uuid, 1);

    if (signedLink && signedLink.download_url) {
      window.open(signedLink.download_url, "_blank")?.focus();
    } else {
      handleErrors(error);
    }
  }

  return {
    openFileByUUID,
  };
}
