import { twMerge } from "tailwind-merge";
import { useTheme } from "../themes/provider.tsx";
import { TextProps } from "./text.types.ts";

export default function Text({ as = "span", children, size, className, ...props }: TextProps) {
  const theme = useTheme("text");
  const Component = as;
  return (
    <>
      <Component
        className={twMerge(theme.base, size ? theme.variants.size[size] : undefined, className)}
        {...props}
      >
        {children}
      </Component>
    </>
  );
}
