import { ApiFilterBase } from "./filter.types.ts";
import { ApiApplicantBase } from "./recruit/applicant.types.ts";

export interface ApiApplicantDataProtection {
  type: DataProtectionTypes;
  state: DataProtectionState;
  datetime_sent?: Date;
  datetime_state_start: Date;
  datetime_state_end: Date;
}

export interface ApiDataProtectionRequest extends ApiApplicantBase {
  data_protection: ApiApplicantDataProtection[];
}

export interface ApiDataProtectionRequestItem extends ApiApplicantBase {
  data_protection: ApiApplicantDataProtection;
}

export interface ApiDataProtectionProvider {
  data_protection_type: DataProtectionTypes;
  settings?: ApiDataProtectionProviderSettings | null;
}

export interface ApiDataProtectionProviderSettings {
  enabled: boolean;
  email_template: string;
  privacy_policy_link: string;
  retention_period_days: number;
  calendar_full_names: boolean;
}

export interface ApiDataProtectionSettingsPayload extends ApiDataProtectionProviderSettings {
  data_protection_type: DataProtectionTypes;
}

export interface ApiDataProtectionIncreaseDaysPayload {
  data_protection_type: DataProtectionTypes;
  additional_days: number;
}

export interface ApiDataProtectionConsentRequestPayload {
  data_protection_type: DataProtectionTypes;
  object_id: number;
  object_type: DataProtectionObjectTypes;
}

export enum DataProtectionObjectTypes {
  applicant = "applicant",
}

export enum DataProtectionTypes {
  gdpr = "gdpr",
  fz152 = "fz152",
}

export enum DataProtectionState {
  empty = "empty",
  requested = "requested",
  given = "given",
  expiring = "expiring",
  expired = "expired",
  declined = "declined",
}

export enum DataProtectionConfigureTab {
  settings = "base",
  email_template = "email_template",
  automation = "automation",
}

export enum DataProtectionResponsePage {
  accept = "accept",
  decline = "decline",
  deleteRequest = "delete-request",
  editRequest = "edit-request",
}

export interface ApiDataProtectionRequestsFilter extends ApiFilterBase {
  text?: string;
  states?: DataProtectionState[];
}

export interface ApiDataProtectionResponsePayload {
  uuid: string;
  object_id: string;
  comment?: string;
}

export interface DataProtectionSettingsProps {
  tab: DataProtectionSettingsTabs;
}

export enum DataProtectionSettingsTabs {
  settings = "settings",
  requests = "requests",
}

export interface DataProtectionConsentData {
  email_template: string;
  privacy_policy_link: string;
  delete_link: string;
  subject: string;
  preheader: string;
  header: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  button_text1: string;
  button_text2: string;
  button_text3: string;
  text6: string;
  text7: string;
}
