import PageHeader from "../../page/PageHeader.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import { SidebarControls } from "./SidebarControls.tsx";

export interface SidebarHeaderProps {
  title: string;
}

export function SidebarHeader({ title }: SidebarHeaderProps) {
  return (
    <Stack direction={"horizontal"} items={"center"} justify={"between"} className={"pr-6"}>
      <PageHeader title={title} />
      <SidebarControls />
    </Stack>
  );
}
