import { createContext, ReactNode, useContext, useEffect, useMemo } from "react";
import { UIKitProvider } from "../ui/themes/provider.tsx";
import { useThemeStore } from "../stores/theme.store.ts";
import { applyThemeConfig } from "./utils.ts";
import { AppTheme, AppThemeBundle, AppThemeKey, AppThemes, getAppTheme } from "./theme.types.ts";

export interface AppContextProps {
  theme: AppThemeBundle;
}

const AppContext = createContext<AppContextProps>({
  theme: AppThemes.default,
});

export interface AppProviderProps {
  children: ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  const { theme, setTheme } = useThemeStore();

  const context: AppContextProps = useMemo(() => {
    let appThemeBundle = getAppTheme(theme);

    if (appThemeBundle == undefined) {
      setTheme(null);
      appThemeBundle = getAppTheme(null);
    }
    return {
      theme: appThemeBundle,
    };
  }, [theme]);

  useEffect(() => {
    applyThemeConfig(context.theme.appThemeConfig.name);
    document.body.classList.remove(...document.body.classList);
    document.body.className = context.theme.appTheme.body.base;
  }, [theme]);

  return (
    <AppContext.Provider value={context}>
      <UIKitProvider uiKitTheme={context.theme.uiKitTheme}>{children}</UIKitProvider>
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContext");
  }
  return context;
}

export function useAppTheme<T extends AppThemeKey>(key: T): AppTheme[T] {
  const context = useAppContext();
  return useMemo(() => {
    const theme = context.theme.appTheme[key];
    return theme as AppTheme[T];
  }, [key, context]);
}
