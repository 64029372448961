import { DataProtectionResponsePage, DataProtectionTypes } from "../types/data-protection.types.ts";
import { useTranslation } from "react-i18next";

export default function useDataProtectionHook() {
  const { t } = useTranslation();

  function getTitleByType(type: DataProtectionTypes) {
    switch (type) {
      case DataProtectionTypes.gdpr:
        return t("data_protection:type_gdpr");
      case DataProtectionTypes.fz152:
        return t("data_protection:type_fz152");
    }
  }

  function getDataForResponsePage(page: DataProtectionResponsePage) {
    switch (page) {
      case DataProtectionResponsePage.accept:
        return {
          title: t("data_protection:request_accept"),
          description: "",
          btn: t("data_protection:send_request"),
          analyticsPage: "Applied",
          finalTitle: t("data_protection:response_sent_accept_title"),
          finalText: t("data_protection:response_sent_accept_content"),
        };
      case DataProtectionResponsePage.decline:
        return {
          title: t("data_protection:request_decline"),
          description: "",
          btn: t("data_protection:send_request"),
          analyticsPage: "Declined",
          finalTitle: t("data_protection:response_sent_decline_title"),
          finalText: t("data_protection:response_sent_decline_content"),
        };
      case DataProtectionResponsePage.deleteRequest:
        return {
          title: t("data_protection:request_delete"),
          description: t("data_protection:request_delete_description"),
          btn: t("data_protection:send_request"),
          analyticsPage: "Request To Delete",
          finalTitle: t("data_protection:response_sent_delete_title"),
          finalText: t("data_protection:response_sent_delete_content"),
        };
      case DataProtectionResponsePage.editRequest:
        return {
          title: t("data_protection:request_edit"),
          description: t("data_protection:request_edit_description"),
          btn: t("data_protection:send_request"),
          analyticsPage: "Request To Edit",
          finalTitle: t("data_protection:response_sent_edit_title"),
          finalText: t("data_protection:response_sent_edit_content"),
        };
    }
  }

  return { getTitleByType, getDataForResponsePage };
}
