import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException, ApiPassException } from "../types/api.types.ts";
import { apiApprovalPolicies } from "../api/approval-policy.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { ApiApprovalPolicyPayload } from "../types/approval-policy.types.ts";

export const approvalPoliciesKeys = createQueryKeys("approvalPolicies", {
  allPolicies: null,
  policy: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("Policy id is required");

      const result = await apiApprovalPolicies.getPolicyById(id);

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
  }),
});

export const useApprovalPoliciesAll = () => {
  return useQuery({
    ...approvalPoliciesKeys.allPolicies,
    queryFn: async () => {
      const result = await apiApprovalPolicies.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
  });
};

export const useApprovalPolicyDetails = (id: number | undefined, enabled: boolean = true) => {
  return useQuery({
    ...approvalPoliciesKeys.policy(id),
    enabled: enabled && !!id,
  });
};

export interface ApprovalPolicySaveParams {
  id: number;
  payload: ApiApprovalPolicyPayload;
}

export const useApprovalPolicySave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async function ({ id, payload }: ApprovalPolicySaveParams) {
      const result =
        id == 0
          ? await apiApprovalPolicies.createPolicy(payload)
          : await apiApprovalPolicies.updatePolicy(id, payload);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (policy) => {
      queryClient.setQueryData(approvalPoliciesKeys.policy(policy.id).queryKey, policy);
      queryClient.invalidateQueries({
        queryKey: approvalPoliciesKeys.allPolicies.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useApprovalDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function (policyId: number) {
      const { success, error } = await apiApprovalPolicies.deletePolicy(policyId);

      if (error || !success) throw new ApiErrorException(error);

      return { policyId };
    },
    onSuccess: ({ policyId }) => {
      queryClient.setQueryData(approvalPoliciesKeys.policy(policyId).queryKey, undefined);
      queryClient.invalidateQueries({
        queryKey: approvalPoliciesKeys.allPolicies.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
