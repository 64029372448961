import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import { useApplicantsSearchInfinite } from "../../../../queries/recruit/use-applicants.query.ts";
import Table from "../../../../ui/table/Table.tsx";
import { useCallback, useMemo, useRef } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ApiApplicantShort } from "../../../../types/recruit/applicant.types.ts";
import Text from "../../../../ui/typography/Text.tsx";
import Box from "../../../../ui/box/Box.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import { Link } from "react-router-dom";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";

export interface PreviewCandidatesModalProps extends ModalProps {
  applicantIds: number[];
}

export const PreviewCandidatesModal = ({ applicantIds, ...props }: PreviewCandidatesModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  //https://www.mantine-react-table.com/docs/examples/infinite-scrolling
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const {
    data: applicants,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useApplicantsSearchInfinite({
    applicant_ids: applicantIds,
    page_number: 1,
  });

  //flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () => applicants?.pages?.flatMap((page) => page?.items ?? []) ?? [],
    [applicants]
  );

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (scrollHeight: number, scrollTop: number, clientHeight: number) => {
      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isFetching, hasNextPage, isFetchingNextPage]
  );

  const columns = useMemo<ColumnDef<ApiApplicantShort>[]>(
    () => [
      {
        header: t("core:name"),
        cell: (row) => (
          <Link to={`/recruitment/candidates/${row.row.original.id}/`} target={"_blank"}>
            <Stack direction={"horizontal"} gap={"md"} items={"center"}>
              <Avatar
                url={row.row.original.photo_url}
                acronym={`${row.row.original.first_name} ${row.row.original.last_name}`}
                size={"md"}
              />
              <Text
                className={"text-title"}
              >{`${row.row.original.first_name} ${row.row.original.last_name}`}</Text>
            </Stack>
          </Link>
        ),
        accessorKey: "title",
      },
      {
        header: t("core:position"),
        cell: (row) => row.row.original.current_position.position,
        accessorKey: "position",
      },
      {
        header: t("common:company"),
        cell: (row) => row.row.original.current_position.company,
        accessorKey: "department",
      },
    ],
    [flatData]
  );
  return (
    <Modal
      {...props}
      layout="stretch"
      size={"xl"}
      title={`${t("plurals:plural_candidates", { count: applicantIds.length })}`}
      withCloser
      closeByEscEnabled
      onModalContentScroll={(scrollTop, scrollHeight, height) => {
        fetchMoreOnBottomReached(scrollHeight, scrollTop, height);
      }}
      actions={
        <Stack gap={"sm"}>
          <Button onClick={() => close(id)} size={"lg"} className={"w-full"}>
            {t("common:close")}
          </Button>
        </Stack>
      }
    >
      <Box className={"relative"}>
        <Stack className={"overflow-hidden"}>
          <Box ref={tableContainerRef} className={"overflow-y-auto h-full"}>
            <Table data={flatData} columns={columns} showSkeleton={isLoading} />
            {isFetching && <Skeleton className={"h-10 w-full"} />}
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
