import { ApiFile } from "../common.types.ts";

export enum ApiIntegrationType {
  gmail = "gmail",
  google_calendar_service_account = "google-calendar-service-account",
  slack = "slack",
}

export interface ApiIntegration {
  is_active: boolean;
  identity: string;
  integration_type: ApiIntegrationType;
  datetime_last_check: Date;
  credentials?: string;
}

export interface ApiIntegrationLoginUrl {
  authorization_url: string;
}

export interface ApiEmailSendPayload {
  candidate_id: number;
  from_email: string;
  to_email: string;
  subject: string;
  body: string;
  cc_email?: string;
  bcc_email?: string;
  file_ids?: number[];
  thread_id?: string;
}

export interface ApiEmail {
  id: number;
  // owner_id: number;
  candidate_id: number;
  from_email: string;
  to_email: string;
  subject: string;
  body: string;
  is_outgoing: boolean;
  cc_email: string;
  bcc_email: string;
  // original_message_id: string;
  original_thread_id: string;
  // original_created_at: Date;
  files: ApiFile[];
  is_unread: boolean;
}

export interface ApiIntegrationGoogleCalendarSave {
  is_active: boolean;
  credentials?: string;
  identity?: string;
}
