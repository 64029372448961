import { api } from "./axios.ts";
import {
  ApiAction,
  ApiActionCreateByFilterPayload,
  ApiActionPayload,
} from "../types/action.types.ts";

export const apiCandidateActions = {
  getAll: (candidateId: number) => {
    return api.get<ApiAction[]>(`/api/v1/candidate-actions/${candidateId}`);
  },
  create: (candidateId: number, payload: ApiActionPayload) => {
    return api.post<ApiAction>(`/api/v1/candidate-actions/${candidateId}`, payload);
  },
  update: (candidateId: number, actionId: number, payload: ApiActionPayload) => {
    return api.put<ApiAction>(`/api/v1/candidate-actions/${candidateId}/${actionId}`, payload);
  },
  delete: (candidateId: number, actionId: number) => {
    return api.delete(`/api/v1/candidate-actions/${candidateId}/${actionId}`);
  },
  createByFilter: (payload: ApiActionCreateByFilterPayload) => {
    return api.post(`/api/v1/candidate-actions`, payload);
  },
};
