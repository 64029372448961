import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { Control, FieldValues } from "react-hook-form";
import { ApiActionPayload } from "../../types/action.types.ts";
import { apiApplicantActions } from "../../api/applicant-action.api.ts";

export const applicantActionsKeys = createQueryKeys("applicantActions", {
  list: (id: number) => ({
    queryKey: [id],
    queryFn: async () => {
      const result = await apiApplicantActions.getAll(id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useApplicantActions = (id: number, enabled: boolean = true) => {
  return useQuery({
    ...applicantActionsKeys.list(id),
    enabled: enabled,
  });
};

export const useApplicantActionCreate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: ApiActionPayload }) => {
      const result = await apiApplicantActions.create(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: (_, variables) => {
      // Обновляем все экшены
      queryClient.invalidateQueries({
        queryKey: applicantActionsKeys.list(variables.id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useApplicantActionUpdate = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({
      id,
      actionId,
      payload,
    }: {
      id: number;
      actionId: number;
      payload: ApiActionPayload;
    }) => {
      const result = await apiApplicantActions.update(id, actionId, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: (_, variables) => {
      // Обновляем все экшены
      queryClient.invalidateQueries({
        queryKey: applicantActionsKeys.list(variables.id).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

interface ActionDeleteParams {
  applicantId: number;
  actionId: number;
}

export const useApplicantActionDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ applicantId, actionId }: ActionDeleteParams) => {
      const result = await apiApplicantActions.delete(applicantId, actionId);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      // Обновляем все экшены
      queryClient.invalidateQueries({
        queryKey: applicantActionsKeys.list(variables.applicantId).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
