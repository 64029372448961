import { api } from "../axios.ts";
import {
  ApiEmployeeTimeOffCategory,
  ApiEmployeeTimeOffRequestsFilter,
} from "../../types/employees/employee.types.ts";
import { ApiPagedData } from "../../types/filter.types.ts";
import { ApiApprovalRequest } from "../../types/approval-request.types.ts";

export const apiEmployeeTimeOffs = {
  getCategories: async function (employeeId: number) {
    return api.get<ApiEmployeeTimeOffCategory[]>(`/api/v1/employees/${employeeId}/time-off`);
  },
  requests: async function (
    employeeId: number,
    filter: ApiEmployeeTimeOffRequestsFilter,
    signal?: AbortSignal
  ) {
    return api.post<ApiPagedData<ApiApprovalRequest>>(
      `/api/v1/employees/${employeeId}/time-off/search`,
      filter,
      signal
    );
  },
};
