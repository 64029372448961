import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";
import Table from "../../../../ui/table/Table.tsx";
import { useCallback, useMemo, useRef } from "react";
import { ColumnDef, createColumnHelper, DisplayColumnDef } from "@tanstack/react-table";
import Text from "../../../../ui/typography/Text.tsx";
import Box from "../../../../ui/box/Box.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import { Link } from "react-router-dom";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";
import { ApiEmployee, ApiEmployeeFilter } from "../../../../types/employees/employee.types.ts";
import { useEmployeesInfinite } from "../../../../queries/employees/use-employees.query.ts";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { getEmployeePositionTitle } from "../../../../types/employees/employee-position.types.ts";

export enum PreviewEmployeesModalColumn {
  birth_date = "birth_date",
}

export interface PreviewEmployeesModalProps extends ModalProps {
  filter: ApiEmployeeFilter;
  addColumns: PreviewEmployeesModalColumn[];
  modalTitle?: string;
  source?: string;
}

export const PreviewEmployeesModal = ({
  filter,
  addColumns,
  modalTitle,
  source,
  ...props
}: PreviewEmployeesModalProps) => {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  //https://www.mantine-react-table.com/docs/examples/infinite-scrolling
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { renderEmployeeName, renderPartialDate } = useFormatter();

  const {
    data: employees,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useEmployeesInfinite(filter);

  //flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () => employees?.pages?.flatMap((page) => page?.items ?? []) ?? [],
    [employees]
  );

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (scrollHeight: number, scrollTop: number, clientHeight: number) => {
      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isFetching, hasNextPage, isFetchingNextPage]
  );

  const columnHelper = createColumnHelper<ApiEmployee>();
  const columns = useMemo<ColumnDef<ApiEmployee>[]>(() => {
    const inner: DisplayColumnDef<ApiEmployee, unknown>[] = [
      columnHelper.display({
        header: t("core:name"),
        cell: (row) => (
          <Link to={`/employees/${row.row.original.id}/`} target={"_blank"}>
            <Stack direction={"horizontal"} gap={"md"} items={"center"}>
              <Avatar
                url={row.row.original.avatar_url}
                acronym={renderEmployeeName(row.row.original)}
                size={"md"}
              />
              <Text className={"text-title"}>{renderEmployeeName(row.row.original)}</Text>
            </Stack>
          </Link>
        ),
      }),
      columnHelper.display({
        header: t("core:position"),
        cell: (cell) => (
          <Text className={"text-default"}>{getEmployeePositionTitle(cell.row.original)}</Text>
        ),
      }),
      columnHelper.display({
        header: t("core:department"),
        cell: (cell) => (
          <Text className={"text-default"}>
            {cell.row.original.employee_position.department?.title}
          </Text>
        ),
      }),
    ];

    if (addColumns.includes(PreviewEmployeesModalColumn.birth_date)) {
      inner.push(
        columnHelper.display({
          header: t("core:birth_date"),
          cell: (cell) => (
            <Text className={"text-default"}>
              {cell.row.original.birth_date ? renderPartialDate(cell.row.original.birth_date) : "—"}
            </Text>
          ),
        })
      );
    }

    return inner;
  }, [flatData]);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employees.previewViewed, {
      [analyticProperties.source]: source,
    });
  });

  return (
    <Modal
      {...props}
      layout="stretch"
      size={"xl"}
      title={
        modalTitle ||
        (employees && employees.pages.length > 0
          ? `${t("plurals:employees_count", { count: employees.pages[0]?.metadata.total_items })}`
          : "")
      }
      withCloser
      closeByEscEnabled
      onModalContentScroll={(scrollTop, scrollHeight, height) => {
        fetchMoreOnBottomReached(scrollHeight, scrollTop, height);
      }}
      actions={
        <Stack gap={"sm"}>
          <Button onClick={() => close(id)} size={"lg"} className={"w-full"}>
            {t("common:close")}
          </Button>
        </Stack>
      }
    >
      <Box className={"relative"}>
        <Stack className={"overflow-hidden"}>
          <Box ref={tableContainerRef} className={"overflow-y-auto h-full"}>
            <Table data={flatData} columns={columns} showSkeleton={isLoading} />
            {!isLoading && isFetching && <Skeleton className={"h-10 w-full"} />}
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
