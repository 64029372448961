import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../../types/api.types.ts";
import {
  ApiAnalyticsRecruitingFilter,
  ApiAnalyticsRecruitingStageFlowFilter,
  ApiAnalyticsRecruitingStagesFlowReport,
} from "../../types/analytics/analytics.recruiting.types.ts";
import { useQuery } from "@tanstack/react-query";
import { apiAnalytics } from "../../api/analytics/analytics.recruiting.api.ts";

const recruitAnalyticsKeys = createQueryKeys("recruit.analytics", {
  stagesFlow: (filter: ApiAnalyticsRecruitingStageFlowFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }): Promise<ApiAnalyticsRecruitingStagesFlowReport | undefined> => {
      const result = await apiAnalytics.recruiting.stagesFlowReport(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  stageFlow: (filter: ApiAnalyticsRecruitingFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiAnalytics.recruiting.stageFlowReport(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  sourceEfficiency: (filter: ApiAnalyticsRecruitingFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiAnalytics.recruiting.sourceEfficiencyReport(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  closingTimeReport: (filter: ApiAnalyticsRecruitingFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiAnalytics.recruiting.closingTimeReport(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),

  disqualificationReasons: (filter: ApiAnalyticsRecruitingFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiAnalytics.recruiting.disqualificationReasonsReport(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useRecruitAnalyticsStagesFlow = (filter: ApiAnalyticsRecruitingStageFlowFilter) => {
  return useQuery({
    ...recruitAnalyticsKeys.stagesFlow(filter),
  });
};
export const useRecruitAnalyticsStageFlow = (filter: ApiAnalyticsRecruitingFilter) => {
  return useQuery({
    ...recruitAnalyticsKeys.stageFlow(filter),
  });
};

export const useRecruitAnalyticsSourceEfficiencyReport = (filter: ApiAnalyticsRecruitingFilter) => {
  return useQuery({
    ...recruitAnalyticsKeys.sourceEfficiency(filter),
  });
};

export const useRecruitAnalyticsClosingTimeReport = (filter: ApiAnalyticsRecruitingFilter) => {
  return useQuery({
    ...recruitAnalyticsKeys.closingTimeReport(filter),
  });
};

export const useRecruitAnalyticsDisqualificationReasonsReport = (
  filter: ApiAnalyticsRecruitingFilter
) => {
  return useQuery({
    ...recruitAnalyticsKeys.disqualificationReasons(filter),
  });
};
