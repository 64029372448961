import { ApiFilterBase } from "../filter.types.ts";
import { ApiPermissions, ApiRoleShort, EmployeePermissions } from "../role.types.ts";
import { SelectItem } from "../../ui/select/select.types.ts";
import { ApiEmployeePosition } from "./employee-position.types.ts";
import { ApiOrganizationSettings, Languages } from "../organization.types.ts";
import { ApiCountry } from "../location.types.ts";
import { ApiPartialDate } from "../common.types.ts";
import { ApiApprovalRequestTimeOffData } from "../approval-request.types.ts";
import { ApiTimeOffCategoryShort } from "../time-off.types.ts";

export interface ApiAuthorization {
  access_token: string;
}

export interface ApiUserSignupPayload {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  organization_title: string;
  job_title: string;
  language: string;
  timezone: string;
}

export enum EmployeeFullNameRenderType {
  first_last = "first_last",
  last_first = "last_first",
  f_last = "f_last",
}

export enum EmployeeGender {
  male = "male",
  female = "female",
}

export interface ApiEmployeeShort {
  id: number;
  first_name: string;
  last_name: string;
  middle_name?: string;
  avatar_url?: string;
  background_url?: string;
}

export interface ApiEmployeeWithRoles {
  employee: ApiEmployee;
  roles: ApiRoleShort[];
}

export interface ApiEmployeeBase extends ApiEmployeeShort {
  email: string;
  email_personal?: string;
  date_hired_on: Date;
  date_termination?: Date;
  phone?: string;
  phone_personal?: string;
  birth_date?: ApiPartialDate;
  gender?: EmployeeGender;
  color_palette?: string[];
  personnel_number?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: ApiCountry;
  salary?: number;
  salary_description?: string;
}

export interface ApiEmployee extends ApiEmployeeBase {
  employee_position: ApiEmployeePosition;
  permissions: EmployeePermissions;

  time_off?: ApiApprovalRequestTimeOffData;
}

export interface ApiMeEmployee extends ApiEmployeeBase {
  language?: Languages;
  timezone?: string;
  organization_id: number;
  organization: ApiOrganizationSettings;
  email_confirmed: boolean;
  available_languages: string[];
  birthday_display?: BirthdayDisplayType;
  theme?: string;

  permissions: ApiPermissions;
}

export enum ApiEmployeeFilterSort {
  date_birth = "date_birth",
}

export interface ApiEmployeeFilter extends ApiFilterBase {
  text?: string;
  manager_ids?: number[];
  division_ids?: number[];
  department_ids?: number[];
  position_ids?: number[];
  employee_status?: ApiEmployeeStatus;
  sort_by?: ApiEmployeeFilterSort | null;
  exclude_ids?: number[];
}

export enum ApiEmployeeStatus {
  active = "active",
  dismissed = "dismissed",
  all = "all",
}

export interface ApiEmployeeEditPayload {
  last_name?: string | null;
  first_name?: string | null;
  middle_name?: string | null;
  email_personal?: string | null;
  email?: string | null;
  date_hired_on?: Date | null;
  phone_personal?: string | null;
  phone?: string | null;
  date_birth?: Date | null;
  gender?: EmployeeGender | string | null;
  personnel_number?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country_id?: number | null;
  salary?: number | null;
  salary_description?: string | null;
}

export type EmployeeGenderItem = SelectItem;

export const EmployeeGenders: EmployeeGenderItem[] = [
  {
    id: EmployeeGender.male,
    title: "common:gender_male",
  },
  {
    id: EmployeeGender.female,
    title: "common:gender_female",
  },
];

export interface ApiEmployeeAccountSettingsSavePayload {
  language?: Languages;
  timezone?: string;
  birthday_display?: BirthdayDisplayType;
}

export const EmployeeFilterDefault: ApiEmployeeFilter = {
  page_number: 1,
  text: "",
  employee_status: ApiEmployeeStatus.active,
};

export enum BirthdayDisplayType {
  full = "full",
  short = "short",
  none = "none",
}

export interface ApiEmployeeTimeOffCategory extends ApiTimeOffCategoryShort {
  balance: {
    available_balance: string;
  };
}

export interface ApiEmployeeTimeOffRequestsFilter extends ApiFilterBase {
  category_id?: number;
}
