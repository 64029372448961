import FontAwesomeIcon from "../typography/FontAwesomeIcon.tsx";
import { useState } from "react";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { twMerge } from "tailwind-merge";
import { DropdownMenuProps } from "./dropdown-menu.types.ts";
import { useTheme } from "../themes/provider.tsx";
import ButtonIcon from "../button/ButtonIcon.tsx";

export default function DropdownMenu({ trigger, children, className }: DropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(5), flip(), shift()],
  });

  const dismiss = useDismiss(context, { bubbles: true });
  // const click = useClick(context);
  const role = useRole(context, { role: "menu" });
  const click = useClick(context, {
    event: "mousedown",
    toggle: true,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const theme = useTheme("dropdown");

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        })}
        className={className}
      >
        {trigger ? (
          trigger
        ) : (
          <ButtonIcon
            variant={"secondary"}
            icon={<FontAwesomeIcon icon="fa-light fa-ellipsis" />}
          />
        )}
      </div>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className={twMerge(theme.base, "z-popover")}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}
