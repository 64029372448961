import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException, ApiPassException } from "../types/api.types.ts";
import { apiTimeOffers } from "../api/time-off.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import {
  ApiTimeOffAccrualsFilter,
  ApiTimeOffAdjustmentPayload,
  ApiTimeOffCategoryPayload,
  ApiTimeOffPolicyPayload,
} from "../types/time-off.types.ts";
import { ApiApprovalRequestPayload } from "../types/approval-request.types.ts";
import { apiApprovalRequests } from "../api/approval-request.api.ts";
import { employeesKeys } from "./employees/use-employees.query.ts";

export const timeOffsKeys = createQueryKeys("timeOffs", {
  allCategories: null,
  accruals: (filter: ApiTimeOffAccrualsFilter) => ({
    queryKey: [filter],
    queryFn: async ({ signal }) => {
      const result = await apiTimeOffers.accrualsSearch(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
  category: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("Category id is required");

      const result = await apiTimeOffers.getCategoryById(id);

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
  }),
  policy: (id: number | undefined) => ({
    queryKey: [id],
    queryFn: async () => {
      if (id == undefined) throw new ApiPassException("Policy id is required");

      const result = await apiTimeOffers.getPolicyById(id);

      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
  }),
  check: (payload: ApiApprovalRequestPayload | undefined) => ({
    queryKey: ["check", payload],
    queryFn: async () => {
      if (!payload) throw new ApiPassException("Payload is required");

      const result = await apiApprovalRequests.check(payload);
      if (result.error) throw new ApiErrorException(result.error);

      return result.data;
    },
  }),
});

export const useTimeOffCategories = () => {
  return useQuery({
    ...timeOffsKeys.allCategories,
    queryFn: async () => {
      const result = await apiTimeOffers.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
  });
};

export const useTimeOffAccruals = (filter: ApiTimeOffAccrualsFilter) => {
  return useQuery({
    ...timeOffsKeys.accruals(filter),
  });
};

export const useTimeOffCategoryDetails = (id: number | undefined, enabled: boolean = true) => {
  return useQuery({
    ...timeOffsKeys.category(id),
    enabled: enabled && !!id,
  });
};

export const useTimeOffPolicyDetails = (id: number | undefined, enabled: boolean = true) => {
  return useQuery({
    ...timeOffsKeys.policy(id),
    enabled: enabled && !!id,
  });
};

export interface TimeOffSaveCategoryParams {
  id: number;
  payload: ApiTimeOffCategoryPayload;
}

export const useTimeOffSaveCategory = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async function ({ id, payload }: TimeOffSaveCategoryParams) {
      const result =
        id == 0 ? await apiTimeOffers.create(payload) : await apiTimeOffers.update(id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (timeOff) => {
      queryClient.setQueryData(timeOffsKeys.category(timeOff.id).queryKey, timeOff);
      queryClient.invalidateQueries({
        queryKey: timeOffsKeys.allCategories.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useTimeOffDeleteCategory = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async function ({ categoryId }: { categoryId: number }) {
      const { success, error } = await apiTimeOffers.deleteCategory(categoryId);

      if (error || !success) throw new ApiErrorException(error);

      return { categoryId };
    },
    onSuccess: ({ categoryId }) => {
      queryClient.setQueryData(timeOffsKeys.category(categoryId).queryKey, undefined);
      queryClient.invalidateQueries({
        queryKey: timeOffsKeys.allCategories.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export interface TimeOffSavePolicyParams {
  id: number;
  payload: ApiTimeOffPolicyPayload;
}

export const useTimeOffPolicySave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async function ({ id, payload }: TimeOffSavePolicyParams) {
      const result =
        id == 0
          ? await apiTimeOffers.createPolicy(payload)
          : await apiTimeOffers.updatePolicy(id, payload);

      if (result.error || !result.data) throw new ApiErrorException(result.error);

      return result.data;
    },
    onSuccess: (policy) => {
      queryClient.setQueryData(timeOffsKeys.policy(policy.id).queryKey, policy);
      queryClient.invalidateQueries({
        queryKey: timeOffsKeys.category(policy.category_id).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: timeOffsKeys.allCategories.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export interface TimeOffDeletePolicyParams {
  policyId: number;
  categoryId: number;
}

export const usePolicyDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function ({ policyId, categoryId }: TimeOffDeletePolicyParams) {
      const { success, error } = await apiTimeOffers.deletePolicy(policyId);

      if (error || !success) throw new ApiErrorException(error);

      return { policyId, categoryId };
    },
    onSuccess: ({ policyId, categoryId }) => {
      queryClient.setQueryData(timeOffsKeys.policy(policyId).queryKey, undefined);
      queryClient.invalidateQueries({
        queryKey: timeOffsKeys.category(categoryId).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: timeOffsKeys.allCategories.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useTimeOffCheck = (
  payload: ApiApprovalRequestPayload | undefined,
  enabled: boolean = true
) => {
  return useQuery({
    ...timeOffsKeys.check(payload),
    enabled:
      enabled &&
      !!payload?.time_off_data.category_id &&
      !!payload?.time_off_data.date_from &&
      !!payload?.time_off_data.date_to,
  });
};

export interface TimeOffAdjustmentParams {
  id?: number;
  payload: ApiTimeOffAdjustmentPayload;
}

export const useTimeOffAdjustment = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ id, payload }: TimeOffAdjustmentParams) => {
      const result = id
        ? await apiTimeOffers.updateAdjustment(id, payload)
        : await apiTimeOffers.createAdjustment(payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: employeesKeys.timeOffCategories(variables.payload.employee_id).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ["timeOffs"],
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useTimeOffDeleteAdjustment = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async (id: number) => {
      const result = await apiTimeOffers.deleteAdjustment(id);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: timeOffsKeys.allCategories.queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ["timeOffs"],
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
