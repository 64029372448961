import { AvatarSizes } from "../../ui/avatar/avatar.types.ts";
import { ApiEmployee } from "../../types/employees/employee.types.ts";
import { Link } from "react-router-dom";
import { UserCard, UserCardSizes } from "../user-card/UserCard.tsx";
import useFormatter from "../../hooks/use-formatter.hook.ts";
import { EmployeeAvatar } from "../employee-avatar/EmployeeAvatar.tsx";
import { getEmployeePositionTitle } from "../../types/employees/employee-position.types.ts";

export interface EmployeeCardProps {
  employee: ApiEmployee;
  className?: string;
  size?: keyof UserCardSizes;
}

export function EmployeeCard({ employee, size = "md", className }: EmployeeCardProps) {
  const avatarMapping: Record<keyof UserCardSizes, keyof AvatarSizes> = {
    sm: "xl",
    md: "2xl",
    lg: "4xl",
  };
  const { renderEmployeeName } = useFormatter();

  return (
    <Link to={`/employees/${employee.id}`} className={className}>
      <UserCard
        size={size}
        title={renderEmployeeName(employee)}
        caption={getEmployeePositionTitle(employee)}
        avatarElement={<EmployeeAvatar employee={employee} size={avatarMapping[size]} />}
      />
    </Link>
  );
}
