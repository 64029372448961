import { ModalProps } from "../modal/modal.types";
import Modal from "../modal/modal";
import Button from "../button/Button";
import useModals from "../modal/modal.store";
import HtmlViewer from "../../components/html-viewer/HtmlViewer";
import { useTranslation } from "react-i18next";

export interface PreviewContentModalProps extends ModalProps {
  modalTitle: string;
  content: string;
}

export const PreviewContentModal = ({
  content,
  modalTitle,
  ...props
}: PreviewContentModalProps) => {
  const { t } = useTranslation();
  const { close } = useModals();
  const { id } = { ...props };

  return (
    <Modal
      {...props}
      layout="stretch"
      size="lg"
      withCloser
      title={modalTitle}
      closeByEscEnabled
      actions={
        <Button onClick={() => close(id)} size="lg" className="w-full">
          {t("common:close")}
        </Button>
      }
    >
      <HtmlViewer content={content} />
    </Modal>
  );
};
