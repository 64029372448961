import { AppThemeConfig } from "../theme.config.types.ts";
import { AppTheme } from "../theme.types.ts";
import { DefaultAppTheme } from "../default/default.theme.ts";

export const SkylineAppThemeConfig: AppThemeConfig = {
  name: "skyline",
  colorBrandDark: "346deg 87% 33%",
  // colorBrandLight: "210deg 12% 97%",
};

export const SkylineAppTheme: AppTheme = {
  ...DefaultAppTheme,
  ...{
    body: {
      base: "text-default text-base bg-gradient-to-br from-[#9FE8FD] to-[#9E0B2E]",
    },
  },
};
