import { EmployeePageComponentProps } from "./employee-page-components.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { useTranslation } from "react-i18next";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Box from "../../../../ui/box/Box.tsx";
import Table from "../../../../ui/table/Table.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import { ColumnDef } from "@tanstack/react-table";
import {
  ApiTimeOffCategoryType,
  getMockTimeOffAdjustment,
} from "../../../../types/time-off.types.ts";
import { EmptyStateBase } from "../../../../components/empty-state/EmptyStateBase.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { ApprovalRequestTimeOffModal } from "../../../approvals/widgets/ApprovalRequestTimeOffModal.tsx";
import { useMemo, useState } from "react";
import { ApiApprovalRequest } from "../../../../types/approval-request.types.ts";
import { PaginationBlock } from "../../../../ui/pagination/PaginationBlock.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import { Tabs } from "../../../../ui/tabs/Tabs.tsx";
import {
  useEmployeeTimeOffCategoriesData,
  useEmployeeTimeOffRequests,
} from "../../../../queries/employees/use-employees.query.ts";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";
import { ApiEmployeeTimeOffRequestsFilter } from "../../../../types/employees/employee.types.ts";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import { PermissionType } from "../../../../types/role.types.ts";
import { EmployeeEditTimeOffBalanceModal } from "../widgets/EmployeeEditTimeOffBalanceModal.tsx";
import useTimeOffHook from "../../../../hooks/use-time-off.hook.tsx";

export const EmployeeTimeOffs = ({ employee }: EmployeePageComponentProps) => {
  const { t } = useTranslation();
  const { renderDate } = useFormatter();
  const modalsService = useModals();
  const { employee: employeeMe } = useEmployeeStore();
  const { getActionColumnForRequest } = useTimeOffHook();

  const canManageBalance =
    employeeMe && employeeMe.permissions.manage_leave_policies == PermissionType.edit;

  const { data: employeeTimeOffCategories, isLoading: employeeTimeOffCategoriesIsLoading } =
    useEmployeeTimeOffCategoriesData(employee.id);
  const [timeOffFilter, setTimeOffFilter] = useState<ApiEmployeeTimeOffRequestsFilter>({
    page_number: 1,
    category_id: undefined,
  });

  const { data: timeOffRequests, isLoading: isRequestsLoading } = useEmployeeTimeOffRequests(
    employee.id,
    timeOffFilter
  );

  const handleCreateRequest = (timeOffCategoryId?: number) => {
    modalsService.openModal(ApprovalRequestTimeOffModal, {
      timeOffCategoryId: timeOffCategoryId,
      employeeId: employee.id,
    });
  };

  const handleEditBalance = () => {
    if (!employee) return;

    const adjustment = getMockTimeOffAdjustment(employee);

    modalsService.openModal(EmployeeEditTimeOffBalanceModal, {
      adjustment: adjustment,
    });
  };

  const columns = useMemo<ColumnDef<ApiApprovalRequest>[]>(
    () => [
      {
        header: t("common:created"),
        cell: (row) => <Text>{renderDate(new Date(row.row.original.datetime_created_at))}</Text>,
        accessorKey: "datetime_created_at",
        size: 100,
      },
      {
        id: "type",
        header: t("time_off:category"),
        cell: (row) => {
          return (
            <Stack direction="horizontal" gap="sm" items="center">
              <Avatar emoji={row.row.original.time_off_data.category.emoji} />
              <Stack>
                <Title header={row.row.original.time_off_data.category.title ?? ""} size={"sm"} />
                <Text> {t(`time_off:request_status_${row.row.original.status}`)}</Text>
              </Stack>
            </Stack>
          );
        },
        accessorKey: "type",
      },
      {
        id: "balance",
        header: t("time_off:balance"),
        cell: (row) => <Text>{"-" + (row.row.original.time_off_data.balance_requested ?? 0)}</Text>,
      },
      {
        id: "period",
        header: t("common:period"),
        cell: (row) => (
          <Text>
            {renderDate(new Date(row.row.original.time_off_data.date_from))} —{" "}
            {renderDate(new Date(row.row.original.time_off_data.date_to))}
          </Text>
        ),
        accessorKey: "period",
      },
      getActionColumnForRequest(),
    ],
    [timeOffRequests?.items]
  );

  return (
    <Stack gap="lg">
      <Stack justify={"between"} direction={"horizontal"} items={"center"}>
        <Title header={t("time_off:time_offs")} size="md" />
        <Stack direction={"horizontal"} gap={"sm"}>
          {canManageBalance && (
            <Button variant="secondary" size="sm" onClick={handleEditBalance}>
              {t("time_off:edit_balance")}
            </Button>
          )}
          <Button
            variant="primary"
            size="sm"
            leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            onClick={() => {
              handleCreateRequest();
            }}
          >
            {t("time_off:request_timeoff")}
          </Button>
        </Stack>
      </Stack>
      <Stack direction={"horizontal"}>
        <Stack
          direction={"horizontal"}
          gap={"xl"}
          justify={"between"}
          className="w-full grid-cols-2 xl:grid-cols-3 flex-wrap grid gap-4"
        >
          {employeeTimeOffCategoriesIsLoading && (
            <Stack direction={"horizontal"} gap={"md"} items={"center"}>
              <Avatar size={"xl"} emoji={"⌛"} />
              <Stack>
                <Skeleton className={"w-10 h-8 rounded-2xl mb-1"} />
                <Skeleton className={"w-20 h-4 rounded-2xl"} />
              </Stack>
            </Stack>
          )}
          {employeeTimeOffCategories?.map((item) => (
            <Stack
              direction={"horizontal"}
              gap={"md"}
              items={"center"}
              className={"cursor-pointer"}
              key={item.title}
              onClick={() => {
                handleCreateRequest(item.id);
              }}
            >
              <Avatar emoji={item.emoji} size={"xl"} />
              <Stack>
                <Title
                  header={
                    item.category_type === ApiTimeOffCategoryType.accrues_time
                      ? `${parseFloat(item.balance.available_balance)}`
                      : parseFloat(item.balance.available_balance) > 0
                        ? `${parseFloat(item.balance.available_balance)}`
                        : "♾️"
                  }
                  size={"lg"}
                />
                <Text className={"text-secondary"}>{item.title}</Text>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Tabs.List>
        <Tabs.Button
          title={t("time_off:all_timeoffs")}
          isActive={!timeOffFilter.category_id}
          isStretch
          onClick={() => {
            setTimeOffFilter({
              ...timeOffFilter,
              category_id: undefined,
              page_number: 1,
            });
          }}
        />
        {employeeTimeOffCategories?.map((category) => (
          <Tabs.Button
            key={category.id}
            title={category.title}
            isActive={timeOffFilter.category_id === category.id}
            isStretch
            onClick={() => {
              setTimeOffFilter({
                ...timeOffFilter,
                category_id: category.id,
                page_number: 1,
              });
            }}
          />
        ))}
      </Tabs.List>
      <Box>
        <Table
          data={timeOffRequests?.items || []}
          columns={columns}
          isFetching={employeeTimeOffCategoriesIsLoading || isRequestsLoading}
          showSkeleton={employeeTimeOffCategoriesIsLoading || isRequestsLoading}
          notFoundComponent={<EmptyStateNothingFound />}
          emptyStateComponent={
            <EmptyStateBase
              emoji="🏖️"
              title={t("time_off:no_requests_created")}
              description={t("time_off:no_requests_created_description")}
              primaryButton={
                <Button
                  onClick={() => {
                    handleCreateRequest();
                  }}
                  size="sm"
                  variant="secondary"
                  leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
                >
                  {t("time_off:request_timeoff")}
                </Button>
              }
            />
          }
        />
      </Box>
      <PaginationBlock
        metadata={timeOffRequests?.metadata}
        onPageChange={(page) => {
          setTimeOffFilter({ ...timeOffFilter, page_number: page });
        }}
      />
    </Stack>
  );
};
