import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import { ApiNoteEdit, ApiNotesFilter } from "../types/note.types.ts";
import { apiNotes } from "../api/note.api.ts";

export const notesKeys = createQueryKeys("notes", {
  search: (employeeId: number, filter: ApiNotesFilter) => ({
    queryKey: [employeeId, filter],
    queryFn: async ({ signal }) => {
      const result = await apiNotes.searchNotes(employeeId, filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useNotesSearch = (employeeId: number, filter: ApiNotesFilter, enabled = true) => {
  return useQuery({
    ...notesKeys.search(employeeId, filter),
    enabled: enabled,
  });
};

export const useNoteSave = <T extends FieldValues>(control: Control<T> | undefined = undefined) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({
      id,
      employeeId,
      payload,
    }: {
      id: number;
      employeeId: number;
      payload: ApiNoteEdit;
    }) => {
      const result =
        id == 0
          ? await apiNotes.createNote(employeeId, payload)
          : await apiNotes.updateNote(employeeId, id, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: notesKeys.search._def,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useNoteDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ employeeId, id }: { employeeId: number; id: number }) => {
      const result = await apiNotes.deleteNote(employeeId, id);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: notesKeys.search._def,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
