import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import Modal from "../../../../ui/modal/modal.tsx";
import { ApiEmployee } from "../../../../types/employees/employee.types.ts";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import Stack from "../../../../ui/stack/Stack.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { Textarea } from "../../../../ui/textarea/Textarea.tsx";
import { useEmployeeDismissalReasons } from "../../../../queries/employees/use-employee-dismissal-reason.query.ts";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import Select from "../../../../ui/select/Select.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { useEmployeeEmploymentStatusSave } from "../../../../queries/employees/use-employee-employment-status.query.ts";
import {
  ApiEmployeeEmploymentStatus,
  ApiEmployeeEmploymentStatusSave,
} from "../../../../types/employees/employee-employment-status.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import { Switch } from "../../../../ui/switch/Switch.tsx";

export interface EmployeeDismissalModalProps extends ModalProps {
  employee: ApiEmployee;
  employeeEmploymentStatus: ApiEmployeeEmploymentStatus;
}

export function EmployeeTerminationModal({
  employee,
  employeeEmploymentStatus,
  ...props
}: EmployeeDismissalModalProps) {
  const { t } = useTranslation();
  const { id } = { ...props };
  const { close } = useModals();
  const { data: reasons } = useEmployeeDismissalReasons();
  const EmployeeDismissalSchemaObject = yup.object({
    termination_reason_id: yup.number().required(i18n.t("errors:field_required")),
    date_effective_from: yup.date().required(i18n.t("errors:field_required")),
    comment: yup.string(),
    assign_subordinates_to_my_reporting_to: yup.boolean(),
    assign_department_to_departments_parent_manager: yup.boolean(),
  });

  type EmployeeDismissalSchema = yup.InferType<typeof EmployeeDismissalSchemaObject>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EmployeeDismissalSchema>({
    mode: "onSubmit",
    resolver: yupResolver(EmployeeDismissalSchemaObject),
    values: {
      termination_reason_id: employeeEmploymentStatus?.termination_reason?.id || 0,
      date_effective_from: employeeEmploymentStatus?.date_effective_from,
      comment: employeeEmploymentStatus?.comment,
      assign_subordinates_to_my_reporting_to:
        employeeEmploymentStatus?.termination_config?.assign_subordinates_to_my_reporting_to,
      assign_department_to_departments_parent_manager:
        employeeEmploymentStatus?.termination_config
          ?.assign_department_to_departments_parent_manager,
    },
  });

  const employeeEmploymentSave = useEmployeeEmploymentStatusSave(control);

  const onSubmit = handleSubmit(async (data) => {
    const payload: ApiEmployeeEmploymentStatusSave = {
      termination_reason_id: data.termination_reason_id,
      date_effective_from: data.date_effective_from,
      comment: data.comment ?? "",
      termination_config: {
        assign_subordinates_to_my_reporting_to:
          data.assign_subordinates_to_my_reporting_to ?? false,
        assign_department_to_departments_parent_manager:
          data.assign_department_to_departments_parent_manager ?? false,
      },
    };

    try {
      await employeeEmploymentSave.mutateAsync({
        employeeId: employee.id,
        positionId: employeeEmploymentStatus.id,
        payload: payload,
      });
      if (employeeEmploymentStatus.id == 0) {
        analyticsService.trackEvent(analyticEvents.employees.dismissed, {
          [analyticProperties.id]: employee.id,
        });
      }
      close(id);
    } catch {
      return;
    }
  });

  return (
    <Modal
      {...props}
      withCloser={true}
      title={t("core:termination")}
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            type={"submit"}
            size={"lg"}
            onClick={onSubmit}
            isLoading={employeeEmploymentSave.isPending}
          >
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              options={reasons || []}
              required={true}
              emptyTitle={t("core:reason")}
              searchable
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          name={"termination_reason_id"}
        />
        <Controller
          render={({ field: { value, onChange } }) => (
            <Datepicker
              value={value}
              onChange={onChange}
              label={t("core:termination_date")}
              required={true}
              error={errors.date_effective_from?.message}
            />
          )}
          control={control}
          name={"date_effective_from"}
        />
        <Textarea label={t("common:comment")} {...register("comment")} />

        <Title header={t("common:settings")} size={"xs"} paddingBottom={true} paddingTop={true} />
        <Stack gap={"sm"}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Switch
                checked={value}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                size={"sm"}
                title={t("core:reassign_subordinates_to_manager_title")}
                description={t("core:reassign_subordinates_to_manager_description")}
              />
            )}
            control={control}
            name={"assign_subordinates_to_my_reporting_to"}
          />
          <Controller
            render={({ field: { value, onChange } }) => (
              <Switch
                checked={value}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                size={"sm"}
                title={t("core:assign_department_head_title")}
                description={t("core:assign_department_head_description")}
              />
            )}
            control={control}
            name={"assign_department_to_departments_parent_manager"}
          />
        </Stack>
      </form>
    </Modal>
  );
}
