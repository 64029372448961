import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Modal from "../../../../ui/modal/modal";
import useModals from "../../../../ui/modal/modal.store";
import Stack from "../../../../ui/stack/Stack";
import Button from "../../../../ui/button/Button";
import { Textarea } from "../../../../ui/textarea/Textarea";
import { ModalProps } from "../../../../ui/modal/modal.types";
import { useApplicantCVUpdate } from "../../../../queries/recruit/use-applicants.query";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service";

interface ApplicantCVTextEditModalProps extends ModalProps {
  applicantId: number;
  applicantCVText: string;
  applicantCVId: number;
  onSave?: (updatedCVText: string) => void;
}

export const ApplicantCVTextEditModal = ({
  applicantId,
  applicantCVText,
  applicantCVId,
  onSave,
  ...props
}: ApplicantCVTextEditModalProps) => {
  const { t } = useTranslation();
  const { close } = useModals();
  const { id } = props;

  const schema = yup.object({
    cv_text: yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cv_text: applicantCVText,
    },
  });

  const applicantCVUpdate = useApplicantCVUpdate();

  const onSubmit = handleSubmit(async (data) => {
    if (!data.cv_text) return;

    try {
      await applicantCVUpdate.mutateAsync({
        id: applicantId,
        payload: { cv_text: data.cv_text, cv_id: applicantCVId },
      });
      analyticsService.trackEvent(analyticEvents.applicants.cvTextEdited, {
        [analyticProperties.id]: applicantId,
      });
      onSave?.(data.cv_text);
      close(id);
    } catch {
      return;
    }
  });

  return (
    <Modal
      {...props}
      variant="side"
      title={t("ats:edit_cv_text")}
      layout="stretch"
      withCloser={true}
      actions={
        <Stack gap="sm">
          <Button
            size="lg"
            className="w-full"
            type="submit"
            isLoading={applicantCVUpdate.isPending}
            onClick={onSubmit}
          >
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Controller
          name="cv_text"
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              label={t("ats:cv_text")}
              placeholder={t("ats:cv_text_enter") + "..."}
              error={errors.cv_text?.message}
              rows={15}
            />
          )}
        />
      </form>
    </Modal>
  );
};
