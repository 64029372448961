import Stack from "../../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import { SettingsCard } from "../../../components/SettingsCard.tsx";
import { Rule, RuleApplySignType } from "../../../../../types/role.types.ts";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { TimeOffCategoryPolicySchema } from "../TimeOffCategoryPolicyEdit.tsx";
import { useTranslation } from "react-i18next";
import useRoleEditService from "../../../roles/edit/role-edit-service.ts";
import { useCallback } from "react";
import { RuleEditModal } from "../../../roles/edit/widgets/RuleEditModal.tsx";
import { RoleAssignToConditionsItems } from "../../../roles/edit/role-edit.types.ts";
import useModals from "../../../../../ui/modal/modal.store.ts";
import { ApiTimeOffPolicy } from "../../../../../types/time-off.types.ts";

export interface TimeOffPolicyAssignToProps {
  formHook: UseFormReturn<TimeOffCategoryPolicySchema>;
  timeOffPolicy?: ApiTimeOffPolicy;
}

export function TimeOffPolicyAssignTo({ formHook, timeOffPolicy }: TimeOffPolicyAssignToProps) {
  const { t } = useTranslation();
  const { openModal } = useModals();
  const { getValues, control } = formHook;
  const { getAssignCardTitle, getAssignCardDescription } = useRoleEditService();

  const {
    append: assignToAppend,
    remove: assignToRemove,
    update: assignToUpdate,
  } = useFieldArray({
    control,
    name: "assign_to",
  });

  const handleEditAssignTo = useCallback(
    (rule?: Rule | undefined, index?: number | undefined) => {
      openModal(
        RuleEditModal,
        {
          rule,
          forceSign: RuleApplySignType.include,
          conditionTypes: RoleAssignToConditionsItems(),
          onModified(modifiedRule: Rule) {
            if (index !== undefined) {
              assignToUpdate(index, modifiedRule);
            } else {
              assignToAppend(modifiedRule);
            }
          },
          onDelete() {
            assignToRemove(index);
          },
        },
        { size: "lg" }
      );
    },
    [timeOffPolicy]
  );

  return (
    <Stack gap={"lg"}>
      <Title
        header={t("core:assign_employees")}
        caption={t("core:assign_employees_description")}
        size={"lg"}
      />

      <Stack
        direction={"horizontal"}
        gap={"md"}
        className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
      >
        {getValues("assign_to").map((rule, index) => (
          <SettingsCard
            key={`role-card-${index}`}
            title={getAssignCardTitle(rule.condition_type)}
            description={getAssignCardDescription(rule.sign, rule.condition_entities)}
            onClick={() => {
              handleEditAssignTo(rule as Rule, index);
            }}
          />
        ))}
        <SettingsCard
          icon={"fa-plus"}
          title={t("core:create_new_rule")}
          variant={"action"}
          onClick={() => {
            handleEditAssignTo();
          }}
        />
      </Stack>
    </Stack>
  );
}
