import { api } from "./axios.ts";
import {
  ApiDepartment,
  ApiDepartmentFilter,
  ApiDepartmentSavePayload,
  ApiDepartmentShort,
  DepartmentNodeMode,
} from "../types/department.types.ts";
import { ApiPagedData } from "../types/filter.types.ts";

export const apiDepartments = {
  getAll: function () {
    return api.get<ApiDepartment[]>("/api/v1/departments");
  },
  create: function (payload: ApiDepartmentSavePayload) {
    return api.post<ApiDepartment>("/api/v1/departments", payload);
  },
  update: function (id: number, payload: ApiDepartmentSavePayload) {
    return api.put<ApiDepartment>(`/api/v1/departments/${id}`, payload);
  },
  delete: function (id: number) {
    return api.delete(`/api/v1/departments/${id}`);
  },
  search: function (filter: ApiDepartmentFilter, signal: AbortSignal) {
    return api.post<ApiPagedData<ApiDepartmentShort>>("/api/v1/departments/search", filter, signal);
  },
  chart: function (
    department_id: number | undefined,
    mode: DepartmentNodeMode = DepartmentNodeMode.children
  ) {
    return api.post<ApiDepartment[]>("/api/v1/departments/chart", {
      department_id,
      mode,
    });
  },
};
