import { EmployeeInfoItem, EmployeePageComponentProps } from "./employee-page-components.types.ts";
import { Title } from "../../../../components/title/Title.tsx";
import { ListItem } from "../../../../components/list-item/ListItem.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Link } from "react-router-dom";
import { UserListItem } from "../../../../components/user-list/UserListItem.tsx";
import { useTranslation } from "react-i18next";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { useMemo } from "react";
import { EmployeeFieldPermissions } from "../../../../types/role.types.ts";
import usePermissions from "../../../../hooks/use-permissions.hook.ts";
import { getEmployeePositionTitle } from "../../../../types/employees/employee-position.types.ts";

export function EmployeePersonalCard({ employee }: EmployeePageComponentProps) {
  const { t } = useTranslation();
  const {
    renderEmployeeName,
    renderDate,
    renderPartialDate,
    formatDurationPartialDate,
    transformDateToPartialDate,
  } = useFormatter();
  const { canViewAnyOfFields } = usePermissions();

  const canViewCompensationInfo = useMemo(() => {
    return canViewAnyOfFields(employee, [
      EmployeeFieldPermissions.employee_salary,
      EmployeeFieldPermissions.employee_salary_description,
    ]);
  }, [employee]);

  const contacts: EmployeeInfoItem[] = useMemo(() => {
    const result: EmployeeInfoItem[] = [];

    if (employee.email) {
      result.push({
        title: t("core:email"),
        caption: employee.email,
      });
    }
    if (employee.email_personal) {
      result.push({
        title: t("core:email_personal"),
        caption: employee.email_personal,
      });
    }
    if (employee.phone) {
      result.push({
        title: t("core:phone"),
        caption: employee.phone,
      });
    }
    if (employee.phone_personal) {
      result.push({
        title: t("core:phone_personal"),
        caption: employee.phone_personal,
      });
    }

    return result;
  }, [employee]);

  const personal: EmployeeInfoItem[] = useMemo(() => {
    const result: EmployeeInfoItem[] = [];

    if (employee.personnel_number) {
      result.push({
        title: t("core:employee_number"),
        caption: employee.personnel_number,
      });
    }

    if (employee.date_hired_on) {
      result.push({
        title: t("core:hired_on"),
        caption: renderDate(employee.date_hired_on),
        value: formatDurationPartialDate(
          transformDateToPartialDate(employee.date_hired_on),
          employee.date_termination
            ? transformDateToPartialDate(employee.date_termination)
            : undefined
        ),
      });
    }

    if (employee.date_termination) {
      result.push({
        title: t("core:termination_date"),
        caption: renderDate(employee.date_termination),
        value: formatDurationPartialDate(
          transformDateToPartialDate(employee.date_termination),
          undefined
        ),
      });
    }

    if (employee.employee_position.position) {
      result.push({
        title: t("core:position"),
        caption: getEmployeePositionTitle(employee),
      });
    }

    if (employee.employee_position.department) {
      result.push({
        title: t("core:department"),
        caption: employee.employee_position.department.title,
      });
    }

    if (employee.employee_position.division) {
      result.push({
        title: t("core:division"),
        caption: employee.employee_position.division.title,
      });
    }

    if (employee.birth_date) {
      result.push({
        title: t("core:birth_date"),
        caption: renderPartialDate(employee.birth_date) ?? "",
        value: formatDurationPartialDate(employee.birth_date, undefined),
      });
    }

    if (employee.employee_position.location) {
      result.push({
        title: t("common:location"),
        caption: employee.employee_position.location.title,
      });
    }

    if (employee.employee_position.legal_entity) {
      result.push({
        title: t("core:legal_entity"),
        caption: employee.employee_position.legal_entity.title,
      });
    }

    return result;
  }, [employee]);

  const address: EmployeeInfoItem[] = useMemo(() => {
    const result: EmployeeInfoItem[] = [];

    if (employee.country) {
      result.push({
        title: t("core:country"),
        caption: employee.country.title,
      });
    }

    if (employee.city) {
      result.push({
        title: t("core:city"),
        caption: employee.city,
      });
    }

    if (employee.state) {
      result.push({
        title: t("core:state"),
        caption: employee.state,
      });
    }

    if (employee.zip) {
      result.push({
        title: t("core:zip"),
        caption: employee.zip,
      });
    }

    if (employee.address_1) {
      result.push({
        title: t("core:address_line_1"),
        caption: employee.address_1,
      });
    }

    if (employee.address_2) {
      result.push({
        title: t("core:address_line_2"),
        caption: employee.address_2,
      });
    }

    return result;
  }, [employee]);

  const compensation: EmployeeInfoItem[] = useMemo(() => {
    const result: EmployeeInfoItem[] = [];

    if (employee.salary) {
      result.push({
        title: t("core:salary"),
        caption: `${employee.salary}`,
      });
    }

    if (employee.salary_description) {
      result.push({
        title: t("core:salary_description"),
        caption: employee.salary_description,
      });
    }

    return result;
  }, [employee]);

  return (
    <Stack direction={"vertical"}>
      {employee.employee_position.manager && (
        <>
          <Title size={"xs"} header={t("core:manager")} paddingTop={true} paddingBottom={true} />
          <Link to={`/employees/${employee.employee_position.manager.id}`}>
            <UserListItem
              primaryText={renderEmployeeName(employee.employee_position.manager)}
              text2={getEmployeePositionTitle(employee.employee_position.manager)}
              avatarUrl={employee.employee_position.manager.avatar_url}
            />
          </Link>
        </>
      )}

      {personal.length > 0 && (
        <>
          <Title size={"xs"} header={t("core:personal")} paddingTop={true} paddingBottom={true} />
          {personal.map((info, index) => (
            <ListItem
              title={info.title}
              caption={info.caption}
              value={info.value}
              key={`personal-${index}`}
            />
          ))}
        </>
      )}

      {contacts.length > 0 && (
        <>
          <Title size={"xs"} header={t("core:contacts")} paddingTop={true} paddingBottom={true} />
          {contacts.map((contact, index) => (
            <ListItem title={contact.title} caption={contact.caption} key={`contact-${index}`} />
          ))}
        </>
      )}

      {address.length > 0 && (
        <>
          <Title size={"xs"} header={t("core:address")} paddingTop={true} paddingBottom={true} />
          {address.map((item, index) => (
            <ListItem title={item.title} caption={item.caption} key={`address-${index}`} />
          ))}
        </>
      )}

      {compensation.length > 0 && canViewCompensationInfo && (
        <>
          <Title
            size={"xs"}
            header={t("core:compensation")}
            paddingTop={true}
            paddingBottom={true}
          />
          {compensation.map((item, index) => (
            <ListItem title={item.title} caption={item.caption} key={`compensation-${index}`} />
          ))}
        </>
      )}
    </Stack>
  );
}
