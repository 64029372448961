import { useTranslation } from "react-i18next";
import { ApiDepartment, DepartmentNodeMode } from "../types/department.types.ts";
import useFormatter from "./use-formatter.hook.ts";
import { apiDepartments } from "../api/department.api.ts";

export default function useDepartmentsChartHook() {
  const { t } = useTranslation();
  const { renderEmployeeName } = useFormatter();

  const renderCaption = (department: ApiDepartment): string => {
    const nameManager = department.manager ? `${renderEmployeeName(department.manager)}` : "";

    return `${nameManager}`;
  };

  const renderExtra = (department: ApiDepartment): string => {
    const employeesCount = `${t("plurals:employees_count", { count: department.employees_count })}`;

    return `${employeesCount}`;
  };

  const loadDepartmentsChart = async (
    departmentId: number | undefined,
    mode: DepartmentNodeMode = DepartmentNodeMode.children
  ) => {
    const { data: departments } = await apiDepartments.chart(departmentId, mode);

    return departments;
  };

  const loadDepartmentsChartByNodeId = async (
    nodeId: string | undefined,
    mode: DepartmentNodeMode = DepartmentNodeMode.children
  ): Promise<ApiDepartment[]> => {
    if (!nodeId) return [];
    if (nodeId == "root") return [];

    const id = Number(nodeId);

    const departments = await loadDepartmentsChart(id, mode);

    return departments ?? [];
  };

  return {
    renderExtra,
    renderCaption,
    loadDepartmentsChart,
    loadDepartmentsChartByNodeId,
  };
}
