import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { LinkToProps } from "./link-to.types.ts";
import { useTheme } from "../themes/provider.tsx";

export default function LinkTo({
  to,
  children,
  className,
  onClick,
  variant = "primary",
  ...props
}: LinkToProps) {
  const theme = useTheme("linkTo");
  return (
    <>
      <Link
        to={to}
        className={twMerge(theme.base, theme.variants.variant[variant], className)}
        onClick={onClick}
        {...props}
      >
        {children}
      </Link>
    </>
  );
}
