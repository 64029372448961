import { Page } from "../../../components/page/Page.tsx";
import { Chart, ChartNodeData } from "../../../components/chart/Chart.tsx";
import { useTranslation } from "react-i18next";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { useCallback, useEffect, useState } from "react";
import { ApiEmployeeChartItem, EmployeeNodeMode } from "./employees-chart.types.ts";
import useFormatter from "../../../hooks/use-formatter.hook.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { EmployeeViewModal } from "../widgets/EmployeeViewModal.tsx";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import { getEmployeePositionTitle } from "../../../types/employees/employee-position.types.ts";
import useEmployeesChartHook from "../../../hooks/use-employees-chart.hook.ts";

export function EmployeesChart() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const [chart, setChart] = useState<ChartNodeData<ApiEmployeeChartItem>[]>([]);
  const [expanded, setExpanded] = useState<Set<string>>(new Set([]));
  const { renderEmployeeName } = useFormatter();
  const { openModal } = useModals();
  const [searchParams] = useSearchParams();
  const { loadEmployees, loadEmployee, loadEmployeesChartByNodeId } = useEmployeesChartHook();

  useEffect(() => {
    const initializeChart = async () => {
      const employeeIdParam = searchParams.get("employee_id");
      const employeeId = employeeIdParam ? Number(employeeIdParam) : undefined;
      const items = await loadEmployees(
        employeeId,
        employeeId ? EmployeeNodeMode.parent : EmployeeNodeMode.children
      );

      if (employeeId) {
        const opened: string[] = [];
        const current: ApiEmployeeChartItem | undefined = items?.find(
          (employee) => employee.employee.id === employeeId
        );
        const parentId = current?.employee.employee_position.manager?.id;

        opened.push(`${employeeId}`);
        current && parentId && opened.push(`${parentId}`);

        setExpanded(new Set(opened));

        if (!parentId) {
          const rootItems = await loadEmployees(undefined);

          rootItems && appendEmployeesToChart(rootItems);
        }
      }

      items && appendEmployeesToChart(items);
    };

    initializeChart();
  }, [searchParams]);

  const appendEmployeesToChart = useCallback((employees: ApiEmployeeChartItem[]) => {
    setChart((prevChart) => {
      const newEntitiesIds: Set<number | undefined> = new Set(
        employees.map((item) => item?.employee.id)
      );

      /* root and similar nodes delete from old data */
      const prev = [...prevChart]
        .filter((item) => item.original && !newEntitiesIds.has(item.original.employee.id))
        .map((item) => item.original);

      const mergedItems = [...prev, ...employees];
      const nodes: ChartNodeData<ApiEmployeeChartItem>[] = [];
      const mergedIds: Set<number | undefined> = new Set(
        mergedItems.map((item) => item?.employee.id)
      );
      let needRoot = false;
      let countRootElements = 0;

      /* collect data for next step for*/
      mergedItems.forEach((employeeChart) => {
        if (!employeeChart) return;

        const parentIdNumber: number | null =
          employeeChart.employee.employee_position.manager?.id ?? null;

        if (!parentIdNumber) {
          needRoot = true;
          countRootElements++;
        }
      });

      mergedItems.forEach((employeeChart) => {
        if (!employeeChart) return;

        const parentIdNumber: number | null =
          employeeChart.employee.employee_position.manager?.id ?? null;

        let parentId: string | null = parentIdNumber ? `${parentIdNumber}` : "root";
        let isExpandUpNode = false;

        if (parentIdNumber && !mergedIds.has(parentIdNumber) && !needRoot) {
          parentId = null;
          isExpandUpNode = true;
        }

        nodes.push({
          title: renderEmployeeName(employeeChart.employee),
          caption: getEmployeePositionTitle(employeeChart.employee),
          imgUrl: employeeChart.employee.avatar_url,
          id: `${employeeChart.employee.id}`,
          parentId,
          count: employeeChart.direct_subordinates_count,
          isExpandUpNode,
          isRoot: false,
          original: employeeChart,
        });
      });

      if (needRoot) {
        nodes.push({
          title: employee?.organization?.title ?? t("common:company"),
          imgUrl: employee?.organization?.logo_url ?? "/favicon.svg",
          id: "root",
          parentId: null,
          isExpandUpNode: false,
          count: countRootElements,
          isRoot: true,
        });
      }

      return nodes;
    });
  }, []);

  const onTitleClick = useCallback(
    async (nodeId: string) => {
      if (nodeId == "root") return;

      const employee = await loadEmployee(nodeId);

      employee && openModal(EmployeeViewModal, { employee });
    },
    [chart]
  );

  const onExpandClick = useCallback(
    (nodeId: string, needLoadData: boolean) => {
      if (!needLoadData) return;

      loadEmployeesChartByNodeId(nodeId).then((employees) => {
        employees && appendEmployeesToChart(employees);
      });
    },
    [chart]
  );

  const onExpandUpClick = useCallback(
    (nodeId: string) => {
      loadEmployeesChartByNodeId(nodeId, EmployeeNodeMode.parent).then((items) => {
        /* set opened node */
        items.forEach((item) => {
          if (nodeId == `${item.employee.id}` && item.employee.employee_position.manager) {
            setExpanded((prevState) =>
              prevState.add(`${item.employee.employee_position.manager?.id}`)
            );
          }
        });

        items && appendEmployeesToChart(items);

        /* if we have nodes with parent id = null, w need get another root nodes */
        if (!items.some((item) => !item.employee.employee_position.manager)) return;

        loadEmployees(undefined).then((rootItems) => {
          rootItems && appendEmployeesToChart(rootItems);
        });
      });
    },
    [chart]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.employees.viewedChart);
  });

  return (
    <Page className={"bg-light/80 h-full"}>
      <Page.Header showBack={true} title={t("common:company")}></Page.Header>
      {chart.length > 0 && (
        <Chart
          data={chart}
          onExpandClick={onExpandClick}
          onExpandUpClick={onExpandUpClick}
          onNodeClick={onTitleClick}
          expandedSet={expanded}
        />
      )}
    </Page>
  );
}
