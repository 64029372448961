import { useModalStore } from "./modal.store.ts";

export default function ModalService() {
  const { modals } = useModalStore();
  return (
    <>
      {modals.map((modal, index) => {
        return <modal.component key={index} {...modal.props} />;
      })}
    </>
  );
}
