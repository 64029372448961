import DropdownMenu from "../dropdown-menu/DropdownMenu.tsx";
import Text from "../typography/Text.tsx";
import { SelectOption } from "./SelectOption.tsx";
import { SelectItemExtended, SelectValueType } from "./select.types.ts";
import { useMemo } from "react";

export interface DropdownSelectProps {
  onChange: (value: SelectValueType) => void;
  value: SelectValueType;
  options: SelectItemExtended[];
  showCaption?: boolean;
}

export const DropdownSelect = ({
  value,
  options,
  onChange,
  showCaption = true,
}: DropdownSelectProps) => {
  const currentOption = useMemo(
    () => options.find((option) => option.id === value),
    [value, options]
  );
  return (
    <DropdownMenu
      trigger={
        <Text className={"underline text-dark/95 cursor-pointer hover:no-underline"}>
          {currentOption?.title}
        </Text>
      }
    >
      {options.map((option, optionIndex) => (
        <SelectOption
          isMulti={false}
          key={`${optionIndex}`}
          handleSelect={() => {
            onChange(option.id);
          }}
          showCaption={showCaption}
          item={{
            item: option,
            id: option.id,
            title: option.title,
            caption: option.caption,
            selected: option.id == value,
          }}
        />
      ))}
    </DropdownMenu>
  );
};
