import { ApiEmployeeShort } from "./employees/employee.types.ts";
import { SelectItem } from "../ui/select/select.types.ts";
import i18n from "../i18n.ts";

export enum ApiNoteVisibility {
  myself = "myself",
  my_and_employee = "my_and_employee",
  public = "public",
}

export interface ApiNote {
  id: number;
  text: string;
  employee_id: number;
  visibility: ApiNoteVisibility;
  author: ApiEmployeeShort;
  datetime_created_at: Date;
  datetime_updated_at: Date;
}

export interface ApiNoteEdit {
  text: string;
  visibility: ApiNoteVisibility;
}

export interface ApiNotesFilter {
  page_number: number;
}

export const getNotesVisibilityOptions = () => {
  return [
    {
      id: ApiNoteVisibility.myself,
      title: i18n.t("core:note_visibility_private"),
    },
    {
      id: ApiNoteVisibility.my_and_employee,
      title: i18n.t("core:note_visibility_me_and_employee"),
    },
    {
      id: ApiNoteVisibility.public,
      title: i18n.t("core:note_visibility_public"),
    },
  ] as SelectItem[];
};
