import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Stack from "../stack/Stack.tsx";
import { useTheme } from "../themes/provider.tsx";
import { TabButtonProps } from "./tabs.types.ts";

const TabButtonComponent = forwardRef<HTMLButtonElement, TabButtonProps>(
  (
    {
      title,
      onClick,
      isActive,
      countTotal = 0,
      size = "md",
      fit = "md",
      isStretch = false,
    }: TabButtonProps,
    ref
  ) => {
    const theme = useTheme("tabs");

    return (
      <button
        type="button"
        role="tab"
        ref={ref}
        className={twMerge(
          theme.tabButton.base,
          theme.tabButton.variants.size[size],
          theme.tabButton.variants.fit[fit],
          isActive ? theme.tabButton.active.on : theme.tabButton.active.off,
          isStretch ? theme.tabButton.stretch : ""
          // index !== activeTab && !tab.disabled && theme.tabItem.inactive
        )}
        onClick={onClick}
        // onKeyDown={(event) => handleKeyboard({ event, target: index })}
        // style={{ zIndex: index === focusedTab ? 2 : 1 }}
      >
        <Stack direction={"horizontal"} gap={"sm"} justify={"center"} className={"text-nowrap"}>
          {title}
          {countTotal > 0 && <span className={twMerge()}>{countTotal}</span>}
        </Stack>
      </button>
    );
  }
);

const TabButton = TabButtonComponent;
export default TabButton;
