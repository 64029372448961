import { ApiResponse } from "../api/axios";
import { useState } from "react";
import useErrorHandle from "../hooks/use-error-handle.hook";

interface DownloadOptions {
  apiCall: () => Promise<ApiResponse<BlobPart>>;
  onSuccess?: () => void;
}

interface DownloadHelper {
  isExporting: boolean;
  downloadFile: (options: DownloadOptions) => void;
}

export const useDownloadHelper = (): DownloadHelper => {
  const { handleErrors } = useErrorHandle();
  const [isExporting, setIsExporting] = useState(false);

  const extractFileName = (contentDisposition: string | undefined): string => {
    if (!contentDisposition) return "download";
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    return fileNameMatch?.length === 2 ? fileNameMatch[1] : "download";
  };

  const createDownloadLink = (data: BlobPart, fileName: string): void => {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const downloadFile = ({ apiCall, onSuccess }: DownloadOptions): void => {
    setIsExporting(true);

    apiCall()
      .then((response) => {
        if (response.success && response.data && response.headers) {
          const fileName = extractFileName(response.headers["content-disposition"]);
          createDownloadLink(response.data, fileName);
          onSuccess?.();
        } else if (response.error) {
          handleErrors(response.error);
        }
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return { isExporting, downloadFile };
};
