import { Controller, UseFormReturn } from "react-hook-form";
import { TimeOffCategoryPolicySchema } from "../TimeOffCategoryPolicyEdit.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import { Radio } from "../../../../../ui/radio/Radio.tsx";
import { ApiTimeOffPolicyAccrualAtType } from "../../../../../types/time-off.types.ts";
import { useTranslation } from "react-i18next";

export interface TimeOffPolicyAccrualsProps {
  formHook: UseFormReturn<TimeOffCategoryPolicySchema>;
}

export function TimeOffPolicyAccruals({ formHook }: TimeOffPolicyAccrualsProps) {
  const { t } = useTranslation();
  const { control } = formHook;

  return (
    <Stack>
      <Title
        header={t("time_off:accrual_at_type_title")}
        className={"text-dark/60"}
        size={"xs"}
        paddingBottom
      />
      <Box variant={"border"}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <>
              <Radio
                items={"start"}
                value={ApiTimeOffPolicyAccrualAtType.start_of_accrual_period}
                checked={value == ApiTimeOffPolicyAccrualAtType.start_of_accrual_period}
                onChange={() => {
                  onChange(ApiTimeOffPolicyAccrualAtType.start_of_accrual_period);
                }}
              >
                <Title
                  header={t("time_off:accrual_at_type_at_start_title")}
                  caption={t("time_off:accrual_at_type_at_start_caption")}
                  size={"sm"}
                ></Title>
              </Radio>
              <Radio
                items={"start"}
                value={ApiTimeOffPolicyAccrualAtType.end_of_accrual_period}
                checked={value == ApiTimeOffPolicyAccrualAtType.end_of_accrual_period}
                onChange={() => {
                  onChange(ApiTimeOffPolicyAccrualAtType.end_of_accrual_period);
                }}
              >
                <Title
                  header={t("time_off:accrual_at_type_at_end_title")}
                  caption={t("time_off:accrual_at_type_at_end_caption")}
                  size={"sm"}
                ></Title>
              </Radio>
            </>
          )}
          control={control}
          name={"accrual_at_type"}
        />
      </Box>
    </Stack>
  );
}
