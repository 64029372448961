import { UIKitThemeBase } from "../themes/theme.types.ts";

export interface UIKitCardTheme extends UIKitThemeBase {
  active: {
    on: string;
  };
}

export const cardTheme: UIKitCardTheme = {
  base: "rounded-md shadow-md print:shadow-none p-6",
  active: {
    on: "",
  },
};
