import useModals from "../../../../../ui/modal/modal.store.ts";

import Stack from "../../../../../ui/stack/Stack.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import Modal from "../../../../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../../../../ui/input/Input.tsx";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import * as yup from "yup";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import i18n from "../../../../../i18n.ts";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";
import {
  ApiEmailTemplate,
  ApiEmailTemplateSavePayload,
} from "../../../../../types/email-template.types.ts";
import { useEmailTemplateSave } from "../../../../../queries/use-email-templates.query.ts";
import { RichEditor } from "../../../../../ui/rich-editor/RichEditor.tsx";

export interface EmailTemplateEditModalProps extends ModalProps {
  emailTemplateId: number;
  emailTemplate?: ApiEmailTemplate;
}

export function EmailTemplatesEditModal({
  emailTemplateId,
  emailTemplate,
  ...props
}: EmailTemplateEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const emailTemplateSchema = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
    subject: yup.string().required(i18n.t("errors:field_required")),
    body: yup.string().required(i18n.t("errors:field_required")),
  });

  interface EmailTemplateSchema extends yup.InferType<typeof emailTemplateSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EmailTemplateSchema>({
    mode: "onSubmit",
    resolver: yupResolver(emailTemplateSchema),
    defaultValues: {
      title: emailTemplate?.title,
      subject: emailTemplate?.subject,
      body: emailTemplate?.body,
    },
  });

  const emailTemplateSave = useEmailTemplateSave(control);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload: ApiEmailTemplateSavePayload = {
        title: data.title,
        subject: data.subject,
        body: data.body,
      };
      const savedData = await emailTemplateSave.mutateAsync({
        id: emailTemplateId,
        payload: payload,
      });
      if (emailTemplateId == 0) {
        analyticsService.trackEvent(analyticEvents.emailTemplates.created, {
          [analyticProperties.id]: savedData.id,
        });
      } else {
        analyticsService.trackEvent(analyticEvents.emailTemplates.edited, {
          [analyticProperties.id]: emailTemplateId,
        });
      }
      close(id);
    } catch {
      return;
    }
  });
  return (
    <>
      <Modal
        {...props}
        title={t("core:email_template")}
        withCloser
        size={"lg"}
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button
              type={"submit"}
              size={"lg"}
              isLoading={emailTemplateSave.isPending}
              onClick={onSubmit}
            >
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Input
            required={true}
            label={t("common:title")}
            autoFocus={true}
            type="text"
            {...register("title")}
            error={errors.title?.message}
          />
          <Input
            required={true}
            label={t("core:email_subject")}
            type={"text"}
            {...register("subject", {
              value: undefined,
            })}
            error={errors.subject?.message}
          />
          <Stack>
            <Controller
              render={({ field: { value, onChange } }) => (
                <RichEditor
                  value={value || ""}
                  onChange={onChange}
                  error={errors.body?.message}
                  placeholder={t("core:email_template_enter_body")}
                />
              )}
              control={control}
              name={"body"}
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
}
