import { api } from "./axios.ts";
import {
  ApiApprovalRequest,
  ApiApprovalRequestDetails,
  ApiApprovalRequestPayload,
  ApiApprovalRequestsFilter,
  ApiApprovalRequestTimeOffCheck,
} from "../types/approval-request.types.ts";
import { ApiPagedData } from "../types/filter.types.ts";
import { ApiOperationResult } from "../types/common.types.ts";

export const apiApprovalRequests = {
  search: (filter: ApiApprovalRequestsFilter, signal?: AbortSignal) => {
    return api.post<ApiPagedData<ApiApprovalRequest>>(
      "/api/v1/approval-requests/search",
      filter,
      signal
    );
  },
  getMy: (signal?: AbortSignal) => {
    return api.get<ApiApprovalRequest[]>("/api/v1/approval-requests/my", signal);
  },
  getById: (id: number) => {
    return api.get<ApiApprovalRequestDetails>(`/api/v1/approval-requests/${id}`);
  },
  create: (payload: ApiApprovalRequestPayload) => {
    return api.post<ApiApprovalRequest>("/api/v1/approval-requests", payload);
  },
  update: (id: number, payload: ApiApprovalRequestPayload) => {
    return api.put<ApiApprovalRequest>(`/api/v1/approval-requests/${id}`, payload);
  },
  cancel: (id: number) => {
    return api.post<ApiOperationResult>(`/api/v1/approval-requests/${id}/cancel`, {});
  },
  approve: (id: number) => {
    return api.post<ApiOperationResult>(`/api/v1/approval-requests/${id}/approve`, {});
  },
  reject: (id: number) => {
    return api.post<ApiOperationResult>(`/api/v1/approval-requests/${id}/reject`, {});
  },
  delete: (id: number) => {
    return api.delete<ApiOperationResult>(`/api/v1/approval-requests/${id}`);
  },
  check: (payload: ApiApprovalRequestPayload) => {
    return api.post<ApiApprovalRequestTimeOffCheck>(
      "/api/v1/approval-requests/time-off/check",
      payload
    );
  },
};
