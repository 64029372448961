import Modal from "../../ui/modal/modal.tsx";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../ui/modal/modal.types.ts";
import { ApiEmail } from "../../types/integrations/integration.types.ts";
import Stack from "../../ui/stack/Stack.tsx";
import HtmlViewer from "../html-viewer/HtmlViewer.tsx";
import { ListItem } from "../list-item/ListItem.tsx";
import { ApiFile } from "../../types/common.types.ts";
import { FileItem } from "../file-item/FileItem.tsx";
import Button from "../../ui/button/Button.tsx";
import useModals from "../../ui/modal/modal.store.ts";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../services/analytics-service.ts";
import { apiIntegrations } from "../../api/integrations/integration.api.ts";

export interface EmailViewModalProps extends ModalProps {
  email: ApiEmail;
  onReply: () => void;
}

const formatEmails = (emails: string) => {
  return emails
    .split(",")
    .map((email) => email.trim())
    .join("\n");
};

export const EmailViewModal = ({ email, onReply, ...props }: EmailViewModalProps) => {
  const { t } = useTranslation();
  const { close } = useModals();
  const { id } = { ...props };

  const handleReply = () => {
    onReply();
    close(id);
  };

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.email.viewed, {
      [analyticProperties.id]: email.id,
    });

    apiIntegrations.emailMarkAsRead(email.id);
  });

  return (
    <Modal
      {...props}
      layout={"stretch"}
      size={"lg"}
      title={t("core:email_noun")}
      withCloser
      closeByEscEnabled
      actions={
        !email.is_outgoing ? (
          <Button onClick={handleReply} size={"lg"} className={"w-full"} type={"button"}>
            {t("common:reply")}
          </Button>
        ) : (
          <Button onClick={() => close(id)} size={"lg"} className={"w-full"} type={"button"}>
            {t("common:close")}
          </Button>
        )
      }
    >
      <Stack gap={"md"}>
        <Stack className={"w-full"}>
          <ListItem preTitle={t("core:email_from")} title={email.from_email} />
          {email.cc_email && (
            <ListItem preTitle={t("core:email_cc_email")} title={formatEmails(email.cc_email)} />
          )}
          {email.to_email && (
            <ListItem preTitle={t("core:email_to")} title={formatEmails(email.to_email)} />
          )}
          {email.bcc_email && (
            <ListItem preTitle={t("core:email_bcc_email")} title={formatEmails(email.bcc_email)} />
          )}
          {email.subject && <ListItem preTitle={t("core:email_subject")} title={email.subject} />}
        </Stack>
        <Stack gap="sm">
          {email.body && <HtmlViewer content={email.body} />}
          {email.files.map((file: ApiFile) => (
            <FileItem file={file} />
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
};
