import { useStageSave } from "../../../../../queries/recruit/use-stages.query.ts";
import { ModalProps } from "../../../../../ui/modal/modal.types.ts";
import useModals from "../../../../../ui/modal/modal.store.ts";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import i18n from "../../../../../i18n.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../../services/analytics-service.ts";
import Modal from "../../../../../ui/modal/modal.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { Input } from "../../../../../ui/input/Input.tsx";
import { ServerErrorField } from "../../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../../ui/button/Button.tsx";
import { ApiPipelineStage, StageEmojiDefault } from "../../../../../types/recruit/stages.types.ts";
import { EmojiPickerDropdown } from "../../../../../components/emoji/EmojiPickerDropdown.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import Avatar from "../../../../../ui/avatar/Avatar.tsx";
import FontAwesomeIcon from "../../../../../ui/typography/FontAwesomeIcon.tsx";

export interface StageEditModalProps extends ModalProps {
  stage: ApiPipelineStage;
}

export function StageEditModal({ stage, ...props }: StageEditModalProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const stageSchema = yup.object({
    title: yup.string().required(i18n.t("errors:field_required")),
    emoji: yup.string().required(i18n.t("errors:field_required")),
  });

  interface StageSchema extends yup.InferType<typeof stageSchema> {}

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<StageSchema>({
    mode: "onSubmit",
    resolver: yupResolver(stageSchema),
    defaultValues: {
      title: stage.title,
      emoji: stage.emoji || StageEmojiDefault,
    },
  });

  const stageSave = useStageSave(control);

  const onSubmit = handleSubmit((stageData) => {
    stageSave.mutate(
      {
        id: stage.id,
        payload: {
          title: stageData.title,
          emoji: stageData.emoji,
        },
      },
      {
        onSuccess: (data) => {
          if (stage.id == 0) {
            analyticsService.trackEvent(analyticEvents.pipelines.stageCreated, {
              [analyticProperties.id]: data.id,
            });
          } else {
            analyticsService.trackEvent(analyticEvents.pipelines.stageEdited, {
              [analyticProperties.id]: stage.id,
            });
          }
          close(id);
        },
      }
    );
  });

  return (
    <>
      <Modal
        {...props}
        title={t("ats:stage")}
        withCloser
        actions={
          <Stack gap={"sm"}>
            <ServerErrorField errors={errors} />
            <Button type={"submit"} size={"lg"} isLoading={stageSave.isPending} onClick={onSubmit}>
              {t("common:save")}
            </Button>
          </Stack>
        }
      >
        <form onSubmit={onSubmit}>
          <Stack gap={"2xl"}>
            <Controller
              control={control}
              render={({ field: { value, onChange } }) => (
                <EmojiPickerDropdown
                  onEmojiSelect={(emoji) => {
                    onChange(emoji);
                  }}
                >
                  <Box className={"relative w-40 h-40 group cursor-pointer "}>
                    <Avatar variant={"opaque"} url={undefined} emoji={value} size={"2xl"} />
                    <Box
                      className={
                        "bg-dark/20 opacity-0 group-hover:opacity-100 absolute inset-0 rounded-2xl flex items-center justify-center"
                      }
                    >
                      <FontAwesomeIcon
                        icon={"fa-light fa-icons"}
                        className={"text-[74px] text-light "}
                      />
                    </Box>
                  </Box>
                </EmojiPickerDropdown>
              )}
              name={"emoji"}
            />
            <Input
              required={true}
              label={t("common:title")}
              autoFocus={true}
              type="text"
              {...register("title", {
                value: stage.title,
              })}
              error={errors.title?.message}
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
}
