import { UIKitThemeBase } from "../themes/theme.types.ts";
import React, { ElementType, ReactElement, ReactNode, RefObject } from "react";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";
export interface UIKitListOptionTheme extends UIKitThemeBase {
  cover: string;
  title: string;
  caption: string;
  leftIcon: string;
  rightIcon: string;
}

export const listOptionTheme: UIKitListOptionTheme = {
  base: "",
  cover: "outline-0 py-1 px-2 gap-2 rounded-md hover:bg-dark/5 cursor-pointer transition",
  title: "text-md text-dark/95",
  caption: "text-sm text-dark/60",
  leftIcon: "h-4 w-4 pt-0.5",
  rightIcon: "h-4 w-4 pt-0.5",
};

export type ListOptionProps<T extends ElementType = "div"> = PolymorphicComponentProp<
  T,
  {
    children?: React.ReactNode;
    href?: string;
    blank?: boolean;

    preTitle?: string;
    title?: string;
    caption?: string;
    value?: string;

    preTitleSlot?: ReactNode;
    titleSlot?: ReactNode;
    captionSlot?: ReactNode;
    valueSlot?: ReactNode;

    leftIcon?: ReactElement;
    rightIcon?: ReactElement;
    ref?: RefObject<HTMLDivElement>;
  }
>;
