import {
  EmployeeFieldPermissions,
  PermissionType,
  RuleApplySignType,
  RuleConditionType,
} from "../../../../types/role.types.ts";
import { SelectItem } from "../../../../ui/select/select.types.ts";
import { useTranslation } from "react-i18next";
import {
  EmployeeFieldPermissionConfigItem,
  PermissionsSection,
  RoleApplyToConditionsItems,
  RoleAssignToConditionsItems,
  RuleSignItems,
} from "./role-edit.types.ts";

export interface FieldPermissionConfigItemDescription extends Pick<PermissionType, never> {
  [key: string]: string;
}

export interface FieldPermissionConfigItem {
  title: string;
  field: `employee_permissions.${keyof typeof EmployeeFieldPermissions}`;
  availableOptions: PermissionType[];
  availableOptionsDescription?: FieldPermissionConfigItemDescription;
}

export default function useRoleEditService() {
  const { t } = useTranslation();

  const getEmployeeFieldPermissionsConfig = () => {
    return [
      {
        title: t("core:full_name"),
        type: EmployeeFieldPermissions.employee_name,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_name",
      },
      {
        title: t("core:employee_number"),
        type: EmployeeFieldPermissions.employee_number,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_number",
      },
      {
        title: t("core:hired_on"),
        type: EmployeeFieldPermissions.employee_hired_on,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_hired_on",
      },
      {
        title: t("core:email"),
        type: EmployeeFieldPermissions.employee_email,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_email",
      },
      {
        title: t("core:email_personal"),
        type: EmployeeFieldPermissions.employee_email_personal,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_email_personal",
      },
      {
        title: t("core:phone"),
        type: EmployeeFieldPermissions.employee_phone,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_phone",
      },
      {
        title: t("core:phone_personal"),
        type: EmployeeFieldPermissions.employee_phone_personal,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_phone_personal",
      },
      {
        title: t("core:gender"),
        type: EmployeeFieldPermissions.employee_gender,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_gender",
      },
      {
        title: t("core:birth_date"),
        type: EmployeeFieldPermissions.employee_date_birth,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_date_birth",
      },
      {
        title: t("core:birthday_display_settings"),
        type: EmployeeFieldPermissions.employee_date_birth_display,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_date_birth_display",
        availableOptionsDescription: {
          [PermissionType.none]: t("core:birthday_display_settings_permission_none_description"),
        },
      },
      {
        title: t("common:photo"),
        type: EmployeeFieldPermissions.employee_avatar,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_avatar",
      },
      {
        title: t("common:background"),
        type: EmployeeFieldPermissions.employee_avatar,
        availableOptions: [PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_background",
      },
    ] as EmployeeFieldPermissionConfigItem[];
  };

  const getEmployeeFieldPermissionsConfigAddress = () => {
    return [
      {
        title: t("core:address_line_1"),
        type: EmployeeFieldPermissions.employee_address_address_line_1,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_address_line_1",
      },
      {
        title: t("core:address_line_2"),
        type: EmployeeFieldPermissions.employee_address_address_line_2,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_address_line_2",
      },
      {
        title: t("core:city"),
        type: EmployeeFieldPermissions.employee_address_city,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_city",
      },
      {
        title: t("core:state"),
        type: EmployeeFieldPermissions.employee_address_state,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_state",
      },
      {
        title: t("core:zip"),
        type: EmployeeFieldPermissions.employee_address_zip_code,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_zip_code",
      },
      {
        title: t("core:country"),
        type: EmployeeFieldPermissions.employee_address_country,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_address_country",
      },
    ] as EmployeeFieldPermissionConfigItem[];
  };

  const getEmployeeFieldPermissionsConfigJob = () => {
    return [
      {
        title: t("core:position"),
        type: EmployeeFieldPermissions.employee_position_job_title,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_job_title",
      },
      {
        title: t("common:date_effective_from"),
        type: EmployeeFieldPermissions.employee_position_date,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_date",
      },
      {
        title: t("core:manager"),
        type: EmployeeFieldPermissions.employee_position_reporting_to,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_reporting_to",
      },
      {
        title: t("core:division"),
        type: EmployeeFieldPermissions.employee_position_division,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_division",
      },
      {
        title: t("core:department"),
        type: EmployeeFieldPermissions.employee_position_department,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_department",
      },
      {
        title: t("common:location"),
        type: EmployeeFieldPermissions.employee_position_location,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_location",
      },
      {
        title: t("core:legal_entity"),
        type: EmployeeFieldPermissions.employee_position_legal_entity,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_legal_entity",
      },
      {
        title: t("common:comment"),
        type: EmployeeFieldPermissions.employee_position_comment,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_position_comment",
      },
    ] as EmployeeFieldPermissionConfigItem[];
  };

  const getEmployeeFieldPermissionsCompensation = () => {
    return [
      {
        title: t("core:salary"),
        type: EmployeeFieldPermissions.employee_salary,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_salary",
      },
      {
        title: t("core:salary_description"),
        type: EmployeeFieldPermissions.employee_salary_description,
        availableOptions: [PermissionType.none, PermissionType.view, PermissionType.edit],
        field: "employee_permissions.employee_salary_description",
      },
    ] as EmployeeFieldPermissionConfigItem[];
  };

  const permissionTypesTexts: Record<PermissionType, string> = {
    [PermissionType.none]: t("common:permission_type_none"),
    [PermissionType.view]: t("common:permission_type_view"),
    [PermissionType.edit]: t("common:permission_type_edit"),
  };

  const getPermissionRecruitmentSection = () => {
    return {
      title: t("ats:recruit"),
      groups: [
        {
          title: t("ats:recruit"),
          items: [
            {
              title: t("ats:recruit_module"),
              description: t("ats:recruit_module_description"),
              field: "api_permissions.recruiting_module",
            },
          ],
        },
        {
          title: t("ats:jobs"),
          items: [
            {
              title: t("ats:permissions_show_all_jobs"),
              description: t("ats:permissions_show_all_jobs_description"),
              field: "api_permissions.show_all_jobs",
            },
            {
              title: t("ats:permissions_manage_jobs"),
              description: t("ats:permissions_manage_jobs_description"),
              field: "api_permissions.manage_jobs",
            },
            {
              title: t("ats:permissions_show_salary"),
              description: t("ats:permissions_show_salary_description"),
              field: "api_permissions.show_jobs_salaries",
            },
          ],
        },
        {
          title: t("ats:candidates"),
          items: [
            {
              title: t("ats:permissions_show_all_candidates"),
              description: t("ats:permissions_show_all_candidates_description"),
              field: "api_permissions.show_all_candidates",
            },
            {
              title: t("ats:permissions_manage_candidates"),
              description: t("ats:permissions_manage_candidates_description"),
              field: "api_permissions.manage_candidates",
            },
            {
              title: t("ats:permissions_delete_candidates"),
              description: t("ats:permissions_delete_candidates_description"),
              field: "api_permissions.delete_candidates",
            },
            {
              title: t("ats:permissions_show_candidate_salary"),
              description: t("ats:permissions_show_candidate_salary_description"),
              field: "api_permissions.show_candidates_salaries",
            },
            {
              title: t("ats:permissions_delete_all_comments"),
              description: t("ats:permissions_delete_all_comments_description"),
              field: "api_permissions.delete_all_comments",
            },
          ],
        },
        {
          title: t("common:reporting"),
          items: [
            {
              title: t("ats:report_hiring_pipeline_title"),
              description: t(""),
              field: "api_permissions.reporting_recruiting_stage_flow",
            },
            {
              title: t("ats:report_source_efficiency_title"),
              description: t(""),
              field: "api_permissions.reporting_recruiting_source_efficiency",
            },
            {
              title: t("ats:report_closing_time_title"),
              description: t(""),
              field: "api_permissions.reporting_recruiting_closing_time",
            },
            {
              title: t("ats:report_disqualification_reasons_title"),
              description: t(""),
              field: "api_permissions.reporting_recruiting_disqualification_reasons",
            },
          ],
        },
      ],
    } as PermissionsSection;
  };

  const getPermissionSettingsSection = () => {
    return {
      title: t("common:settings"),
      groups: [
        {
          title: t("common:general"),
          items: [
            {
              title: t("core:organization"),
              description: t("core:permissions_manage_organization_description"),
              field: "api_permissions.settings_organization",
            },
            {
              title: t("core:divisions"),
              description: t("core:permissions_manage_divisions_description"),
              field: "api_permissions.settings_divisions",
            },
            {
              title: t("core:departments"),
              description: t("core:permissions_manage_departments_description"),
              field: "api_permissions.settings_departments",
            },
            {
              title: t("core:positions_and_levels"),
              description: "",
              field: "api_permissions.settings_positions_levels",
            },
            {
              title: t("common:locations"),
              description: t("core:permissions_manage_locations_description"),
              field: "api_permissions.settings_locations",
            },
            {
              title: t("core:legal_entities"),
              description: t("core:permissions_manage_legal_entities"),
              field: "api_permissions.settings_legal_entities",
            },
            {
              title: t("calendar:calendar"),
              description: t("core:permissions_manage_calendar_description"),
              field: "api_permissions.settings_organization",
            },
            {
              title: t("time_off:title"),
              description: t("time_off:settings_description"),
              field: "api_permissions.access_to_leave_timeline",
            },
          ],
        },
        {
          title: t("ats:recruit"),
          items: [
            {
              title: t("ats:pipelines"),
              description: t("ats:permissions_manage_pipelines_description"),
              field: "api_permissions.settings_pipelines",
            },
            {
              title: t("ats:sources"),
              description: t("ats:permissions_manage_sources_description"),
              field: "api_permissions.settings_sources",
            },
            {
              title: t("ats:tags"),
              description: "",
              field: "api_permissions.manage_candidates",
            },
            {
              title: t("core:email_templates"),
              description: "",
              field: "api_permissions.settings_email_templates",
            },
            {
              title: t("ats:disqualification_reasons"),
              description: "",
              field: "api_permissions.settings_candidate_disqualification_reasons",
            },
          ],
        },
        {
          title: t("common:security"),
          items: [
            {
              title: t("core:roles_and_permissions"),
              description: "",
              field: "api_permissions.settings_roles_permissions",
            },
            {
              title: t("data_protection:data_protection"),
              description: "",
              field: "api_permissions.settings_data_protection",
            },
          ],
        },
      ],
    } as PermissionsSection;
  };

  const getPermissionGeneralSection = () => {
    return {
      title: t("common:general"),
      groups: [
        {
          title: t("core:employees"),
          items: [
            {
              title: t("core:permissions_add_new_employees"),
              description: t("core:permissions_add_new_employees_description"),
              field: "api_permissions.add_employees",
            },
            {
              title: t("core:terminate_employee"),
              description: t("core:permissions_terminate_employees_description"),
              field: "api_permissions.dismiss_employees",
            },
            {
              title: t("core:permissions_view_terminated_employees"),
              description: t("core:permissions_view_terminated_employees_description"),
              field: "api_permissions.see_dismissed_employees",
            },
            {
              title: t("core:permissions_view_org_chart"),
              description: t("core:permissions_view_org_chart_description"),
              field: "api_permissions.see_organization_structure",
            },
            {
              title: t("core:permissions_view_departments_chart"),
              description: t("core:permissions_view_departments_chart_description"),
              field: "api_permissions.see_departments_structure",
            },
            {
              title: t("core:permissions_access_to_notes"),
              description: t("core:permissions_access_to_notes_description"),
              field: "api_permissions.see_notes",
            },
            {
              title: t("core:permissions_manage_leave_policies"),
              description: t("core:permissions_manage_leave_policies_description"),
              field: "api_permissions.manage_leave_policies",
            },
            {
              title: t("core:permissions_manage_approval_policy"),
              description: t("core:permissions_manage_approval_policy_description"),
              field: "api_permissions.manage_approval_policy",
            },
          ],
        },
      ],
    } as PermissionsSection;
  };

  const getPermissionSections = () => {
    return [
      getPermissionGeneralSection(),
      getPermissionSettingsSection(),
      getPermissionRecruitmentSection(),
    ] as PermissionsSection[];
  };

  const getAssignCardTitle = (type: RuleConditionType): string => {
    let title = `${type}`;

    RoleAssignToConditionsItems().forEach((item) => {
      if (item.id === type) title = item.title;
    });

    return `${title}`;
  };

  const getSignTitle = (sign: RuleApplySignType): string => {
    if (sign == RuleApplySignType.include) return "";
    let title = `${sign}`;

    RuleSignItems().forEach((item) => {
      if (item.id === sign) title = item.title;
    });

    return title + ": ";
  };

  const getAssignCardDescription = (
    sign: RuleApplySignType,
    entities?: SelectItem[] | undefined
  ): string => {
    return getSignTitle(sign) + entities?.map((entity) => entity.title).join(", ");
  };

  const getApplyCardTitle = (type: RuleConditionType): string => {
    let title = `${type}`;

    RoleApplyToConditionsItems().forEach((item) => {
      if (item.id === type) title = item.title;
    });

    return `${title}`;
  };

  return {
    getEmployeeFieldPermissionsConfig,
    getEmployeeFieldPermissionsConfigAddress,
    getEmployeeFieldPermissionsConfigJob,
    getEmployeeFieldPermissionsCompensation,
    permissionTypesTexts,
    getPermissionSections,
    getAssignCardTitle,
    getApplyCardTitle,
    getSignTitle,
    getAssignCardDescription,
    getPermissionSettingsSection,
    getPermissionRecruitmentSection,
    getPermissionGeneralSection,
  };
}
