import { api } from "../axios.ts";
import { ApiSource, ApiSourceSavePayload } from "../../types/recruit/source.types.ts";
import { ApiOperationResult } from "../../types/common.types.ts";

export const apiSources = {
  getAll: async function () {
    return api.get<ApiSource[]>("/api/v1/applicant-sources");
  },
  create: async function (payload: ApiSourceSavePayload) {
    return api.post<ApiSource>("/api/v1/applicant-sources", payload);
  },
  update: async function (id: number, payload: ApiSourceSavePayload) {
    return api.put<ApiSource>(`/api/v1/applicant-sources/${id}`, payload);
  },
  delete: async function (id: number) {
    return api.delete<ApiOperationResult>(`/api/v1/applicant-sources/${id}`);
  },
};
