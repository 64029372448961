import { twMerge } from "tailwind-merge";
import { ReactNode } from "react";
import Box from "../../ui/box/Box.tsx";

export interface TagProps {
  children: ReactNode;
  color: string;
}

export const Tag = ({ children, color }: TagProps) => {
  return (
    <Box
      className={twMerge(
        "text-sm font-semibold px-2 py-1 border rounded-md align-middle flex items-center"
      )}
      style={{ borderColor: color, color: color }}
    >
      <label className={twMerge(`text-[${color}]`)}>{children}</label>
    </Box>
  );
};
