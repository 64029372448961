import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { ApiErrorException } from "../types/api.types.ts";
import { apiCurrencies } from "../api/currency.api.ts";

const currenciesKeys = createQueryKeys("currencies", {
  all: null,
});

export const useCurrencies = () => {
  return useQuery({
    ...currenciesKeys.all,
    queryFn: async () => {
      const result = await apiCurrencies.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 10 * 1000,
  });
};
