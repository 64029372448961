import { api } from "../axios.ts";
import { ApiPagedData } from "../../types/filter.types.ts";
import {
  ApiEmployeeEmploymentStatus,
  ApiEmployeeEmploymentStatusSave,
} from "../../types/employees/employee-employment-status.types.ts";
import { ApiOperationResult } from "../../types/common.types.ts";

export const apiEmployeeEmploymentStatus = {
  create: async function (id: number, payload: ApiEmployeeEmploymentStatusSave) {
    return api.post<ApiOperationResult>(`/api/v1/employees/${id}/employment-statuses`, payload);
  },
  update: async function (
    id: number,
    positionId: number,
    payload: ApiEmployeeEmploymentStatusSave
  ) {
    return api.put<ApiOperationResult>(
      `/api/v1/employees/${id}/employment-statuses/${positionId}`,
      payload
    );
  },
  delete: async function (employeeId: number, employeePositionId: number) {
    return api.delete<ApiOperationResult>(
      `/api/v1/employees/${employeeId}/employment-statuses/${employeePositionId}`
    );
  },
  search: async function (employeeId: number, pageNumber: number) {
    return api.post<ApiPagedData<ApiEmployeeEmploymentStatus>>(
      `/api/v1/employees/${employeeId}/employment-statuses/search`,
      {
        page_number: pageNumber,
      }
    );
  },
};
