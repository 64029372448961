import { ReactNode } from "react";
import Stack from "../../ui/stack/Stack.tsx";
import Text from "../../ui/typography/Text.tsx";
import Avatar from "../../ui/avatar/Avatar.tsx";

export interface EmptyStateBaseProps {
  emoji: string;
  title: string;
  description?: string;
  primaryButton?: ReactNode;
}

export const EmptyStateBase = ({
  emoji = "😎",
  title,
  description,
  primaryButton,
}: EmptyStateBaseProps) => {
  return (
    <Stack items={"center"} className={"px-6 py-8 w-full h-full flex-grow"} justify={"center"}>
      <Avatar url={undefined} emoji={emoji} size={"xl"} />
      <Text className={"mt-2 text-title text-center"} size={"lg"}>
        {title}
      </Text>
      {description && <Text>{description}</Text>}
      {primaryButton && (
        <Stack direction={"horizontal"} className={"mt-4"}>
          {primaryButton}
        </Stack>
      )}
    </Stack>
  );
};
