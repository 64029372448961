import { UIKitThemeBase } from "../themes/theme.types.ts";

export interface ExpandableRow<T> {
  item: T;
  subRows: ExpandableRow<T>[];
}

export interface UIKitTableTheme extends UIKitThemeBase {
  // container: string;
  // tableWrapper: string;
  table: {
    thead: {
      base: string;
      th: string;
    };
    tbody: {
      tr: string;
      td: string;
    };
  };
}

export const tableTheme: UIKitTableTheme = {
  // container: "inline-block min-w-full py-2",
  // tableWrapper: "overflow-hidden ",
  base: "table-fixed w-full",
  table: {
    thead: {
      base: "border-b border-black/10",
      th: "py-4 font-semibold text-dark/60 text-left text-xs uppercase align-top tracking-wide [&:not(:last-child)]:pr-3 ",
    },
    tbody: {
      tr: "border-b border-black/10 h-14",
      td: "py-2.5 text-dark/60 [&:not(:last-child)]:pr-3 break-words",
    },
  },
};
