import { AppThemeConfig } from "../theme.config.types.ts";
import { AppTheme } from "../theme.types.ts";
import { DefaultAppTheme } from "../default/default.theme.ts";

export const MaxAppThemeConfig: AppThemeConfig = {
  name: "max",
  colorBrandDark: "212deg 31% 30%",
  // colorBrandLight: "210deg 12% 97%",
};

export const MaxAppTheme: AppTheme = {
  ...DefaultAppTheme,
  ...{
    body: {
      base: "text-default text-base bg-gradient-to-r from-[#FCD800] to-[#E1081E]",
    },
  },
};
