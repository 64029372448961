import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar.tsx";
import { useAppTheme } from "../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import { useEmployeeAppConfigStore } from "../stores/employee.store.ts";
import useSidebar from "../hooks/use-sidebar-hook.ts";

// https://codepen.io/tinito/pen/WNXPOKX

export default function AppLayout() {
  const layoutTheme = useAppTheme("layout");
  const { sidebarIsCollapsed } = useEmployeeAppConfigStore();
  const { sidebarIsShowing } = useSidebar();

  return (
    <>
      <div className={twMerge("h-full w-screen", layoutTheme.base)}>
        <aside
          className={twMerge(
            layoutTheme.aside,
            sidebarIsShowing && layoutTheme.variant.withSidebar.aside,
            sidebarIsCollapsed && sidebarIsShowing && layoutTheme.variant.collapsed.aside
          )}
        >
          <Sidebar />
        </aside>
        <main
          className={twMerge(
            layoutTheme.main,
            sidebarIsShowing && layoutTheme.variant.withSidebar.main,
            sidebarIsCollapsed && sidebarIsShowing && layoutTheme.variant.collapsed.main
          )}
        >
          <Outlet />
        </main>
      </div>
    </>
  );
}
