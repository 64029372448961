import { useQuery } from "@tanstack/react-query";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../types/api.types.ts";
import { apiCalendars } from "../api/calendar.api.ts";

const calendarKeys = createQueryKeys("calendars", {
  all: null,
});

export const useCalendars = () => {
  return useQuery({
    ...calendarKeys.all,
    queryFn: async () => {
      const result = await apiCalendars.getAll();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    staleTime: 60 * 1000,
  });
};
