import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import { Input } from "../../../../ui/input/Input.tsx";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import i18n from "../../../../i18n.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useModals from "../../../../ui/modal/modal.store.ts";
import { useTimeOffAdjustment } from "../../../../queries/use-time-offs.query.ts";
import Select from "../../../../ui/select/Select.tsx";
import { useEmployeeTimeOffCategoriesData } from "../../../../queries/employees/use-employees.query.ts";
import Datepicker from "../../../../ui/datepicker/Datepicker.tsx";
import { Radio } from "../../../../ui/radio/Radio.tsx";
import Box from "../../../../ui/box/Box.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { useEffect } from "react";
import {
  ApiTimeOffAdjustmentPayload,
  ApiTimeOffAdjustmentType,
} from "../../../../types/time-off.types.ts";

export interface EmployeeEditTimeOffBalanceModalProps extends ModalProps {
  adjustment: ApiTimeOffAdjustmentPayload;
  adjustmentId?: number;
}

export const EmployeeEditTimeOffBalanceModal = ({
  adjustment,
  adjustmentId,
  ...props
}: EmployeeEditTimeOffBalanceModalProps) => {
  const { t } = useTranslation();
  const { id } = { ...props };
  const { close } = useModals();

  const { data: categories, isLoading: isCategoriesLoading } = useEmployeeTimeOffCategoriesData(
    adjustment.employee_id,
    true
  );

  const isUpdate = !!adjustmentId;

  const BalanceSchemaObject = yup.object({
    category_id: yup.number().required(i18n.t("errors:field_required")),
    date_expire: yup.date().nullable(),
    date_adjustment: yup.date().required(i18n.t("errors:field_required")),
    balance: yup.number().required(i18n.t("errors:field_required")),
    adjustmentType: yup
      .string()
      .oneOf([ApiTimeOffAdjustmentType.increase, ApiTimeOffAdjustmentType.decrease])
      .required(),
  });

  type BalanceSchema = yup.InferType<typeof BalanceSchemaObject>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<BalanceSchema>({
    mode: "onSubmit",
    resolver: yupResolver(BalanceSchemaObject),
    defaultValues: {
      adjustmentType:
        adjustment.balance < 0
          ? ApiTimeOffAdjustmentType.decrease
          : ApiTimeOffAdjustmentType.increase,
      date_adjustment: adjustment.date_adjustment,
      category_id: adjustment.category_id,
      balance: Math.abs(adjustment.balance),
      date_expire: adjustment.date_expire,
    },
  });

  useEffect(() => {
    if (!isUpdate && categories?.length) {
      setValue("category_id", categories[0].id);
    }
  }, [categories]);

  const adjustmentSave = useTimeOffAdjustment(control);

  const onSubmit = handleSubmit((data) => {
    const balance =
      data.adjustmentType === ApiTimeOffAdjustmentType.decrease
        ? -Math.abs(data.balance)
        : Math.abs(data.balance);

    adjustmentSave.mutate(
      {
        id: adjustmentId,
        payload: {
          employee_id: adjustment.employee_id,
          category_id: data.category_id,
          balance,
          date_expire: data.date_expire || null,
          date_adjustment: data.date_adjustment,
        },
      },
      {
        onSuccess: () => {
          analyticsService.trackEvent(analyticEvents.timeOffs.balanceAdjusted, {
            [analyticProperties.id]: data.category_id,
          });
          close(id);
        },
      }
    );
  });

  const adjustmentType = watch("adjustmentType");

  return (
    <Modal
      {...props}
      variant="middle"
      title={t("time_off:edit_balance")}
      layout="base"
      withCloser={true}
      actions={
        <Stack gap="sm">
          <ServerErrorField errors={errors} />
          <Button
            size="lg"
            className="w-full"
            isLoading={adjustmentSave.isPending}
            onClick={onSubmit}
          >
            {t("common:save")}
          </Button>
          <Button
            variant="plain"
            size="lg"
            onClick={() => {
              close(id);
            }}
          >
            {t("common:cancel")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit}>
        <Stack gap={"lg"}>
          <Stack>
            <Controller
              control={control}
              name="adjustmentType"
              render={({ field }) => (
                <Box variant="border">
                  <Radio
                    value={ApiTimeOffAdjustmentType.increase}
                    checked={field.value === ApiTimeOffAdjustmentType.increase}
                    onChange={() => field.onChange(ApiTimeOffAdjustmentType.increase)}
                  >
                    {t("time_off:increase_balance")}
                  </Radio>
                  <Radio
                    value={ApiTimeOffAdjustmentType.decrease}
                    checked={field.value === ApiTimeOffAdjustmentType.decrease}
                    onChange={() => field.onChange(ApiTimeOffAdjustmentType.decrease)}
                  >
                    {t("time_off:decrease_balance")}
                  </Radio>
                </Box>
              )}
            />
          </Stack>
          <Stack>
            <Controller
              control={control}
              name="category_id"
              render={({ field }) => (
                <Select
                  label={t("time_off:category")}
                  required
                  options={categories?.map((category) => ({
                    id: category.id,
                    title: category.title,
                  }))}
                  isLoading={isCategoriesLoading}
                  emptyTitle={t("common:select")}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.category_id?.message}
                  readOnly={isUpdate}
                />
              )}
            />
            {adjustmentType === ApiTimeOffAdjustmentType.increase && (
              <Controller
                control={control}
                name="date_expire"
                render={({ field }) => (
                  <Datepicker
                    label={t("time_off:expiration_date")}
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.date_expire?.message}
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="date_adjustment"
              render={({ field }) => (
                <Datepicker
                  label={t("common:date")}
                  required
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.date_adjustment?.message}
                />
              )}
            />
            <Input
              label={t("time_off:balance")}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              maxLength={3}
              required
              {...register("balance")}
              error={errors.balance?.message}
            />
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};
