import { AppThemeConfig } from "../theme.config.types.ts";
import { AppTheme } from "../theme.types.ts";
import { DefaultAppTheme } from "../default/default.theme.ts";

export const NeoAppThemeConfig: AppThemeConfig = {
  name: "neo",
  colorBrandDark: "202deg 84% 26%",
  // colorBrandLight: "210deg 12% 97%",
};

export const NeoAppTheme: AppTheme = {
  ...DefaultAppTheme,
  ...{
    body: {
      base: "text-default text-base bg-gradient-to-br from-[#0B527B] to-[#F8F8F8]",
    },
  },
};
