import { Page } from "../../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import { Tabs } from "../../../../ui/tabs/Tabs.tsx";
import RolesList from "./components/RolesList.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import EmployeesWithRoles from "./components/EmployeesWithRoles.tsx";
import { PageRolesProps } from "../role.types.ts";
import ButtonLink from "../../../../ui/button/ButtonLink.tsx";

export default function RolesV2({ tab = "list" }: PageRolesProps) {
  const { t } = useTranslation();

  const handleTabChanged = (tabIndex: number) => {
    const urls = ["/settings/roles-and-permissions", "/settings/roles-and-permissions/employees"];

    window.history.replaceState({}, "", urls[tabIndex]);
  };

  return (
    <Page>
      <Page.Header showBack={true} title={t("core:roles_and_permissions")}>
        <ButtonLink
          href={"/settings/roles-and-permissions/new"}
          leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
        >
          {t("common:create_new")}
        </ButtonLink>
      </Page.Header>
      <Page.Content>
        <Stack direction={"vertical"} className={"px-16"}>
          <Tabs onActiveTabChange={handleTabChanged}>
            <Tabs.Item title={t("core:roles")}>
              <RolesList />
            </Tabs.Item>
            <Tabs.Item title={t("core:employees")} active={tab == "employees"}>
              <EmployeesWithRoles />
            </Tabs.Item>
          </Tabs>
        </Stack>
      </Page.Content>
    </Page>
  );
}
