import { ElementType } from "react";
import Box from "../box/Box.tsx";
import { twMerge } from "tailwind-merge";
import { SkeletonProps } from "./skeleton.types.ts";
import { useTheme } from "../themes/provider.tsx";

export default function Skeleton<T extends ElementType = "div">({
  children,
  className,
}: SkeletonProps<T>) {
  const theme = useTheme("skeleton");
  return (
    <>
      <Box className={twMerge(theme.base, className)}>{children}</Box>
    </>
  );
}
