import { useTranslation } from "react-i18next";
import { useApprovalPoliciesAll } from "../../../../../queries/use-approval-policies.query.ts";
import { ApprovalPolicyCategory } from "./ApprovalPolicyCategory.tsx";
import { ApiApprovalPolicyCategory } from "../../../../../types/approval-policy.types.ts";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../../../services/analytics-service.ts";

export function ApprovalTabPolicyCategories() {
  const { t } = useTranslation();
  const { data: policies, isPending: policiesIsLoading } = useApprovalPoliciesAll();

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.approvals.categoriesViewed);
  });

  return (
    <ApprovalPolicyCategory
      title={t("approval:time_off_approvals")}
      caption={t("approval:setup_caption")}
      isLoading={policiesIsLoading}
      policies={
        policies?.filter((policy) => policy.category == ApiApprovalPolicyCategory.time_off) ?? []
      }
    />
  );
}
