import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Stack from "../stack/Stack.tsx";
import { RadioProps } from "./radio.types.ts";

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ children, className, checked, items = "center", ...rest }, ref) => {
    return (
      <label className={twMerge("relative flex items-center cursor-pointer", className)}>
        <Stack direction={"horizontal"} className={"py-2"} gap={"smd"} items={items}>
          <input
            ref={ref}
            type="radio"
            className={twMerge(
              "peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-dark/10 shadow-md checked:bg-dark checked:text-light flex-shrink-0",
              "before:content-[''] before:absolute before:w-2 before:h-2 before:bg-light before:rounded-full before:left-1 before:opacity-0 before:top-1 before:-m-[1px]",
              checked && "before:opacity-100",
              items == "start" && "top-[1px]"
            )}
            checked={checked}
            {...rest}
          />
          {children}
        </Stack>
      </label>
    );
  }
);
