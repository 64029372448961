import {
  ApiApprovalPolicy,
  ApiApprovalPolicyCategory,
} from "../../../../../types/approval-policy.types.ts";
import { Title } from "../../../../../components/title/Title.tsx";
import Stack from "../../../../../ui/stack/Stack.tsx";
import { Card } from "../../../../../ui/card/Card.tsx";
import Skeleton from "../../../../../ui/skeleton/Skeleton.tsx";
import { SettingsCard } from "../../../components/SettingsCard.tsx";
import { useTranslation } from "react-i18next";

interface ApprovalPolicyCategoryProps {
  title: string;
  caption: string;
  isLoading: boolean;
  policies: ApiApprovalPolicy[];
}

export function ApprovalPolicyCategory({
  title,
  caption,
  policies,
  isLoading,
}: ApprovalPolicyCategoryProps) {
  const { t } = useTranslation();

  return (
    <>
      <Title paddingTop={true} header={title} caption={caption} paddingBottom={true} size={"lg"} />
      <Stack
        direction={"horizontal"}
        gap={"md"}
        className={"grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"}
      >
        {isLoading ? (
          <Card className="cursor-pointer min-h-48 flex flex-col justify-between items-start">
            <Stack gap="sm" className={"w-full"}>
              <Skeleton className={"w-1/2 h-6"}></Skeleton>
              <Skeleton className={"w-full h-5"}></Skeleton>
            </Stack>
            <Skeleton className={"w-1/2 h-6"}></Skeleton>
          </Card>
        ) : (
          <>
            {policies.map((policy) => (
              <SettingsCard
                title={policy.title}
                key={policy.id}
                description={""}
                to={`/settings/approval-policies/${policy.id}`}
                extra={t("plurals:employees_count", {
                  count: policy.employees_count ?? 0,
                })}
              />
            ))}
            <SettingsCard
              icon={"fa-plus"}
              title={t("approval:create_title")}
              to={`/settings/approval-policies/new?type=${ApiApprovalPolicyCategory.time_off}`}
              variant={"action"}
            />
          </>
        )}
      </Stack>
    </>
  );
}
