import { SelectItem } from "../ui/select/select.types.ts";
import timeZonesV2 from "../assets/timezones.json";

export interface TimezoneItem extends SelectItem {
  id: string;
  title: string;
  // utc: string[];
}

export default function getTimezones(): TimezoneItem[] {
  // https://gist.github.com/ykessler/3349960
  // v3.0 https://docs.pydantic.dev/latest/api/pydantic_extra_types_timezone_name/#pydantic_extra_types.timezone_name.get_timezones
  const result: TimezoneItem[] = [];
  for (const timezone of timeZonesV2) {
    result.push({
      id: timezone,
      title: timezone,
    });
  }
  result.sort((a, b) => a.title.localeCompare(b.title));
  return result;
}

export const DefaultTimezone = "Europe/London";
