import Box from "../../../../ui/box/Box.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { useCallback, useMemo, useState } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import Skeleton from "../../../../ui/skeleton/Skeleton.tsx";
import { ApiEntityTypes, ApiFile } from "../../../../types/common.types.ts";
import {
  ApiAction,
  ApiActionPayload,
  ApiActionTypes,
  ApiActionVisibilities,
} from "../../../../types/action.types.ts";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import Avatar from "../../../../ui/avatar/Avatar.tsx";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../../../../ui/button/ButtonIcon.tsx";
import { twMerge } from "tailwind-merge";
import { PermissionType } from "../../../../types/role.types.ts";
import { useEmployeeStore } from "../../../../stores/employee.store.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import { ConfirmationPopup } from "../../../../components/confirmation-popup/ConfirmationPopup.tsx";
import { ChangeStageModal } from "../widgets/ChangeStageModal/ChangeStageModal.tsx";
import { FileUpload } from "../../../../components/file-upload/FileUpload.tsx";
import { ApiCandidate } from "../../../../types/recruit/applicant.types.ts";
import { intervalToDuration } from "date-fns";
import {
  useCandidateDelete,
  useCandidateSnooze,
} from "../../../../queries/recruit/use-candidates.query.ts";
import { ActionItem } from "../../../../components/action-item/ActionItem.tsx";
import { useApplicantDetails } from "../../../../queries/recruit/use-applicants.query.ts";
import { EmailSendModal } from "../../../../components/email/EmailSendModal.tsx";
import LinkTo from "../../../../ui/link/LinkTo.tsx";
import {
  getStageEmoji,
  StageDisqualifiedEmoji,
  StageReservedEmoji,
} from "../../../../types/recruit/stages.types.ts";
import {
  useCandidateActionCreate,
  useCandidateActionDelete,
  useCandidateActions,
} from "../../../../queries/recruit/use-candidate-actions.query.ts";
import { AddComment } from "../../../../components/comment-input/AddComment.tsx";
import {
  ApiEventAttendeeResponseStatuses,
  ApiEventEdit,
  ApiEventTypes,
  getMockEventEdit,
} from "../../../../types/event.types.ts";
import useFormatter from "../../../../hooks/use-formatter.hook.ts";
import { EventEditModal } from "../../../../components/event/EventEditModal.tsx";
import LinkButtonTo from "../../../../ui/link/LinkButtonTo.tsx";

export interface CandidateCardProps {
  candidate: ApiCandidate;
  isFirst: boolean;
  collapsed: boolean;
  applicantId: number;
}

export function CandidateCard({ candidate, collapsed, isFirst, applicantId }: CandidateCardProps) {
  const { t } = useTranslation();
  const modalsService = useModals();
  const { renderRelativeDate } = useFormatter();
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const [showAll, setShowAll] = useState(false);
  const { employee } = useEmployeeStore();

  const { data: actions, isLoading: isActionsLoading } = useCandidateActions(
    candidate.id,
    !isCollapsed
  );
  const { data: applicantFull } = useApplicantDetails(applicantId);

  const candidateDelete = useCandidateDelete();
  const candidateActionDelete = useCandidateActionDelete();
  const candidateSnooze = useCandidateSnooze();
  const candidateActionCreate = useCandidateActionCreate();

  const [editAction, setEditAction] = useState<ApiAction | undefined>(undefined);

  const handleChangeStage = useCallback(() => {
    modalsService.openModal(ChangeStageModal, {
      pipelineId: candidate.pipeline.id,
      candidate: candidate,
    });
  }, [candidate]);

  const handleAttachFile = useCallback(
    (file: ApiFile) => {
      const payload: ApiActionPayload = {
        file_ids: [file.id],
        visibility: ApiActionVisibilities.public,
      };
      candidateActionCreate.mutate(
        { id: candidate.id, payload },
        {
          onSuccess: () => {
            analyticsService.trackEvent(analyticEvents.candidates.addedAction, {
              [analyticProperties.id]: candidate.id,
              [analyticProperties.actionType]: "File Added",
              [analyticProperties.source]: "App",
            });
          },
        }
      );
    },
    [candidate]
  );

  const canDeleteCandidate: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.delete_candidates == PermissionType.edit;
  }, [employee]);

  const canDeleteCandidateAction = (action: ApiAction) => {
    if (employee == undefined) return false;
    if (employee.permissions.delete_all_comments == PermissionType.edit) return true;
    if (action.author.id != employee.id) return false;

    // Простые люди могут удалять события, после отмены события
    // if (action.event && action.event.is_cancelled) return true;

    return (
      [
        ApiActionTypes.stage_changed,
        ApiActionTypes.comment_added,
        ApiActionTypes.disqualified,
        ApiActionTypes.qualified,
      ].indexOf(action.type) !== -1
    );
  };

  const canEditCandidateAction = (action: ApiAction) => {
    return (
      employee != undefined &&
      action.author.id === employee?.id &&
      [
        ApiActionTypes.stage_changed,
        ApiActionTypes.comment_added,
        ApiActionTypes.disqualified,
        ApiActionTypes.qualified,
      ].indexOf(action.type) !== -1
    );
  };

  const canManageCandidate: boolean = useMemo(() => {
    return employee != undefined && employee.permissions.manage_candidates == PermissionType.edit;
  }, [employee]);

  const daysOverdue = useMemo(() => {
    if (!candidate.datetime_stage_deadline || !candidate.stage_is_overdue) return 0;
    const interval = intervalToDuration({
      start: candidate.datetime_stage_deadline,
      end: new Date(),
    });
    return (interval.days || 0) + 1;
  }, [candidate]);

  const handleSnooze = useCallback(() => {
    candidateSnooze.mutate(candidate.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.candidates.snoozed, {
          [analyticProperties.id]: candidate.id,
        });
      },
    });
  }, [candidate]);

  const handleEditAction = useCallback(
    (action: ApiAction) => {
      if (action.type === ApiActionTypes.comment_added) {
        setEditAction(action);
        return;
      }
      modalsService.openModal(ChangeStageModal, {
        pipelineId: candidate.pipeline.id,
        action: action,
        // candidate: candidate,
      });
    },
    [candidate]
  );

  const handleDeleteAction = useCallback(
    (actionId: number) => {
      modalsService.openModal(ConfirmationPopup, {
        question: t("common:delete"),
        text: t("common:delete_confirm"),
        acceptTitle: t("common:delete"),
        onAccept: async function () {
          try {
            await candidateActionDelete.mutateAsync({
              applicantId: applicantId,
              candidateId: candidate.id,
              actionId: actionId,
            });
            analyticsService.trackEvent(analyticEvents.candidates.actionDeleted, {
              [analyticProperties.id]: candidate.id,
            });
          } catch {
            return;
          }
        },
      });
    },
    [candidate]
  );

  const handleDelete = useCallback(async () => {
    modalsService.openModal(ConfirmationPopup, {
      question: t("ats:unassign_from_job_title"),
      text: t("ats:unassign_from_job_description"),
      acceptTitle: t("common:yes"),
      onAccept: async function () {
        try {
          await candidateDelete.mutateAsync(candidate.id);
          analyticsService.trackEvent(analyticEvents.candidates.deleted, {
            [analyticProperties.id]: candidate.id,
          });
        } catch {
          return;
        }
      },
    });
  }, [candidate]);

  const handleSendEmail = useCallback(() => {
    modalsService.openModal(EmailSendModal, {
      candidateId: candidate.id,
      applicantEmail: applicantFull?.contacts.email || "",
    });
  }, [applicantFull]);

  const handleAddEvent = useCallback(() => {
    if (employee) {
      const eventToEdit: ApiEventEdit = getMockEventEdit(employee);
      eventToEdit.type = ApiEventTypes.interview;
      eventToEdit.interview = {
        candidate_id: candidate.id,
        hide_salary: true,
      };
      if (applicantFull && applicantFull.contacts.email) {
        eventToEdit.suggestedAttendees = [
          {
            email: applicantFull.contacts.email,
            is_organizer: false,
            response_status: ApiEventAttendeeResponseStatuses.needs_action,
          },
        ];
      }

      modalsService.openModal(EventEditModal, {
        event: eventToEdit,
      });
    }
  }, [applicantFull, employee]);

  return (
    <Stack
      gap={"md"}
      className={twMerge("py-6 px-6 border-dark/5 border-b", isCollapsed ? undefined : "bg-light")}
    >
      <Stack gap={"md"}>
        <Stack justify={"between"} direction={"horizontal"}>
          {candidate.disqualification_reason ? (
            candidate.reserved === true ? (
              <Avatar
                size={isFirst ? "xl" : "md"}
                url={null}
                emoji={StageReservedEmoji}
                variant={"default"}
              />
            ) : (
              <Avatar
                size={isFirst ? "xl" : "md"}
                url={null}
                emoji={StageDisqualifiedEmoji}
                variant={"default"}
              />
            )
          ) : (
            <Avatar
              size={isFirst ? "xl" : "md"}
              url={null}
              variant={"default"}
              emoji={getStageEmoji(candidate.pipeline_stage)}
            />
          )}
          <Stack direction={"horizontal"}>
            <ButtonIcon
              variant={"plain"}
              onClick={() => {
                setIsCollapsed(!isCollapsed);
              }}
              icon={
                <FontAwesomeIcon
                  icon={twMerge("fa-light", isCollapsed ? "fa-chevron-down" : "fa-chevron-up")}
                />
              }
            />
          </Stack>
        </Stack>
        <Stack gap={"sm"}>
          <Stack>
            <Text size={"lg"} className={"text-title"}>
              {candidate.disqualification_reason == null
                ? candidate.pipeline_stage.title
                : candidate.reserved === true
                  ? t("ats:action_reserved")
                  : t("ats:action_disqualified")}
            </Text>
            <Stack direction={"horizontal"}>
              <LinkTo
                variant={"secondary"}
                to={`/recruitment/candidates/?job_id=${candidate.job.id}`}
                className={"text-secondary flex gap-sm"}
              >
                {`${candidate.job.title}`}
              </LinkTo>
            </Stack>
          </Stack>
          {isCollapsed && candidate.last_action && (
            <Text>{`${t("common:updated")} ${renderRelativeDate(candidate.last_action.datetime_created_at)}`}</Text>
          )}
        </Stack>
      </Stack>
      {!isCollapsed && (
        <Stack gap={"sm"}>
          {candidate.stage_is_overdue && (
            <Box>
              <Text className={"text-warning font-semibold"}>
                {`${t("ats:overdue")}: ${t("plurals:plural_day", { count: daysOverdue + candidate.stage_time_limit })}`}
              </Text>
              <Text>{" · "}</Text>
              <Text className={"text-secondary"}>
                {`${t("ats:overdue_standard_time")} ${t("plurals:plural_day", { count: candidate.stage_time_limit })}.`}
              </Text>
            </Box>
          )}

          <Stack direction={"horizontal"} gap={"sm"} className={"flex-wrap"}>
            {canManageCandidate && (
              <Button
                variant={"primary"}
                onClick={handleChangeStage}
                leftIcon={<FontAwesomeIcon icon={"fa-light fa-right-left-large"} />}
              >
                {t("ats:change_stage")}
              </Button>
            )}
            {candidate.stage_is_overdue && (
              <Button
                variant={"secondary"}
                isLoading={candidateSnooze.isPending}
                onClick={handleSnooze}
              >
                {t("common:snooze")}
              </Button>
            )}
            <Button
              onClick={handleSendEmail}
              leftIcon={<FontAwesomeIcon icon={"fa-light fa-envelope text-lg"} />}
              variant={"secondary"}
            ></Button>
            <Button
              onClick={() => handleAddEvent()}
              leftIcon={<FontAwesomeIcon icon={"fa-light fa-calendar-plus text-lg"} />}
              variant={"secondary"}
            ></Button>
            <Box>
              <FileUpload
                entityId={candidate.id}
                entityType={ApiEntityTypes.candidate}
                afterSend={handleAttachFile}
              />
            </Box>
            <Box>
              {canDeleteCandidate && (
                <DropdownMenu
                  trigger={
                    <Button
                      className={"w-full"}
                      variant={"secondary"}
                      leftIcon={<FontAwesomeIcon icon="fa-light fa-ellipsis text-lg" />}
                    />
                  }
                >
                  <Button
                    onClick={handleDelete}
                    leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                    variant={"menu"}
                  >
                    {t("ats:unassign_from_job_title")}
                  </Button>
                </DropdownMenu>
              )}
            </Box>
          </Stack>
        </Stack>
      )}
      {!isCollapsed && <AddComment entityId={candidate.id} entityType={ApiEntityTypes.candidate} />}
      {isActionsLoading && <Skeleton className="w-full h-10" />}
      {!isCollapsed && (
        <Stack>
          {actions &&
            actions.slice(0, showAll ? actions.length : 5).map((action) =>
              editAction && editAction.id === action.id ? (
                <AddComment
                  action={action}
                  entityId={action.item_id}
                  entityType={ApiEntityTypes.candidate}
                  onSaved={() => {
                    setEditAction(undefined);
                  }}
                />
              ) : (
                <ActionItem
                  action={action}
                  key={`comment-v2-${action.id}`}
                  actionsSlot={
                    (canEditCandidateAction(action) || canDeleteCandidateAction(action)) && (
                      <DropdownMenu
                        trigger={
                          <FontAwesomeIcon
                            icon={"fa-light fa-ellipsis"}
                            className={"cursor-pointer"}
                          />
                        }
                      >
                        {canEditCandidateAction(action) && (
                          <Button
                            onClick={() => handleEditAction(action)}
                            leftIcon={<FontAwesomeIcon icon={"fa-light fa-pen-to-square"} />}
                            variant={"menu"}
                          >
                            {t("common:edit")}
                          </Button>
                        )}
                        {canDeleteCandidateAction(action) && (
                          <Button
                            onClick={() => handleDeleteAction(action.id)}
                            leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                            variant={"menu"}
                          >
                            {t("common:delete")}
                          </Button>
                        )}
                      </DropdownMenu>
                    )
                  }
                />
              )
            )}
          {actions && actions.length > 5 && (
            <Box>
              <LinkButtonTo
                onClick={() => {
                  setShowAll(!showAll);
                }}
                size={"sm"}
                variant={"secondary"}
              >
                {showAll ? t("common:collapse") : t("common:show_more")}
              </LinkButtonTo>
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
}
