import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Fragment, useMemo } from "react";
import { SidebarConfigItem } from "../sidebar-service.tsx";
import Stack from "../../../ui/stack/Stack.tsx";
import Box from "../../../ui/box/Box.tsx";
import { SidebarItem } from "./SidebarItem.tsx";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { getSettingsVisibleToEmployee } from "../../../pages/settings/index/settings.types.ts";
import { Title } from "../../title/Title.tsx";
import { SidebarHeader } from "./SidebarHeader.tsx";

export const SidebarSettings = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const menuConfig = useMemo(() => {
    const items = [
      {
        title: t("common:overview"),
        to: "/settings",
        isActive: false,
      },
    ] as SidebarConfigItem[];
    for (let i = 0; i < items.length; i++) {
      items[i].isActive = pathname.endsWith(items[i].to);
    }
    return items;
  }, [pathname]);

  const { employee } = useEmployeeStore();

  const menu = useMemo(() => {
    if (!employee) return [];

    return getSettingsVisibleToEmployee(employee);
  }, [employee]);

  return (
    <>
      <SidebarHeader title={t("common:settings")} />
      <Stack className={"px-4"}>
        <Stack gap={"sm"}>
          {menuConfig.map((item, index) => (
            <SidebarItem
              to={item.to || "/"}
              key={"sidebar-" + index}
              isActive={item.isActive}
              title={item.title}
            />
          ))}
        </Stack>
        {menu.map((item, index) => (
          <Fragment key={`sidebar-menu-group-${index}`}>
            <Box className={"px-2"}>
              <Title header={item.title} size={"xs"} paddingBottom paddingTop />
            </Box>
            <Stack gap={"xs"}>
              {item.items.map((settingsItem) => (
                <SidebarItem
                  key={`'secondary-menu-${settingsItem.title}`}
                  to={settingsItem.to}
                  isActive={pathname.startsWith(settingsItem.to)}
                  title={settingsItem.menuTitle}
                />
              ))}
            </Stack>
          </Fragment>
        ))}
      </Stack>
    </>
  );
};
