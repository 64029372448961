import {
  EmployeeFieldPermissions,
  EmployeeFieldPermissionsKeys,
  Permissions,
  PermissionsKeys,
  PermissionType,
  RuleApplySignType,
  RuleConditionType,
  RoleType,
} from "../../../../types/role.types.ts";
import * as yup from "yup";
import { MixedSchema } from "yup";
import i18n from "../../../../i18n.ts";
import { SelectItem } from "../../../../ui/select/select.types.ts";

export interface EmployeeFieldPermissionConfigItem {
  title: string;
  field: `employee_permissions.${keyof typeof EmployeeFieldPermissions}`;
  type: EmployeeFieldPermissions;
  availableOptions: PermissionType[];
}

export interface PermissionsItem {
  title: string;
  description?: string;
  field: `api_permissions.${keyof typeof Permissions}`;
}

export interface PermissionsSectionGroup {
  title: string;
  items: PermissionsItem[];
}

export interface PermissionsSection {
  title: string;
  groups: PermissionsSectionGroup[];
}

type ApiPermissionsSchemaType = {
  [key in PermissionsKeys]: MixedSchema<PermissionType>;
};

type EmployeePermissionSchemaType = {
  [key in EmployeeFieldPermissionsKeys]: MixedSchema<PermissionType>;
};
export const EditRoleSchemaObject = yup.object({
  title: yup.string().required(),
  description: yup.string().default(""),
  type: yup.mixed<RoleType>().oneOf(Object.values(RoleType)).required(),
  enable_apply_to: yup.boolean().default(false),
  api_permissions: yup.object().shape(
    Object.keys(Permissions).reduce((acc, key) => {
      return {
        ...acc,
        [key]: yup.mixed<PermissionType>().oneOf(Object.values(PermissionType)).required(),
      };
    }, {}) as ApiPermissionsSchemaType
  ),
  // api_permissions: yup.object().shape(api_permissions),
  employee_permissions: yup.object().shape(
    Object.keys(EmployeeFieldPermissions).reduce((acc, key) => {
      return {
        ...acc,
        [key]: yup.mixed<PermissionType>().oneOf(Object.values(PermissionType)).required(),
      };
    }, {}) as EmployeePermissionSchemaType
  ),
  assign_to_list: yup
    .array()
    .required()
    .of(
      yup.object({
        condition_type: yup
          .mixed<RuleConditionType>()
          .oneOf(Object.values(RuleConditionType))
          .required(),
        sign: yup.number().oneOf([RuleApplySignType.include, RuleApplySignType.exclude]).required(),
        condition_ids: yup.array().of(yup.number().required()).required(),
        condition_entities: yup.array(),
      })
    ),
  apply_to_list: yup
    .array()
    .required()
    .of(
      yup.object({
        condition_type: yup
          .mixed<RuleConditionType>()
          .oneOf(Object.values(RuleConditionType))
          .required(),
        sign: yup.number().oneOf([RuleApplySignType.include, RuleApplySignType.exclude]).required(),
        condition_ids: yup.array().of(yup.number().required()).required(),
        condition_entities: yup.array(),
      })
    ),
});

export type EditRoleSchema = yup.InferType<typeof EditRoleSchemaObject>;

export const RoleAssignToConditionsItems = () => {
  return [
    {
      id: RuleConditionType.organization,
      title: i18n.t("core:organization"),
    },
    {
      id: RuleConditionType.division,
      title: i18n.t("core:division"),
    },
    {
      id: RuleConditionType.department,
      title: i18n.t("core:department"),
    },
    {
      id: RuleConditionType.position,
      title: i18n.t("core:position"),
    },
    {
      id: RuleConditionType.employee,
      title: i18n.t("core:employee"),
    },
    {
      id: RuleConditionType.heads_of_departments,
      title: i18n.t("core:heads_of_departments"),
    },
    // {
    //   id: RuleConditionType.heads_of_teams,
    //   title: i18n.t("core:teamleads"),
    // },
    {
      id: RuleConditionType.location,
      title: i18n.t("common:location"),
    },
    {
      id: RuleConditionType.legal_entity,
      title: i18n.t("core:legal_entity"),
    },
  ] as SelectItem[];
};

export const RoleApplyToConditionsItems = () => {
  return [
    {
      id: RuleConditionType.myself,
      title: i18n.t("core:rule_myself"),
    },
    {
      id: RuleConditionType.my_departments,
      title: i18n.t("core:rule_my_departments"),
    },
    {
      id: RuleConditionType.my_team,
      title: i18n.t("core:rule_my_team"),
    },
    {
      id: RuleConditionType.organization,
      title: i18n.t("core:organization"),
    },
    {
      id: RuleConditionType.division,
      title: i18n.t("core:division"),
    },
    {
      id: RuleConditionType.department,
      title: i18n.t("core:department"),
    },
    {
      id: RuleConditionType.position,
      title: i18n.t("core:position"),
    },
    {
      id: RuleConditionType.employee,
      title: i18n.t("core:employee"),
    },
    {
      id: RuleConditionType.location,
      title: i18n.t("common:location"),
    },
    {
      id: RuleConditionType.legal_entity,
      title: i18n.t("core:legal_entity"),
    },
  ] as SelectItem[];
};

export const RuleSignItems = () => {
  return [
    {
      id: RuleApplySignType.include,
      title: i18n.t("common:include"),
    },
    {
      id: RuleApplySignType.exclude,
      title: i18n.t("common:exclude"),
    },
  ] as SelectItem[];
};
