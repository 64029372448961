import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiErrorException } from "../types/api.types.ts";
import { apiDataProtection } from "../api/data-protection.api.ts";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../hooks/use-error-handle.hook.tsx";
import {
  ApiDataProtectionResponsePayload,
  ApiDataProtectionRequestsFilter,
  ApiDataProtectionSettingsPayload,
  ApiDataProtectionIncreaseDaysPayload,
  DataProtectionObjectTypes,
} from "../types/data-protection.types.ts";
import { applicantsKeys } from "./recruit/use-applicants.query.ts";

export const dataProtectionKeys = createQueryKeys("dataProtection", {
  accept: null,
  decline: null,
  editRequest: null,
  deleteRequest: null,
  settings: null,
  consentData: null,
  increaseDays: null,
  searchRequests: (filter: ApiDataProtectionRequestsFilter) => ({
    queryKey: ["searchRequests", filter],
    queryFn: async ({ signal }) => {
      const result = await apiDataProtection.search(filter, signal);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useDataProtectionSettings = (enabled = true) => {
  return useQuery({
    ...dataProtectionKeys.settings,
    queryFn: async () => {
      const result = await apiDataProtection.settings();
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    enabled: enabled,
  });
};

export const useDataProtectionConsentData = (uuid: string | undefined) => {
  return useQuery({
    ...dataProtectionKeys.consentData,
    queryFn: async () => {
      const result = await apiDataProtection.consentData(uuid ?? "");
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    enabled: !!uuid,
  });
};

export const useDataProtectionSearchRequests = (filter: ApiDataProtectionRequestsFilter) => {
  return useQuery({
    ...dataProtectionKeys.searchRequests(filter),
  });
};

export const useDataProtectionAccept = (answer: ApiDataProtectionResponsePayload) => {
  return useMutation({
    ...dataProtectionKeys.accept,
    mutationFn: async () => {
      const result = await apiDataProtection.accept(answer);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  });
};

export const useDataProtectionEditRequest = (answer: ApiDataProtectionResponsePayload) => {
  return useMutation({
    ...dataProtectionKeys.editRequest,
    mutationFn: async () => {
      const result = await apiDataProtection.editRequest(answer);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  });
};

export const useDataProtectionDeleteRequest = (answer: ApiDataProtectionResponsePayload) => {
  return useMutation({
    ...dataProtectionKeys.deleteRequest,
    mutationFn: async () => {
      const result = await apiDataProtection.deleteRequest(answer);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  });
};

export const useDataProtectionDecline = (answer: ApiDataProtectionResponsePayload) => {
  return useMutation({
    ...dataProtectionKeys.decline,
    mutationFn: async () => {
      const result = await apiDataProtection.decline(answer);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  });
};

export const useDataProtectionSettingsSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async function (payload: ApiDataProtectionSettingsPayload) {
      const result = await apiDataProtection.updateSettings(payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: dataProtectionKeys.settings.queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export interface useDataProtectionIncreaseDaysParams {
  objectId: number;
  payload: ApiDataProtectionIncreaseDaysPayload;
}

export const useDataProtectionIncreaseDays = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async function ({ objectId, payload }: useDataProtectionIncreaseDaysParams) {
      const result = await apiDataProtection.increaseDays(objectId, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);

      if (DataProtectionObjectTypes.applicant === "applicant") {
        queryClient.invalidateQueries({
          queryKey: applicantsKeys.detail(objectId).queryKey,
        });
      }

      queryClient.invalidateQueries({
        queryKey: dataProtectionKeys.searchRequests._def,
      });

      return result.data;
    },
    onSuccess: () => {},
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};
