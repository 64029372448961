import { api } from "./axios.ts";
import { ApiLegalEntity, ApiLegalEntitySavePayload } from "../types/legal-entity.types.ts";

export const apiLegalEntities = {
  getAll: () => {
    return api.get<ApiLegalEntity[]>(`/api/v1/legal-entities`);
  },
  create: (payload: ApiLegalEntitySavePayload) => {
    return api.post<ApiLegalEntity>(`/api/v1/legal-entities`, payload);
  },
  update: (id: number, payload: ApiLegalEntitySavePayload) => {
    return api.put<ApiLegalEntity>(`/api/v1/legal-entities/${id}`, payload);
  },
  delete: (id: number) => {
    return api.delete(`/api/v1/legal-entities/${id}`);
  },
};
