import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ModalProps } from "../../../../ui/modal/modal.types.ts";
import useModals from "../../../../ui/modal/modal.store.ts";
import { ServerErrorField } from "../../../../hooks/use-error-handle.hook.tsx";
import {
  ApiIntegration,
  ApiIntegrationGoogleCalendarSave,
} from "../../../../types/integrations/integration.types.ts";
import { useGoogleCalendarManage } from "../../../../queries/integrations/use-integrations.query.ts";
import Modal from "../../../../ui/modal/modal.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import Button from "../../../../ui/button/Button.tsx";
import { Textarea } from "../../../../ui/textarea/Textarea.tsx";
import { Title } from "../../../../components/title/Title.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { Switch } from "../../../../ui/switch/Switch.tsx";
import { useEffectOnce } from "react-use";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import LinkTo from "../../../../ui/link/LinkTo.tsx";
import { Input } from "../../../../ui/input/Input.tsx";
import { useState } from "react";

export interface SettingsIntegrationGoogleCalendarProps extends ModalProps {
  integration?: ApiIntegration;
}

export function SettingsIntegrationGoogleCalendarModal({
  integration,
  ...props
}: SettingsIntegrationGoogleCalendarProps) {
  const { close } = useModals();
  const { id } = { ...props };
  const { t } = useTranslation();

  const SettingsIntegrationGoogleCalendarSchemaObject = yup.object({
    is_active: yup.boolean().required(),
    identity: yup.string(),
    credentials: yup.string(),
  });

  type SettingsIntegrationGoogleCalendarSchema = yup.InferType<
    typeof SettingsIntegrationGoogleCalendarSchemaObject
  >;

  const [isActive, setIsActive] = useState<boolean>(integration?.is_active || false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SettingsIntegrationGoogleCalendarSchema>({
    mode: "onSubmit",
    resolver: yupResolver(SettingsIntegrationGoogleCalendarSchemaObject),
    defaultValues: {
      is_active: integration?.is_active,
      credentials: integration?.credentials ?? "",
      identity: integration?.identity,
    },
  });

  const manageIntegrationGoogleCalendar = useGoogleCalendarManage(control);

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.integrations.manageViewed, {
      [analyticProperties.source]: "Google Calendar",
    });
  });

  const onSubmit = handleSubmit((schema) => {
    const payload: ApiIntegrationGoogleCalendarSave = {
      is_active: schema.is_active,
      credentials: schema.credentials,
      identity: schema.identity,
    };

    manageIntegrationGoogleCalendar.mutate(payload, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.integrations.edited, {
          [analyticProperties.source]: "Google Calendar",
        });

        close(id);
      },
    });
  });

  return (
    <Modal
      {...props}
      size={"lg"}
      title={t("integrations:google_calendar_title")}
      withCloser
      closeByEscEnabled
      actions={
        <Stack gap={"sm"}>
          <ServerErrorField errors={errors} />
          <Button
            onClick={onSubmit}
            isLoading={manageIntegrationGoogleCalendar.isPending}
            size={"lg"}
            className={"w-full"}
            type={"submit"}
          >
            {t("common:save")}
          </Button>
        </Stack>
      }
    >
      <form onSubmit={onSubmit} className={"flex flex-col flex-grow"}>
        <Title
          size={"md"}
          header={t("integrations:google_calendar_install_overview")}
          paddingBottom
        />
        <Stack gap={"md"}>
          <Text className={"whitespace-pre-line"}>
            {t("integrations:google_calendar_install_description")}
          </Text>
          <Text>
            {t("integrations:google_calendar_install_help")}{" "}
            <LinkTo
              target={"_blank"}
              to={"https://help.skailer.com/en/articles/10008437-google-calendar-integration"}
            >
              {t("integrations:google_calendar_install_help_link")}
            </LinkTo>
            .
          </Text>
        </Stack>
        <Title header={t("integrations:manage")} paddingBottom paddingTop />
        <Stack gap={"sm"}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <Switch
                checked={value}
                onChange={(newValue) => {
                  onChange(newValue);
                  setIsActive(newValue);
                }}
                size={"sm"}
                title={t("integrations:google_calendar_active_switch")}
                description={t("integrations:google_calendar_active_switch_description")}
              />
            )}
            control={control}
            name={"is_active"}
          />
          {isActive && (
            <>
              <Input
                label={t("integrations:google_calendar_identity")}
                type={"text"}
                {...register("identity")}
                error={errors.identity?.message}
              />
              <Textarea
                maxHeight={300}
                label={t("integrations:google_calendar_credentials")}
                error={errors.credentials?.message}
                {...register("credentials")}
              ></Textarea>
            </>
          )}
        </Stack>
      </form>
    </Modal>
  );
}
