import { ComponentProps } from "react";
import { UIKitThemeBase } from "../themes/theme.types.ts";
import { ApiPaginationMetadata } from "../../types/filter.types.ts";

export interface PaginationProps extends ComponentProps<"nav"> {
  showingLabel: string;
  currentPage: number;
  onPageChange: (page: number) => void;
  totalPages: number;
}

export interface PaginationBlockProps {
  metadata?: ApiPaginationMetadata;
  onPageChange: (page: number) => void;
}

export interface UIKitPaginationTheme extends UIKitThemeBase {
  pages: {
    base: string;
    left: string;
    right: string;
    number: string;
    active: {
      on: string;
      off: string;
    };
  };
}

export const paginationTheme: UIKitPaginationTheme = {
  base: "flex gap-1",
  pages: {
    base: "inline-flex items-center px-2 py-1 enabled:cursor-pointer disabled:text-secondary rounded-md",
    left: "bg-dark/5",
    right: "bg-dark/5",
    number: "",
    active: {
      on: "bg-brand-dark text-white",
      off: "bg-dark/5 enabled:hover:bg-dark/10 ",
    },
  },
};
