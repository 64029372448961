import { Rule, RuleConditionType } from "./role.types.ts";

export interface ApiApprovalPolicy {
  id: number;
  title: string;
  category: ApiApprovalPolicyCategory;
  flow_processing_type: ApiApprovalPolicyType;
  flow_skip: boolean;
  flow_rules: Rule[];
  assign_to: Rule[];
  employees_count?: number;
}

export interface ApiApprovalPolicyFlowRule {
  condition_type: RuleConditionType;
  condition_ids?: (number | string)[];
}

export interface ApiApprovalPolicySettings {
  is_enabled: boolean;
}

export type ApiApprovalPolicyPayload = Omit<ApiApprovalPolicy, "id">;

export enum ApiApprovalPolicyCategory {
  time_off = "time_off",
  compensation = "compensation",
}

export enum ApiApprovalPolicyType {
  full = "full",
  any = "any",
}

export interface ApprovalSettingsProps {
  tab: ApiApprovalSettingsTabs;
}

export enum ApiApprovalSettingsTabs {
  settings = "settings",
  history = "history",
}
