import { createQueryKeys } from "@lukemorales/query-key-factory";
import { ApiErrorException } from "../../types/api.types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Control, FieldValues } from "react-hook-form";
import useErrorHandle from "../../hooks/use-error-handle.hook.tsx";
import { apiEmployees } from "../../api";
import { ApiEmployeeAssignPositionPayload } from "../../types/employees/employee-position.types.ts";
import { employeesKeys } from "./use-employees.query.ts";

export const employeePositionsKeys = createQueryKeys("employeePositions", {
  search: (employeeId: number, pageNumber: number) => ({
    queryKey: [employeeId, pageNumber],
    queryFn: async () => {
      const result = await apiEmployees.positionSearch(employeeId, pageNumber);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
  }),
});

export const useEmployeePositionsSearch = (employeeId: number, page: number) => {
  return useQuery({
    ...employeePositionsKeys.search(employeeId, page),
  });
};

export interface SaveParams {
  employeeId: number;
  positionId: number;
  payload: ApiEmployeeAssignPositionPayload;
}

export const useEmployeePositionSave = <T extends FieldValues>(
  control: Control<T> | undefined = undefined
) => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();
  return useMutation({
    mutationFn: async ({ employeeId, positionId, payload }: SaveParams) => {
      const result =
        positionId == 0
          ? await apiEmployees.positionCreate(employeeId, payload)
          : await apiEmployees.positionUpdate(employeeId, positionId, payload);
      if (result.error || !result.data) throw new ApiErrorException(result.error);
      return result.data;
    },

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        // todo обнулять только нужного сотрудника
        queryKey: employeePositionsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: employeesKeys.detail(variables.employeeId).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error, control);
    },
  });
};

export const useEmployeePositionDelete = () => {
  const queryClient = useQueryClient();
  const { onError } = useErrorHandle();

  return useMutation({
    mutationFn: async ({ employeeId, positionId }: { employeeId: number; positionId: number }) => {
      const result = await apiEmployees.positionDelete(employeeId, positionId);
      if (result.error) throw new ApiErrorException(result.error);
      return result.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: employeePositionsKeys.search._def,
      });
      queryClient.invalidateQueries({
        queryKey: employeesKeys.detail(variables.employeeId).queryKey,
      });
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
