import {
  useDataProtectionAccept,
  useDataProtectionConsentData,
  useDataProtectionDecline,
} from "../../../queries/use-data-protection.query.ts";
import {
  ApiDataProtectionResponsePayload,
  DataProtectionResponsePage,
} from "../../../types/data-protection.types.ts";
import useDataProtectionHook from "../../../hooks/use-data-protection.hook.ts";
import { useParams } from "react-router-dom";
import { useState } from "react";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import Stack from "../../../ui/stack/Stack.tsx";
import { Title } from "../../../components/title/Title.tsx";
import Text from "../../../ui/typography/Text.tsx";
import LinkTo from "../../../ui/link/LinkTo.tsx";
import Button from "../../../ui/button/Button.tsx";
import Skeleton from "../../../ui/skeleton/Skeleton.tsx";
import Box from "../../../ui/box/Box.tsx";

export interface DataProtectionResponseLetterProps {
  page: DataProtectionResponsePage;
  onSent: (responseType?: DataProtectionResponsePage) => void;
}

export function DataProtectionResponseLetter({ onSent }: DataProtectionResponseLetterProps) {
  const { getDataForResponsePage } = useDataProtectionHook();
  const { uuid, object_id } = useParams();
  const [response, setResponse] = useState<ApiDataProtectionResponsePayload>({
    object_id: object_id ?? "",
    uuid: uuid ?? "",
    comment: "",
  });
  const accept = useDataProtectionAccept(response);
  const decline = useDataProtectionDecline(response);
  const { data, isPending: dataIsLoading } = useDataProtectionConsentData(uuid);

  const onSubmit = (responseType: DataProtectionResponsePage) => {
    setResponse({ ...response, comment: "" });

    switch (responseType) {
      case DataProtectionResponsePage.accept:
        accept.mutate();
        break;
      case DataProtectionResponsePage.decline:
        decline.mutate();
        break;
    }

    onSent(responseType);

    const responseData = getDataForResponsePage(responseType);

    analyticsService.trackEvent(analyticEvents.dataProtection.responseReceived, {
      [analyticProperties.actionType]: responseData.analyticsPage,
      [analyticProperties.id]: object_id,
    });
  };

  const isPending = accept.isPending || decline.isPending;

  return (
    <Stack gap={"md"}>
      {dataIsLoading ? (
        <Skeleton className={"h-8 w-full"} />
      ) : (
        <>
          <Stack gap={"sm"}>
            <Title header={data?.header ?? ""} />
            <Text>{data?.text1}</Text>
          </Stack>
          <hr />
          <Stack gap={"sm"}>
            <Text className={"font-semibold"}>{data?.text2}</Text>
            <div dangerouslySetInnerHTML={{ __html: data?.text3 ?? "" }}></div>
          </Stack>
          <Stack gap={"sm"}>
            <Text>{data?.text4}</Text>
            <div dangerouslySetInnerHTML={{ __html: data?.email_template ?? "" }}></div>
          </Stack>
          <Text>{data?.text5}</Text>
          <hr />
          <Stack direction={"horizontal"} justify={"between"} gap={"sm"}>
            <Button
              variant={"secondary"}
              onClick={() => {
                window.open(data?.privacy_policy_link);
              }}
            >
              {data?.button_text1}
            </Button>
            <Stack gap={"sm"} direction={"horizontal"}>
              <Button
                variant={"plain"}
                isLoading={isPending}
                onClick={() => {
                  onSubmit(DataProtectionResponsePage.decline);
                }}
              >
                {data?.button_text2}
              </Button>
              <Button
                variant={"primary"}
                isLoading={isPending}
                onClick={() => {
                  onSubmit(DataProtectionResponsePage.accept);
                }}
              >
                {data?.button_text3}
              </Button>
            </Stack>
          </Stack>
          <hr />
          <Box>
            <Text>{data?.text6}</Text>&nbsp;
            <LinkTo to={data?.delete_link ?? ""}>{data?.text7}</LinkTo>
          </Box>
        </>
      )}
    </Stack>
  );
}
