import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Table from "../../../../ui/table/Table.tsx";
import Button from "../../../../ui/button/Button.tsx";
import FontAwesomeIcon from "../../../../ui/typography/FontAwesomeIcon.tsx";
import Stack from "../../../../ui/stack/Stack.tsx";
import useModals from "../../../../ui/modal/modal.store.ts";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu.tsx";
import EmptyStateNothingFound from "../../../../components/empty-state/EmptyStateNothingFound.tsx";
import EmptyState from "../../../../components/empty-state/EmptyState.tsx";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../../services/analytics-service.ts";
import { SourceEditModal } from "./widgets/SourceEditModal.tsx";
import { ApiSource, SourceTypes } from "../../../../types/recruit/source.types.ts";
import { Page } from "../../../../components/page/Page.tsx";
import Text from "../../../../ui/typography/Text.tsx";
import { useSourceDelete, useSources } from "../../../../queries/use-sources.query.ts";
import { useEffectOnce } from "react-use";

export default function Sources() {
  const { t } = useTranslation();

  const modalsService = useModals();
  const { data: sources, isLoading, isFetching } = useSources();
  const sourceDelete = useSourceDelete();

  const handleEditOrCreate = useCallback((source: ApiSource | undefined = undefined) => {
    const sourceToEdit = source || {
      id: 0,
      title: "",
      source_type: SourceTypes.custom,
      datetime_created_at: new Date(),
    };
    modalsService.openModal(SourceEditModal, {
      source: sourceToEdit,
    });
  }, []);

  const handleDelete = useCallback((source: ApiSource) => {
    sourceDelete.mutate(source.id, {
      onSuccess: () => {
        analyticsService.trackEvent(analyticEvents.sources.deleted, {
          [analyticProperties.id]: source.id,
        });
      },
    });
  }, []);

  const columnHelper = createColumnHelper<ApiSource>();
  const columns = useMemo<ColumnDef<ApiSource>[]>(
    () => [
      {
        header: t("common:title"),
        cell: (row) => <Text className={"text-title"}>{row.row.original.title}</Text>,
        accessorKey: "title",
        size: "auto" as unknown as number,
      },
      columnHelper.display({
        id: "actions",
        cell: (cell) =>
          cell.row.original.source_type == SourceTypes.custom && (
            <Stack className={"justify-end"} gap={"sm"} direction={"horizontal"}>
              <Button
                onClick={() => {
                  handleEditOrCreate(cell.row.original);
                }}
                variant={"secondary"}
              >
                {t("common:edit")}
              </Button>
              <DropdownMenu>
                <Button
                  onClick={() => {
                    handleDelete(cell.row.original);
                  }}
                  leftIcon={<FontAwesomeIcon icon={"fa-light fa-trash"} />}
                  variant={"menu"}
                >
                  {t("common:delete")}
                </Button>
              </DropdownMenu>
            </Stack>
          ),
        size: 100,
      }),
    ],
    [sources]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.sources.viewed);
  });

  return (
    <>
      <Page>
        <Page.Header showBack={true} title={t("ats:sources")}>
          <Stack>
            <Button
              onClick={() => {
                handleEditOrCreate();
              }}
              leftIcon={<FontAwesomeIcon icon="fa-light fa-plus" />}
            >
              {t("common:create_new")}
            </Button>
          </Stack>
        </Page.Header>
        <Page.Content>
          <Table
            data={sources || []}
            columns={columns}
            showSkeleton={isLoading}
            isFetching={isFetching || sourceDelete.isPending}
            notFoundComponent={<EmptyStateNothingFound />}
            emptyStateComponent={<EmptyState />}
          />
        </Page.Content>
      </Page>
    </>
  );
}
