import { TabListProps } from "./tabs.types.ts";
import { useTheme } from "../themes/provider.tsx";
import { twMerge } from "tailwind-merge";

export const TabList = ({ children, size = "md" }: TabListProps) => {
  const theme = useTheme("tabs");
  return (
    <div
      aria-label="Tabs"
      role="tablist"
      className={twMerge(theme.tabList.base, theme.tabList.variants.size[size])}
    >
      {children}
    </div>
  );
};
