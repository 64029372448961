import Stack from "../../ui/stack/Stack.tsx";
import Avatar from "../../ui/avatar/Avatar.tsx";
import Text from "../../ui/typography/Text.tsx";
import Box from "../../ui/box/Box.tsx";
import { MouseEventHandler } from "react";
import { twMerge } from "tailwind-merge";
import { CheckBox } from "../../ui/checkbox/CheckBox.tsx";

export interface PersonalItemProps {
  avatarUrl?: string;
  primaryText: string;
  text2?: string;
  text3?: string;
  className?: string;
  id?: number;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
  isChecked?: boolean;
  selectViewIsVisible?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  warning?: boolean;
}

export const PersonalItem = ({
  avatarUrl,
  primaryText,
  text2,
  text3,
  onClick,
  onSelect,
  selected,
  selectViewIsVisible,
  warning = false,
  className,
}: PersonalItemProps) => {
  return (
    <Box
      className={twMerge(
        "pr-6 h-[5.5rem] flex-shrink-0 py-3.5 ",
        selectViewIsVisible ? "pl-0" : "pl-6",
        className
      )}
      onClick={(e) => {
        if (selectViewIsVisible) {
          onSelect?.(!selected);
        } else {
          onClick?.(e);
        }
      }}
    >
      <Stack direction={"horizontal"} justify={"between"}>
        <Stack className={"overflow-hidden"} direction={"horizontal"} gap={"smd"} items={"start"}>
          {selectViewIsVisible && (
            <Box className={"pl-6 pt-2.5"}>
              <CheckBox checked={selected} readOnly />
            </Box>
          )}
          <Box className={"mt-1 relative"}>
            <Avatar size={"md"} url={avatarUrl} acronym={primaryText} />
            {onSelect && (
              <Box
                className={twMerge(
                  "pointer rounded-lg absolute inset-0 flex justify-center items-center opacity-0 transition-opacity bg-dark/50 group",
                  selectViewIsVisible ? "opacity-0" : "hover:bg-dark/80 hover:opacity-100 "
                )}
                onClick={(event) => {
                  onSelect(!selected);
                  event.stopPropagation();
                }}
              >
                <Box
                  className={twMerge(
                    "leading-4 h-4 w-4 flex justify-center items-center " +
                      "transition-opacity group-hover:opacity-100",
                    selected ? "opacity-100" : "opacity-0"
                  )}
                >
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={"w-full h-full align-middle text-light"}
                  >
                    <path
                      d="M3.87424 6.53892L3.87457 6.53924C4.15279 6.8132 4.59793 6.81173 4.87436 6.53595L4.87439 6.53592L9.93895 1.48069L9.93949 1.48015C10.2172 1.20017 10.2172 0.746738 9.93949 0.466756C9.66125 0.18619 9.20968 0.186081 8.93131 0.466429C8.9312 0.466538 8.93109 0.466647 8.93098 0.466756L4.37472 5.00648L2.06607 2.73476C1.78767 2.45477 1.33637 2.455 1.05825 2.73545C0.780585 3.01543 0.780585 3.46886 1.05825 3.74884L1.05824 3.74884L1.05918 3.74977L3.87424 6.53892Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.3"
                    />
                  </svg>
                </Box>
              </Box>
            )}
          </Box>
          <Stack className={"overflow-hidden"}>
            <Text className={"text-title overflow-hidden overflow-ellipsis whitespace-nowrap"}>
              {primaryText}
            </Text>

            {text2 && (
              <Text
                className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}
              >
                {text2}
              </Text>
            )}
            {text3 && (
              <Text
                className={"text-secondary overflow-hidden overflow-ellipsis whitespace-nowrap"}
              >
                {text3}
              </Text>
            )}
          </Stack>
        </Stack>
        {warning && (
          <Box className={"w-2 h-2 bg-warning rounded-full flex flex-shrink-0 mt-1"}></Box>
        )}
      </Stack>
    </Box>
  );
};
