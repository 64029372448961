import { api } from "./axios.ts";
import { ApiOperationResult } from "../types/common.types.ts";
import {
  ApiTimeOffAccrualsFilter,
  ApiTimeOffAdjustmentPayload,
  ApiTimeOffCategory,
  ApiTimeOffCategoryPayload,
  ApiTimeOffLog,
  ApiTimeOffPolicy,
  ApiTimeOffPolicyPayload,
} from "../types/time-off.types.ts";
import { ApiPagedData } from "../types/filter.types.ts";

export const apiTimeOffers = {
  getAll: async function () {
    return api.get<ApiTimeOffCategory[]>("/api/v1/time-offs/categories");
  },
  update: async function (id: number, payload: ApiTimeOffCategoryPayload) {
    return api.put<ApiTimeOffCategory>(`/api/v1/time-offs/categories/${id}`, payload);
  },
  create: async function (payload: ApiTimeOffCategoryPayload) {
    return api.post<ApiTimeOffCategory>("/api/v1/time-offs/categories", payload);
  },
  deleteCategory: async function (id: number) {
    return api.delete<ApiOperationResult>(`/api/v1/time-offs/categories/${id}`);
  },
  getCategoryById: async function (id: number) {
    return api.get<ApiTimeOffCategory>(`/api/v1/time-offs/categories/${id}`);
  },
  getPolicyById: async function (id: number) {
    return api.get<ApiTimeOffPolicy>(`/api/v1/time-offs/policies/${id}`);
  },
  createPolicy: async function (payload: ApiTimeOffPolicyPayload) {
    return api.post<ApiTimeOffPolicy>("/api/v1/time-offs/policies", payload);
  },
  updatePolicy: async function (id: number, payload: ApiTimeOffPolicyPayload) {
    return api.put<ApiTimeOffPolicy>(`/api/v1/time-offs/policies/${id}`, payload);
  },
  deletePolicy: async function (id: number) {
    return api.delete<ApiOperationResult>(`/api/v1/time-offs/policies/${id}`);
  },
  accrualsSearch: async function (filter: ApiTimeOffAccrualsFilter, signal?: AbortSignal) {
    return api.post<ApiPagedData<ApiTimeOffLog>>(
      `/api/v1/time-offs/accruals/search`,
      filter,
      signal
    );
  },
  createAdjustment: async function (payload: ApiTimeOffAdjustmentPayload) {
    return api.post<ApiOperationResult>("/api/v1/time-offs/adjustment", payload);
  },
  updateAdjustment: async function (id: number, payload: ApiTimeOffAdjustmentPayload) {
    return api.put<ApiOperationResult>(`/api/v1/time-offs/adjustment/${id}`, payload);
  },
  deleteAdjustment: async function (id: number) {
    return api.delete<ApiOperationResult>(`/api/v1/time-offs/adjustment/${id}`);
  },
};
