import { Page } from "../../../components/page/Page.tsx";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Chart, ChartNodeData } from "../../../components/chart/Chart.tsx";
import { useEmployeeStore } from "../../../stores/employee.store.ts";
import { useEffectOnce } from "react-use";
import analyticsService, { analyticEvents } from "../../../services/analytics-service.ts";
import { useSearchParams } from "react-router-dom";
import { ApiDepartment, DepartmentNodeMode } from "../../../types/department.types.ts";
import useModals from "../../../ui/modal/modal.store.ts";
import { DepartmentViewModal } from "../widgets/DepartmentViewModal.tsx";
import useDepartmentsChartHook from "../../../hooks/use-departments-chart.hook.ts";

export function DepartmentsChart() {
  const { t } = useTranslation();
  const { employee } = useEmployeeStore();
  const [chart, setChart] = useState<ChartNodeData<ApiDepartment>[]>([]);
  const [searchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<Set<string>>(new Set([]));
  const { openModal } = useModals();
  const { renderCaption, renderExtra, loadDepartmentsChart, loadDepartmentsChartByNodeId } =
    useDepartmentsChartHook();

  useEffect(() => {
    const initializeChart = async () => {
      const departmentIdParam = searchParams.get("department_id");
      const departmentId = departmentIdParam ? Number(departmentIdParam) : undefined;
      const departments = await loadDepartmentsChart(
        departmentId,
        departmentId ? DepartmentNodeMode.parent : DepartmentNodeMode.children
      );

      if (departmentId) {
        const opened: string[] = [];
        const current: ApiDepartment | undefined = departments?.find(
          (dep) => dep.id === departmentId
        );

        opened.push(`${departmentId}`);
        current && current.parent_id && opened.push(`${current.parent_id}`);

        setExpanded(new Set(opened));

        if (!current?.parent_id) {
          const rootItems = await loadDepartmentsChart(undefined);

          rootItems && appendDepartmentsToChart(rootItems);
        }
      }

      departments && appendDepartmentsToChart(departments);
    };

    initializeChart();
  }, [searchParams]);

  const appendDepartmentsToChart = useCallback(
    (departments: ApiDepartment[]) => {
      setChart((prevChart) => {
        const newEntitiesIds: Set<number | undefined> = new Set(
          departments.map((item) => item?.id)
        );
        const nodes: ChartNodeData<ApiDepartment>[] = [];

        /* root and similar nodes delete from old data */
        const prev = [...prevChart]
          .filter((item) => item.original && !newEntitiesIds.has(item.original.id))
          .map((item) => item.original);

        const mergedItems = [...prev, ...departments];
        const mergedIds: Set<number | undefined> = new Set(mergedItems.map((item) => item?.id));
        let needRoot = false;
        let countRootElements = 0;

        /* collect data for next step for*/
        mergedItems.forEach((chartItem) => {
          if (!chartItem) return;

          const parentIdNumber: number | null = chartItem.parent_id ?? null;

          if (!parentIdNumber) {
            needRoot = true;
            countRootElements++;
          }
        });

        mergedItems.forEach((chartItem) => {
          if (!chartItem) return;

          const parentIdNumber: number | null = chartItem.parent_id ?? null;

          let parentId: string | null = parentIdNumber ? `${parentIdNumber}` : "root";
          let isExpandUpNode = false;

          if (parentIdNumber && !mergedIds.has(parentIdNumber) && !needRoot) {
            parentId = null;
            isExpandUpNode = true;
          }

          nodes.push({
            title: chartItem.title,
            caption: renderCaption(chartItem),
            extra: renderExtra(chartItem),
            imgUrl: chartItem.manager?.avatar_url ?? null,
            id: `${chartItem.id}`,
            parentId: parentId,
            count: chartItem.direct_subordinates_count,
            isRoot: false,
            isExpandUpNode,
            original: chartItem,
          });
        });

        if (needRoot) {
          nodes.push({
            title: employee?.organization?.title ?? t("common:company"),
            imgUrl: employee?.organization?.logo_url ?? "/favicon.svg",
            id: "root",
            parentId: null,
            isExpandUpNode: false,
            count: countRootElements,
            isRoot: true,
          });
        }

        return nodes;
      });
    },
    [chart]
  );

  const onNodeClick = useCallback(
    async (nodeId: string) => {
      if (nodeId == "root") return;

      const node = chart.find((item) => item.id == nodeId);

      node && node.original && openModal(DepartmentViewModal, { department: node.original });
    },
    [chart]
  );

  const onExpandClick = useCallback(
    (nodeId: string, needLoadData: boolean) => {
      if (!needLoadData) return;

      loadDepartmentsChartByNodeId(nodeId).then((departments) => {
        departments && appendDepartmentsToChart(departments);
      });
    },
    [chart]
  );

  const onExpandUpClick = useCallback(
    (nodeId: string) => {
      loadDepartmentsChartByNodeId(nodeId, DepartmentNodeMode.parent).then((items) => {
        /* set opened node */
        items.forEach((item) => {
          if (nodeId == `${item.id}` && item.parent_id) {
            setExpanded((prevState) => prevState.add(`${item.parent_id}`));
          }
        });

        items && appendDepartmentsToChart(items);

        /* if we have nodes with parent id = null, w need get another root nodes */
        if (!items.some((item) => !item.parent_id)) return;

        loadDepartmentsChart(undefined).then((rootItems) => {
          rootItems && appendDepartmentsToChart(rootItems);
        });
      });
    },
    [chart]
  );

  useEffectOnce(() => {
    analyticsService.trackEvent(analyticEvents.departments.viewedChart);
  });

  return (
    <Page className={"bg-light/80 h-full"}>
      <Page.Header showBack={true} title={t("core:departments")} />
      {chart.length > 0 && (
        <Chart
          data={chart}
          onNodeClick={onNodeClick}
          expandedSet={expanded}
          onExpandClick={onExpandClick}
          onExpandUpClick={onExpandUpClick}
        />
      )}
    </Page>
  );
}
