import { ForwardedRef, forwardRef } from "react";
import { useAppTheme } from "../../themes/provider.tsx";
import { twMerge } from "tailwind-merge";
import { PageContentProps } from "./page.types.ts";

const PageContent = forwardRef(
  ({ children, className }: PageContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const theme = useAppTheme("page");

    return (
      <div ref={ref} className={twMerge(theme.content, className)}>
        {children}
      </div>
    );
  }
);

export default PageContent;
