import React, { ElementType } from "react";
import { PolymorphicComponentProp } from "../../helpers/generic-as-prop.ts";
import { UIKitThemeBase } from "../themes/theme.types.ts";

export type SkeletonProps<T extends ElementType = "div"> = PolymorphicComponentProp<
  T,
  {
    children?: React.ReactNode;
  }
>;

export interface UIKitSkeletonTheme extends UIKitThemeBase {}

export const skeletonTheme: UIKitSkeletonTheme = {
  base:
    "relative overflow-hidden bg-dark/5 before:absolute before:inset-0 before:-translate-x-full " +
    "before:animate-[shimmer_1.4s_infinite] before:bg-gradient-to-r before:from-transparent before:via-dark/5 " +
    "before:to-transparent rounded shrink-0",
};
