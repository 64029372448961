import { ReactNode } from "react";
import Stack from "../../../ui/stack/Stack.tsx";
import Box from "../../../ui/box/Box.tsx";

export interface FullScreenCardProps {
  children: ReactNode;
}

export const FullScreenCard = ({ children }: FullScreenCardProps) => {
  return (
    <>
      <Box className={"bg-brand-light  h-screen"}>
        <Box className={"absolute left-0 top-0 right-0 h-1/2"}>
          <img
            src={"/backgrounds/clouds.jpeg"}
            alt={"background"}
            className={"w-full h-full object-cover"}
          />
        </Box>
        <main className={"h-full relative z-20 overflow-auto"}>
          <Stack className="items-center justify-between mx-auto sm:h-screen z-10">
            <div></div>
            {children}
            <div></div>
          </Stack>
        </main>
      </Box>
    </>
  );
};
