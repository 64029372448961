import Stack from "../../../../../ui/stack/Stack.tsx";
import { Title } from "../../../../../components/title/Title.tsx";
import Box from "../../../../../ui/box/Box.tsx";
import { Controller, UseFormReturn } from "react-hook-form";
import { Switch } from "../../../../../ui/switch/Switch.tsx";
import { Input } from "../../../../../ui/input/Input.tsx";
import { useTranslation } from "react-i18next";
import { TimeOffCategoryPolicySchema } from "../TimeOffCategoryPolicyEdit.tsx";

export interface TimeOffPolicyLimitsProps {
  formHook: UseFormReturn<TimeOffCategoryPolicySchema>;
}

export function TimeOffPolicyLimits({ formHook }: TimeOffPolicyLimitsProps) {
  const { t } = useTranslation();
  const { register, control, watch } = formHook;
  const dailyAmountEnabled = watch("daily_amount_enabled");
  const requestTotalEnabled = watch("request_total_enabled");
  const noticePeriodEnabled = watch("notice_period_enabled");

  return (
    <Stack gap={"sm"}>
      <Title header={t("common:limits")} size={"xs"} className={"text-dark/60"} />

      <Box variant={"border"}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Switch
              checked={value}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              size={"sm"}
              title={t("time_off:limits_negative_balance_allowance_title")}
              description={t("time_off:limits_negative_balance_allowance_caption")}
            />
          )}
          control={control}
          name={"limits_negative_balance_allowed"}
        />
      </Box>

      <Box variant={"border"}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Switch
              checked={value}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              size={"sm"}
              title={t("time_off:minimum_daily_amount_title")}
              description={t("time_off:minimum_daily_amount_caption")}
            />
          )}
          control={control}
          name={"daily_amount_enabled"}
        />

        {dailyAmountEnabled && (
          <Input
            className={"w-1/2 ml-9"}
            type={"number"}
            label={t("time_off:min_amount")}
            {...register("min_daily_amount")}
          />
        )}
      </Box>

      <Box variant={"border"}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Switch
              checked={value}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              size={"sm"}
              title={t("time_off:request_amount_title")}
              description={t("time_off:request_amount_caption")}
            />
          )}
          control={control}
          name={"request_total_enabled"}
        />

        {requestTotalEnabled && (
          <>
            <Input
              className={"w-1/2 ml-9"}
              type={"number"}
              label={t("time_off:min_amount")}
              {...register("min_request_total")}
            />
            <Input
              className={"w-1/2 ml-9"}
              type={"number"}
              label={t("time_off:max_amount")}
              {...register("max_request_total")}
            />
          </>
        )}
      </Box>

      <Box variant={"border"}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Switch
              checked={value}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              size={"sm"}
              title={t("time_off:notice_period_title")}
              description={t("time_off:notice_period_caption")}
            />
          )}
          control={control}
          name={"notice_period_enabled"}
        />

        {noticePeriodEnabled && (
          <>
            <Input
              className={"w-1/2 ml-9"}
              type={"number"}
              label={t("time_off:min_amount")}
              {...register("min_notice_period")}
            />

            <Input
              className={"w-1/2 ml-9"}
              type={"number"}
              label={t("time_off:max_amount")}
              {...register("max_notice_period")}
            />
          </>
        )}
      </Box>
    </Stack>
  );
}
