import {
  Children,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { TabItem } from "./TabItem.tsx";
import { TabEventProps, TabItemProps, TabsProps, TabsRef } from "./tabs.types.ts";
import { useTheme } from "../themes/provider.tsx";
import TabButton from "./TabButton.tsx";
import { TabList } from "./TabList.tsx";

const TabComponent = forwardRef<TabsRef, TabsProps>(
  (
    { children, className, size = "md", onActiveTabChange, isStretch = true, ...props },
    ref: ForwardedRef<TabsRef>
  ) => {
    const id = useId();
    const tabs = useMemo(
      () =>
        Children.map(
          Children.toArray(children) as ReactElement<PropsWithChildren<TabItemProps>>[],
          ({ props }) => props
        ),
      [children]
    );
    const tabRefs = useRef<HTMLButtonElement[]>([]);
    const [activeTab, setActiveTab] = useState(
      Math.max(
        0,
        tabs.findIndex((tab) => tab.active)
      )
    );
    const [focusedTab, setFocusedTab] = useState(-1);

    const setActiveTabWithCallback = (activeTab: number) => {
      setActiveTab(activeTab);
      if (onActiveTabChange) onActiveTabChange(activeTab);
    };

    const handleClick = ({ target }: TabEventProps): void => {
      setActiveTabWithCallback(target);
      setFocusedTab(target);
    };

    const theme = useTheme("tabs");
    return (
      <div className={twMerge(theme.base, className)} ref={ref}>
        <TabList size={size} {...props}>
          {tabs.map((tab, index) => (
            <TabButton
              size={size}
              key={index}
              type="button"
              aria-controls={`${id}-tabpanel-${index}`}
              aria-selected={index === activeTab}
              isActive={index === activeTab}
              disabled={tab.disabled}
              id={`${id}-tab-${index}`}
              onClick={() => handleClick({ target: index })}
              // onKeyDown={(event) => handleKeyboard({ event, target: index })}
              ref={(element) => (tabRefs.current[index] = element as HTMLButtonElement)}
              tabIndex={index === focusedTab ? 0 : -1}
              title={tab.title}
              isStretch={isStretch}
            />
          ))}
        </TabList>

        <div className={twMerge(theme.tabItemContainer.base)}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              aria-labelledby={`${id}-tab-${index}`}
              hidden={index !== activeTab}
              id={`${id}-tabpanel-${index}`}
              role="tabpanel"
              tabIndex={0}
            >
              {index == activeTab && tab.children}
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export const Tabs = Object.assign(TabComponent, {
  Item: TabItem,
  List: TabList,
  Button: TabButton,
});
