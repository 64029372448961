import {
  ApiDataProtectionResponsePayload,
  DataProtectionResponsePage,
} from "../../../types/data-protection.types.ts";
import useDataProtectionHook from "../../../hooks/use-data-protection.hook.ts";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  useDataProtectionDeleteRequest,
  useDataProtectionEditRequest,
} from "../../../queries/use-data-protection.query.ts";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import analyticsService, {
  analyticEvents,
  analyticProperties,
} from "../../../services/analytics-service.ts";
import Stack from "../../../ui/stack/Stack.tsx";
import { Title } from "../../../components/title/Title.tsx";
import Text from "../../../ui/typography/Text.tsx";
import { Textarea } from "../../../ui/textarea/Textarea.tsx";
import { ServerErrorField } from "../../../hooks/use-error-handle.hook.tsx";
import Button from "../../../ui/button/Button.tsx";

export interface DataProtectionResponseFormProps {
  page: DataProtectionResponsePage;
  onSent: () => void;
}

export function DataProtectionResponseForm({ page, onSent }: DataProtectionResponseFormProps) {
  const { getDataForResponsePage } = useDataProtectionHook();
  const pageData = getDataForResponsePage(page);
  const { t } = useTranslation();
  const { uuid, object_id } = useParams();
  const [response, setResponse] = useState<ApiDataProtectionResponsePayload>({
    object_id: object_id ?? "",
    uuid: uuid ?? "",
    comment: "",
  });

  const editRequest = useDataProtectionEditRequest(response);
  const deleteRequest = useDataProtectionDeleteRequest(response);
  const DataProtectionAnswerSchemaObject = yup.object({
    comment: yup.string(),
  });

  interface DataProtectionAnswerSchema
    extends yup.InferType<typeof DataProtectionAnswerSchemaObject> {
    comment?: string;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DataProtectionAnswerSchema>({
    mode: "onSubmit",
    resolver: yupResolver(DataProtectionAnswerSchemaObject),
    defaultValues: {
      comment: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    setResponse({ ...response, comment: data.comment ?? "" });

    switch (page) {
      case DataProtectionResponsePage.editRequest:
        editRequest.mutate();
        break;
      case DataProtectionResponsePage.deleteRequest:
        deleteRequest.mutate();
        break;
    }

    onSent();

    analyticsService.trackEvent(analyticEvents.dataProtection.responseReceived, {
      [analyticProperties.actionType]: pageData.analyticsPage,
      [analyticProperties.id]: object_id,
    });
  });

  const isPending = editRequest.isPending || deleteRequest.isPending;

  return (
    <form className="" onSubmit={onSubmit}>
      <Stack gap={"xl"}>
        <Stack gap={"sm"}>
          <Title header={pageData.title} />
          <Text>{pageData.description}</Text>
        </Stack>
        <Textarea
          label={t("common:message")}
          {...register("comment")}
          error={errors.comment?.message}
        />
        <ServerErrorField errors={errors} />
        <Button
          type={"submit"}
          size="lg"
          onClick={onSubmit}
          className="w-full"
          isLoading={isPending}
        >
          {pageData.btn}
        </Button>
      </Stack>
    </form>
  );
}
